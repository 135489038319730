//Tempo
export const SET_TIME = "SET_TIME";

//THEME
export const SET_THEME = "SET_THEME";

//Fazenda
export const SET_FAZENDA = "SET_FAZENDA";
export const SET_LIST_FAZENDA = "SET_LIST_FAZENDA";
export const SET_TALHAO = "SET_TALHAO";
export const SET_TALHAO_TABLET = "SET_TALHAO_TABLET";
export const SET_LIST_TALHAO_TABLET = "SET_LIST_TALHAO_TABLET";
export const SET_FILTRO_TALHAO = "SET_FILTRO_TALHAO";

//Equipamento
export const SET_EQUIPAMENTO = "SET_EQUIPAMENTO";
export const SET_LIST_EQUIPAMENTO = "SET_LIST_EQUIPAMENTO";
export const SET_EQUIPAMENTO_NULL = "SET_EQUIPAMENTO_NULL";
export const SET_ONE_EQUIPAMENTO = "SET_ONE_EQUIPAMENTO";

//Antena
export const SET_ANTENA = "SET_ANTENA";
export const SET_LIST_ANTENA = "SET_LIST_ANTENA";
export const SET_LIST_ANTENA_CONFIG = "SET_LIST_ANTENA_CONFIG";

//Servidor
export const SET_SERVIDOR = "SET_SERVIDOR";
export const SET_LIST_SERVIDOR = "SET_LIST_SERVIDOR";

//Tipo de Parada
export const SET_TIPOPARADA = "SET_TIPOPARADA";
export const SET_LIST_TIPOPARADA = "SET_LIST_TIPOPARADA";

//Operação
export const SET_OPERACAO = "SET_OPERACAO";
export const SET_LIST_OPERACAO = "SET_LIST_OPERACAO";
export const SET_FILTER_OPERACAO = "SET_FILTER_OPERACAO";
export const SET_OPERACAO_BBOX = "SET_OPERACAO_BBOX";
export const SET_REGISTRO_OPERACAO = "SET_REGISTRO_OPERACAO";

//Alertas
export const SET_ALERTA = "SET_ALERTA";
export const SET_LIST_ALERTA = "SET_LIST_ALERTA";
export const SET_RENDER_ALERTA = "SET_RENDER_ALERTA";

export const SET_ALERTA_USUARIO = "SET_ALERTA_USUARIO";
export const SET_LIST_ALERTA_USUARIO = "SET_LIST_ALERTA_USUARIO";

//Operador
export const SET_OPERADOR = "SET_OPERADOR";
export const SET_LIST_OPERADOR = "SET_LIST_OPERADOR";

//Cultura
export const SET_CULTURA = "SET_CULTURA";
export const SET_LIST_CULTURA = "SET_LIST_CULTURA";
export const SET_CULTURA_CLIENTE = "SET_CULTURA_CLIENTE";
export const SET_LIST_CULTURA_CLIENTE = "SET_LIST_CULTURA_CLIENTE";

//Safra
export const SET_SAFRA = "SET_SAFRA";
export const SET_LIST_SAFRA = "SET_LIST_SAFRA";
export const SET_SAFRA_CLIENTE = "SET_SAFRA_CLIENTE";
export const SET_LIST_SAFRA_CLIENTE = "SET_LIST_SAFRA_CLIENTE";

//Tipo de operação
export const SET_TIPOOP = "SET_TIPOOP";
export const SET_TIPOOP_PARADA = "SET_TIPOOP_PARADA";
export const SET_LIST_TIPOOP = "SET_LIST_TIPOOP";
export const SET_TIPOOP_CLIENTE = "SET_TIPOOP_CLIENTE";
export const SET_LIST_TIPOOP_CLIENTE = "SET_LIST_TIPOOP_CLIENTE";

//Testando o Thunk
export const TESTE_ASYNC_SUCCESS = "TESTE_ASYNC_SUCCESS";
export const TESTE_ASYNC_FAILURE = "TESTE_ASYNC_FAILURE";
export const TESTE_ASYNC_START = "TESTE_ASYNC_START";

//Menu

//Process
export const SET_PROCESS = "SET_PROCESS";
export const DESATIVAR_PROCESS = "DESATIVAR_PROCESS";
export const SET_PREFERENCIA = "SET_PREFERENCIA";
export const SET_DASHBOARD_TOGGLE = "SET_DASHBOARD_TOGGLE";

//MapReducer
export const SET_COORDINATES = "SET_COORDINATES";
export const SET_REFRESH = "SET_REFRESH";
export const SET_FOCUS = "SET_FOCUS";
export const SET_EQUIPA_POLYGON = "SET_EQUIPA_POLYGON";
export const PUSH_POLYGON = "PUSH_POLYGON";
export const REM_POLYGONS = "REM_POLYGONS";
export const SET_OVERLAY_EQUIPA = "SET_OVERLAY_EQUIPA";
export const PUSH_OVERLAY_EQUIPA = "PUSH_OVERLAY_EQUIPA";
export const REM_OVERLAY_EQUIPA = "REM_OVERLAY_EQUIPA";
export const SET_OVERLAY_OPERACIONAL = "SET_OVERLAY_OPERACIONAL";
export const PUSH_OVERLAY_OPERACIONAL = "PUSH_OVERLAY_OPERACIONAL";
export const REM_OVERLAY_OPERACIONAL = "REM_OVERLAY_OPERACIONAL";
export const SET_LIST_POLYLINE = "SET_LIST_POLYLINE";
export const PUSH_FILTRO_POLYLINE = "PUSH_FILTRO_POLYLINE";
export const REM_FILTRO_POLYLINE = "REM_FILTRO_POLYLINE";
export const SET_FILTRO_POLYLINE = "SET_FILTRO_POLYLINE";
export const PUSH_POLYLINE_RENDER = "PUSH_POLYLINE_RENDER";
export const REM_POLYLINE_RENDER = "REM_POLYLINE_RENDER";

//Markers
export const SET_MARKERS = "SET_MARKERS";
export const PUSH_MARKER = "PUSH_MARKER";
export const REM_MARKERS = "REM_MARKERS";
export const PUSH_ALL_MARKERS = "PUSH_ALL_MARKERS";
export const REM_ALL_MARKERS = "REM_ALL_MARKERS";
export const SET_NOTIFY = "SET_NOTIFY";
export const PUSH_NOTIFY = "PUSH_NOTIFY";
export const REM_NOTIFY = "REM_NOTIFY";
export const SET_PARADA = "SET_PARADA";
export const PUSH_PARADA = "PUSH_PARADA";
export const REM_PARADA = "REM_PARADA";
export const SET_MARKER_ANTENA = "SET_MARKER_ANTENA";
export const PUSH_MARKER_ANTENA = "PUSH_MARKER_ANTENA";
export const REM_MARKER_ANTENA = "REM_MARKER_ANTENA";
export const PUSH_FLAG_MARKER = "PUSH_FLAG_MARKER";
export const REM_FLAG_MARKER = "REM_FLAG_MARKER";

//Login
export const LOGIN_PROCESS_SUCCESS = "LOGIN_PROCESS_SUCESS";
export const LOGIN_PROCESS_LOGOUT = "LOGIN_PROCESS_LOGOUT";
export const LOGIN_PROCESS_TOKEN_EXPIRED = "LOGIN_PROCESS_TOKEN_EXPIRED";

//Loader
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_NULL = "SET_NULL";

//Upload Avatar
export const SET_UPLOAD_AVATAR_START = "SET_UPLOAD_AVATAR_START";
export const SET_UPLOAD_AVATAR_ERROR = "SET_UPLOAD_AVATAR_ERROR";
export const SET_UPLOAD_AVATAR_SUCCESS = "SET_UPLOAD_AVATAR_SUCCESS";
export const SET_UPLOAD_AVATAR_NULL = "SET_UPLOAD_AVATAR_NULL";
// ATENÇÃO, TODO, ADAPTAR A ENTIDADE AVATAR PARA O NOVO PADRÃO

//Mensagem
export const SET_LIST_MENSAGEM = "SET_LIST_MENSAGEM";

//RELATÓRIO
export const SET_RELATORIO = "SET_RELATORIO";
export const SET_REL_OPERACIONAL = "SET_REL_OPERACIONAL";
export const SET_TAB_OPERACIONAL = "SET_TAB_OPERACIONAL";
export const SET_REL_ONE = "SET_REL_ONE";

//Keep Alive - conexão
export const SET_KEEP_ALIVE = "SET_KEEP_ALIVE";

//Usuario
export const SET_USUARIO = "SET_USUARIO";
export const SET_LIST_USUARIO = "SET_LIST_USUARIO";

//Implemento
export const SET_IMPLEMENTO = "SET_IMPLEMENTO";
export const SET_LIST_IMPLEMENTO = "SET_LIST_IMPLEMENTO";

//Parâmetro de Consumo
export const SET_PARAMETRO = "SET_PARAMETRO";
export const SET_LIST_PARAMETRO = "SET_LIST_PARAMETRO";
