import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
    IRegOpeParadaModule,
    IRegistroOpeModule,
} from "../../../../reducers/OperacaoReducer";
import { ColumnDef } from "@tanstack/react-table";
import { segParaHoras } from "../../../../config/helpers";
import TabelaAlertaParada from "./TabelaAlertaParada";

export interface IListaAlertaParadaProps {
    setOpenModal: Function;
    cont: any;
    tipoModal: any;
}
function ListaAlertaParada(props: IListaAlertaParadaProps) {
    const [dataParadas, setDataParadas] = useState<any>([]);

    const colunasParada = useMemo<ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "data_inicial",
                id: "data_inicial",
                header: "Inicio Parada",
                cell: (info: any) => {
                    let newDate = info.getValue().split("T")[0];
                    let arrDate = newDate.split("-");
                    return (
                        arrDate[2] +
                        "/" +
                        arrDate[1] +
                        "/" +
                        arrDate[0] +
                        " " +
                        info.getValue().split("T")[1]
                    );
                },
                enableGrouping: false,
            },
            {
                accessorKey: "data_final",
                id: "data_final",
                header: "Final Parada",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Parada Não Finalizada";
                    } else {
                        let newDate = info.getValue().split("T")[0];
                        let arrDate = newDate.split("-");
                        return (
                            arrDate[2] +
                            "/" +
                            arrDate[1] +
                            "/" +
                            arrDate[0] +
                            " " +
                            info.getValue().split("T")[1]
                        );
                    }
                },
                enableGrouping: false,
            },
            {
                accessorKey: "duracao",
                id: "duracao",
                header: "Duração Parada",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Parada Não Finalizada";
                    } else {
                        return (
                            segParaHoras(info.getValue(), "horas").h +
                            "h" +
                            segParaHoras(info.getValue(), "horas").m +
                            "m" +
                            segParaHoras(info.getValue(), "horas").s +
                            "s"
                        );
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Parada Não Finalizada";
                    } else {
                        return (
                            segParaHoras(getValue(), "horas").h +
                            "h" +
                            segParaHoras(getValue(), "horas").m +
                            "m" +
                            segParaHoras(getValue(), "horas").s +
                            "s"
                        );
                    }
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "consumo",
                id: "consumo",
                header: "Consumo Parado",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "";
                    } else return info.getValue().toFixed(2) + "L";
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Parada Não Finalizada";
                    } else return getValue().toFixed(2) + "L";
                },
                enableGrouping: false,
                aggregationFn: "sum",
            },
            {
                accessorKey: "motivo",
                id: "motivo",
                header: "Motivo Parada",
                cell: (info: any) => info.getValue(),
            },
        ],
        []
    );
    const colunasAlerta = useMemo<ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "data_hora",
                id: "data_hora",
                header: "Data/Hora Alerta",
                cell: (info: any) => {
                    let newDate = info.getValue().split("T")[0];
                    let arrDate = newDate.split("-");
                    return (
                        arrDate[2] +
                        "/" +
                        arrDate[1] +
                        "/" +
                        arrDate[0] +
                        " " +
                        info.getValue().split("T")[1]
                    );
                },
                enableGrouping: false,
            },
            {
                accessorKey: "txtalerta",
                id: "txtalerta",
                header: "Motivo Alerta",
                cell: (info: any) => info.getValue().split("-")[1],
            },
        ],
        []
    );

    useEffect(() => {
        let arrParadas: any = [];
        if (props.cont) {
            if (props.tipoModal === 0) {
                if (props.cont.registro_operacao_parada !== undefined) {
                    if (props.cont.registro_operacao_parada?.length > 0) {
                        arrParadas.push(
                            ...props.cont.registro_operacao_parada.map(
                                (a: IRegOpeParadaModule) => {
                                    return {
                                        motivo: a.tipoOperacao_parada
                                            ?.descricao,
                                        ...a,
                                    };
                                }
                            )
                        );
                    }
                }
            }
        }

        setDataParadas(arrParadas);
    }, [props]);

    return (
        <div>
            <TabelaAlertaParada
                columns={props.tipoModal === 0 ? colunasParada : colunasAlerta}
                data={
                    props.tipoModal === 0
                        ? dataParadas
                        : props.cont.registro_operacao_alerta
                }
            />
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaAlertaParada);
