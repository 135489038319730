import { Empty, Form, Select, Button, Popover, Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTipoOp } from "../../store/tipoOpActions";
import { setFocus } from "../../store/actions";
import { setSafra } from "../../store/safraActions";
import { setCultura } from "../../store/culturaActions";
import { ativarProcess } from "../../store/actions";
import { IEquipamento } from "../../reducers/EquipamentoReducer";
import { IFazenda } from "../../reducers/FazendaReducer";
import { ISafra, ISafraList } from "../../reducers/SafraReducer";
import { ICultura, ICulturaList } from "../../reducers/CulturaReducer";
import { ITipoOp, ITipoOpList } from "../../reducers/TipoOpReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { IStore } from "../../store";
import { IProcessList } from "../../reducers/ProcessReducer";
import { IPreferencia } from "../../reducers/PreferenciaReducer";
import { IStatusList } from "../../store/types";
import { getEquipamentoListFiltro } from "../../store/polylineAction";
import { ITime } from "../../reducers/TimeReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import FazendaController from "./FazendaController";
import TalhaoController from "./TalhaoController";
import EquipamentoController from "./EquipamentoController";
import TipoOpController from "./TipoOpController";
import CulturaController from "./CulturaController";
import SafraController from "./SafraController";
import OperadorController from "./OperadorController";
import { IOperador } from "../../reducers/OperadorReducer";
import TempoController from "./TempoController";
// import {
//     setTipoOp,
//     ativarProcess,
//     setFocus,
//     setCultura,
//     setSafra,
// } from "../../actions";
const { Option } = Select;
export interface IFiltroControllerProps {
    fazenda: IFazenda;
    safras: ISafraList;
    culturas: ICulturaList;
    tipoOps: ITipoOpList;
    menuState: IMenu;
    processListState: IProcessList;
    setTipoOp: Function;
    ativarProcess: Function;
    setCultura: Function;
    setFocus: Function;
    setSafra: Function;
    prefs: IPreferencia;
    loader: IStatusList;
    getEquipamentoListFiltro: Function;
    time: ITime;
    tema: ITheme;
    operador: IOperador;
}
function FiltroController(props: IFiltroControllerProps) {
    const {
        fazenda,
        setFocus,
        ativarProcess,
        menuState,
        processListState,
        tipoOps,
        setTipoOp,
        culturas,
        setCultura,
        safras,
        setSafra,
        prefs,
        loader,
        time,
        getEquipamentoListFiltro,
        operador,
    } = props;
    const [form] = Form.useForm();

    function handleChangeTipoOp(value: any) {
        if (value === "all") {
            setFocus({ view: 7, subview: null });
            setTipoOp({});
        } else {
            setFocus({ view: 7, subview: null });
            setTipoOp(
                tipoOps[
                    tipoOps.findIndex(
                        (t: ITipoOp) => t.id_tipo_operacao === value
                    )
                ]
            );
        }

        // ativarProcess({
        //     menuItem: menuState[menuState.findIndex(m => m.id === 6)],
        //     processList: processListState,
        // });
        // setFocus(3);
    }
    function handleChangeCultura(value: any) {
        if (value === "all") {
            setFocus({ view: 7, subview: null });
            setCultura({});
        } else {
            setFocus({ view: 7, subview: null });
            setCultura(
                culturas[
                    culturas.findIndex((c: ICultura) => c.id_cultura === value)
                ]
            );
        }

        // ativarProcess({
        //     menuItem: menuState[menuState.findIndex(m => m.id === 6)],
        //     processList: processListState,
        // });
        // setFocus(3);
    }
    function handleChangeSafra(value: any) {
        if (value === "all") {
            setFocus({ view: 7, subview: null });
            setSafra({});
        } else {
            setFocus({ view: 7, subview: null });
            setSafra(
                safras[safras.findIndex((s: ISafra) => s.id_safra === value)]
            );
        }
        // ativarProcess({
        //     menuItem: menuState[menuState.findIndex(m => m.id === 6)],
        //     processList: processListState,
        // });
        // setFocus(3);
    }

    useEffect(() => {
        if (loader.loader?.get?.success) {
            if (Object.keys(prefs).findIndex((p: any) => p === "safra") > -1) {
                form.setFieldsValue({ safra: prefs.safra });
            } else {
                form.setFieldsValue({ safra: "all" });
            }
            if (
                Object.keys(prefs).findIndex((p: any) => p === "cultura") > -1
            ) {
                form.setFieldsValue({ cultura: prefs.cultura });
            } else {
                form.setFieldsValue({ cultura: "all" });
            }
            if (Object.keys(prefs).findIndex((p: any) => p === "tipoOp") > -1) {
                form.setFieldsValue({ tipoOp: prefs.tipoOp });
            } else {
                form.setFieldsValue({ tipoOp: "all" });
            }
            if (operador.id_operador !== undefined) {
                form.setFieldsValue({ operador: operador.id_operador });
            } else {
                form.setFieldsValue({ operador: "all" });
            }
        }
    }, [prefs]);

    return (
        <>
            <Form
                form={form}
                layout="horizontal"
                style={{
                    display: "flex",
                    padding: "4px",
                    marginBottom: "-20px",
                    flexDirection: "column",
                    // textAlign: "left",
                    // alignItems: "flex-end",
                }}
            >
                <div className="tipoOp-controller-popover">
                    <TipoOpController />
                </div>
                <div className="cultura-controller-popover">
                    <CulturaController />
                </div>
                <div className="safra-controller-popover">
                    <SafraController />
                </div>
                <div className="operador-controller-popover">
                    <OperadorController />
                </div>
            </Form>
            <div className="fazenda-controller-popover">
                <FazendaController tema={props.tema} />
            </div>
            <div className="talhao-controller-popover">
                <TalhaoController tema={props.tema} />
            </div>
            <div className="equipamento-controller-popover">
                <EquipamentoController tema={props.tema} />
            </div>
            <div className="tempo-controller-popover">
                <TempoController tema={props.tema} />
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        tipoOps: store.tiposOp,
        menuState: store.menu,
        processListState: store.processos,
        safras: store.safras,
        culturas: store.culturas,
        prefs: store.preferencia,
        loader: store.loader,
        time: store.time,
        operador: store.operador,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setTipoOp,
            setCultura,
            setSafra,
            ativarProcess,
            setFocus,
            getEquipamentoListFiltro,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltroController);
