import { Input, Form, Button, Col, Row, Switch } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { setStatusNull } from "../../../../store/actions";
import { adicionarOperador } from "../../../../store/operadorAction";
import { IStatusList } from "../../../../store/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

interface AdicionarOperadoresProps {
    adicionarOperador: Function;
    status: IStatusList;
    setModal?: any;
    setStatusNull: any;
}
function AdicionarOperadores(props: AdicionarOperadoresProps) {
    const { adicionarOperador, status, setModal, setStatusNull } = props;
    const [validoNome, setValidoNome] = useState(false);
    const [validoSenha, setValidoSenha] = useState(false);

    const [estado, setEstado] = useState(true);

    const handleDisable = useCallback(() => {
        if (validoNome === true && validoSenha === true) {
            return false;
        }
        return true;
    }, [validoSenha, validoNome]);

    useEffect(() => {
        if (status.operador?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "operador");
        }
    });

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    let obj = {
                        nome: v.nome,
                        senha: v.senha,
                        ativo: estado,
                    };
                    adicionarOperador(obj);
                    props.setModal(false);
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome"
                        >
                            <Input placeholder="Digite o nome do operador" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque uma senha",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject()
                                            );
                                        }
                                        if (isNaN(value)) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject(
                                                    "Use apenas numeros"
                                                )
                                            );
                                        }
                                        if (value.length !== 4) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject(
                                                    "A senha deve conter 4 digitos"
                                                )
                                            );
                                        }

                                        setValidoSenha(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Senha:"
                            name="senha"
                        >
                            <Input.Password
                                maxLength={4}
                                placeholder="Digite a senha do operador"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item name="ativo" label="">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "15px",
                                    alignItems: "baseline",
                                }}
                            >
                                <div>
                                    <Switch
                                        checked={estado}
                                        onChange={(x: any) => setEstado(x)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </div>
                                <div
                                    onClick={() => setEstado(!estado)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Ativo
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={status.operador?.add?.loading}
                    >
                        Salvar operador
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendaList: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarOperador,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdicionarOperadores);
