import { Button, Col, Form, Input, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../../reducers/EquipamentoReducer";
import {
    IImplemento,
    IImplementoList,
} from "../../../../reducers/ImplementoReducer";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import { IStore } from "../../../../store";
import { v4 as uuidv4 } from "uuid";
import { IStatusList } from "../../../../store/types";
import { editarParametro } from "../../../../store/parametrosActions";
import { setStatusNull } from "../../../../store/actions";

const { Option, OptGroup } = Select;

export interface IEditarParametroProps {
    setModal: any;
    cont: any;
    status: IStatusList;

    equipamentos: IEquipamentoList;
    tiposOp: ITipoOpList;
    implementos: IImplementoList;

    setStatusNull: any;
    editarParametro: Function;

    tipoOpCList: ITipoOpClienteList;
}

function EditarParametro(props: IEditarParametroProps) {
    const {
        equipamentos,
        tiposOp,
        implementos,
        editarParametro,
        setStatusNull,
        status,

        tipoOpCList,
    } = props;

    const [implem, setImplem] = useState("");
    const [tipoOp, setTipoOp] = useState("");

    const [equipaSelec, setEquipaSelec] = useState(true);
    const [numero, setNumero] = useState(true);
    const [media, setMedia] = useState(0);

    const [haDesloc, setHaDesloc] = useState(false);
    const [haOpe, setHaOpe] = useState(false);

    const [form] = Form.useForm();

    const filteredOpeOptions = tiposOp.filter((o: any) =>
        tipoOpCList.some(
            (s: any) => s.tipo_operacao_temp === o.id_tipo_operacao
        )
    );

    const handleDisable = useCallback(() => {
        if (numero === true && equipaSelec === true) {
            return false;
        }
        return true;
    }, [numero, equipaSelec]);

    function handleChangeMedia(value: any) {
        setMedia(parseFloat(value));
        form.setFieldsValue({ media: parseFloat(value) });
    }

    function handleChangeImplem(value: any) {
        setImplem(value);
    }

    function handleChangeOp(value: any) {
        setTipoOp(value);
    }

    useEffect(() => {
        if (status.parametro?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "parametro");
        }
    }, [status.parametro?.edit?.success]);

    useEffect(() => {
        if (filteredOpeOptions.length > 0) {
            filteredOpeOptions.forEach((o: any) => {
                if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                    setHaOpe(true);
                } else if (
                    o.id_tipo_operacao >= 900 &&
                    o.id_tipo_operacao <= 999
                ) {
                    setHaDesloc(true);
                }
            });
        }
    }, [filteredOpeOptions, tipoOpCList]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={(v: any) => {
                    editarParametro(v);
                    //props.setModal(false);
                }}
                initialValues={{
                    ...props.cont,
                    implemento_temp: props.cont.implemento_temp
                        ? props.cont.implemento_temp
                        : implem,
                    tipo_operacao_temp: props.cont.tipo_operacao_temp
                        ? props.cont.tipo_operacao_temp
                        : tipoOp,
                }}
            >
                <Form.Item
                    name="id_parametro_consumo"
                    style={{ display: "none" }}
                >
                    <Input type="hidden" name="alerta_id"></Input>
                </Form.Item>
                <Row gutter={[24, 0]}>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um equipamento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setEquipaSelec(false),
                                                Promise.reject()
                                            );
                                        }
                                        setEquipaSelec(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Equipamento:"
                            name="equipamento_temp"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um equipamento"
                                //onChange={handleChangeEquipa}
                            >
                                {/* <Option value="">Todos</Option> */}
                                {equipamentos.map((e: IEquipamento) => {
                                    return (
                                        <Option
                                            key={uuidv4()}
                                            value={e.id_equipamento}
                                        >
                                            {e.numero_patrimonio +
                                                " -" +
                                                e.descricao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "implemento_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um implemento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === undefined) {
                                            return Promise.reject();
                                        }
                                        if (value === "") {
                                            return Promise.resolve();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Implemento:"
                            name="implemento_temp"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um implemento"
                                onChange={handleChangeImplem}
                            >
                                <Option value="">Todos</Option>
                                {implementos.map((e: IImplemento) => {
                                    return (
                                        <Option
                                            key={uuidv4()}
                                            value={e.id_implemento}
                                        >
                                            {e.descricao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "tipo_operacao_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um tipo de operação",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === "") {
                                            return Promise.resolve();
                                        } else {
                                            if (value === undefined) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            label="Tipo de Operação:"
                            name="tipo_operacao_temp"
                        >
                            <Select
                                showSearch
                                filterOption={(inputValue, option: any) => {
                                    let condicao = false;

                                    if (option.children) {
                                        return option.children
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    } else if (option.label) {
                                        return option.label
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    }

                                    return condicao;
                                }}
                                allowClear={true}
                                placeholder="Selecione um tipo de operação"
                                onChange={handleChangeOp}
                            >
                                <Option value="">Todos</Option>
                                <OptGroup label="Tipo Operação">
                                    {haOpe ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao > 0 &&
                                                        e.id_tipo_operacao <
                                                            800 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhuma op.
                                                    cadastrada.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                                <OptGroup label="Tipo Deslocamento">
                                    {haDesloc ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao >=
                                                        900 &&
                                                        e.id_tipo_operacao <=
                                                            999 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhum deslocamento
                                                    cadastrado.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite a média de litros/hora",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setNumero(false),
                                                Promise.reject()
                                            );
                                        }
                                        setNumero(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Média litros/hora:"
                            name="media"
                        >
                            <Input
                                type="number"
                                placeholder="Litros/Hora"
                                addonAfter={"L/h"}
                                min={0}
                                step={0.01}
                                onChange={(e: any) =>
                                    handleChangeMedia(e.target.value)
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={handleDisable()}
                            loading={status.parametro?.add?.loading}
                        >
                            Salvar parâmetro
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        tiposOp: store.tiposOp,
        implementos: store.implementos,
        status: store.loader,

        tipoOpCList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setStatusNull,
            editarParametro,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarParametro);
