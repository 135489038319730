import { Input, Form, Button, Col, Row, Switch, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { setStatusNull } from "../../../../store/actions";
import { adicionarOperador } from "../../../../store/operadorAction";
import { IStatusList, ITalhaoTablet } from "../../../../store/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { Option } from "antd/lib/mentions";
import { adicionarTalhao } from "../../../../store/talhaoActions";

interface AdicionarTalhaoProps {
    fazendas: IFazendaList;
    adicionarTalhao: Function;
    status: IStatusList;
    setModal?: any;
    setStatusNull: any;

    fazendaSelec: any;
}
function AdicionarTalhao(props: AdicionarTalhaoProps) {
    const { adicionarTalhao, status, setModal, setStatusNull, fazendas } =
        props;
    const [validoNome, setValidoNome] = useState(false);
    const [validoTalhao, setValidoTalhao] = useState(
        props.fazendaSelec ? true : false
    );

    const [estado, setEstado] = useState(true);

    const handleDisable = useCallback(() => {
        if (validoNome === true && validoTalhao === true) {
            return false;
        }
        return true;
    }, [validoTalhao, validoNome]);

    useEffect(() => {
        if (status.talhao_tablet?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "talhao_tablet");
        }
    });

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    adicionarTalhao(v);
                    props.setModal(false);
                }}
                initialValues={{ fazenda_temp: props.fazendaSelec }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome_talhao"
                        >
                            <Input placeholder="Digite o nome do talhao" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione uma Fazenda",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoTalhao(false),
                                                Promise.reject()
                                            );
                                        }

                                        setValidoTalhao(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fazenda:"
                            name="fazenda_temp"
                        >
                            <Select placeholder="Selecione uma fazenda">
                                {fazendas.map(f => (
                                    <Option
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={status.talhao_tablet?.add?.loading}
                    >
                        Salvar Talhão
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendas: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarTalhao,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarTalhao);
