import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IFazenda } from "../../reducers/FazendaReducer";
import { IOperador, IOperadorList } from "../../reducers/OperadorReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import { IStore } from "../../store";
import { IStatusList } from "../../store/types";
import { setOperador } from "../../store/operadorAction";
import { setFocus } from "../../store/actions";
import { Form, Select, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect } from "react";

const { Option } = Select;

interface IOperadorController {
    fazenda: IFazenda;
    operadores: IOperadorList;
    operador: IOperador;

    loader: IStatusList;
    tema: ITheme;

    setOperador: Function;
    setFocus: Function;
}

function OperadorController(props: IOperadorController) {
    const {
        fazenda,
        operadores,
        operador,
        setOperador,
        tema,
        setFocus,
        loader,
    } = props;

    const [form] = Form.useForm();

    function handleChangeOperador(value: any) {
        if (value === "all") {
            setFocus({ view: 7, subview: null });
            setOperador({});
        } else if (value === "naoIdentificado") {
            setFocus({ view: 7, subview: null });
            setOperador({ id_operador: 0 });
        } else {
            setFocus({ view: 7, subview: null });
            setOperador(
                operadores[
                    operadores.findIndex(
                        (c: IOperador) => c.id_operador === value
                    )
                ]
            );
        }
    }

    const opFilter = useCallback(() => {
        return operadores.filter((o: IOperador) => {
            if (o.ativo) {
                return o;
            }
        });
    }, [operadores]);

    useEffect(() => {
        if (operador.id_operador !== undefined) {
            form.setFieldsValue({ operador: operador.id_operador });
        } else {
            form.setFieldsValue({ operador: "all" });
        }
    }, [operador]);

    return (
        <>
            <Form>
                <Form.Item
                    name="operador"
                    label={
                        <span
                            className={
                                props.tema
                                    ? "icon-filtros-dark"
                                    : "icon-filtros"
                            }
                        >
                            <Tooltip title="Operador">
                                <i className="fas fa-id-badge"></i>{" "}
                            </Tooltip>
                        </span>
                    }
                    colon={false}
                >
                    <Select
                        style={{
                            width: 200,
                            color: tema ? "#fff" : "#606060",
                            border: "1px solid #C1C1C1",
                            backgroundColor: tema ? "#757575" : "",
                        }}
                        size="small"
                        disabled={fazenda.id_fazenda === ""}
                        bordered={false}
                        placeholder="Escolha"
                        suffixIcon={<i className="fas fa-chevron-right"></i>}
                        onChange={handleChangeOperador}
                        onSelect={handleChangeOperador}
                        dropdownClassName="agrochip-dropdown"
                    >
                        <Option value="all" label="Todos" key={uuidv4()}>
                            <div
                                style={{
                                    display: "flex",

                                    flexDirection: "column",
                                }}
                            >
                                <div>Todas os operadores</div>
                            </div>
                        </Option>
                        {opFilter().map((e: IOperador) => {
                            return (
                                <Option
                                    value={e.id_operador}
                                    key={e.id_operador}
                                >
                                    {e.nome}
                                </Option>
                            );
                        })}
                        <Option
                            value="naoIdentificado"
                            label="Não Identificado"
                            key={uuidv4()}
                        >
                            <div
                                style={{
                                    display: "flex",

                                    flexDirection: "column",
                                }}
                            >
                                <div>Não Identificado</div>
                            </div>
                        </Option>
                    </Select>
                </Form.Item>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        operadores: store.operadores,
        operador: store.operador,
        loader: store.loader,
        tema: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setOperador,
            setFocus,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(OperadorController);
