import {
    SET_TIPOOP,
    SET_LIST_TIPOOP,
    SET_TIPOOP_CLIENTE,
    SET_LIST_TIPOOP_CLIENTE,
    SET_TIPOOP_PARADA,
} from "../store/actionTypes";

export interface ITipoOpModule {
    id_tipo_operacao?: number;
    descricao?: string;
}

export type ITipoOp = ITipoOpModule;

export type ITipoOpList = ITipoOpModule[];

export interface ITipoOpAction {
    type: any;
    payload: ITipoOp;
}

export interface ISetListTipoOpAction {
    type: any;
    payload: ITipoOpList;
}

const TipoOp = {} as ITipoOp;
const TipoOpList = [] as ITipoOpList;

export const tipoOpReducer = (state = TipoOp, action: ITipoOpAction) => {
    switch (action.type) {
        case SET_TIPOOP:
            return action.payload;
        default:
            return state;
    }
};

export const tipoOpListReducer = (
    state = TipoOpList,
    action: ISetListTipoOpAction
) => {
    switch (action.type) {
        case SET_LIST_TIPOOP:
            return action.payload;
        default:
            return state;
    }
};

const TipoOpParada = {} as ITipoOp;

export const tipoOpParadaReducer = (
    state = TipoOpParada,
    action: ITipoOpAction
) => {
    switch (action.type) {
        case SET_TIPOOP_PARADA:
            return action.payload;
        default:
            return state;
    }
};

export interface ITipoOpClienteModule {
    id_tipo_operacao_cliente?: string;
    tipo_operacao_temp?: number;
    tempo_alerta_parada?: number;
}

export type ITipoOpCliente = ITipoOpClienteModule;

export type ITipoOpClienteList = ITipoOpClienteModule[];

export interface ITipoOpClienteAction {
    type: any;
    payload: string;
}

export interface ISetListTipoOpClienteAction {
    type: any;
    payload: [];
}

const TipoOpCliente: ITipoOpCliente = {};

const TipoOpClienteList: ITipoOpClienteList = [];

export const tipoOpClienteReducer = (
    state = TipoOpCliente,
    action: ITipoOpClienteAction
) => {
    switch (action.type) {
        case SET_TIPOOP_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};

export const tipoOpClienteListReducer = (
    state = TipoOpClienteList,
    action: ITipoOpClienteAction
) => {
    switch (action.type) {
        case SET_LIST_TIPOOP_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};
