import {
    //LOADER
    SET_LOADING,
    SET_ERROR,
    SET_SUCCESS,
    SET_NULL,

    //LOGIN
    LOGIN_PROCESS_SUCCESS,
    LOGIN_PROCESS_LOGOUT,
    LOGIN_PROCESS_TOKEN_EXPIRED,


    //MAPA
    SET_REFRESH,
    SET_COORDINATES,
    SET_FOCUS,
    SET_EQUIPA_POLYGON,
    PUSH_POLYGON,
    REM_POLYGONS,
    SET_OVERLAY_EQUIPA,
    PUSH_OVERLAY_EQUIPA,
    REM_OVERLAY_EQUIPA,

    //PROCESS
    SET_PROCESS,
    DESATIVAR_PROCESS,
    SET_DASHBOARD_TOGGLE,

    //MARKERS
    SET_MARKERS,
    PUSH_MARKER,
    REM_MARKERS,
    SET_NOTIFY,
    PUSH_NOTIFY,
    REM_NOTIFY,
    SET_MARKER_ANTENA,
    PUSH_MARKER_ANTENA,
    REM_MARKER_ANTENA,
    PUSH_FLAG_MARKER,
    REM_FLAG_MARKER,

    //TIME
    SET_TIME,

    //OPERAÇÃO
    PUSH_ALL_MARKERS,
    REM_ALL_MARKERS,

    //KEEP ALIVE
    SET_KEEP_ALIVE,
    SET_PARADA,
    REM_PARADA,
    PUSH_PARADA,
} from "./actionTypes";

import { persistor } from ".";
import { IProcessModule } from "../reducers/ProcessReducer";
import { IMarkerModule } from "../reducers/MarkerReducer";
import { ITime } from "../reducers/TimeReducer";
import {
    IEquipaPolygonModule,
    IFoco,
    IMapCoord,
    IOverlayEquipaModule,
} from "./types";
import { Dispatch } from "redux";
import { IKeepAlive } from "../reducers/StatusReducer";

//Métodos de Status para o LOADER
export const setStatusStart = (method: string, entity: string) => ({
    type: SET_LOADING,
    method: method,
    entity: entity,
});

export const setStatusError = (
    method: string,
    entity: string,
    error: string
) => ({
    type: SET_ERROR,
    method: method,
    entity: entity,
    error: error,
});

export const setStatusSuccess = (method: string, entity: string) => ({
    type: SET_SUCCESS,
    method: method,
    entity: entity,
});

export const setStatusNull = (method: string, entity: string) => ({
    type: SET_NULL,
    method: method,
    entity: entity,
});

export const logoutReset = (value: any) => {
    return async (dispatch: any) => {
        dispatch({ type: "RESET" });
        await persistor.purge();
        await persistor.persist();
    };
};
/////////////////////////////////////////

//Métodos de estados para o processo de AUTENTICAÇÃO

export const loginProcessSuccess = (value: any) => ({
    type: LOGIN_PROCESS_SUCCESS,
    payload: value,
});

export const loginProcessLogOut = () => ({
    type: LOGIN_PROCESS_LOGOUT,
});

export const loginProcessTokenExpired = () => ({
    type: LOGIN_PROCESS_TOKEN_EXPIRED,
});

export const logou = (token: any) => {
   return (dispatch: any) => {
    fetch(`${process.env.REACT_APP_BASE_URL_API}api/log/`, {
        method: "post",
        headers: {
            "Content-Type": "text/plain",
            Authorization: "Bearer " + token,
        },
    })
        .then((response: any) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(res => {
            if(res.erro) {
                console.log(res.erro);
            } else {
                console.log(res)
            }
        })
        .catch(error => {
            console.log(error);
        });
   }
};

export const loginProcess = (value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "auth"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}login`, {
            method: "post",
            headers: {
                "Content-Type": "text/plain",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "auth", res.erro));
                } else {
                    dispatch(
                        loginProcessSuccess({
                            token: res.access_token,
                            user: {
                                id: res.id_usuario,
                                nome: res.nomeUsuario,
                                email: res.email,
                            },
                        })
                    );
                    dispatch(logou(res.access_token))                       
                    
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "auth", error.data));
            });
    };
};

/////////////////////////////////////////////////////

//Métodos de manutenção do MAPA

export const setRefresh = (value: string) => ({
    type: SET_REFRESH,
    v: value,
});
export const setCoordinates = (value: IMapCoord) => ({
    type: SET_COORDINATES,
    coord: value,
});
export const setFocus = (value: IFoco) => ({
    type: SET_FOCUS,
    payload: value,
});
export const setEquipaPolygon = (value: IEquipaPolygonModule[]) => ({
    type: SET_EQUIPA_POLYGON,
    payload: value,
});

export const pushPolygon = (value: IEquipaPolygonModule) => ({
    type: PUSH_POLYGON,
    objeto: value,
});

export const remPolygons = (value: any) => ({
    type: REM_POLYGONS,
    objeto: value,
});

export const setOverlayEquipa = (value: IOverlayEquipaModule[]) => ({
    type: SET_OVERLAY_EQUIPA,
    payload: value,
});

export const pushOverlayEquipa = (value: IOverlayEquipaModule) => ({
    type: PUSH_OVERLAY_EQUIPA,
    objeto: value,
});

export const remOverlayEquipa = (value: any) => ({
    type: REM_OVERLAY_EQUIPA,
    objeto: value,
});

/////////////////////////////////////////

//Métodos para administração de PROCESSOS

export const setProcess = (value: IProcessModule) => ({
    type: SET_PROCESS,
    payload: value,
});

export const ativarProcess = (value: any) => {
    return (dispatch: Dispatch) => {
        if (value.menuItem.process === null) {
        } else {
            let wProcess = value.processList.findIndex(
                (m: any) => m.id === value.menuItem.process
            );
            if (wProcess < 0) {
            } else {
                dispatch(setProcess(value.processList[wProcess]));
            }
        }
    };
};

export const desativarProcess = (value: IProcessModule) => ({
    type: DESATIVAR_PROCESS,
    payload: value,
});

export const setDashboardToggle = (value: string) => ({
    type: SET_DASHBOARD_TOGGLE,
    payload: value,
});

/////////////////////////////////////////

//Métodos para administração dos MARCADORES

export const setMarkers = (value: IMarkerModule[]) => ({
    type: SET_MARKERS,
    objeto: value,
});
export const remMarkers = (value: any) => ({
    type: REM_MARKERS,
    objeto: value,
});
export const pushMarker = (value: IMarkerModule) => ({
    type: PUSH_MARKER,
    objeto: value,
});
export const pushAllMarkers = (value: IMarkerModule) => ({
    type: PUSH_ALL_MARKERS,
    objeto: value,
});
export const remAllMarkers = (value: any) => ({
    type: REM_ALL_MARKERS,
    objeto: value,
});
export const setNotify = (value: IMarkerModule[]) => ({
    type: SET_NOTIFY,
    objeto: value,
});
export const remNotify = (value: any) => ({
    type: REM_NOTIFY,
    objeto: value,
});
export const pushNotify = (value: IMarkerModule) => ({
    type: PUSH_NOTIFY,
    objeto: value,
});

export const setParada = (value: IMarkerModule[]) => ({
    type: SET_PARADA,
    objeto: value,
});
export const remParada = (value: any) => ({
    type: REM_PARADA,
    objeto: value,
});
export const pushParada = (value: IMarkerModule) => ({
    type: PUSH_PARADA,
    objeto: value,
});

export const setMarkerAntena = (value: IMarkerModule[]) => ({
    type: SET_MARKER_ANTENA,
    payload: value,
});

export const pushMarkerAntena = (value: IMarkerModule) => ({
    type: PUSH_MARKER_ANTENA,
    objeto: value,
});

export const remMarkerAntena = (value: any) => ({
    type: REM_MARKER_ANTENA,
    objeto: value,
});

export const pushFlagMarker = (value: IMarkerModule) => ({
    type: PUSH_FLAG_MARKER,
    objeto: value,
});

export const remFlagMarker = (value: any) => ({
    type: REM_FLAG_MARKER,
    objeto: value,
});

/////////////////////////////////////////////////////

//Métodos para o tempo

export const setTime = (payload: ITime) => ({
    type: SET_TIME,
    payload: payload,
});

//////////////////////////////////////////////////////

//Métodos para Keep Alive

export const setKeepAlive = (payload: IKeepAlive) => ({
    type: SET_KEEP_ALIVE,
    payload: payload,
});

export const getKeepAlive = () => {
    return async (dispatch: any) => {
        try {
            const keepAlive = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}api/status/`,
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }
            );

            if (keepAlive.status === 401 || keepAlive.status === 403) {
                dispatch(loginProcessTokenExpired());
            }

            //

            const keepAliveJSON = JSON.parse(await keepAlive.text());

            if (keepAliveJSON.status === "Ok") {
                dispatch(setKeepAlive(true));
            }

            //
        } catch (e: any) {
            //
            if (e.message === "Failed to fetch") {
                dispatch(setKeepAlive(false));
            }
        }
    };
};
