import { Button, Col, Form, Input, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { v4 as uuidv4 } from "uuid";
import MultiToggle from "react-multi-toggle";
import { IStatusList } from "../../../../store/types";
import { setStatusNull } from "../../../../store/actions";
import { editarImplemento } from "../../../../store/implementoActions";

const { Option } = Select;

export interface IEditarImplementoProps {
    setModal: any;
    cont: any;

    status: IStatusList;

    estados: any[];
    tipos: any[];

    setStatusNull: any;
    editarImplemento: Function;
}

function EditarImplemento(props: IEditarImplementoProps) {
    const { estados, tipos, status, setStatusNull, editarImplemento } = props;
    const [descr, setDescr] = useState(true);
    const [numero, setNumero] = useState(true);
    const [tipo, setTipo] = useState(true);
    const [estado, setEstado] = useState(props.cont.estado);
    const [largura, setLargura] = useState(props.cont.largura);

    const [form] = Form.useForm();

    const handleDisable = useCallback(() => {
        if (
            descr === true &&
            tipo === true &&
            numero === true &&
            estado !== null
        ) {
            return false;
        }
        return true;
    }, [descr, numero, tipo, estado]);

    function onChange(value: any) {
        setEstado(parseInt(value));
        form.setFieldsValue({ estado: parseInt(value) });
    }
    function handleChangeLarg(value: any) {
        setLargura(parseFloat(value));
        form.setFieldsValue({ largura: parseFloat(value) });
    }

    useEffect(() => {
        if (status.implemento?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "implemento");
        }
    });

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(v: any) => {
                editarImplemento(v);
                props.setModal(false);
            }}
            initialValues={{
                ...props.cont,
                estado: parseInt(props.cont.estado),
                tipo: parseInt(props.cont.tipo),
            }}
        >
            <Form.Item name="id_implemento" style={{ display: "none" }}>
                <Input type="hidden" name="id_implemento"></Input>
            </Form.Item>
            <Form.Item name="cod_erp" style={{ display: "none" }}>
                <Input type="hidden" name="cod_erp"></Input>
            </Form.Item>
            <Row gutter={[24, 0]}>
                <Col span={10}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Digite uma descrição",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return (
                                            setDescr(false), Promise.reject()
                                        );
                                    }
                                    setDescr(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        label="Descrição do implemento:"
                        name="descricao"
                    >
                        <Input placeholder="Digite uma descrição" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Digite a largura",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return (
                                            setNumero(false), Promise.reject()
                                        );
                                    }
                                    setNumero(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        label="Largura:"
                        name="largura"
                    >
                        <Input
                            type="number"
                            placeholder="Largura"
                            addonAfter={"m"}
                            min={0}
                            step={0.01}
                            onChange={(e: any) =>
                                handleChangeLarg(e.target.value)
                            }
                        />
                        {/* <InputNumber
                        min={0}
                        formatter={value => converterM(value)}
                        parser={val => currencyParser(val)}
                        controls={false}
                        style={{
                            width: "100%",
                        }}
                    /> */}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Digite o nº do patrimônio",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return (
                                            setNumero(false), Promise.reject()
                                        );
                                    }
                                    setNumero(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        label="Nº do patrimônio:"
                        name="numero_patrimonio"
                    >
                        <Input placeholder="Digite o nº do patrimônio" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={10}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Selecione um tipo",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return setTipo(false), Promise.reject();
                                    }
                                    setTipo(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        label="Tipo de implemento:"
                        name="tipo"
                    >
                        <Select
                            showSearch
                            style={{
                                width: "100%",
                            }}
                            placeholder="Selecione um tipo..."
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {tipos.map((t: any) => {
                                return (
                                    <Option key={uuidv4()} value={t.id}>
                                        {t.nome}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item label="Estado:" name="estado">
                        <MultiToggle
                            options={estados}
                            selectedOption={estado}
                            onSelectOption={onChange}
                        />
                        <Input type="hidden" name="estado"></Input>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={handleDisable()}
                        loading={status.implemento?.edit?.loading}
                    >
                        Salvar implemento
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        estados: store.attrImplemento.estado,
        tipos: store.attrImplemento.tipos,

        status: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setStatusNull,
            editarImplemento,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarImplemento);
