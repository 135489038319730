import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { adicionarAlerta } from "../../../../store/alertaActions";
import { setStatusNull } from "../../../../store/actions";
import { IStatusList } from "../../../../store/types";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect, useState } from "react";
import { tipos } from "../../../../reducers/OperacaoReducer";
import { criterioAlerta, IAlerta } from "../../../../reducers/AlertaReducer";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../../reducers/EquipamentoReducer";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;

interface AdicionarAlertasProps {
    adicionarAlerta: Function;
    setModal: any;
    status: IStatusList;
    setStatusNull: any;
    tipoEquip: any[];
    equipamentos: IEquipamentoList;
    tiposOp: ITipoOpList;
    alerta: IAlerta;

    tipoOpCList: ITipoOpClienteList;
}

function AdicionarAlerta(props: AdicionarAlertasProps) {
    const {
        adicionarAlerta,
        tipoEquip,
        setStatusNull,
        status,
        equipamentos,
        tiposOp,
        alerta,

        tipoOpCList,
    } = props;

    const [tipoEquipa, setTipoEquipa] = useState(false);
    const [criterio, setCriterio] = useState(false);
    const [tipoAlerta, setTipoAlerta] = useState(false);
    const [habilitaValor, setHabilitaValor] = useState(false);
    const [selecTipoEquip, setSelecTipoEquip] = useState("");

    const [equipa, setEquipa] = useState("");
    const [tipoOp, setTipoOp] = useState("");

    const [exibePortal, setExibePortal] = useState(true);
    const [exibeBordo, setExibeBordo] = useState(false);
    const [exibeWhats, setExibeWhats] = useState(true);

    const [opcoes, setOpcoes] = useState([]);

    const [reqOp, setReqOp] = useState(true);

    const [haDesloc, setHaDesloc] = useState(false);
    const [haOpe, setHaOpe] = useState(false);

    const [form] = Form.useForm();

    const filteredOpeOptions = tiposOp.filter((o: any) =>
        tipoOpCList.some(
            (s: any) => s.tipo_operacao_temp === o.id_tipo_operacao
        )
    );

    const handleDisable = useCallback(() => {
        if (reqOp) {
            if (
                tipoEquipa === true &&
                criterio === true &&
                tipoAlerta === true &&
                (exibePortal === true || exibeWhats === true)
            ) {
                return false;
            }
        } else {
            if (
                tipoEquipa === true &&
                tipoAlerta === true &&
                (exibePortal === true || exibeWhats === true)
            ) {
                return false;
            }
        }

        return true;
    }, [tipoEquipa, criterio, tipoAlerta, exibePortal, exibeWhats, reqOp]);

    function handleChange(value: any) {
        const teste = tipos.findIndex(
            (t: any) => t.value.toUpperCase() === value
        );

        form.setFieldsValue({ criterio: null });
        setReqOp(true);

        let criterios: any = criterioAlerta.filter((c: any) => {
            if (teste !== -1) {
                if (tipos[teste].typeValue === "na") {
                    setReqOp(false);
                    setHabilitaValor(false);
                    form.setFieldsValue({ valor: null });
                    form.setFieldsValue({ criterio: "" });
                    form.setFieldsValue({ exibe_bordo: false });
                } else {
                    if (tipos[teste].typeValue === "boolean") {
                        setHabilitaValor(false);
                        form.setFieldsValue({ valor: null });
                        return c.id === 1 || c.id === 2;
                    } else {
                        setHabilitaValor(true);
                        return c.id === 3 || c.id === 4;
                    }
                }
            }
        });
        setOpcoes(criterios);
    }

    function handleChangeEquipa(value: any) {
        setEquipa(value);
    }

    function handleChangeOp(value: any) {
        setTipoOp(value);
    }

    useEffect(() => {
        if (status.alerta?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "alerta");
        }
    });

    useEffect(() => {
        if (!reqOp) {
            setExibePortal(true);
        }
    }, [reqOp]);

    useEffect(() => {
        if (filteredOpeOptions.length > 0) {
            filteredOpeOptions.forEach((o: any) => {
                if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                    setHaOpe(true);
                } else if (
                    o.id_tipo_operacao >= 900 &&
                    o.id_tipo_operacao <= 999
                ) {
                    setHaDesloc(true);
                }
            });
        }
    }, [filteredOpeOptions, tipoOpCList]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={(v: any) => {
                    adicionarAlerta(v);
                    props.setModal(false);
                }}
                initialValues={{
                    equipamento_temp: equipa,
                    tipo_operacao_temp: tipoOp,
                    exibe_bordo: exibeBordo,
                    exibe_portal: exibePortal,
                    exibe_whatsapp: exibeWhats,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo de equipamento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipoEquipa(false),
                                                Promise.reject()
                                            );
                                        }
                                        setTipoEquipa(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo de Equipamento:"
                            name="tipo_equipamento"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um tipo de equipamento"
                                onChange={v => {
                                    setSelecTipoEquip(v);
                                    form.setFieldsValue({
                                        equipamento_temp: "",
                                        tipo: null,
                                    });
                                }}
                            >
                                {tipoEquip.map((e: any) => {
                                    return (
                                        <Option key={uuidv4()} value={e.id}>
                                            {e.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "equipamento_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um equipamento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === undefined) {
                                            return Promise.reject();
                                        }
                                        if (value === "") {
                                            return Promise.resolve();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Equipamento:"
                            name="equipamento_temp"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um equipamento"
                                onChange={handleChangeEquipa}
                            >
                                <Option value="">Todos</Option>
                                {equipamentos
                                    .filter(
                                        (e: any) =>
                                            e.tipo === selecTipoEquip + ""
                                    )
                                    .map((e: IEquipamento) => {
                                        return (
                                            <Option
                                                key={uuidv4()}
                                                value={e.id_equipamento}
                                            >
                                                {e.numero_patrimonio +
                                                    " -" +
                                                    e.descricao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    {" "}
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo de alerta",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipoAlerta(false),
                                                Promise.reject()
                                            );
                                        }
                                        setTipoAlerta(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo do Alerta:"
                            name="tipo"
                        >
                            <Select
                                placeholder="Selecione um tipo de alerta"
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                                onChange={handleChange}
                            >
                                {tipos
                                    .filter(t => {
                                        if (t.tipoEquip) {
                                            return (
                                                t.tipoEquip ===
                                                Number(selecTipoEquip)
                                            );
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((t: any) => {
                                        return (
                                            <Option
                                                key={uuidv4()}
                                                value={t.value.toUpperCase()}
                                            >
                                                {t.label}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    required: reqOp,
                                    message: "Selecione um critério",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            value === undefined ||
                                            value === null ||
                                            value === ""
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            if (!value) {
                                                return (
                                                    setCriterio(false),
                                                    Promise.reject()
                                                );
                                            }
                                            setCriterio(true);
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Critério:"
                            name="criterio"
                        >
                            <Select
                                disabled={!reqOp}
                                placeholder="Selecione um critério"
                                allowClear={true}
                                onChange={(v: any) => {
                                    if (v === "VERDADEIRO" || v === "FALSO") {
                                        setHabilitaValor(false);
                                        form.setFieldsValue({ valor: null });
                                    } else {
                                        setHabilitaValor(true);
                                    }
                                }}
                            >
                                {opcoes.map((c: any) => {
                                    return (
                                        <Option key={uuidv4()} value={c.nome}>
                                            {c.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: habilitaValor,
                                    message: "Coloque um valor",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            value === undefined ||
                                            value === null
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            if (isNaN(value)) {
                                                return Promise.reject(
                                                    "Use apenas numeros"
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            label="Valor:"
                            name="valor"
                        >
                            <Input
                                disabled={!habilitaValor}
                                placeholder="Digite um valor"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "tipo_operacao_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um tipo de operação",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === "") {
                                            return Promise.resolve();
                                        } else {
                                            if (value === undefined) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            label="Tipo de Operação:"
                            name="tipo_operacao_temp"
                        >
                            <Select
                                disabled={!reqOp}
                                showSearch
                                filterOption={(inputValue, option: any) => {
                                    let condicao = false;

                                    if (option.children) {
                                        return option.children
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    } else if (option.label) {
                                        return option.label
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    }

                                    return condicao;
                                }}
                                allowClear={true}
                                placeholder="Selecione um tipo de operação"
                                onChange={handleChangeOp}
                            >
                                <Option value="">Todos</Option>
                                <OptGroup label="Tipo Operação">
                                    {haOpe ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao > 0 &&
                                                        e.id_tipo_operacao <
                                                            800 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhuma op.
                                                    cadastrada.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                                <OptGroup label="Tipo Deslocamento">
                                    {haDesloc ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao >=
                                                        900 &&
                                                        e.id_tipo_operacao <=
                                                            999 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhum deslocamento
                                                    cadastrado.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div>Exibição do alerta:</div>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item label="" name="exibe_portal">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "5px",
                                            alignItems: "baseline",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                disabled={!reqOp}
                                                defaultChecked
                                                checked={exibePortal}
                                                onChange={(x: any) => {
                                                    setExibePortal(x);
                                                    form.setFieldsValue({
                                                        exibe_portal: x,
                                                    });
                                                }}
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                            />
                                            <Input
                                                type="hidden"
                                                name="exibe_portal"
                                            ></Input>
                                        </div>{" "}
                                        <div
                                            onClick={() =>
                                                setExibePortal(!exibePortal)
                                            }
                                            style={{
                                                pointerEvents: `${
                                                    !reqOp ? "none" : "auto"
                                                }`,
                                                cursor: "pointer",
                                            }}
                                        >
                                            Portal
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}> */}
                            <Form.Item label="" name="exibe_bordo">
                                <div
                                    style={{
                                        display: "none",
                                        flexDirection: "row",
                                        columnGap: "5px",
                                        alignItems: "baseline",
                                        marginTop: "10px",
                                    }}
                                >
                                    <div>
                                        <Switch
                                            disabled={!reqOp}
                                            defaultChecked
                                            checked={exibeBordo}
                                            onChange={(x: any) => {
                                                setExibeBordo(x);
                                                form.setFieldsValue({
                                                    exibe_bordo: x,
                                                });
                                            }}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                        />
                                        <Input
                                            type="hidden"
                                            name="exibe_bordo"
                                        ></Input>
                                    </div>{" "}
                                    <div
                                        onClick={() =>
                                            setExibeBordo(!exibeBordo)
                                        }
                                        style={{
                                            pointerEvents: `${
                                                !reqOp ? "none" : "auto"
                                            }`,
                                            cursor: "pointer",
                                        }}
                                    >
                                        Bordo
                                    </div>
                                </div>
                            </Form.Item>
                            {/* </Col> */}
                            <Col span={12}>
                                <Form.Item label="" name="exibe_whatsapp">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "5px",
                                            alignItems: "baseline",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                defaultChecked
                                                checked={exibeWhats}
                                                onChange={(x: any) => {
                                                    setExibeWhats(x);
                                                    form.setFieldsValue({
                                                        exibe_whatsapp: x,
                                                    });
                                                }}
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                            />
                                            <Input
                                                type="hidden"
                                                name="exibe_whatsapp"
                                            ></Input>
                                        </div>{" "}
                                        <div
                                            onClick={() =>
                                                setExibeWhats(!exibeWhats)
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            WhatsApp
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Selecione uma forma de visualização",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (typeof value !== "number") {
                                            return (
                                                setHabilitaExibicao(false),
                                                Promise.reject()
                                            );
                                        }
                                        setHabilitaExibicao(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Exibição:"
                            name=""
                        >
                            <Select
                                placeholder="Selecione"
                                allowClear={true}
                                onChange={handleExibicao}
                            >
                                {exibir.map((c: any) => {
                                    return (
                                        <Option key={uuidv4()} value={c.valor}>
                                            {c.tipo}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item> */}
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        loading={status.alerta?.add?.loading}
                        block
                    >
                        Salvar Alerta
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        status: store.loader,
        tipoEquip: store.attrEquipamento.tipos,
        tiposOp: store.tiposOp,
        alerta: store.alerta,

        tipoOpCList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ adicionarAlerta, setStatusNull }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarAlerta);
