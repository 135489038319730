import {
    SET_CULTURA,
    SET_CULTURA_CLIENTE,
    SET_LIST_CULTURA,
    SET_LIST_CULTURA_CLIENTE,
} from "../store/actionTypes";

export interface ICulturaModule {
    id_cultura?: number;
    nomeCultura?: string;
    cod_erp?: string;
}

export type ICultura = ICulturaModule;

export type ICulturaList = ICulturaModule[];

export interface ICulturaAction {
    type: any;
    payload: string;
}

export interface ISetListCulturaAction {
    type: any;
    payload: [];
}

const Cultura: ICultura = {};

const CulturaList: ICulturaList = [];

export const culturaReducer = (state = Cultura, action: ICulturaAction) => {
    switch (action.type) {
        case SET_CULTURA:
            return action.payload;
        default:
            return state;
    }
};

export const culturaListReducer = (
    state = CulturaList,
    action: ISetListCulturaAction
) => {
    switch (action.type) {
        case SET_LIST_CULTURA:
            return action.payload;
        default:
            return state;
    }
};

export interface ICulturaClienteModule {
    id_cultura_cliente?: string;
    cultura_temp?: number;
}

export type ICulturaCliente = ICulturaClienteModule;

export type ICulturaClienteList = ICulturaClienteModule[];

export interface ICulturaClienteAction {
    type: any;
    payload: string;
}

export interface ISetListCulturaClienteAction {
    type: any;
    payload: [];
}

const CulturaCliente: ICulturaCliente = {};

const CulturaClienteList: ICulturaClienteList = [];

export const culturaClienteReducer = (
    state = CulturaCliente,
    action: ICulturaClienteAction
) => {
    switch (action.type) {
        case SET_CULTURA_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};

export const culturaClienteListReducer = (
    state = CulturaClienteList,
    action: ISetListCulturaClienteAction
) => {
    switch (action.type) {
        case SET_LIST_CULTURA_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};
