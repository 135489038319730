import { useState, useMemo, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    // adicionarFazenda,
    deletarFazenda,
    // fazendaProcessNull,
} from "../../../store/fazendaActions";
import AdicionarFazenda from "./Fazendas/AdicionarFazenda";
import { Modal, Button, Popconfirm, Select } from "antd";
import EditarFazenda from "./Fazendas/EditarFazenda";
import { IFazendaList } from "../../../reducers/FazendaReducer";
import { setStatusNull } from "../../../store/actions";
import { IStore } from "../../../store";
import { IStatusList, ITalhaoTablet } from "../../../store/types";
import { ITheme } from "../../../reducers/ThemeReducer";
import {
    deletarTalhao,
    getTalhoes,
    importarTalhao,
} from "../../../store/talhaoActions";
import AdicionarTalhao from "./Talhoes/AdicionarTalhao";
import EditarTalhao from "./Talhoes/EditarTalhao";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Option } = Select;

interface TalhoesProps {
    talhoes: ITalhaoTablet[];
    fazendas: IFazendaList;
    theme: ITheme;
    loader: IStatusList;
    deletarTalhao: Function;
    getTalhoes: Function;

    importarTalhao: Function;

    setStatusNull: Function;
}

function Talhoes(props: TalhoesProps) {
    const {
        deletarTalhao,
        getTalhoes,
        talhoes,
        fazendas,
        theme,
        loader,
        importarTalhao,
        setStatusNull,
    } = props;
    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [faz, setFaz] = useState<any>("");
    const [talhao, setTalhao] = useState<any>(0);
    const [which, setWhich] = useState<any>(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);
    const [importVisible, setImportVisible] = useState(false);

    useMemo(() => {
        getTalhoes();
    }, []);

    const listaTalhao = useCallback(() => {
        if (faz) {
            let fazShape: any = fazendas.filter(
                (e: any) => e.id_fazenda === faz
            );
            let features: GeoJSON.FeatureCollection = JSON.parse(
                fazShape[0].shape
            );
            return features.features;
        }

        return [];
    }, [fazendas, faz]);

    const desativaImport = useCallback(() => {
        if (talhoes.filter((e: any) => e.fazenda_temp === faz).length > 0) {
            return true;
        }
        if (faz === "") {
            return true;
        }
        return false;
    }, [talhoes, faz]);

    const handleSave = () => {
        let fazenda: any = fazendas.filter((e: any) => e.id_fazenda === faz);

        let newArr = listaTalhao().map((t: any) => {
            return {
                nome_talhao: t.properties?.[JSON.parse(fazenda[0].campo).nome],
                fazenda_temp: faz,
                coordenadas: JSON.stringify({
                    bbox: t.geometry.bbox,
                    coordinates:
                        t.geometry.coordinates.length > 1
                            ? t.geometry.coordinates.flat()
                            : t.geometry.coordinates,
                }),
                area: t.properties?.[JSON.parse(fazenda[0].campo).size],
            };
        });

        let obj = {
            dados: newArr,
        };
        importarTalhao(obj);
    };

    function handleEditar(id: any) {
        setConfigModal({
            titulo: "Editar Talhão",
            conteudo: <EditarTalhao talhao={talhao} setModal={setModal} />,
        });
        setModal(true);
    }

    function handleSelectFaz(value: any) {
        setFaz(value);
        setWhich(0);
    }

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Talhão",
            conteudo: (
                <AdicionarTalhao setModal={setModal} fazendaSelec={faz} />
            ),
        });
        setModal(true);
    }

    function handleExcluir() {
        deletarTalhao(faz + "/" + which);
        setExcluirVisible(false);
    }
    useMemo(() => {
        if (loader.talhao_tablet?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setWhich(0);
            setStatusNull("del", "talhao_tablet");
        }
    }, [loader.talhao_tablet?.del?.success]);

    useMemo(() => {
        if (loader.talhao_tablet?.add?.success) {
            setImportVisible(false);
            setConfirmLoading(false);
            setStatusNull("add", "talhao_tablet");
        }
    }, [loader.talhao_tablet?.add?.success]);

    return (
        <>
            {/* <div style={{ height: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    {" "}
                    <div
                        style={{
                            flex: 1,
                            height: fazendas.length > 1 ? "49vh" : "100%",
                            overflowY:
                                fazendas.length > 1 ? "scroll" : "hidden",
                        }}
                    >
                        {fazendas.map((f: any) => {
                            let classN = theme
                                ? "select-expand-li-dark"
                                : "select-expand-li";
                            if (f.id_fazenda === which) {
                                classN += " active";
                            }
                            return (
                                <>
                                    <div
                                        style={{
                                            paddingRight: "10px",
                                        }}
                                    >
                                        Talhões cadastrados na {f.nome}:{" "}
                                        <ul
                                            className={
                                                theme
                                                    ? "select-expand-ul-dark"
                                                    : "select-expand-ul"
                                            }
                                        >
                                            {talhoes.map(o => {
                                                if (
                                                    o.fazenda_temp ===
                                                    f.id_fazenda
                                                ) {
                                                    let classN = theme
                                                        ? "select-expand-li-dark"
                                                        : "select-expand-li";
                                                    if (o.id_talhao === which) {
                                                        classN += " active";
                                                    }
                                                    return (
                                                        <li
                                                            className={classN}
                                                            key={o.id_talhao}
                                                            value={o.id_talhao}
                                                            onClick={() => {
                                                                setWhich(
                                                                    o.id_talhao
                                                                );
                                                                setFaz(
                                                                    o.fazenda_temp
                                                                );
                                                                setTalhao(o);
                                                            }}
                                                            onDoubleClick={() =>
                                                                handleEditar(
                                                                    o.id_talhao
                                                                )
                                                            }
                                                        >
                                                            {o.nome_talhao}
                                                        </li>
                                                    );
                                                }
                                            })}

                                            <li
                                                style={{ flex: 1 }}
                                                onClick={() => setWhich(0)}
                                            ></li>
                                        </ul>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            paddingLeft: "10px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir esse Talhão?"
                            onConfirm={handleExcluir}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                disabled={which === 0 ? true : false}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div> */}

            <div style={{ height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "start" }}>
                    <div
                        style={{
                            paddingRight: "10px",
                        }}
                    >
                        Talhões cadastrados na fazenda:
                    </div>
                    <div>
                        <Select
                            style={{
                                width: "200px",
                            }}
                            size="small"
                            onSelect={handleSelectFaz}
                        >
                            {fazendas.map((f: any) => {
                                return (
                                    <Option
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {talhoes.map(o => {
                                if (o.fazenda_temp === faz) {
                                    let classN = theme
                                        ? "select-expand-li-dark"
                                        : "select-expand-li";
                                    if (o.id_talhao === which) {
                                        classN += " active";
                                    }
                                    return (
                                        <li
                                            className={classN}
                                            key={o.id_talhao}
                                            value={o.id_talhao}
                                            onClick={() => {
                                                setWhich(o.id_talhao);
                                                setFaz(o.fazenda_temp);
                                                setTalhao(o);
                                            }}
                                            onDoubleClick={() =>
                                                handleEditar(o.id_talhao)
                                            }
                                        >
                                            {o.nome_talhao}
                                        </li>
                                    );
                                }
                            })}

                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich(0)}
                            ></li>
                        </ul>
                    </div>{" "}
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            paddingLeft: "10px",
                        }}
                    >
                        <Button
                            type="primary"
                            disabled={faz === ""}
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={importVisible}
                            title="Importar todos os talhões vinculados ao ShapeFile?"
                            onConfirm={handleSave}
                            onCancel={() => setImportVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={desativaImport()}
                            icon={<QuestionCircleFilled />}
                        >
                            <Button
                                type="primary"
                                disabled={desativaImport()}
                                style={{ width: "100%" }}
                                onClick={() => setImportVisible(true)}
                            >
                                Importar
                            </Button>{" "}
                        </Popconfirm>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir esse Talhão?"
                            onConfirm={handleExcluir}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                disabled={which === 0 ? true : false}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>

                <Modal
                    title={configModal.titulo}
                    visible={modal}
                    destroyOnClose={true}
                    maskStyle={{ backdropFilter: "blur(5px)" }}
                    onCancel={() => setModal(false)}
                    footer={null}
                    width={600}
                    centered={true}
                    zIndex={1025}
                >
                    {configModal.conteudo}
                </Modal>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        talhoes: store.talhoesTablet,
        fazendas: store.fazendas,
        theme: store.theme,
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getTalhoes,
            deletarTalhao,
            importarTalhao,

            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Talhoes);
