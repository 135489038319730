import { SET_LIST_MENSAGEM } from "../store/actionTypes";
import { IEquipamento } from "./EquipamentoReducer";

export interface IMsgModule {
    id_mensagem: string;
    cod_fila: number | null;
    data_hora: Date;
    texto: string;
    equipamento_temp: string;
}

export type IMensagemList = IMsgModule[];
const mensagemList: IMensagemList = [];

export interface IMensagemReducer {
    type: any;
    payload: IMensagemList;
}
export const mensagemListReducer = (
    state = mensagemList,
    action: IMensagemReducer
) => {
    switch (action.type) {
        case SET_LIST_MENSAGEM:
            return action.payload;
        default:
            return state;
    }
};
