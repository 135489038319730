import {
    SET_FILTRO_TALHAO,
    SET_LIST_TALHAO_TABLET,
} from "../store/actionTypes";
import { ITalhaoTablet } from "../store/types";

const Talhao: ITalhaoTablet = {};

export interface ITalhaoTabletReducer {
    type: any;
    payload: ITalhaoTablet;
}

export const talhaoTabletReducer = (
    state = Talhao,
    action: ITalhaoTabletReducer
) => {
    switch (action.type) {
        case SET_LIST_TALHAO_TABLET:
            return action.payload;
        default:
            return state;
    }
};

const Talhoes: ITalhaoTablet[] = [];

export interface ITalhaoListReducer {
    type: any;
    payload: ITalhaoTablet[];
}

export const talhaoListTabletReducer = (
    state = Talhoes,
    action: ITalhaoListReducer
) => {
    switch (action.type) {
        case SET_LIST_TALHAO_TABLET:
            return action.payload;
        default:
            return state;
    }
};

const talhoesFiltro: ITalhaoTablet[] = [];

export interface ITalhaoFiltroReducer {
    type: any;
    payload: ITalhaoTablet[];
}

export const talhaoFiltroReducer = (
    state = talhoesFiltro,
    action: ITalhaoFiltroReducer
) => {
    switch (action.type) {
        case SET_FILTRO_TALHAO:
            return action.payload;
        default:
            return state;
    }
};
