import { SET_LIST_PARAMETRO, SET_PARAMETRO } from "../store/actionTypes";
import { IEquipamento } from "./EquipamentoReducer";
import { IImplemento } from "./ImplementoReducer";
import { ITipoOp } from "./TipoOpReducer";

export interface IParametroModule {
    id_parametro_consumo?: string;
    media?: number;
    equipamento_temp?: IEquipamento;
    implemento_temp?: IImplemento;
    tipo_operacao_temp?: ITipoOp;
}

export type IParametro = IParametroModule;
const Parametro: IParametro = {};

export type IParametroList = IParametroModule[];
const ParametroList: IParametroList = [];

export interface IParametroReducer {
    type: any;
    payload: IParametro;
}

export const parametroReducer = (
    state = Parametro,
    action: IParametroReducer
) => {
    switch (action.type) {
        case SET_PARAMETRO:
            return action.payload;
        default:
            return state;
    }
};

export interface IParametroListReducer {
    type: any;
    payload: IParametroList;
}

export const parametroListReducer = (
    state = ParametroList,
    action: IParametroListReducer
) => {
    switch (action.type) {
        case SET_LIST_PARAMETRO:
            return action.payload;
        default:
            return state;
    }
};
