import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { editarServidor } from "../../../../store/servidorActions";
import { setStatusNull } from "../../../../store/actions";
import { IStatusList } from "../../../../store/types";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { useCallback, useEffect, useState } from "react";
import {
    message,
    Form,
    Row,
    Col,
    Input,
    Select,
    Button,
    List,
    Modal,
} from "antd";
import { IAntenaList } from "../../../../reducers/AntenaReducer";
import { IServidorModule } from "../../../../reducers/ServidorReducer";
import EditarAntena from "../Antenas/EditarAntena";
import AdicionarAntena from "../Antenas/AdicionarAntena";

const { Option } = Select;

interface PropsEditServidor {
    status: IStatusList;
    fazendas: IFazendaList;

    antenas: IAntenaList;

    cont: IServidorModule;
    setModal: Function;

    editarServidor: Function;
    setStatusNull: Function;

    getAntenas: Function;
}

function EditarServidor(props: PropsEditServidor) {
    const { status, editarServidor, setStatusNull, fazendas, antenas } = props;

    const [descricao, setDescricao] = useState(true);
    const [faz, setFaz] = useState(true);
    const [ip, setIp] = useState(true);

    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [modal, setModal] = useState(false);

    function handleEditar(id: any) {
        const antKey = antenas.findIndex((ant: any) => ant.id_antena === id);
        setConfigModal({
            titulo: "Editar Antena",
            conteudo: (
                <EditarAntena setModal={setModal} cont={antenas[antKey]} />
            ),
        });
        //

        setModal(true);
    }

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Antena",
            conteudo: (
                <AdicionarAntena
                    setModal={setModal}
                    server={props.cont.id_local_server}
                />
            ),
        });
        setModal(true);
    }

    const handleDisable = useCallback(() => {
        if (descricao === true && faz === true && ip === true) {
            return false;
        }
        return true;
    }, [descricao, faz, ip]);

    useEffect(() => {
        if (status.servidor?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "servidor");
        }
    }, [status]);

    useEffect(() => {
        props.getAntenas(props.cont.id_local_server, true);
    }, []);

    useEffect(() => {}, [status]);

    return (
        <>
            <Form
                layout="vertical"
                initialValues={props.cont}
                onFinish={(v: any) => {
                    editarServidor(v);
                    props.setModal(false);
                }}
            >
                <Form.Item name="id_local_server" style={{ display: "none" }}>
                    <Input type="hidden" name="id_local_server"></Input>
                </Form.Item>{" "}
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite uma descrição",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setDescricao(false),
                                                Promise.reject()
                                            );
                                        }
                                        setDescricao(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Descrição:"
                            name="descricao"
                        >
                            <Input placeholder="Digite a descrição do servidor" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione uma fazenda",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setFaz(false), Promise.reject()
                                            );
                                        }
                                        setFaz(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fazenda:"
                            name="fazenda_temp"
                        >
                            <Select
                                defaultValue={props.cont.fazenda_temp}
                                disabled={true}
                            >
                                {fazendas.map((i: any, k: any) => {
                                    return (
                                        <Option key={k} value={i.id_fazenda}>
                                            {i.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite um IP",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setIp(false), Promise.reject()
                                            );
                                        }
                                        setIp(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="IP do servidor:"
                            name="ip_local_server"
                        >
                            <Input placeholder="Digite o IP do servidor" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <List
                            loading={status.antena?.get?.loading}
                            header={
                                <div
                                    style={{
                                        fontSize: "16px",
                                        borderTop: "1px solid #ccc",
                                        padding: "20px 8px 0 0",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>Antenas </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            onClick={() => handleAdicionar()}
                                        >
                                            Adicionar
                                        </Button>
                                    </div>
                                </div>
                            }
                            itemLayout="horizontal"
                            dataSource={antenas}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <Button
                                            key="list-loadmore-edit"
                                            onClick={() => {
                                                handleEditar(item.id_antena);
                                            }}
                                        >
                                            Editar
                                        </Button>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={item.nome}
                                        description={
                                            item.ultimo_timestamp === null
                                                ? "Não há registro de conexão"
                                                : item.ultimo_timestamp
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={handleDisable()}
                        loading={status.servidor?.edit?.loading}
                    >
                        Salvar servidor
                    </Button>
                </div>
            </Form>

            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={680}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendas: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarServidor,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarServidor);
