import { IPolylineFiltro, IPolylineList } from "../reducers/PolylineReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import {
    SET_FILTRO_POLYLINE,
    SET_LIST_POLYLINE,
    PUSH_FILTRO_POLYLINE,
    REM_FILTRO_POLYLINE,
    PUSH_POLYLINE_RENDER,
    REM_POLYLINE_RENDER,
} from "./actionTypes";
import { getBBoxOp } from "../config/helpers";
import { setOpBBox } from "./operacoesAction";
import { IEquipamento } from "../reducers/EquipamentoReducer";

export const setFiltroPolyline = (value: any) => ({
    type: SET_FILTRO_POLYLINE,
    payload: value,
});

export const pushFiltroPolyline = (value: any) => ({
    type: PUSH_FILTRO_POLYLINE,
    payload: value,
});

export const remFiltroPolyline = () => ({
    type: REM_FILTRO_POLYLINE,
});

export const pushPolylineRender = (value: any) => ({
    type: PUSH_POLYLINE_RENDER,
    payload: value,
});

export const remPolylineRender = () => ({
    type: REM_POLYLINE_RENDER,
});

export const setListPolyline = (value: IPolylineList) => ({
    type: SET_LIST_POLYLINE,
    payload: value,
});
type polylineTypes = string | undefined;
export interface IGetPolylineProps {
    [key: string]: polylineTypes;
    faz: polylineTypes;
    equip: polylineTypes;
    dataIni?: polylineTypes;
    dataFin?: polylineTypes;
    gpson?: polylineTypes;
    polyline?: polylineTypes;
}
export const getPolyline = (props: IGetPolylineProps) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "polyline"));
        const keys = Object.keys(props);
        var urlApi =
            "api/operacao/" +
            props.faz +
            "/?page=0&size=100000&colunas=longitude,latitude,timestamp" +
            "&equipamento=" +
            props.equip +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz" && key !== "equip")
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.text();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "polyline", res.erro));
                } else {
                    dispatch(setListPolyline(JSON.parse(res).dados));
                    dispatch(setOpBBox(getBBoxOp(JSON.parse(res).dados)));
                    dispatch(setStatusSuccess("get", "polyline"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "polyline", error.data));
            });
    };
};

export const getEquipamentoListFiltro = (props: IGetPolylineProps) => {
    return async (dispatch: any) => {
        // dispatch(setStatusStart("get", "polyline"));
        dispatch(setStatusStart("get", "filtros"));
        const keys = Object.keys(props);
        var urlApi =
            "api/operacao/" +
            props.faz +
            "/filtro_equipa_op?dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz" && key !== "dataIni" && key !== "dataFin")
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        const equipasPorFiltros = await fetch(
            `${process.env.REACT_APP_BASE_URL_API}` + urlApi,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );

        const equipasPorFiltrosJSON = JSON.parse(
            await equipasPorFiltros.text()
        );

        var array = [];

        const keyss = Object.keys(props);
        var urlApii =
            "api/operacao/" +
            props.faz +
            "/?dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keyss.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz" && key !== "dataIni" && key !== "dataFin")
                    urlApii += key + "=" + props[key] + "&";
            }
        });

        for (const e of equipasPorFiltrosJSON) {
            const polyPorEquipa = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}` +
                    urlApii +
                    "equipamento=" +
                    e +
                    "&page=0&size=100000&colunas=latitude,longitude,tipo_operacao_temp,cultura_temp,safra_temp,talhao_temp,implemento_temp",
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }
            );

            const polyPorEquipaJSON = JSON.parse(await polyPorEquipa.text());

            array.push({
                id: e,
                polyline: polyPorEquipaJSON.dados,
            });
        }

        dispatch(setFiltroPolyline(array));
        dispatch(setStatusSuccess("get", "filtros"));
        // dispatch(setStatusSuccess("get", "polyline"));

        // fetch(
        //     `${process.env.REACT_APP_BASE_URL_API}api/operacao/${props.faz}/filtro_equipa_op?dataIni=${props.dataIni}&dataFin=${props.dataFin}&tipo_operacao=${props.tipoOp}`,
        //     {
        //         method: "GET",
        //         headers: {
        //             Authorization: "Bearer " + localStorage.getItem("token"),
        //             "Content-Type": "application/json;charset=UTF-8",
        //         },
        //     }
        // )
        //     .then((response: any) => {
        //         if (response.ok) {
        //             return response.text();
        //         } else {
        //             if (response.status === 401) {
        //                 dispatch(loginProcessTokenExpired());
        //             }
        //         }
        //     })
        //     .then(res => {
        //         if (res.erro) {
        //
        //             dispatch(setStatusError("get", "polyline", res.erro));
        //         } else {
        //             var array: any = [];
        //             JSON.parse(res).forEach((e: any) => {
        //                 fetch(
        //                     `${process.env.REACT_APP_BASE_URL_API}api/operacao/${props.faz}/?dataIni=${props.dataIni}&dataFin=${props.dataFin}&tipo_operacao=${props.tipoOp}&equipamento=${e}&colunas=latitude,longitude`,
        //                     {
        //                         method: "GET",
        //                         headers: {
        //                             Authorization:
        //                                 "Bearer " +
        //                                 localStorage.getItem("token"),
        //                             "Content-Type":
        //                                 "application/json;charset=UTF-8",
        //                         },
        //                     }
        //                 )
        //                     .then((response: any) => {
        //                         if (response.ok) {
        //                             return response.text();
        //                         } else {
        //                             if (response.status === 401) {
        //                                 dispatch(loginProcessTokenExpired());
        //                             }
        //                         }
        //                     })
        //                     .then(res => {
        //                         if (res.erro) {
        //
        //                             dispatch(
        //                                 setStatusError(
        //                                     "get",
        //                                     "polyline",
        //                                     res.erro
        //                                 )
        //                             );
        //                         } else {
        //                             array.push({
        //                                 id: e,
        //                                 polyline: JSON.parse(res),
        //                             });
        //                         }
        //                     });
        //             });
        //             setTimeout(() => {
        //                 dispatch(setFiltroPolyline(array));
        //             }, 2000);
        //         }
        //     })
        //     .catch(error => {
        //         dispatch(setStatusError("get", "polyline", error.data));
        //
        //     });
    };
};
