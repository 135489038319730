import styled, { keyframes, createGlobalStyle, css } from "styled-components";

export const selected_theme = "normal";

const theme = {
    normal: {
        config_menu_border: "#f0f0f0",
        config_menu_item_background_hover: "#f5f5f5",
    },
    dark: {
        config_menu_border: "#303030",
        config_menu_item_background_hover: "#313131",
    },
};

// const antdCss = css<any>`
//     ${require("antd/dist/antd.dark.css")}
// `;

//Keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
`;

//Componentes
export const MapWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: #ccc;
`;

export const MenuTop = styled.div<any>`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100vw;
    background-color: ${props =>
        props.dark ? "#404040" : "rgba(255, 255, 255, 0.9)"};
`;

interface MenuLeftInterface {
    ativo?: boolean | undefined; //
    tema?: boolean;
}

export const MenuLeft = styled.div<MenuLeftInterface>`
    position: fixed;
    left: 0;
    top: 40px;
    z-index: 2;
    height: calc(100vh - 40px);
    width: 40px;
    background: ${props => (props.tema ? "#303030" : "rgb(47, 53, 126)")};
    background: ${props =>
        props.tema
            ? "linear-gradient(90deg, rgba(48, 48, 48, 0.97) 40%, rgba(48, 48, 48, 0.7) 100%)"
            : "linear-gradient(90deg, rgba(47, 53, 126, 0.97) 40%, rgba(47, 53, 126, 0.7) 100%)"};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 20px;
    text-align: center;
`;

export const MenuLeftTopItem = styled.div<MenuLeftInterface>`
    & i {
        padding-top: 15px;
        color: #fff;
        cursor: pointer;
        border-bottom: ${props => (props.ativo ? "3px solid #fff" : "0")};
        padding-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: ${props => (props.ativo ? "2px" : "5px")};
    }
    & i:hover {
        color: #ccc;
    }
    @media screen and (max-width: 920px) {
        & i {
            padding: 0px;
        }
    }
`;

export const MenuLeftBottomItem = styled.div<MenuLeftInterface>`
    & i {
        padding-top: 15px;
        color: #fff;
        cursor: pointer;
        border-bottom: ${props => (props.ativo ? "3px solid #fff" : "0")};
        padding-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: ${props => (props.ativo ? "2px" : "5px")};
    }
    & i:hover {
        color: #ccc;
    }
    @media screen and (max-width: 920px) {
        & i {
            padding-top: 0px;
        }
    }
`;

export const Logo = styled.div`
    width: 40px;
    height: 40px;
    padding: 8px;

    & img {
        width: 24px;
        height: 24px;
    }
`;

interface OverviewInterface {
    visivel: boolean;
}

export const Overview = styled.div<OverviewInterface>`
    display: ${props => (props.visivel ? "flex" : "none")};
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    margin-left: -400px;
    margin-top: -300px;
    width: 800px;
    height: 600px;
    color: #fff;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    border: 1px solid #fff;
    border-radius: 15px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);

    & i {
        cursor: pointer;
        opacity: 0.8;
    }
    & i:hover {
        opacity: 1;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const RightContainer = styled.div<any>`
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    font-size: 24px;
    padding: 8px;
    color: ${props => (props.tema ? "#FFF" : "#1c462e")};

    & i {
        padding-left: 10px;
        cursor: pointer;
    }
    & .ant-badge i {
        color: ${props => (props.tema ? "#FFF" : "#1c462e")} !important;
    }
    & .ant-badge i:hover {
        color: #337d53 !important;
    }
    & i:hover {
        color: #337d53;
    }
    & .fa-redo-alt {
        padding-top: 3px;
        font-size: 20px;
    }
    & .fa-expand {
        margin-left: 10px;
        border-left: 2px solid ${props => (props.tema ? "#FFF" : "#1c462e")};
    }
    & img:hover {
        opacity: 0.7;
    }
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40px;
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40px;
    padding-bottom: 20px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgb(30, 34, 82);
`;

interface SideWrapperInterface {
    dplay: boolean | undefined;
}

export const SideWrapper = styled.div<SideWrapperInterface>`
    position: absolute;
    display: flex;
    opacity: ${props => (props.dplay ? "1" : "0")};
    transition: opacity 0.3s ease-in-out, right 0.3s ease-in-out;
    flex-direction: column;
    justify-content: space-between;
    align-content: stretch;
    right: ${props => (props.dplay ? "0" : "-350px")};
    top: 0px;
    z-index: 2;
    width: 400px;
    padding-left: 20px;
    height: calc(100vh - 40px);
    background: rgb(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.7) 25%,
        rgba(0, 0, 0, 0.9) 50%,
        rgba(0, 0, 0, 1) 100%
    );
    text-align: center;

    & .logo-est {
        text-align: right;
        padding: 10px;
    }

    & .logo-est img {
        width: 100px;
    }
`;

export const ContentOverflow = styled.div`
    color: #fff;
    padding: 5px 10px 0 0px;
    overflow: auto;
    flex: 1;
    vertical-align: top;
    text-align: left;

    & h2 {
        color: #fff;
    }

    & .not-found {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        background: #000;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 20px;
        border: 4px solid #000;
    }
`;

interface EquipaTituloInterface {
    corIgnicao: string;
}

export const EquipaTitulo = styled.div<EquipaTituloInterface>`
    display: flex;
    color: #fff;
    justify-content: flex-end;
    padding-right: 10px;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;

    & .esquerda-titulo {
        display: flex;
        flex-direction: column;
        padding: 3px 10px 0 0;
        color: ${props => props.corIgnicao};
    }

    & .esquerda-titulo .icone {
        padding: 10px 10px 0 10px;
    }

    & .esquerda-titulo .icone i {
        font-size: 40px;
    }

    & .esquerda-titulo .descricao {
        text-align: center;
    }

    & .centro-titulo {
        display: block;
        width: 210px;
    }

    & .direita-titulo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
    }

    & h1 {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    & h2 {
        margin-top: -15px;
        font-size: 20px;
        color: #ffffff;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    & i {
        font-size: 50px;
    }
`;

export const ViewContainer = styled.div`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
`;

//Configurações
export const ConfigWrapper = styled.div`
    margin: -24px;
    display: flex;
    user-select: none;
`;

interface ConfigMenuListInterface {
    tema: boolean;
}
export const ConfigMenuList = styled.div<ConfigMenuListInterface>`
    position: fixed;
    width: 200px;
    height: 400px;
    border-right: 1px solid
        ${props => (props.tema ? "rgb(66, 66, 66)" : "rgb(240, 240, 240)")};
    user-select: none;
`;

interface ConfigMenuItemInterface {
    active: boolean;
    tema: boolean;
}

export const ConfigMenuItem = styled.div<ConfigMenuItemInterface>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    font-weight: bold;
    background-color: ${props => (props.active ? "rgba(47, 53, 126, 1)" : "")};
    color: ${props => (props.active ? "#FFF" : "")};

    &:hover {
        cursor: pointer;
        background-color: ${props =>
            props.active ? "" : props.tema ? "rgb(64, 64, 64)" : "#f5f5f5"};
    }
`;

export const ConfigContent = styled.div`
    margin-left: 200px;
    padding: 20px;
    flex: 1;
    overflow: auto;
    height: 100%;
`;

interface SpinnerInterface {
    view: boolean | undefined;
}

export const Spinner = styled.div<SpinnerInterface>`
    opacity: ${props => (props.view ? "1" : "0")};
    animation: ${rotate} 1s linear infinite;
    transition: opacity 0.3s ease-in-out;
`;

interface ITutorialContainer {
    show: any;
}

export const TutorialContainer = styled.div<ITutorialContainer>`
    position: absolute;
    z-index: 1032;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: ${props => (props.show ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(3px);
    user-select: none;
`;

interface ITutorialBoasVindas {
    fade: any;
}

export const TutorialBoasVindas = styled.div<ITutorialBoasVindas>`
    width: 600px;
    color: #fff;
    opacity: ${props => (props.fade ? "1" : "0")};
    animation: ${props => (props.fade ? fadeIn : fadeOut)} 1s linear 1;
`;
