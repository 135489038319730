import {} from "../store/types";
import {
    LOGIN_PROCESS_SUCCESS,
    LOGIN_PROCESS_LOGOUT,
    LOGIN_PROCESS_TOKEN_EXPIRED,
} from "../store/actionTypes";

//import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
    id: string;
    nome: string;
    email: string;
}

export interface AuthState {
    loading: boolean;
    token: string;
    expired: boolean;
    error: string;
    user: User;
}

const initialState = {
    loading: false,
    token: "",
    expired: false,
    error: "",
    user: {
        id: "",
        nome: "",
        email: "",
    },
} as AuthState;

// const authSlice = createSlice({
//     name: "auth",
//     initialState,
//     reducers: {
//         loginProcessStart(state: any, action: PayloadAction<AuthState>) {
//             return {
//                 ...state,
//                 loading: true
//             }
//         },
//         loginProcessSuccess(state: any, action: PayloadAction<AuthState>) {
//             return {
//                 ...state,
//                 loading: false,
//                 error: "",
//                 token: action.payload.token,
//                 user: action.payload.user,
//             };
//         }
//     }
// })

// export const { loginProcessStart, loginProcessSuccess} = authSlice.actions;
// export const authReducer = authSlice.reducer;

export const authReducer = (state: AuthState = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_PROCESS_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                loading: false,
                error: "",
                token: action.payload.token,
                user: action.payload.user,
            };
        case LOGIN_PROCESS_LOGOUT:
            localStorage.clear();
            return {
                loading: false,
                token: "",
                error: "",
                expired: false,
            };
        case LOGIN_PROCESS_TOKEN_EXPIRED:
            return {
                ...state,
                expired: true,
            };
        default:
            return state;
    }
};
