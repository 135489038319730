import { Modal } from "antd";
import Colunas from "./Colunas";
import Filtros from "./Filtros";

export default function ModalOpera(props: any) {
    var tituloModal = "";

    if (props.dialog === 1) {
        tituloModal = "Visualização de Colunas";
    }
    if (props.dialog === 2) {
        tituloModal = "Seleção de Filtros";
    }
    return (
        <Modal
            destroyOnClose={true}
            width={800}
            visible={props.visivel}
            closable={false}
            maskClosable={false}
            footer={false}
            zIndex={1060}
            title={tituloModal}
        >
            {props.dialog === 1 && (
                <Colunas
                    colunas={props.colunas}
                    colunasOpe={props.colunasOpe}
                    setColunas={props.setColunas}
                    abaAtiva={props.abaAtiva}
                    abasList={props.abasList}
                    setAbasList={props.setAbasList}
                    setVisivel={props.setVisivel}
                />
            )}
            {props.dialog === 2 && (
                <Filtros
                    setFiltrosDef={props.setFiltrosDef}
                    setDataSelec={props.setDataSelec}
                    setVisivel={props.setVisivel}
                    abaAtiva={props.abaAtiva}
                    abasList={props.abasList}
                    setAbasList={props.setAbasList}
                />
            )}
        </Modal>
    );
}
