import { Input, Form, Button, message, Col, Row, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import MultiToggle from "react-multi-toggle";
import { bindActionCreators } from "redux";
import { adicionarEquipamento } from "../../../../store/equipamentoActions";
import { setStatusNull } from "../../../../store/actions";
import { connect } from "react-redux";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";
import { CirclePicker } from "react-color";

const { Option } = Select;

export interface IAdicinarEquipamentoProps {
    status: IStatusList;
    estados: any[];
    tipos: any[];
    comunicacao: any[];
    adicionarEquipamento: Function;
    setStatusNull: any;

    setModal: any;
}
function AdicionarEquipamento(props: IAdicinarEquipamentoProps) {
    const {
        status,
        estados,
        tipos,
        comunicacao,
        adicionarEquipamento,
        setStatusNull,
    } = props;

    const colorList = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#FFFFFF",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#000000",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
        "#858585",
    ];

    const [descr, setDescr] = useState(false);
    const [deviceId, setDeviceId] = useState(false);
    const [serial, setSerial] = useState(false);
    const [tipo, setTipo] = useState(false);
    const [numero, setNumero] = useState(false);
    const [displayCor, setDisplayCor] = useState(false);
    const [larg, setLarg] = useState(false);
    const [comunica, setComunica] = useState(false);

    const [largura, setLargura] = useState(1);
    const [estado, setEstado] = useState(1);
    const [cor, setCor] = useState(
        JSON.stringify({
            cor: colorList[Math.floor(Math.random() * colorList.length)],
        })
    );
    const [form] = Form.useForm();

    const handleDisable = useCallback(() => {
        if (
            descr === true &&
            tipo === true &&
            numero === true &&
            larg === true &&
            comunica === true &&
            estado !== null
        ) {
            return false;
        }
        return true;
    }, [descr, numero, tipo, estado, larg, comunica]);

    function handleClick() {
        setDisplayCor(!displayCor);
    }

    function handleClose() {
        setDisplayCor(false);
    }

    function handleChangeCor(color: any) {
        setCor(JSON.stringify({ cor: color.hex }));
        form.setFieldsValue({ estilo: JSON.stringify({ cor: color.hex }) });
    }

    function handleChangeLarg(value: any) {
        setLargura(parseFloat(value));
        form.setFieldsValue({ largura: parseFloat(value) });
    }

    function onChange(value: any) {
        setEstado(value);
        form.setFieldsValue({ estado: value });
    }

    useEffect(() => {
        if (status.equipamento?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "equipamento");
        }
    });

    // useEffect(() => {
    //
    // }, [estado, cor]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={(v: any) => {
                    //
                    adicionarEquipamento(v);
                    props.setModal(false);
                }}
                initialValues={{ estado: estado, estilo: cor }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite uma descrição",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setDescr(false),
                                                Promise.reject()
                                            );
                                        }
                                        setDescr(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Descrição do equipamento:"
                            name="descricao"
                        >
                            <Input placeholder="Digite uma descrição" />
                        </Form.Item>
                    </Col>

                    {/* <Col span={4}>
                        <Form.Item label="Icone:" name="icone">
                            <Select
                                placeholder="custom"
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="icon-select"
                                style={{
                                    width: "70px",
                                }}
                            >
                                {icons.map((i, k) => {
                                    return (
                                        <Option
                                            className="icon-picker-item"
                                            key={k}
                                            value={i}
                                        >
                                            <i className={i}></i>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite o número do patrimônio",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setNumero(false),
                                                Promise.reject()
                                            );
                                        }
                                        setNumero(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nº do patrimônio:"
                            name="numero_patrimonio"
                        >
                            <Input placeholder="Digite o número do patrimônio" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipo(false), Promise.reject()
                                            );
                                        }
                                        setTipo(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo de equipamento:"
                            name="tipo"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Selecione um tipo..."
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tipos.map((t: any) => {
                                    return (
                                        <Option key={uuidv4()} value={t.id}>
                                            {t.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Estado:" name="estado">
                            <MultiToggle
                                options={estados}
                                selectedOption={estado}
                                onSelectOption={onChange}
                            />
                            <Input type="hidden" name="estado"></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            label="Identificação do dispositivo:"
                            name="device_id"
                        >
                            <Input
                                placeholder="Digite o identificador"
                                maxLength={17}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Serial do equipamento:" name="serial">
                            <Input
                                placeholder="Digite o nº de serial"
                                maxLength={9}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite a largura",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setLarg(false), Promise.reject()
                                            );
                                        }
                                        setLarg(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Largura:"
                            name="largura"
                        >
                            <Input
                                type="number"
                                placeholder="Largura"
                                addonAfter={"m"}
                                min={0}
                                step={0.01}
                                onChange={(e: any) =>
                                    handleChangeLarg(e.target.value)
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Cor:" name="estilo">
                            <Input type="hidden" name="estilo"></Input>
                            <div>
                                <div
                                    style={{
                                        padding: "3px",
                                        background: "#fff",
                                        borderRadius: "1px",
                                        boxShadow: "0 0 0 1px #D9D9D9",
                                        display: "inline-block",
                                        cursor: "pointer",
                                        width: "100%",
                                    }}
                                    onClick={handleClick}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "25px",
                                            borderRadius: "5px",
                                            background: JSON.parse(cor).cor,
                                        }}
                                    />
                                </div>
                                {displayCor ? (
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: "0",
                                            zIndex: "2",
                                            backgroundColor: "#FFF",
                                            border: "1px solid #D9D9D9",
                                            padding: "10px",
                                            boxShadow:
                                                "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "fixed",
                                                top: "0px",
                                                right: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                            }}
                                            onClick={handleClose}
                                        />
                                        <CirclePicker
                                            colors={colorList}
                                            width="300px"
                                            color={JSON.parse(cor).cor}
                                            onChange={handleChangeCor}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setComunica(false),
                                                Promise.reject()
                                            );
                                        }
                                        setComunica(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Comunicação:"
                            name="comunicacao"
                        >
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Selecione"
                            >
                                {" "}
                                {comunicacao.map((c: any) => {
                                    return (
                                        <Option key={uuidv4()} value={c.value}>
                                            {c.displayName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={handleDisable()}
                            loading={status.equipamento?.add?.loading}
                        >
                            Salvar equipamento
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        estados: store.attrEquipamento.estado,
        tipos: store.attrEquipamento.tipos,
        comunicacao: store.attrEquipamento.comunicacao,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarEquipamento,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdicionarEquipamento);
