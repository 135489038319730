import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { v4 as uuidv4 } from "uuid";
import MultiToggle from "react-multi-toggle";
import { IStatusList } from "../../../../store/types";
import { adicionarImplemento } from "../../../../store/implementoActions";
import { setStatusNull } from "../../../../store/actions";

const { Option } = Select;

export interface IAdicionarImplementoProps {
    setModal: any;
    estados: any[];
    tipos: any[];

    status: IStatusList;

    adicionarImplemento: Function;
    setStatusNull: any;
}

function AdicionarImplemento(props: IAdicionarImplementoProps) {
    const { estados, tipos, status, setStatusNull, adicionarImplemento } =
        props;
    const [descr, setDescr] = useState(false);
    const [numero, setNumero] = useState(false);
    const [tipo, setTipo] = useState(false);
    const [estado, setEstado] = useState(1);
    const [largura, setLargura] = useState(0);

    const [form] = Form.useForm();

    const handleDisable = useCallback(() => {
        if (
            descr === true &&
            tipo === true &&
            numero === true &&
            estado !== null
        ) {
            return false;
        }
        return true;
    }, [descr, numero, tipo, estado]);

    function onChange(value: any) {
        setEstado(value);
        form.setFieldsValue({ estado: value });
    }
    function handleChangeLarg(value: any) {
        setLargura(parseFloat(value));
        form.setFieldsValue({ largura: parseFloat(value) });
    }

    useEffect(() => {
        if (status.implemento?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "implemento");
        }
    });

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={(v: any) => {
                    adicionarImplemento(v);
                    props.setModal(false);
                }}
                initialValues={{ estado: estado }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite uma descrição",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setDescr(false),
                                                Promise.reject()
                                            );
                                        }
                                        setDescr(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Descrição do implemento:"
                            name="descricao"
                        >
                            <Input placeholder="Digite uma descrição" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite a largura",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setNumero(false),
                                                Promise.reject()
                                            );
                                        }
                                        setNumero(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Largura:"
                            name="largura"
                        >
                            <Input
                                type="number"
                                placeholder="Largura"
                                addonAfter={"m"}
                                min={0}
                                step={0.01}
                                onChange={(e: any) =>
                                    handleChangeLarg(e.target.value)
                                }
                            />
                            {/* <InputNumber
                                min={0}
                                formatter={value => converterM(value)}
                                parser={val => currencyParser(val)}
                                controls={false}
                                style={{
                                    width: "100%",
                                }}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite o nº do patrimônio",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setNumero(false),
                                                Promise.reject()
                                            );
                                        }
                                        setNumero(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nº do patrimônio:"
                            name="numero_patrimonio"
                        >
                            <Input placeholder="Digite o nº do patrimônio" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipo(false), Promise.reject()
                                            );
                                        }
                                        setTipo(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo de implemento:"
                            name="tipo"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Selecione um tipo..."
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tipos.map((t: any) => {
                                    return (
                                        <Option key={uuidv4()} value={t.id}>
                                            {t.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item label="Estado:" name="estado">
                            <MultiToggle
                                options={estados}
                                selectedOption={estado}
                                onSelectOption={onChange}
                            />
                            <Input type="hidden" name="estado"></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={handleDisable()}
                            loading={status.implemento?.add?.loading}
                        >
                            Salvar implemento
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        estados: store.attrImplemento.estado,
        tipos: store.attrImplemento.tipos,

        status: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarImplemento,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdicionarImplemento);
