import {
    SET_MARKERS,
    PUSH_MARKER,
    REM_MARKERS,
    SET_NOTIFY,
    PUSH_NOTIFY,
    REM_NOTIFY,
    SET_MARKER_ANTENA,
    PUSH_MARKER_ANTENA,
    REM_MARKER_ANTENA,
    PUSH_ALL_MARKERS,
    REM_ALL_MARKERS,
    PUSH_FLAG_MARKER,
    REM_FLAG_MARKER,
    SET_PARADA,
    PUSH_PARADA,
    REM_PARADA,
} from "../store/actionTypes";

export interface IMarkerModule {
    id: string;
    icon: object;
    coord?: object;
}

export type IMarkers = IMarkerModule[];
export type IMarkersAll = IMarkerModule[];
export type INotifys = IMarkerModule[];
export type IMarkerAntena = IMarkerModule[];
export type IFlagMarker = IMarkerModule[];

export type IParadas = IMarkerModule[];

const Markers: IMarkers = [];

const MarkersAll: IMarkers = [];

const Notifys: INotifys = [];

const Paradas: IParadas = [];

const flagMarker: IMarkers = [];

const markerAntena: IMarkerAntena = [];

export interface IMarkersAction {
    type: any;
    objeto: IMarkerModule;
}

export interface IMarkersAllAction {
    type: any;
    objeto: IMarkerModule;
}

export interface IFlagMarkerAction {
    type: any;
    objeto: IMarkerModule;
}

export const markersAtivosReducer = (
    state = Markers,
    action: IMarkersAction
) => {
    switch (action.type) {
        case SET_MARKERS:
            return action.objeto;
        case PUSH_MARKER:
            var newState = state;
            // const haReg = newState.findIndex(r => r === action.objeto);
            if (newState.length > 0) {
                newState.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                });
                newState = [];
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_MARKERS:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

export const markersAllReducer = (
    state = MarkersAll,
    action: IMarkersAllAction
) => {
    switch (action.type) {
        case PUSH_ALL_MARKERS:
            var newState = state;
            const haReg = newState.findIndex(r => r === action.objeto);
            if (haReg === -1) {
                // newState.forEach((m: any) => {
                //     if (typeof m.icon.setMap === "function")
                //         m.icon.setMap(null); //Se for Google Maps
                // });
                // newState = [];
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_ALL_MARKERS:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

export const notifysAtivosReducer = (
    state = Notifys,
    action: IMarkersAction
) => {
    switch (action.type) {
        case SET_NOTIFY:
            return action.objeto;
        case PUSH_NOTIFY:
            var newState = state;
            // const haReg = newState.findIndex(r => r === action.objeto);
            if (newState.length > 0) {
                /*newState.forEach(m => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                });
                newState = [];*/
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_NOTIFY:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

export const paradasReducer = (state = Paradas, action: IMarkersAction) => {
    switch (action.type) {
        case SET_PARADA:
            return action.objeto;
        case PUSH_PARADA:
            var newState = state;
            // const haReg = newState.findIndex(r => r === action.objeto);
            if (newState.length > 0) {
                /*newState.forEach(m => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                });
                newState = [];*/
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_PARADA:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

export const markerAntenaReducer = (
    state = markerAntena,
    action: IMarkersAction
) => {
    switch (action.type) {
        case SET_MARKER_ANTENA:
            return action.objeto;
        case PUSH_MARKER_ANTENA:
            var newState = state;
            // const haReg = newState.findIndex(r => r === action.objeto);
            if (newState.length > 0) {
                // newState.forEach((m) => {
                //     if (typeof m.icon.setMap === "function")
                //         m.icon.setMap(null); //Se for Google Maps
                // });
                // newState = [];
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_MARKER_ANTENA:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

export const flagMarkerReducer = (
    state = flagMarker,
    action: IFlagMarkerAction
) => {
    switch (action.type) {
        case PUSH_FLAG_MARKER:
            var newState = state;
            const haReg = newState.findIndex(r => r === action.objeto);
            if (haReg === -1) {
                // newState.forEach((m: any) => {
                //     if (typeof m.icon.setMap === "function")
                //         m.icon.setMap(null); //Se for Google Maps
                // });
                // newState = [];
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_FLAG_MARKER:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.icon.setMap === "function")
                        m.icon.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};
