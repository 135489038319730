import { Modal } from "antd";
import FiltrosOperacional from "./FiltrosOperacional";
import ColunasOperacional from "./ColunasOperacional";
//

export default function ModalOperacional(props: any) {
    var tituloModal = "";

    if (props.dialog === 1) {
        tituloModal = "Seleção de Filtros";
    }
    if (props.dialog === 2) {
        tituloModal = "Visualização de Colunas";
    }

    return (
        <Modal
            destroyOnClose={true}
            width={800}
            visible={props.visivel}
            closable={false}
            maskClosable={false}
            footer={false}
            zIndex={1060}
            title={tituloModal}
        >
            {props.dialog === 1 && (
                <FiltrosOperacional
                    setVisivel={props.setVisivel}
                    visivel={props.visivel}
                    abaAtiva={props.abaAtiva}
                    infos={props.infos}
                    setInfos={props.setInfos}
                />
            )}
            {props.dialog === 2 && (
                <ColunasOperacional
                    setVisivel={props.setVisivel}
                    infos={props.infos}
                    setInfos={props.setInfos}
                    data={props.data}
                    colunas={props.colunas}
                    colunasLocal={props.colunasLocal}
                    setColunasLocal={props.setColunasLocal}
                />
            )}
        </Modal>
    );
}
