import {
    SET_COORDINATES,
    SET_REFRESH,
    SET_FOCUS,
    SET_EQUIPA_POLYGON,
    PUSH_POLYGON,
    REM_POLYGONS,
    SET_OVERLAY_EQUIPA,
    PUSH_OVERLAY_EQUIPA,
    REM_OVERLAY_EQUIPA,
    // SET_OVERLAY_OPERACIONAL,
    // PUSH_OVERLAY_OPERACIONAL,
    // REM_OVERLAY_OPERACIONAL,
} from "../store/actionTypes";
import {
    IEquipaPolygon,
    IEquipaPolygonReducerAction,
    IFazendaPolygon,
    IFoco,
    IMapCoord,
    IMapCReducerAction,
    IMapFocoReducerAction,
    IMapReducerAction,
    IMapRender,
    // IOperacionalPolygon,
    IOverlayEquipaReducerAction,
} from "../store/types";

const mapRender: IMapRender = "";

const mapCoord: IMapCoord = {
    lat: -15.232349122318963,
    lng: -54.31503295898438,
    zoom: 8,
};

const Foco: IFoco = { view: 4, subView: null }; // 1: Fazenda, 2: Talhão, 3: Equipamento

const equipaPolygon: IEquipaPolygon = [];

const fazendaPolygon: IFazendaPolygon = [];

//const operacionalPolygon: IOperacionalPolygon = [];

export const mapReducer = (state = mapRender, action: IMapReducerAction) => {
    switch (action.type) {
        case SET_REFRESH:
            return action.v;
        default:
            return state;
    }
};

export const mapCReducer = (state = mapCoord, action: IMapCReducerAction) => {
    switch (action.type) {
        case SET_COORDINATES:
            return action.coord;
        default:
            return state;
    }
};

export const mapFocoReducer = (state = Foco, action: IMapFocoReducerAction) => {
    switch (action.type) {
        case SET_FOCUS:
            return action.payload;
        default:
            return state;
    }
};

export const equipaPolygonReducer = (
    state = equipaPolygon,
    action: IEquipaPolygonReducerAction
) => {
    switch (action.type) {
        case SET_EQUIPA_POLYGON:
            let newState = action.payload?.map((s: any) => {
                return { lat: s.latitude, lng: s.longitude };
            });
            return newState;
        case PUSH_POLYGON:
            var newPolygon = state;
            // const haReg = newPolygon.findIndex(
            //     (r: any) => r.id === action.objeto?.id
            // );
            if (newPolygon.length > 0) {
                newPolygon.forEach((p: any) => {
                    if (typeof p.polyline.setMap === "function")
                        p.polyline.setMap(null);
                });
                newPolygon = [];
                newPolygon.push(action.objeto);
            } else {
                newPolygon.push(action.objeto);
            }
            return newPolygon;
        case REM_POLYGONS:
            if (state.length > 0) {
                state.forEach((c: any) => {
                    if (typeof c.polyline.setMap === "function")
                        c.polyline.setMap(null);
                });
            }
            return state;
        default:
            return state;
    }
};

export const overlayEquipaReducer = (
    state = fazendaPolygon,
    action: IOverlayEquipaReducerAction
) => {
    switch (action.type) {
        case SET_OVERLAY_EQUIPA:
            return action.objeto;
        case PUSH_OVERLAY_EQUIPA:
            var newState = state;
            if (newState.length > 0) {
                // newState.forEach((m) => {
                //     if (typeof m.rect.setMap === "function")
                //         m.rect.setMap(null); //Se for Google Maps
                // });
                // newState = [];
                newState.push(action.objeto);
            } else {
                newState.push(action.objeto);
            }

            return newState;
        case REM_OVERLAY_EQUIPA:
            if (state.length > 0) {
                state.forEach((m: any) => {
                    if (typeof m.rect.setMap === "function")
                        m.rect.setMap(null); //Se for Google Maps
                    //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
                });
            }
            return [];
        default:
            return state;
    }
};

// export const overlaYOperacionalReducer = (
//     state = operacionalPolygon,
//     action
// ) => {
//     switch (action.type) {
//         case SET_OVERLAY_OPERACIONAL:
//             return action.objeto;
//         case PUSH_OVERLAY_OPERACIONAL:
//             var newState = state;
//             if (newState.length > 0) {
//                 newState.push(action.objeto);
//             } else {
//                 newState.push(action.objeto);
//             }

//             return newState;
//         case REM_OVERLAY_OPERACIONAL:
//             if (state.length > 0) {
//                 state.forEach(m => {
//                     if (typeof m.rect.setMap === "function")
//                         m.rect.setMap(null); //Se for Google Maps
//                     //if (typeof m.icon.remove === "function") m.icon.remove(); //Se for OpenStreet
//                 });
//             }
//             return [];
//         default:
//             return state;
//     }
// };
