import { Button, Modal, Popconfirm } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IUser, IUserList } from "../../../reducers/UserReducer";
import { IStore } from "../../../store";
import { AuthState, IStatusList } from "../../../store/types";
import { getUsuario } from "../../../store/userActions";
import AdicionarUsuarios from "./Usuarios/AdicionarUsuarios";
import EditarUsuario from "./Usuarios/EditarUsuario";

interface IUsuariosProps {
    usuarios: IUserList;
    usuario: IUser;

    auth: AuthState;
    loader: IStatusList;

    getUsuario: Function;
    theme: ITheme;
}

function Usuarios(props: IUsuariosProps) {
    const { usuario, usuarios, loader, auth, getUsuario, theme } = props;
    const [which, setWhich] = useState("");
    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });

    useMemo(() => {
        getUsuario(auth.user.id);
    }, []);

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Usuário",
            conteudo: <AdicionarUsuarios setModal={setModal} tema={theme} />,
        });
        setModal(true);
    }

    function handleEditar(id: any) {
        const userKey = usuarios.findIndex((user: any) => user.id_user === id);
        setConfigModal({
            titulo: "Editar Usuário",
            conteudo: (
                <EditarUsuario
                    tema={theme}
                    setModal={setModal}
                    cont={usuarios[userKey]}
                />
            ),
        });

        setModal(true);
    }

    function handleDisable() {
        if (which !== "") {
            if (usuario.id_user) {
                return which !== usuario.id_user;
            } else {
                return false;
            }
        } else return true;
    }

    return (
        <>
            <div style={{ height: "100%" }}>
                Usuários cadastrados:
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {usuarios.map((u: any) => {
                                let classN = theme
                                    ? "select-expand-li-dark"
                                    : "select-expand-li";
                                if (u.id_user === which) {
                                    classN += " active";
                                }
                                return (
                                    <li
                                        className={classN}
                                        key={u.id_user}
                                        value={u.id_user}
                                        onClick={() => setWhich(u.id_user)}
                                        // onDoubleClick={(e: any) =>
                                        //     console.log(e)
                                        // }
                                    >
                                        {u.nome}
                                    </li>
                                );
                            })}
                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich("")}
                            ></li>
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={handleDisable()}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        {/* <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir o servidor selecionado?"
                            onConfirm={() => handleExcluir(which)}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                // disabled={which === 0 ? true : false}
                                disabled={true}
                            >
                                Excluir
                            </Button>
                        </Popconfirm> */}
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        auth: store.auth,
        loader: store.loader,
        usuarios: store.usuarios,
        usuario: store.usuario,

        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ getUsuario }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
