import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    List,
    message,
    Row,
    Select,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IAntenaList } from "../../../../reducers/AntenaReducer";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { IStore } from "../../../../store";
import { setStatusNull } from "../../../../store/actions";
import { adicionarServidor } from "../../../../store/servidorActions";
import { IStatusList } from "../../../../store/types";

const { Option } = Select;

interface PropsAddServidor {
    status: IStatusList;
    fazendas: IFazendaList;

    adicionarServidor: Function;
    setStatusNull: Function;

    setModal: Function;
}

function AdicionarServidor(props: PropsAddServidor) {
    const { status, fazendas, adicionarServidor, setStatusNull } = props;

    const [descricao, setDescricao] = useState(false);
    const [faz, setFaz] = useState(false);
    const [ip, setIp] = useState(false);

    const handleDisable = useCallback(() => {
        if (descricao === true && faz === true && ip === true) {
            return false;
        }
        return true;
    }, [descricao, faz, ip]);

    useEffect(() => {
        if (status.servidor?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "servidor");
        }
    });

    const data = [
        {
            title: "Ant Design Title 1",
        },
        {
            title: "Ant Design Title 2",
        },
        {
            title: "Ant Design Title 3",
        },
        {
            title: "Ant Design Title 4",
        },
    ];

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    adicionarServidor(v);
                    props.setModal(false);
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite uma descrição",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setDescricao(false),
                                                Promise.reject()
                                            );
                                        }
                                        setDescricao(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Descrição:"
                            name="descricao"
                        >
                            <Input placeholder="Digite a descrição do servidor" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione a fazenda",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setFaz(false), Promise.reject()
                                            );
                                        }
                                        setFaz(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fazenda:"
                            name="fazenda_temp"
                        >
                            <Select placeholder="Selecione a fazenda">
                                {fazendas.map((i: any, k: any) => {
                                    return (
                                        <Option key={k} value={i.id_fazenda}>
                                            {i.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Digite um IP",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setIp(false), Promise.reject()
                                            );
                                        }
                                        setIp(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="IP do servidor:"
                            name="ip_local_server"
                        >
                            <Input placeholder="Digite o IP do servidor" />
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={handleDisable()}
                        loading={status.servidor?.add?.loading}
                    >
                        Salvar servidor
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        //servidores: store.servidores,
        fazendas: store.fazendas,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarServidor,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarServidor);
