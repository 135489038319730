import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import {
    adicionarAlertaUser,
    deletarAlertaUser,
    editarAlerta,
} from "../../../../store/alertaActions";
import { setStatusNull } from "../../../../store/actions";
import { IStatusList } from "../../../../store/types";

import { tipos } from "../../../../reducers/OperacaoReducer";
import {
    IAlertaUser,
    criterioAlerta,
} from "../../../../reducers/AlertaReducer";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../../reducers/EquipamentoReducer";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { IUser, IUserList } from "../../../../reducers/UserReducer";

const { Option, OptGroup } = Select;

interface EditarAlertasProps {
    status: IStatusList;
    setStatusNull: any;
    editarAlerta: Function;
    tipoEquip: any[];
    equipamentos: IEquipamentoList;
    tiposOp: ITipoOpList;

    cont: any;
    setModal: any;

    users: any;

    tipoOpCList: ITipoOpClienteList;
    usuarios: IUserList;

    adicionarAlertaUser: Function;
    deletarAlertaUser: Function;
}

function EditarAlerta(props: EditarAlertasProps) {
    const {
        status,
        editarAlerta,
        setStatusNull,
        tipoEquip,
        equipamentos,
        tiposOp,

        tipoOpCList,
        usuarios,

        adicionarAlertaUser,
        deletarAlertaUser,
    } = props;

    const [form] = Form.useForm();

    const [criterio, setCriterio] = useState(true);
    const [tipoAlerta, setTipoAlerta] = useState(true);
    const [tipoEquipa, setTipoEquipa] = useState(true);

    const [habilitaValor, setHabilitaValor] = useState(false);

    const [selecTipoEquip, setSelecTipoEquip] = useState(
        props.cont.tipo_equipamento
    );

    const [equipa, setEquipa] = useState("");
    const [tipoOp, setTipoOp] = useState("");

    const [reqOp, setReqOp] = useState(props.cont.criterio ? true : false);

    const [exibePortal, setExibePortal] = useState(props.cont.exibe_portal);
    const [exibeBordo, setExibeBordo] = useState(props.cont.exibe_bordo);
    const [exibeWhats, setExibeWhats] = useState(props.cont.exibe_whatsapp);

    const [haDesloc, setHaDesloc] = useState(false);
    const [haOpe, setHaOpe] = useState(false);

    const [userList, setUserList] = useState(false);

    const filteredOpeOptions = tiposOp.filter((o: any) =>
        tipoOpCList.some(
            (s: any) => s.tipo_operacao_temp === o.id_tipo_operacao
        )
    );

    const filteredUsers = usuarios.filter((u: IUser) => {
        if (u.envia_alerta === true) {
            return (
                props.users.findIndex(
                    (i: any) => i.usuario_temp === u.id_user
                ) === -1
            );
        }
    });

    const [opcoes, setOpcoes] = useState(
        criterioAlerta.filter((c: any) => {
            if (
                tipos[
                    tipos.findIndex(
                        (t: any) => t.value.toUpperCase() === props.cont.tipo
                    )
                ].typeValue === "boolean"
            ) {
                return c.id === 1 || c.id === 2;
            } else return c.id === 3 || c.id === 4;
        })
    );

    const handleDisable = useCallback(() => {
        if (reqOp) {
            if (
                tipoEquipa === true &&
                criterio === true &&
                tipoAlerta === true &&
                (exibePortal === true || exibeWhats === true)
            ) {
                return false;
            }
        } else {
            if (
                tipoEquipa === true &&
                tipoAlerta === true &&
                (exibePortal === true || exibeWhats === true)
            ) {
                return false;
            }
        }

        return true;
    }, [tipoEquipa, criterio, tipoAlerta, exibePortal, exibeWhats, reqOp]);

    function handleChange(value: any) {
        const teste = tipos.findIndex(
            (t: any) => t.value.toUpperCase() === value
        );

        form.setFieldsValue({ criterio: null });
        setReqOp(true);

        let criterios: any = criterioAlerta.filter((c: any) => {
            if (teste !== -1) {
                if (tipos[teste].typeValue === "na") {
                    setReqOp(false);
                    setHabilitaValor(false);
                    form.setFieldsValue({ valor: null });
                    form.setFieldsValue({ criterio: "" });
                    form.setFieldsValue({ exibe_bordo: false });
                } else {
                    if (tipos[teste].typeValue === "boolean") {
                        setHabilitaValor(false);
                        form.setFieldsValue({ valor: null });
                        return c.id === 1 || c.id === 2;
                    } else {
                        setHabilitaValor(true);
                        return c.id === 3 || c.id === 4;
                    }
                }
            }
        });

        setOpcoes(criterios);
    }

    function handleChangeEquipa(value: any) {
        setEquipa(value);
    }

    function handleChangeOp(value: any) {
        setTipoOp(value);
    }

    useEffect(() => {
        if (status.alerta?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "alerta");
        }
    });

    useEffect(() => {
        if (props.cont.valor !== null) {
            setHabilitaValor(true);
        }
    }, [props.cont.valor]);

    useEffect(() => {
        if (!reqOp) {
            setExibePortal(true);
        }
    }, [reqOp]);

    useEffect(() => {
        if (filteredOpeOptions.length > 0) {
            filteredOpeOptions.forEach((o: any) => {
                if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                    setHaOpe(true);
                } else if (
                    o.id_tipo_operacao >= 900 &&
                    o.id_tipo_operacao <= 999
                ) {
                    setHaDesloc(true);
                }
            });
        }
    }, [filteredOpeOptions, tipoOpCList]);

    useEffect(() => {
        if (exibeWhats === false) {
            form.setFieldsValue({ users: [] });
        }
    }, [exibeWhats]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    ...props.cont,
                    criterio: props.cont.criterio ? props.cont.criterio : "",
                    equipamento_temp: props.cont.equipamento_temp
                        ? props.cont.equipamento_temp
                        : equipa,
                    tipo_operacao_temp: props.cont.tipo_operacao_temp
                        ? props.cont.tipo_operacao_temp
                        : tipoOp,
                    users:
                        props.users.length > 0
                            ? props.users.map((u: IAlertaUser) => {
                                  return usuarios[
                                      usuarios.findIndex(
                                          (i: IUser) =>
                                              u.usuario_temp === i.id_user
                                      )
                                  ].id_user;
                              })
                            : [],
                }}
                onFinish={(v: any) => {
                    if (v.exibe_whatsapp) {
                        props.users.map((u: any) => {
                            let f = v.users.find(
                                (e: any) => e === u.usuario_temp
                            );
                            if (f === undefined) {
                                //delete u.id_alerta_user
                                deletarAlertaUser(u.id_alerta_user);
                            }
                            //if undefined removeu
                            //else add
                            //cuidar para não add repetido
                        });
                        v.users.map((n: any) => {
                            let f2 = props.users.find(
                                (u: IAlertaUser) => u.usuario_temp === n
                            );
                            if (f2 === undefined) {
                                let obj = {
                                    usuario_temp: n,
                                    alerta_temp: props.cont.alerta_id,
                                };
                                adicionarAlertaUser(obj);
                            }
                        });
                    }
                    let objAlerta = {
                        alerta_id: v.alerta_id,
                        tipo: v.tipo,
                        criterio: v.criterio,
                        valor: v.valor,
                        tipo_equipamento: v.tipo_equipamento,
                        exibe_portal: v.exibe_portal,
                        exibe_bordo: false, //variavel desabilitada no momento
                        exibe_whatsapp: v.exibe_whatsapp,
                        equipamento_temp: v.equipamento_temp,
                        tipo_operacao_temp: v.tipo_operacao_temp,
                    };
                    editarAlerta(objAlerta);
                    props.setModal(false);
                }}
            >
                <Form.Item name="alerta_id" style={{ display: "none" }}>
                    <Input type="hidden" name="alerta_id"></Input>
                </Form.Item>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo de equipamento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipoEquipa(false),
                                                Promise.reject()
                                            );
                                        }
                                        setTipoEquipa(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo de Equipamento:"
                            name="tipo_equipamento"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um tipo de equipamento"
                                onChange={v => {
                                    setSelecTipoEquip(v);
                                    form.setFieldsValue({
                                        equipamento_temp: "",
                                        tipo: null,
                                    });
                                }}
                            >
                                {tipoEquip.map((e: any) => {
                                    return (
                                        <Option key={uuidv4()} value={e.id}>
                                            {e.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "equipamento_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um equipamento",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === undefined) {
                                            return Promise.reject();
                                        }
                                        if (value === "") {
                                            return Promise.resolve();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Equipamento:"
                            name="equipamento_temp"
                        >
                            <Select
                                allowClear={true}
                                placeholder="Selecione um equipamento"
                                onChange={handleChangeEquipa}
                            >
                                <Option value="">Todos</Option>
                                {equipamentos
                                    .filter(
                                        (e: any) =>
                                            e.tipo === selecTipoEquip + ""
                                    )
                                    .map((e: IEquipamento) => {
                                        return (
                                            <Option
                                                key={uuidv4()}
                                                value={e.id_equipamento}
                                            >
                                                {e.numero_patrimonio +
                                                    " -" +
                                                    e.descricao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um tipo de alerta",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setTipoAlerta(false),
                                                Promise.reject()
                                            );
                                        }
                                        setTipoAlerta(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tipo do Alerta:"
                            name="tipo"
                        >
                            <Select
                                placeholder="Selecione um tipo de alerta"
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                                onChange={handleChange}
                            >
                                {tipos
                                    .filter(t => {
                                        if (t.tipoEquip) {
                                            return (
                                                t.tipoEquip ===
                                                Number(selecTipoEquip)
                                            );
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((t: any) => {
                                        return (
                                            <Option
                                                key={uuidv4()}
                                                value={t.value.toUpperCase()}
                                            >
                                                {t.label}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    required: reqOp,
                                    message: "Selecione um critério",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            value === undefined ||
                                            value === null ||
                                            value === ""
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            if (!value) {
                                                return (
                                                    setCriterio(false),
                                                    Promise.reject()
                                                );
                                            }
                                            setCriterio(true);
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Critério:"
                            name="criterio"
                        >
                            <Select
                                disabled={!reqOp}
                                placeholder="Selecione um critério"
                                allowClear={true}
                                onChange={(v: any) => {
                                    if (v === "VERDADEIRO" || v === "FALSO") {
                                        setHabilitaValor(false);
                                        form.setFieldsValue({ valor: "" });
                                    } else {
                                        setHabilitaValor(true);
                                    }
                                }}
                            >
                                {opcoes.map((c: any) => {
                                    return (
                                        <Option key={uuidv4()} value={c.nome}>
                                            {c.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: habilitaValor,
                                    message: "Coloque um valor",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            value === undefined ||
                                            value === null
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            if (isNaN(value)) {
                                                return Promise.reject(
                                                    "Use apenas numeros"
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            label="Valor:"
                            name="valor"
                        >
                            <Input
                                disabled={!habilitaValor}
                                placeholder="Digite um valor"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required:
                                        form.getFieldValue(
                                            "tipo_operacao_temp"
                                        ) !== "undefined"
                                            ? false
                                            : true,
                                    message: "Selecione um tipo de operação",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value === "") {
                                            return Promise.resolve();
                                        } else {
                                            if (value === undefined) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            label="Tipo de Operação:"
                            name="tipo_operacao_temp"
                        >
                            <Select
                                disabled={!reqOp}
                                allowClear={true}
                                showSearch
                                filterOption={(inputValue, option: any) => {
                                    let condicao = false;

                                    if (option.children) {
                                        return option.children
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    } else if (option.label) {
                                        return option.label
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    }

                                    return condicao;
                                }}
                                placeholder="Selecione um tipo de operação"
                                onChange={handleChangeOp}
                            >
                                <Option value="">Todos</Option>
                                <OptGroup label="Tipo Operação">
                                    {haOpe ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao > 0 &&
                                                        e.id_tipo_operacao <
                                                            800 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhuma op.
                                                    cadastrada.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                                <OptGroup label="Tipo Deslocamento">
                                    {haDesloc ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao >=
                                                        900 &&
                                                        e.id_tipo_operacao <=
                                                            999 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhum deslocamento
                                                    cadastrado.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div>Exibição do alerta:</div>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item label="" name="exibe_portal">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "5px",
                                            alignItems: "baseline",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                disabled={!reqOp}
                                                defaultChecked
                                                checked={exibePortal}
                                                onChange={(x: any) => {
                                                    setExibePortal(x);
                                                    form.setFieldsValue({
                                                        exibe_portal: x,
                                                    });
                                                }}
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                            />
                                            <Input
                                                type="hidden"
                                                name="exibe_portal"
                                            ></Input>
                                        </div>{" "}
                                        <div
                                            onClick={() =>
                                                setExibePortal(!exibePortal)
                                            }
                                            style={{
                                                pointerEvents: `${
                                                    !reqOp ? "none" : "auto"
                                                }`,
                                                cursor: "pointer",
                                            }}
                                        >
                                            Portal
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item label="" name="exibe_bordo">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "5px",
                                            alignItems: "baseline",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                disabled={!reqOp}
                                                defaultChecked
                                                checked={exibeBordo}
                                                onChange={(x: any) => {
                                                    setExibeBordo(x);
                                                    form.setFieldsValue({
                                                        exibe_bordo: x,
                                                    });
                                                }}
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                            />
                                            <Input
                                                type="hidden"
                                                name="exibe_bordo"
                                            ></Input>
                                        </div>{" "}
                                        <div
                                            onClick={() =>
                                                setExibeBordo(!exibeBordo)
                                            }
                                            style={{
                                                pointerEvents: `${
                                                    !reqOp ? "none" : "auto"
                                                }`,
                                                cursor: "pointer",
                                            }}
                                        >
                                            Bordo
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col> */}
                            <Col span={12}>
                                <Form.Item label="" name="exibe_whatsapp">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "5px",
                                            alignItems: "baseline",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                defaultChecked
                                                checked={exibeWhats}
                                                onChange={(x: any) => {
                                                    setExibeWhats(x);
                                                    form.setFieldsValue({
                                                        exibe_whatsapp: x,
                                                    });
                                                }}
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                            />
                                            <Input
                                                type="hidden"
                                                name="exibe_whatsapp"
                                            ></Input>
                                        </div>{" "}
                                        <div
                                            onClick={() =>
                                                setExibeWhats(!exibeWhats)
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            WhatsApp
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <div
                        style={{
                            borderTop: "1px solid #f0f0f0",
                            width: "100%",
                            padding: "12px",
                        }}
                    >
                        Usuários vinculados ao alerta:
                    </div>
                    <Col span={24}>
                        <Form.Item
                            rules={[
                                {
                                    required: exibeWhats ? true : false,
                                    message: "Selecione um usuário",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setUserList(false),
                                                Promise.reject()
                                            );
                                        }
                                        setUserList(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Envio para:"
                            name="users"
                        >
                            <Select
                                disabled={exibeWhats === false}
                                mode="multiple"
                                allowClear={false}
                                showSearch
                                filterOption={(inputValue, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) >= 0
                                }
                                placeholder="Selecione usuários"
                                style={{ maxHeight: "80px", overflow: "auto" }}
                                onChange={(e: any) => {
                                    if (e.includes("")) {
                                        let userIds: any = [];
                                        filteredUsers.map((u: IUser) => {
                                            userIds.push(u.id_user);
                                        });
                                        props.users.length > 0 &&
                                            props.users.map(
                                                (i: IAlertaUser) => {
                                                    userIds.push(
                                                        i.usuario_temp
                                                    );
                                                }
                                            );
                                        form.setFieldsValue({
                                            users: [...userIds],
                                        });
                                    }
                                }}
                                onDeselect={() => {}}
                                dropdownClassName="agrochip-dropdown"
                            >
                                {props.users.length > 0 &&
                                    props.users.map((u: IAlertaUser) => {
                                        return (
                                            <Select.Option
                                                key={u.usuario_temp}
                                                value={u.usuario_temp}
                                                label={
                                                    usuarios[
                                                        usuarios.findIndex(
                                                            (i: any) =>
                                                                i.id_user ===
                                                                u.usuario_temp
                                                        )
                                                    ].nome
                                                }
                                            >
                                                {
                                                    usuarios[
                                                        usuarios.findIndex(
                                                            (i: any) =>
                                                                i.id_user ===
                                                                u.usuario_temp
                                                        )
                                                    ].nome
                                                }
                                            </Select.Option>
                                        );
                                    })}
                                <Option value="">Todos</Option>
                                {filteredUsers.map((u: IUser) => {
                                    return (
                                        <Option
                                            value={u.id_user}
                                            key={u.id_user}
                                        >
                                            {u.nome_contato}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        loading={status.alerta?.edit?.loading}
                        block
                    >
                        Salvar Alerta
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        status: store.loader,
        tipoEquip: store.attrEquipamento.tipos,
        tiposOp: store.tiposOp,

        tipoOpCList: store.tiposOpCliente,
        usuarios: store.usuarios,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        { setStatusNull, editarAlerta, adicionarAlertaUser, deletarAlertaUser },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarAlerta);
