import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    ICulturaClienteList,
    ICulturaList,
} from "../../../reducers/CulturaReducer";
import { IEquipamentoList } from "../../../reducers/EquipamentoReducer";
import { IFazendaList } from "../../../reducers/FazendaReducer";
import { ISafraClienteList, ISafraList } from "../../../reducers/SafraReducer";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../reducers/TipoOpReducer";
import { Divider, Form, Select, Row, Col, Button, Tabs, Switch } from "antd";
import { IPreferencia } from "../../../reducers/PreferenciaReducer";
import {
    editarPreferencia,
    IEditPreferencia,
} from "../../../store/preferenciaActions";
import { IStore } from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { ITheme } from "../../../reducers/ThemeReducer";

const { Option } = Select;
const { TabPane } = Tabs;

interface PropsGeral {
    fazendaListState: IFazendaList;
    equipamentoListState: IEquipamentoList;
    culturaListState: ICulturaList;
    safraListState: ISafraList;
    tipoOpListState: ITipoOpList;
    editarPreferencia: Function;
    preferencia: IPreferencia;
    theme: ITheme;

    culturaCList: ICulturaClienteList;
    safraCList: ISafraClienteList;
    tipoOpCList: ITipoOpClienteList;
}

function Geral(props: PropsGeral) {
    const {
        fazendaListState,
        equipamentoListState,
        culturaListState,
        safraListState,
        tipoOpListState,
        editarPreferencia,
        preferencia,
        theme,

        culturaCList,
        safraCList,
        tipoOpCList,
    } = props;

    const [faz, setFaz] = useState(preferencia.faz ? preferencia.faz : "");
    const [equipa, setEquipa] = useState(
        preferencia.equipa ? preferencia.equipa : ""
    );
    const [safra, setSafra] = useState(
        preferencia.safra ? preferencia.safra : ""
    );
    const [cultura, setCultura] = useState(
        preferencia.cultura ? preferencia.cultura : ""
    );
    const [tipoOp, setTipoOp] = useState(
        preferencia.tipoOp ? preferencia.tipoOp : ""
    );
    const [tutorial, setTutorial] = useState(
        preferencia.tutorial ? preferencia.tutorial : ""
    );
    const [tema, setTema] = useState(preferencia.tema ? preferencia.tema : "");

    const [tabAtiva, setTabAtiva] = useState(uuidv4());

    const tabs = [
        { title: "Preferências de acesso", key: "preferencia" },
        { title: "Tema", key: "tema" },
    ];

    function handleSelectFaz(value: any) {
        setFaz(value);
    }
    function handleSelectEquipa(value: any) {
        setEquipa(value);
    }
    function handleSelectSafra(value: any) {
        setSafra(value);
    }
    function handleSelectCult(value: any) {
        setCultura(value);
    }
    function handleSelectTipoOp(value: any) {
        setTipoOp(value);
    }
    function handleSelectTema(value: any) {
        setTema(value);
    }

    function salvarPreferencias() {
        let obj: any = {};

        [
            { faz: faz },
            { equipa: equipa },
            { safra: safra },
            { cultura: cultura },
            { tipoOp: tipoOp },
            { tutorial: tutorial },
            { tema: tema },
        ].forEach((c: any) => {
            let varName = Object.keys(c)[0];
            if (c[varName] !== "") {
                obj[varName] = c[varName];
            }
        });

        editarPreferencia({ preferencia: JSON.stringify(obj) });
    }

    const filteredCultOptions = culturaListState.filter((o: any) =>
        culturaCList.some((s: any) => s.cultura_temp === o.id_cultura)
    );
    const filteredSfrOptions = safraListState.filter((o: any) =>
        safraCList.some((s: any) => s.safra_temp === o.id_safra)
    );
    const filteredOpeOptions = tipoOpListState.filter((o: any) =>
        tipoOpCList.some((s: any) => {
            if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                return s.tipo_operacao_temp === o.id_tipo_operacao;
            }
        })
    );

    return (
        <div
            style={{
                marginTop: "-15px",
            }}
        >
            <Tabs defaultActiveKey="preferencias" onChange={(x: any) => {}}>
                <TabPane tab="Preferências de acesso" key="preferencias">
                    <div
                        style={{
                            position: "absolute",
                            backgroundColor: theme ? "#303030" : "white",
                            right: 0,
                            bottom: 5,
                            width: "600px",
                            height: "52px",
                            zIndex: 2,
                            textAlign: "right",
                            padding: "8px 20px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => salvarPreferencias()}
                        >
                            Aplicar
                        </Button>
                    </div>

                    <div>
                        <Form
                            layout="vertical"
                            initialValues={{
                                faz: preferencia.faz ? preferencia.faz : "",
                                equipa: preferencia.equipa
                                    ? preferencia.equipa
                                    : "",
                                safra: preferencia.safra
                                    ? preferencia.safra
                                    : "",
                                tipoOp: preferencia.tipoOp
                                    ? preferencia.tipoOp
                                    : "",
                                cultura: preferencia.cultura
                                    ? preferencia.cultura
                                    : "",
                                tutorial: preferencia.tutorial
                                    ? preferencia.tutorial
                                    : "",
                            }}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        name="faz"
                                        label="Fazenda padrão"
                                    >
                                        <Select
                                            placeholder="Escolha uma fazenda"
                                            onSelect={handleSelectFaz}
                                        >
                                            <Option value="">
                                                Não aplicar
                                            </Option>
                                            {fazendaListState.map((f: any) => {
                                                return (
                                                    <Option
                                                        key={f.id_fazenda}
                                                        value={f.id_fazenda}
                                                    >
                                                        {f.nome}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="equipa"
                                        label="Equipamento padrão"
                                    >
                                        <Select
                                            placeholder="Escolha um equipamento"
                                            onSelect={handleSelectEquipa}
                                        >
                                            <Option value="">
                                                Não aplicar
                                            </Option>
                                            {equipamentoListState.map(
                                                (e: any) => {
                                                    return (
                                                        <Option
                                                            key={
                                                                e.id_equipamento
                                                            }
                                                            value={
                                                                e.id_equipamento
                                                            }
                                                        >
                                                            {e.descricao}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col span={6}>
                                    <Form.Item
                                        name="safra"
                                        label="Safra padrão"
                                    >
                                        <Select
                                            placeholder="Escolha uma safra"
                                            onSelect={handleSelectSafra}
                                        >
                                            <Option value="">
                                                Não aplicar
                                            </Option>
                                            {filteredSfrOptions.map(
                                                (s: any) => {
                                                    return (
                                                        <Option
                                                            key={s.id_safra}
                                                            value={s.id_safra}
                                                        >
                                                            {s.descricao}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        name="cultura"
                                        label="Cultura padrão"
                                    >
                                        <Select
                                            placeholder="Escolha uma cultura"
                                            onSelect={handleSelectCult}
                                        >
                                            <Option value="">
                                                Não aplicar
                                            </Option>
                                            {filteredCultOptions.map(
                                                (c: any) => {
                                                    return (
                                                        <Option
                                                            key={c.id_cultura}
                                                            value={c.id_cultura}
                                                        >
                                                            {c.nomeCultura}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        name="tipoOp"
                                        label="Tipo de operação padrão"
                                    >
                                        <Select
                                            placeholder="Escolha um tipo de operação"
                                            onSelect={handleSelectTipoOp}
                                        >
                                            <Option value="">
                                                Não aplicar
                                            </Option>
                                            {filteredOpeOptions.map(
                                                (o: any) => {
                                                    return (
                                                        <Option
                                                            key={
                                                                o.id_tipo_operacao
                                                            }
                                                            value={
                                                                o.id_tipo_operacao
                                                            }
                                                        >
                                                            {o.descricao}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <div
                                        style={{
                                            borderTop:
                                                "1px solid " +
                                                `${
                                                    theme
                                                        ? "#424242"
                                                        : "#f0f0f0"
                                                }`,
                                            paddingTop: "10px",
                                        }}
                                    >
                                        <Form.Item name="tutorial" label="">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    columnGap: "15px",
                                                    alignItems: "baseline",
                                                }}
                                            >
                                                <div>
                                                    <Switch
                                                        defaultChecked
                                                        checked={tutorial}
                                                        onChange={(x: any) =>
                                                            setTutorial(x)
                                                        }
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                    />
                                                </div>{" "}
                                                <div
                                                    onClick={() =>
                                                        setTutorial(!tutorial)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Visualizar tutorial de
                                                    boas-vindas ao iniciar
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </TabPane>
                <TabPane tab="Tema" key="tema">
                    <div
                        style={{
                            position: "absolute",
                            backgroundColor: theme ? "#303030" : "white",
                            right: 0,
                            bottom: 5,
                            width: "600px",
                            height: "52px",
                            zIndex: 2,
                            textAlign: "right",
                            padding: "8px 20px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => salvarPreferencias()}
                        >
                            Aplicar
                        </Button>
                    </div>

                    <div>
                        <Form
                            layout="vertical"
                            initialValues={{
                                tema: preferencia.tema,
                            }}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={12}>
                                    <Form.Item name="tema" label="Tema padrão">
                                        <Select
                                            placeholder="Escolha uma preferência de tema"
                                            onSelect={handleSelectTema}
                                        >
                                            <Option value={false}>Claro</Option>
                                            <Option value={true}>Escuro</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendaListState: store.fazendas,
        equipamentoListState: store.equipamentos,
        culturaListState: store.culturas,
        safraListState: store.safras,
        tipoOpListState: store.tiposOp,
        preferencia: store.preferencia,
        theme: store.theme,

        culturaCList: store.culturasCliente,
        safraCList: store.safrasCliente,
        tipoOpCList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ editarPreferencia }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Geral);
