import {
    ConfigContent,
    ConfigMenuList,
    ConfigMenuItem,
    ConfigWrapper,
    Spinner,
} from "../../styles/Main";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Fazendas from "./Configuracoes/Fazendas";
import Equipamentos from "./Configuracoes/Equipamentos";
import Operadores from "./Configuracoes/Operadores";
import { bindActionCreators } from "redux";
import Geral from "./Configuracoes/Geral";
import { IStore } from "../../store";
import { IStatusList } from "../../store/types";
import Servidores from "./Configuracoes/Servidores";
import { ITheme } from "../../reducers/ThemeReducer";
import Alertas from "./Configuracoes/Alertas";
import Usuarios from "./Configuracoes/Usuarios";
import Talhoes from "./Configuracoes/Talhoes";
import Implementos from "./Configuracoes/Implementos";
import Parametros from "./Configuracoes/Parametros";
import ParametrosOpe from "./Configuracoes/ParametrosOpe";

interface ConfiguracoesProps {
    loader: IStatusList;
    theme: ITheme;
}

function Configuracoes(props: ConfiguracoesProps) {
    const [which, setWhich] = useState(1);
    const { loader, theme } = props;

    const itens = [
        { id: 1, nome: "Geral" },
        { id: 2, nome: "Fazendas", status: loader.fazenda?.get?.loading },
        { id: 3, nome: "Talhões" },
        { id: 4, nome: "Parâmetros Operacional" },
        {
            id: 5,
            nome: "Operadores",
            status: loader.operador?.get?.loading,
        },
        {
            id: 6,
            nome: "Equipamentos",
            status: loader.equipamento?.get?.loading,
        },
        // { id: 5, nome: "Antenas", status: loader.antena?.get?.loading },
        { id: 7, nome: "Implementos" },
        { id: 8, nome: "Parâmetros de Consumo" },
        { id: 9, nome: "Servidores" },
        { id: 10, nome: "Alertas" },
        //        { id: 4, nome: "Cultura" },
        //        { id: 5, nome: "Safra" },
        //        { id: 6, nome: "Tipo de Operação" },
        { id: 11, nome: "Usuários" },
    ];

    const renderContent = (which: any) => {
        switch (which) {
            case 1:
                return <Geral />;
            case 2:
                return <Fazendas />;
            case 3:
                return <Talhoes />;
            case 4:
                return <ParametrosOpe />;
            case 5:
                return <Operadores />;
            case 6:
                return <Equipamentos />;
            // case 5:
            //     return <Antenas />;
            case 7:
                return <Implementos />;
            case 8:
                return <Parametros />;
            case 9:
                return <Servidores />;
            case 10:
                return <Alertas />;
            case 11:
                return <Usuarios />;
            default:
                return <div>Nada foi selecionado.</div>;
        }
    };

    return (
        <ConfigWrapper>
            <ConfigMenuList
                tema={theme}
                style={{ overflowY: "scroll", overflowX: "hidden" }}
            >
                {itens.map(i => {
                    return (
                        <ConfigMenuItem
                            tema={theme}
                            key={i.id}
                            active={which === i.id}
                            onClick={() => setWhich(i.id)}
                        >
                            <div>{i.nome}</div>
                            <Spinner view={i.status}>
                                <i className="fas fa-spinner"></i>
                            </Spinner>
                        </ConfigMenuItem>
                    );
                })}
            </ConfigMenuList>
            <ConfigContent>{renderContent(which)}</ConfigContent>
        </ConfigWrapper>
    );
}

function mapStateToProps(store: IStore) {
    return {
        loader: store.loader,
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuracoes);
