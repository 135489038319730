import { message } from "antd";
import {
    IAlerta,
    IAlertaList,
    IAlertaRender,
    IAlertaUser,
    IAlertaUserList,
} from "../reducers/AlertaReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import {
    SET_ALERTA,
    SET_ALERTA_USUARIO,
    SET_LIST_ALERTA,
    SET_LIST_ALERTA_USUARIO,
    SET_RENDER_ALERTA,
} from "./actionTypes";

export const setAlerta = (value: IAlerta) => ({
    type: SET_ALERTA,
    payload: value,
});

export const setListAlerta = (value: IAlertaList) => ({
    type: SET_LIST_ALERTA,
    payload: value,
});

export const setRenderAlerta = (value: IAlertaRender) => ({
    type: SET_RENDER_ALERTA,
    payload: value,
});

export const getAlerta = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "alerta"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "alerta", res.erro));
                } else {
                    dispatch(setListAlerta(res));
                    dispatch(setStatusSuccess("get", "alerta"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "alerta", error.data));
            });
    };
};

interface IAddAlerta {
    tipo: string;
    criterio: string;
    valor: number | null;
    tipo_equipamento: number;
    exibe_portal: boolean;
    exibe_bordo: boolean;
    exibe_whatsapp: boolean;
    equipamento_temp: string | null;
    tipo_operacao_temp: string | null;
}

export const adicionarAlerta = (value: IAddAlerta) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "alerta"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "alerta", res.erro));
                } else {
                    dispatch(getAlerta());
                    dispatch(setStatusSuccess("add", "alerta"));
                    message.success("Alerta adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "alerta", error.data));
            });
    };
};

interface IEditAlerta {
    alerta_id: string;
    tipo: string;
    criterio: string;
    valor: number | null;
    tipo_equipamento: number;
    exibe_portal: boolean;
    exibe_bordo: boolean;
    exibe_whatsapp: boolean;
    equipamento_temp: string | null;
    tipo_operacao_temp: string | null;
}

export const editarAlerta = (value: IEditAlerta) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "alerta"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "alerta", res.erro));
                } else {
                    dispatch(getAlerta());
                    dispatch(setStatusSuccess("edit", "alerta"));
                    message.success("Alerta editado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "alerta", error.data));
            });
    };
};

interface IDeletAlerta {
    value: string;
}

export const deletarAlerta = (value: IDeletAlerta) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "alerta"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta/` + value, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "alerta", res.erro));
                } else {
                    dispatch(getAlerta());
                    dispatch(getAlertaUser());
                    dispatch(setStatusSuccess("del", "alerta"));
                    message.success("Alerta deletado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "alerta", error.data));
            });
    };
};

type alertaRenderTypes = string | undefined;
export interface IGetAlertaRenderProps {
    [key: string]: alertaRenderTypes;
    faz: alertaRenderTypes;
    equip: alertaRenderTypes;
    dataIni?: alertaRenderTypes;
    dataFin?: alertaRenderTypes;
    gpson?: alertaRenderTypes;
    alerta?: alertaRenderTypes;
}
export const getAlertaRender = (props: IGetAlertaRenderProps) => {
    return (dispatch: any) => {
        const keys = Object.keys(props);
        var urlApi =
            "api/operacao/" +
            props.faz +
            "/?page=0&size=100000&colunas=longitude,latitude,alerta,txtalerta,equipamento_temp,tipo_operacao_temp,operador_temp,implemento_temp,talhao_temp" +
            "&equipamento=" +
            props.equip +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz" && key !== "equip")
                    urlApi += key + "=" + props[key] + "&";
            }
        });
        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.text();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch(setRenderAlerta(JSON.parse(res).dados));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const setAlertaUser = (value: IAlertaUser) => ({
    type: SET_ALERTA_USUARIO,
    payload: value,
});

export const setListAlertaUser = (value: IAlertaUserList) => ({
    type: SET_LIST_ALERTA_USUARIO,
    payload: value,
});

export const getAlertaUser = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "alertaUser"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta_usuario/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "alertaUser", res.erro));
                } else {
                    dispatch(setListAlertaUser(res));
                    dispatch(setStatusSuccess("get", "alertaUser"));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

interface IAddAlertaUser {
    usuario_temp: string | null;
    alerta_temp: number | null;
}

export const adicionarAlertaUser = (value: IAddAlertaUser) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "alertaUser"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/alerta_usuario/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "alertaUser", res.erro));
                } else {
                    dispatch(getAlertaUser());
                    dispatch(setStatusSuccess("add", "alertaUser"));
                    // message.success("Alerta adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "alertaUser", error.data));
            });
    };
};

interface IDeletAlertaUser {
    value: string;
}

export const deletarAlertaUser = (value: IDeletAlertaUser) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "alertaUser"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/alerta_usuario/` + value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "alertaUser", res.erro));
                } else {
                    dispatch(getAlertaUser());
                    dispatch(setStatusSuccess("del", "alertaUser"));
                    message.success("Usuário desvinculado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "alertaUser", error.data));
            });
    };
};
