import shp from "shpjs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const getCoord = async (geo: any, setCoord: any) => {
    let lat_all: any = [];
    let lng_all: any = [];

    geo.features.forEach((g: any) => {
        let lat_p = g.geometry.bbox[1];
        let lat_s = g.geometry.bbox[3];
        let lng_p = g.geometry.bbox[0];
        let lng_s = g.geometry.bbox[2];

        lat_all.push(lat_p, lat_s);
        lng_all.push(lng_p, lng_s);
    });

    lat_all.sort();
    lng_all.sort();

    const lowX = lat_all[0];
    const highX = lat_all[lat_all.length - 1];
    const lowY = lng_all[0];
    const highY = lng_all[lng_all.length - 1];

    const centerX = lowX + (highX - lowX) / 2;
    const centerY = lowY + (highY - lowY) / 2;

    // const city = await getCity(centerX, centerY);
    let newCoord = {
        centerX: centerX,
        centerY: centerY,
        lowX: lowX,
        highX: highX,
        lowY: lowY,
        highY: highY,
        zoom: 12,
        // city: city.data.results[0].locations[0].adminArea5,
    };
    setCoord(JSON.stringify(newCoord));
};

// const getCity = (lat: any, long: any) => {
//     const res = axios(
//         "https://www.mapquestapi.com/geocoding/v1/reverse?key=QOzRj2cvqaHUGddA3t9iTCv3smhfvdXG&location=" +
//             lat +
//             "," +
//             long +
//             "&includeRoadMetadata=true&includeNearestIntersection=true"
//     );

//     return res;
// };

const shapeConvert = (
    shape: any,
    setShape: any,
    setCoord: any,
    setFName: any
) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
        shp.parseZip(e.target.result).then((geo: any) => {
            let newGeo = JSON.parse(JSON.stringify(geo));
            let newFeatures = newGeo.features.map((g: any) => {
                let newG = JSON.parse(JSON.stringify(g));
                newG.properties["id_agrochip"] = uuidv4();
                return newG;
            });
            newGeo["features"] = newFeatures;
            setShape(unescape(decodeURIComponent(JSON.stringify(newGeo))));
            setFName(geo.fileName);
            getCoord(geo, setCoord);
        });
    };
    reader.readAsArrayBuffer(shape);
};

export default shapeConvert;
