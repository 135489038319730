import { Button, Modal, Popconfirm, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    IAlertaList,
    IAlertaUser,
    IAlertaUserList,
} from "../../../reducers/AlertaReducer";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStore } from "../../../store";
import { setStatusNull } from "../../../store/actions";
import { IStatusList } from "../../../store/types";
import AdicionarAlerta from "./Alertas/AdicionarAlerta";
import {
    getAlerta,
    deletarAlerta,
    deletarAlertaUser,
} from "../../../store/alertaActions";
import EditarAlerta from "./Alertas/EditarAlerta";
import { tipos } from "../../../reducers/OperacaoReducer";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../reducers/EquipamentoReducer";
import { formataTexto } from "../../../config/helpers";
import { ITipoOp, ITipoOpList } from "../../../reducers/TipoOpReducer";
import { IUser, IUserList } from "../../../reducers/UserReducer";

interface AlertasProps {
    loader: IStatusList;
    theme: ITheme;
    alertaList: IAlertaList;
    equipamentos: IEquipamentoList;
    tipoEquip: any[];
    tiposOp: ITipoOpList;

    getAlerta: Function;
    deletarAlerta: Function;
    setStatusNull: Function;

    usuarios: IUserList;
    alertasUser: IAlertaUserList;
    deletarAlertaUser: Function;
}

function Alertas(props: AlertasProps) {
    const {
        loader,
        theme,
        alertaList,
        equipamentos,
        tipoEquip,
        tiposOp,
        getAlerta,
        deletarAlerta,
        setStatusNull,

        usuarios,
        alertasUser,
        deletarAlertaUser,
    } = props;
    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [which, setWhich] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    const [excluirUser, setExcluirUser] = useState(false);

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Alerta",
            conteudo: <AdicionarAlerta setModal={setModal} />,
        });
        setModal(true);
    }

    function handleEditar(id: any) {
        const alertaId = alertaList.findIndex((a: any) => a.alerta_id === id);
        setConfigModal({
            titulo: "Editar Alerta",
            conteudo: (
                <EditarAlerta
                    setModal={setModal}
                    cont={alertaList[alertaId]}
                    users={alertasUser.filter(
                        (u: IAlertaUser) => u.alerta_temp === id
                    )}
                />
            ),
        });
        setModal(true);
    }

    function handleExcluir() {
        setConfirmLoading(true);
        deletarAlerta(which);
    }

    function handleExcluirUser() {
        // setConfirmLoading(true);
        // deletarAlerta(which);
    }

    useMemo(() => {
        if (loader.alerta?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "alerta");
        }
        getAlerta();
        setWhich(0);
    }, [loader.alerta?.del?.success, getAlerta]);

    return (
        <>
            <div style={{ height: "100%" }}>
                Alertas cadastrados:
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {alertaList.map((a: any) => {
                                let classN = theme
                                    ? "select-expand-li-dark"
                                    : "select-expand-li";
                                if (a.alerta_id === which) {
                                    classN += " active";
                                }
                                return (
                                    <li
                                        className={classN}
                                        key={a.alerta_id}
                                        value={a.alerta_id}
                                        onClick={() => setWhich(a.alerta_id)}
                                        onDoubleClick={() =>
                                            handleEditar(a.alerta_id)
                                        }
                                        style={{
                                            display: "inline-block",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Tipo Equipamento:{" "}
                                                {a.tipo_equipamento
                                                    ? tipoEquip[
                                                          tipoEquip.findIndex(
                                                              (t: any) =>
                                                                  t.id ===
                                                                  a.tipo_equipamento
                                                          )
                                                      ].nome
                                                    : "Todos"}
                                            </div>
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Equipamento:{" "}
                                                {a.equipamento_temp
                                                    ? equipamentos[
                                                          equipamentos.findIndex(
                                                              (
                                                                  e: IEquipamento
                                                              ) =>
                                                                  e.id_equipamento ===
                                                                  a.equipamento_temp
                                                          )
                                                      ].numero_patrimonio
                                                    : "Todos"}
                                            </div>
                                        </div>{" "}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {" "}
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Tipo de Operação:{" "}
                                                {a.tipo_operacao_temp
                                                    ? tiposOp[
                                                          tiposOp.findIndex(
                                                              (o: ITipoOp) =>
                                                                  o.id_tipo_operacao ===
                                                                  a.tipo_operacao_temp
                                                          )
                                                      ].descricao
                                                    : "Todas"}
                                            </div>
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Tipo de Alerta:{" "}
                                                {
                                                    tipos[
                                                        tipos.findIndex(
                                                            (t: any) =>
                                                                t.value.toUpperCase() ===
                                                                a.tipo
                                                        )
                                                    ].label
                                                }
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {Object.keys(a.criterio).length >
                                                0 && (
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Critério:{" "}
                                                    {formataTexto(a.criterio)}
                                                </div>
                                            )}{" "}
                                            {a.valor && (
                                                <div
                                                    style={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    Valor:{" "}
                                                    {formataTexto(a.valor + "")}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {" "}
                                            <div>Usuários vinculados:</div>
                                            {alertasUser.map((u: any) => {
                                                return (
                                                    a.alerta_id ===
                                                        u.alerta_temp && (
                                                        <Tag
                                                            closable
                                                            onClose={() =>
                                                                deletarAlertaUser(
                                                                    u.id_alerta_user
                                                                )
                                                            }
                                                        >
                                                            {
                                                                usuarios[
                                                                    usuarios.findIndex(
                                                                        (
                                                                            i: IUser
                                                                        ) => {
                                                                            return (
                                                                                i.id_user ===
                                                                                u.usuario_temp
                                                                            );
                                                                        }
                                                                    )
                                                                ].nome
                                                            }
                                                        </Tag>
                                                    )
                                                );
                                            })}
                                        </div>
                                        {/* {tipos[
                                                tipos.findIndex(
                                                    (t: any) =>
                                                        t.value.toUpperCase() ===
                                                        a.tipo
                                                )
                                            ].label +
                                                " " +
                                                a.criterio}{" "}
                                            {typeof a.valor !== null && a.valor} */}
                                    </li>
                                );
                            })}
                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich(0)}
                            ></li>
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir o alerta selecionado?"
                            onConfirm={handleExcluir}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                disabled={which === 0 ? true : false}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        tipoEquip: store.attrEquipamento.tipos,
        tiposOp: store.tiposOp,
        loader: store.loader,
        theme: store.theme,
        alertaList: store.alertas,

        usuarios: store.usuarios,
        alertasUser: store.alertasUser,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getAlerta,
            deletarAlerta,
            setStatusNull,

            deletarAlertaUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Alertas);
