import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Badge, Button, Empty, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { getMensagem, adicionarMensagem } from "../../store/msgActions";
import { Spinner } from "../../styles/Main";
import { IStore } from "../../store";
import { ITheme } from "../../reducers/ThemeReducer";
import { v4 as uuidv4 } from "uuid";
import { IMensagemList, IMsgModule } from "../../reducers/MensagemReducer";
import { IEquipamento } from "../../reducers/EquipamentoReducer";

const { TextArea } = Input;
const MsgContainer = styled.div<MsgInterface>`
    display: flex;
    flex-direction: row;
    width: 800px;
    height: 600px;
    border: 1px solid ${props => (props.tema ? "rgb(66, 66, 66)" : "#d9d9d9")};
    border-radius: 2px;
`;
const MsgAgendaBar = styled.div<MsgInterface>`
    width: 250px;
    border-right: 1px solid
        ${props => (props.tema ? "rgb(66, 66, 66)" : "#d9d9d9")};
    display: flex;
    flex-direction: column;
`;
const MsgRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const MsgAgendaSearch = styled.div<MsgInterface>`
    border-bottom: 1px solid
        ${props => (props.tema ? "rgb(66, 66, 66)" : "#d9d9d9")};
`;
const MsgAgendaList = styled.div`
    flex: 1;
    width: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

interface MsgAgendaItemProps {
    equipa: any;
    id: any;
    tema: ITheme;
}
const MsgAgendaItem = styled.div<MsgAgendaItemProps>`
    height: 70px;
    /*border-bottom: 1px solid #d9d9d9;*/
    display: flex;
    /*align-items: stretch;*/
    padding: 10px 0;
    background-color: ${props =>
        props.id === props.equipa ? "rgb(47, 53, 126)" : ""};

    color: ${props => (props.id === props.equipa ? "rgb(255, 255, 255)" : "")};

    & i {
        color: ${props =>
            props.id === props.equipa ? "rgb(255, 255, 255)" : ""};
    }

    &:hover {
        cursor: pointer;
        background-color: ${props =>
            props.id !== props.equipa
                ? props.tema
                    ? "rgb(64, 64, 64)"
                    : "#f5f5f5"
                : ""};
    }
`;
const MsgEquipDetalhes = styled.div<MsgInterface>`
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid
        ${props => (props.tema ? "rgb(66, 66, 66)" : "#d9d9d9")};
    height: 78px;
`;
interface MsgInterface {
    tema?: boolean;
}
const MsgTextoEnv = styled.div<MsgInterface>`
    padding: 10px;
    display: inline-block;
    border: 2px solid ${props => (props.tema ? "rgb(66, 66, 66)" : "#dedede")};
    background-color: ${props => (props.tema ? "#757575" : "#f1f1f1")};
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 5px;
    text-align: right;
`;
const MsgTextArea = styled.div<MsgInterface>`
    padding: 5px;
    background-color: #33333;
    border-top: 1px solid
        ${props => (props.tema ? "rgb(66, 66, 66)" : "#d9d9d9")};
`;

interface MsgControllerProps {
    equipa: IEquipamento;
    mensagens: IMensagemList;
    tema: boolean;
    servidores: any;
    addMsg: Function;
    msgStatus: any;
}

function MensagemView(props: MsgControllerProps) {
    const [msgReEnvStatus, setmsgReEvnStatus] = useState(false);
    useEffect(() => {
        if (msgReEnvStatus) {
            setTimeout(() => {
                setmsgReEvnStatus(false);
            }, 8000);
        }
    }, [props.msgStatus.mensagem]);
    function validaReenviar(codFila: any, timeOut: any) {
        let atual: any = new Date();
        let ultimo = new Date(timeOut);

        if (codFila !== null && atual.getTime() - ultimo.getTime() > 30000) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: 1,

                alignItems: "stretch",
            }}
        >
            <div
                style={{
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                    width: "100%",
                    height: "479px",
                    padding: "20px",
                }}
            >
                {props.mensagens.length > 0 ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                            }}
                        >
                            {props.mensagens.map((m: IMsgModule) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                columnGap: "10px",
                                            }}
                                        >
                                            <MsgTextoEnv
                                                tema={props.tema}
                                                key={m.id_mensagem}
                                            >
                                                <div>{m.texto}</div>
                                                <div
                                                    style={{
                                                        textAlign: "right",
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    {m.data_hora}
                                                    <i
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                        className={
                                                            m.cod_fila === null
                                                                ? "fas fa-check-double"
                                                                : "fas fa-check"
                                                        }
                                                    ></i>
                                                </div>
                                            </MsgTextoEnv>
                                            <div
                                                style={{
                                                    display: validaReenviar(
                                                        m.cod_fila,
                                                        m.data_hora
                                                    )
                                                        ? "flex"
                                                        : "none",
                                                    alignItems: "center",
                                                    marginBottom: "-10px",
                                                }}
                                            >
                                                <i
                                                    style={{
                                                        fontSize: "16px",
                                                        cursor: msgReEnvStatus
                                                            ? "not-allowed"
                                                            : "pointer",
                                                    }}
                                                    className="fas fa-share"
                                                    onClick={e => {
                                                        if (!msgReEnvStatus) {
                                                            setmsgReEvnStatus(
                                                                true
                                                            );
                                                            props.addMsg(
                                                                {
                                                                    texto: m.texto,
                                                                    data_hora:
                                                                        "",
                                                                    equipamento_temp:
                                                                        props
                                                                            .equipa
                                                                            .id_equipamento,
                                                                    id_mensagem:
                                                                        m.id_mensagem,
                                                                },
                                                                props.equipa
                                                                    .comunicacao ===
                                                                    "LORA"
                                                                    ? props
                                                                          .servidores[0]
                                                                          .id_local_server
                                                                    : "none"
                                                            );
                                                        }
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Empty
                            description="Ainda não há mensagens para esse equipamento."
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

function MensagemController(props: any) {
    const [msgText, setMsgText] = useState("");
    const [equipa, setEquipa] = useState<any>("");
    const [buscaEquipamento, setBuscaEquipamento] = useState("");

    const {
        equipamentos,
        atributos,
        getMensagem,
        adicionarMensagem,
        mensagens,
        msgStatus,
        theme,
        servidores,
    } = props;

    const equipamentosFilter = useCallback(() => {
        return equipamentos.filter((e: any) => {
            if (e.estado !== "-1" && e.comunicacao !== "OFFLINE") {
                return (
                    e.descricao
                        .toLowerCase()
                        .includes(buscaEquipamento.toLowerCase()) ||
                    e.numero_patrimonio
                        .toLowerCase()
                        .includes(buscaEquipamento.toLowerCase())
                );
            }
        });
    }, [buscaEquipamento, equipamentos]);

    useEffect(() => {
        if (equipa !== "") {
            getMensagem(equipa.id_equipamento);
        }
    }, [equipa]);

    const status = useCallback(equipa => {
        let atual = new Date().getTime();
        if (equipa.ultima_operacao !== undefined) {
            if (
                equipa.ultima_operacao.motorON === true &&
                atual - new Date(equipa.ultima_operacao?.timestamp).getTime() <
                    360000
            ) {
                return "Ligado";
            } else if (equipa.ultima_operacao.motorON === false) {
                return "Desligado";
            } else {
                return "Sem informação atual";
            }
        } else return "Sem informação atual";

        // let statusOn = equipamentosFilter().filter((l: IEquipamento) => {
        //     if (l.ultima_operacao !== undefined) {
        //         return (
        //             l.ultima_operacao.motorON === true &&
        //             atual - new Date(l.ultima_operacao?.timestamp).getTime() <
        //                 720000
        //         );
        //     }
        // });
        // let statusOff = equipamentosFilter().filter((e: IEquipamento) => {
        //     if (e.ultima_operacao !== undefined) {
        //         return e.ultima_operacao.motorON === false;
        //     }
        // });
        // return {
        //     ligado: statusOn,
        //     desligado: statusOff,
        // };
    }, []);

    const corBadge: any = (i: any) => {
        if (i.ultima_operacao !== undefined) {
            if (i.ultima_operacao.motorON === false) {
                return "#000";
            } else if (
                i.ultima_operacao.motorON === true &&
                new Date().getTime() -
                    new Date(i.ultima_operacao?.timestamp).getTime() <
                    360000
            ) {
                return "#12eb02";
            } else return "#de0000";
        } else return "#000";

        return "default";
    };

    return (
        <MsgContainer tema={theme}>
            <MsgAgendaBar tema={theme}>
                <MsgAgendaSearch tema={theme}>
                    <Input
                        suffix={<SearchOutlined />}
                        bordered={false}
                        placeholder="Buscar nome"
                        size="large"
                        onChange={value =>
                            setBuscaEquipamento(value.target.value)
                        }
                        style={{ width: 250 }}
                        allowClear={true}
                        value={buscaEquipamento}
                    />
                </MsgAgendaSearch>
                <MsgAgendaList>
                    {equipamentosFilter().map((e: any) => {
                        return (
                            <MsgAgendaItem
                                tema={theme}
                                onClick={() => {
                                    setEquipa(e);
                                    props.setEquipaMSG(e.id_equipamento);
                                }}
                                key={e.id_equipamento}
                                id={e.id_equipamento}
                                equipa={equipa.id_equipamento}
                            >
                                <div
                                    style={{
                                        width: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Badge
                                        count={
                                            <div
                                                style={{
                                                    borderRadius: "50%",
                                                    width: "10px",
                                                    height: "10px",
                                                    backgroundColor:
                                                        corBadge(e),
                                                }}
                                            ></div>
                                        }
                                    >
                                        <span
                                            style={{
                                                fontFamily: "agrochip",
                                                fontSize: "30px",
                                            }}
                                        >
                                            {e.tipo}
                                        </span>
                                        {/* <i
                                            style={{
                                                fontSize: "30px",
                                            }}
                                            className={e.icone}
                                        ></i> */}
                                    </Badge>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "190px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginLeft: "10px",
                                        flex: 1,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {e.numero_patrimonio} - {e.descricao}
                                    </div>
                                    <div>
                                        {
                                            atributos.tipos[
                                                atributos.tipos.findIndex(
                                                    (a: any) =>
                                                        a.id ===
                                                        parseInt(e.tipo)
                                                )
                                            ].nome
                                        }
                                    </div>
                                </div>
                            </MsgAgendaItem>
                        );
                    })}
                </MsgAgendaList>
            </MsgAgendaBar>
            <MsgRightContainer>
                {equipa !== "" ? (
                    <>
                        <MsgEquipDetalhes tema={theme}>
                            <div
                                style={{
                                    width: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "agrochip",
                                        fontSize: "30px",
                                        paddingRight: "10px",
                                    }}
                                >
                                    {equipa.tipo}
                                </span>
                                {/* <i
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={equipa.icone}
                                ></i> */}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginLeft: "10px",
                                }}
                            >
                                <div style={{ fontWeight: "bold" }}>
                                    {equipa.numero_patrimonio} -{" "}
                                    {equipa.descricao}
                                </div>
                                <div>
                                    {
                                        atributos.tipos[
                                            atributos.tipos.findIndex(
                                                (a: any) =>
                                                    a.id ===
                                                    parseInt(equipa.tipo)
                                            )
                                        ].nome
                                    }{" "}
                                    - {equipa.estado === "1" && "Ativo"}
                                    {equipa.estado === "0" &&
                                        "Em manutenção"} - {status(equipa)}
                                </div>
                            </div>
                            <div
                                style={{
                                    width: "50px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    fontSize: "20px",
                                }}
                            >
                                <Spinner
                                    view={msgStatus.mensagem?.get?.loading}
                                >
                                    <i className="fas fa-spinner"></i>
                                </Spinner>
                            </div>
                        </MsgEquipDetalhes>
                        <MensagemView
                            msgStatus={msgStatus}
                            tema={theme}
                            mensagens={mensagens}
                            equipa={equipa}
                            servidores={servidores}
                            addMsg={adicionarMensagem}
                        />
                        <MsgTextArea tema={theme}>
                            <TextArea
                                disabled={
                                    msgStatus.mensagem?.add?.loading ||
                                    status(equipa) !== "Ligado"
                                }
                                onChange={value =>
                                    setMsgText(
                                        value.target.value.replace(
                                            /[^\w\s]/gi,
                                            ""
                                        )
                                    )
                                }
                                autoSize={{ minRows: 1, maxRows: 1 }}
                                bordered={false}
                                showCount
                                maxLength={50}
                                onPressEnter={event => {
                                    adicionarMensagem(
                                        {
                                            texto: msgText,
                                            data_hora: "",
                                            equipamento_temp:
                                                equipa.id_equipamento,
                                        },
                                        equipa.comunicacao === "LORA"
                                            ? servidores[0].id_local_server
                                            : "none"
                                    );
                                    event.preventDefault();
                                    setMsgText("");
                                }}
                                value={msgText}
                            />
                        </MsgTextArea>
                    </>
                ) : (
                    <div
                        style={{
                            position: "relative",
                            top: "30%",
                            fontWeight: "600",
                        }}
                    >
                        <Empty
                            description="Nenhum equipamento foi selecionado."
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    </div>
                )}
            </MsgRightContainer>
        </MsgContainer>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentos: store.equipamentos,
        atributos: store.attrEquipamento,
        mensagens: store.mensagens,
        msgStatus: store.loader,
        theme: store.theme,
        servidores: store.servidores,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getMensagem,
            adicionarMensagem,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MensagemController);
