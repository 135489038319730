import { Empty, Form, notification, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEquipamento } from "../../store/equipamentoActions";
import { ativarProcess, desativarProcess, setFocus } from "../../store/actions";
import { IFazenda } from "../../reducers/FazendaReducer";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../reducers/EquipamentoReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { IProcess, IProcessList } from "../../reducers/ProcessReducer";
import { useEffect, useState } from "react";
import { IStore } from "../../store";
import { IStatusList } from "../../store/types";
import { v4 as uuidv4 } from "uuid";
import { ITheme } from "../../reducers/ThemeReducer";
import { ITipoOpList } from "../../reducers/TipoOpReducer";
import { formataTexto } from "../../config/helpers";
const { Option } = Select;

interface EquipamentoControllerProps {
    fazenda: IFazenda;
    equipamento: IEquipamento;
    equipamentos: IEquipamentoList;
    tipos: any;
    menuState: IMenu;
    processListState: IProcessList;
    process: IProcess;
    setEquipamento: Function;
    setFocus: Function;
    ativarProcess: Function;
    desativarProcess: Function;
    dashboard: string;
    loader: IStatusList;
    prefs: any;
    tema: ITheme;
    tiposOp: ITipoOpList;
}

function EquipamentoController(props: EquipamentoControllerProps) {
    const {
        fazenda,
        equipamento,
        equipamentos,
        setEquipamento,
        setFocus,
        ativarProcess,
        desativarProcess,
        dashboard,
        menuState,
        processListState,
        process,
        loader,
        prefs,
        tiposOp,
    } = props;
    const [form] = Form.useForm();

    const [equipaParado, setEquipaParado] = useState(false);
    const [equipaAlerta, setEquipaAlerta] = useState(false);
    const [paradoList, setParadoList] = useState<any>([]);
    const [alertaList, setAlertaList] = useState<any>([]);

    function handleChange(value: any) {
        if (value === "all") {
            setFocus({ view: 4, subview: null });
            desativarProcess(process.side);
            setEquipamento({
                id_equipamento: "",
                descricao: "",
                numero_patrimonio: "",
                tipo: "",
                estado: "",
                device_id: "",
                serial: "",
                ultima_operacao: {
                    timestamp: "",
                },
            });
        } else {
            if (dashboard === "mapa") {
                ativarProcess({
                    menuItem:
                        menuState[menuState.findIndex((m: any) => m.id === 2)],
                    processList: processListState,
                });
            }
            setFocus({ view: 3, subView: null });
            setEquipamento(
                equipamentos[
                    equipamentos.findIndex(
                        (e: IEquipamento) => e.id_equipamento === value
                    )
                ]
            );
        }
    }

    useEffect(() => {
        if (loader.loader?.get?.success) {
            if (Object.keys(prefs).findIndex((p: any) => p === "equipa") > -1) {
                // setFocus({ view: 3, subView: null });
                setEquipamento(
                    equipamentos[
                        equipamentos.findIndex(
                            (e: IEquipamento) =>
                                e.id_equipamento === prefs.equipa
                        )
                    ]
                );
                if (dashboard === "mapa") {
                    ativarProcess({
                        menuItem:
                            menuState[
                                menuState.findIndex((m: any) => m.id === 2)
                            ],
                        processList: processListState,
                    });
                }
                form.setFieldsValue({ equipa: prefs.equipa });
            } else {
                //setFocus({ view: 4, subview: null });
                form.setFieldsValue({ equipa: "all" });
            }
        }
    }, [prefs]);

    useEffect(() => {
        if (equipamento.id_equipamento !== "") {
            form.setFieldsValue({ equipa: equipamento.id_equipamento });
        } else {
            form.setFieldsValue({ equipa: "all" });
            desativarProcess(process.side);
        }
    }, [equipamento.id_equipamento]);

    useEffect(() => {
        if (equipamento.id_equipamento !== "") {
            if (dashboard === "mapa") {
                ativarProcess({
                    menuItem:
                        menuState[menuState.findIndex((m: any) => m.id === 2)],
                    processList: processListState,
                });
            }
        }
    }, [dashboard, equipamento.id_equipamento]);

    useEffect(() => {
        let equipa = equipamentos.filter((e: IEquipamento) => {
            if (e.ultima_operacao !== undefined) {
                if (
                    e.ultima_operacao.motorON &&
                    e.ultima_operacao.tipo_operacao_parada_temp !== undefined
                ) {
                    if (
                        new Date().getTime() -
                            new Date(e.ultima_operacao.timestamp).getTime() <
                        360000
                    ) {
                        return true;
                    }
                }
            }
            return false;
        });
        if (equipa.length > 0) {
            setEquipaParado(true);
        } else {
            setEquipaParado(false);
        }
        setParadoList(equipa);
    }, [equipamentos]);

    useEffect(() => {
        let equipa = equipamentos.filter((e: IEquipamento) => {
            if (e.ultima_operacao !== undefined) {
                if (e.ultima_operacao.motorON && e.ultima_operacao.alerta) {
                    if (
                        new Date().getTime() -
                            new Date(e.ultima_operacao.timestamp).getTime() <
                        360000
                    ) {
                        return true;
                    }
                }
            }
            return false;
        });
        if (equipa.length > 0) {
            setEquipaAlerta(true);
        } else {
            setEquipaAlerta(false);
        }
        setAlertaList(equipa);
    }, [equipamentos]);

    useEffect(() => {
        if (equipaAlerta) {
            notification.open({
                type: "warning",
                top: 50,
                message: `Lista de Equipamentos com Alerta:`,
                description: (
                    <div>
                        {alertaList.map((e: IEquipamento) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: props.tema
                                            ? "1px solid #C1C1C1"
                                            : "1px solid #fff",
                                    }}
                                >
                                    <div style={{ fontWeight: "bold" }}>
                                        {e.descricao} - {e.numero_patrimonio}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Motivo:</div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            {" "}
                                            {formataTexto(
                                                e.ultima_operacao.txtalerta
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ),
                duration: 10,
            });
        }
    }, [equipaAlerta]);

    useEffect(() => {
        if (equipaParado) {
            notification.open({
                type: "warning",
                top: 50,
                message: `Lista de Equipamentos Parados:`,
                description: (
                    <div>
                        {paradoList.map((e: IEquipamento) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: props.tema
                                            ? "1px solid #C1C1C1"
                                            : "1px solid #fff",
                                    }}
                                >
                                    <div style={{ fontWeight: "bold" }}>
                                        {e.descricao} - {e.numero_patrimonio}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Motivo:</div>
                                        <div>
                                            {" "}
                                            {e.ultima_operacao
                                                .tipo_operacao_parada_temp !==
                                                undefined &&
                                            e.ultima_operacao
                                                .tipo_operacao_parada_temp !== 0
                                                ? tiposOp[
                                                      tiposOp.findIndex(
                                                          (t: any) =>
                                                              t.id_tipo_operacao ===
                                                              e.ultima_operacao
                                                                  .tipo_operacao_parada_temp
                                                      )
                                                  ].descricao
                                                : "Não Identificado"}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ),
                duration: 10,
            });
        }
    }, [equipaParado]);

    return (
        <Form
            form={form}
            layout="horizontal"
            // style={{
            //     padding: "4px",
            // }}
            className="tour-equipamento "
        >
            <Form.Item
                label={
                    <span
                        className={
                            props.tema ? "icon-filtros-dark" : "icon-filtros"
                        }
                    >
                        {" "}
                        <Tooltip title="Equipamento">
                            <i className="fas fa-tractor"></i>
                        </Tooltip>
                    </span>
                }
                name="equipa"
                colon={false}
            >
                <Select
                    style={{
                        width: 200,
                        color: props.tema ? "#fff" : "#606060",
                        border: "1px solid #C1C1C1",
                        backgroundColor: props.tema ? "#757575" : "",
                    }}
                    size="small"
                    disabled={fazenda.id_fazenda === ""}
                    bordered={false}
                    placeholder="Escolha"
                    optionLabelProp="label"
                    optionFilterProp="filtro"
                    showSearch
                    suffixIcon={<i className="fas fa-chevron-right"></i>}
                    notFoundContent={
                        <Empty
                            description="Nenhuma fazenda foi carregada."
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    }
                    dropdownRender={menu => <div>{menu}</div>}
                    onChange={handleChange}
                    onSelect={handleChange}
                    dropdownClassName="agrochip-dropdown-equipa agrochip-dropdown"
                    dropdownStyle={{ width: "500px" }}
                >
                    <Option value="all" label="Todos" key={uuidv4()}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <i
                                className="fas fa-border-all"
                                style={{
                                    fontSize: "20px",
                                    paddingRight: "10px",
                                }}
                            ></i>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Todos
                                </div>
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    Ver todos os equipamentos
                                </div>
                            </div>
                        </div>
                    </Option>
                    {equipamentos.map((e: any) => {
                        return (
                            <Option
                                value={e.id_equipamento}
                                label={e.descricao}
                                key={e.id_equipamento}
                                filtro={e.descricao + " " + e.numero_patrimonio}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "agrochip",
                                            fontSize: "20px",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        {e.tipo}
                                    </span>
                                    {/* <i
                                        className={e.icone}
                                        style={{
                                            fontSize: "20px",
                                            paddingRight: "10px",
                                        }}
                                    ></i> */}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {e.numero_patrimonio}
                                        </div>
                                        <div
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {e.descricao}
                                        </div>
                                    </div>
                                </div>
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        equipamento: store.equipamento,
        equipamentos: store.equipamentos,
        tipos: store.attrEquipamento.tipos,
        menuState: store.menu,
        processListState: store.processos,
        process: store.processo,
        loader: store.loader,
        prefs: store.preferencia,
        dashboard: store.dashboard,
        tiposOp: store.tiposOp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setEquipamento,
            ativarProcess,
            setFocus,
            desativarProcess,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EquipamentoController);
