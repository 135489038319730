import { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { CoffeeOutlined } from "@ant-design/icons";
import { ContentOverflow } from "../../styles/Main";
import { IStore } from "../../store";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../reducers/EquipamentoReducer";
import {
    IFazendaList,
    IFazendaModule,
    ITalhao,
} from "../../reducers/FazendaReducer";
import { geoContains } from "d3-geo";
import { ITipoOpList } from "../../reducers/TipoOpReducer";
import { InfoCircleFilled } from "@ant-design/icons";
import { ISafraList } from "../../reducers/SafraReducer";
import { ICulturaList } from "../../reducers/CulturaReducer";
import { v4 as uuidv4 } from "uuid";
import { setFocus } from "../../store/actions";
import { ITalhaoTablet } from "../../store/types";
import { ITime } from "../../reducers/TimeReducer";
import { IImplemento, IImplementoList } from "../../reducers/ImplementoReducer";

interface PropsTalhao {
    equipamento: IEquipamento;
    equipamentos: IEquipamentoList;
    tipos: object[];
    operacoes: ITipoOpList;
    talhaoNome: ITalhao;
    fazenda: IFazendaModule;
    fazendas: IFazendaList;
    safras: ISafraList;
    culturas: ICulturaList;
    setFocus: Function;

    talhoes: ITalhaoTablet[];
    talhoesFiltro: ITalhaoTablet[];

    polylineFiltro: any;
    time: ITime;

    implementos: IImplementoList;
}

function Talhao(props: PropsTalhao) {
    const {
        operacoes,
        talhaoNome,
        fazenda,
        equipamentos,
        equipamento,
        fazendas,
        safras,
        culturas,
        setFocus,

        talhoes,
        talhoesFiltro,

        polylineFiltro,
        time,

        implementos,
    } = props;
    const [area, setArea] = useState<any>();
    const [equipT, setEquipT] = useState<any>([]);
    const [operaT, setOperaT] = useState<any>([]);
    const [safraT, setSafraT] = useState<any>([]);
    const [culturaT, setCulturaT] = useState<any>([]);
    const [talhaoAtual, setTalhaoAtual] = useState<any>("");
    const [equipaTalhoes, setEquipaTalhoes] = useState([]);

    const [polyT, setPolyT] = useState([]);
    const [rangeV, setRangeV] = useState(true);

    useEffect(() => {
        if (fazenda.nome !== "") {
            let fazTalhao = JSON.parse(fazenda.shape);
            let fazAreaTotal = 0;
            if (talhaoNome !== "all") {
                let talKey: any;
                if (talhoesFiltro.length > 0) {
                    talKey = talhoesFiltro.findIndex(
                        (t: any) => t.id_talhao === talhaoNome
                    );
                }

                if (talKey > -1) {
                    setArea(talhoesFiltro[talKey].area?.toFixed(2));
                    setTalhaoAtual(talhoesFiltro[talKey].nome_talhao);
                } else if (
                    JSON.parse(fazenda.shape).features.findIndex(
                        (f: any) => f.properties.id_agrochip === talhaoNome
                    ) > -1
                ) {
                    setArea(
                        fazTalhao.features[
                            fazTalhao.features.findIndex((f: any) => {
                                return (
                                    f.properties["id_agrochip"] === talhaoNome
                                );
                            })
                        ].properties[JSON.parse(fazenda.campo).size]
                    );
                    setTalhaoAtual(
                        JSON.parse(fazenda.shape).features[
                            JSON.parse(fazenda.shape).features.findIndex(
                                (f: any) =>
                                    f.properties.id_agrochip === talhaoNome
                            )
                        ].properties[JSON.parse(fazenda.campo).nome]
                    );
                }
            } else {
                fazTalhao.features.forEach((f: any) => {
                    fazAreaTotal += Number(
                        f.properties[JSON.parse(fazenda.campo).size]
                    );
                });
                setArea(fazAreaTotal.toFixed(2));
            }
        }
    }, [talhaoNome, fazenda, talhoesFiltro]);

    useEffect(() => {
        const cacaTalhao = async () => {
            if (fazenda.id_fazenda !== "") {
                let fazAtual =
                    fazendas[
                        fazendas.findIndex(
                            (f: any) => f.id_fazenda === fazenda.id_fazenda
                        )
                    ];

                let atual = new Date().getTime();

                let equipaPonto = equipamentos.filter((e: any) => {
                    if (
                        e.ultima_operacao !== undefined &&
                        e.estado !== "-1" &&
                        e.ultima_operacao.motorON
                    ) {
                        return (
                            e.ultima_operacao.fazenda_temp ===
                                fazenda.id_fazenda &&
                            atual -
                                new Date(
                                    e.ultima_operacao?.timestamp
                                ).getTime() <
                                360000
                        );
                    } else {
                        return false;
                    }
                });

                let talKey: any;
                if (talhoesFiltro.length > 0) {
                    talKey = talhoesFiltro.findIndex(
                        (t: any) => t.id_talhao === talhaoNome
                    );
                }

                let geoFaz = await JSON.parse(fazAtual.shape);
                let listaTalhao: any = [];
                let tSelecionado: any;

                if (talKey > -1) {
                    //filtra os talhoes para verificar quais da lista de talhões cadastrados tem informação de coordenadas
                    listaTalhao = talhoesFiltro.map((g: any) => {
                        if (g.coordenadas !== null) {
                            //se tiver coordenadas o retorno vai ser:
                            //todo o objeto de talhão + o objeto de coordenada do talhão com as coordenadas convertidas + type;
                            return {
                                ...g,
                                coordenadas: {
                                    ...JSON.parse(g.coordenadas),
                                    type: "Polygon",
                                },
                            };
                        } else {
                            //senão o retorno vai ser:
                            //o objeto de talhão sem nenhuma alteração
                            return {
                                ...g,
                            };
                        }
                    });
                } else {
                    tSelecionado =
                        geoFaz.features[
                            geoFaz.features.findIndex(
                                (g: any) =>
                                    g.properties["id_agrochip"] === talhaoNome
                            )
                        ];
                }

                let nOpera: any = [];
                let nSafra: any = [];
                let nCultura: any = [];
                let nEquipaPonto = equipaPonto.filter((f: any) => {
                    if (listaTalhao.length > 0) {
                        //verifica se a lista filtrada dos talhoes é maior que 0
                        if (listaTalhao[talKey] !== undefined) {
                            //valida se tem na lista o talhão referente ao index
                            if (f.ultima_operacao.talhao_temp) {
                                //verifica se tem talhao na ultima operação do equipamento
                                return (
                                    listaTalhao[talKey].id_talhao ===
                                    f.ultima_operacao.talhao_temp
                                ); //retorna o talhão correto
                            } else {
                                if (listaTalhao[talKey].coordenadas !== null) {
                                    //senão verifica se o talhão tem coordenadas cadastradas
                                    return geoContains(
                                        listaTalhao[talKey].coordenadas,
                                        [
                                            Number(f.ultima_operacao.longitude),
                                            Number(f.ultima_operacao.latitude),
                                        ]
                                    ); //retorna o talhão que tiver as coordenadas referentes as coordenadas da ultima operação do equipamento (valida através da função geoContains)
                                } else return false;
                            }
                        } else return false;
                    } else {
                        //se a lista filtrada for vazia usa a função geoContains com as coordenadas do talhão selecionado e as coordenadas da ultima operação do equipamento
                        return geoContains(tSelecionado, [
                            Number(f.ultima_operacao.longitude),
                            Number(f.ultima_operacao.latitude),
                        ]);
                    }
                });
                nEquipaPonto.forEach((n: any) => {
                    if (n.ultima_operacao.tipo_operacao_temp) {
                        if (
                            nOpera.findIndex(
                                (o: any) =>
                                    o === n.ultima_operacao.tipo_operacao_temp
                            ) === -1
                        ) {
                            nOpera.push(n.ultima_operacao.tipo_operacao_temp);
                        }
                    } else {
                        if (
                            nOpera.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nOpera.push("naoIdentificado");
                        }
                    }
                    if (n.ultima_operacao.cultura_temp) {
                        if (
                            nCultura.findIndex(
                                (o: any) => o === n.ultima_operacao.cultura_temp
                            ) === -1
                        ) {
                            nCultura.push(n.ultima_operacao.cultura_temp);
                        }
                    } else {
                        if (
                            nCultura.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nCultura.push("naoIdentificado");
                        }
                    }
                    if (n.ultima_operacao.safra_temp) {
                        if (
                            nSafra.findIndex(
                                (o: any) => o === n.ultima_operacao.safra_temp
                            ) === -1
                        ) {
                            nSafra.push(n.ultima_operacao.safra_temp);
                        }
                    } else {
                        if (
                            nSafra.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nSafra.push("naoIdentificado");
                        }
                    }
                });
                setEquipT(nEquipaPonto);
                setOperaT(nOpera);
                setCulturaT(nCultura);
                setSafraT(nSafra);
            }
        };
        cacaTalhao();
    }, [fazenda, talhaoNome]);

    useEffect(() => {}, [equipaTalhoes]);

    useEffect(() => {
        let dataI = new Date(time.i).getTime();
        let dataF = new Date(time.f).getTime();

        let atual = new Date().getTime();

        setRangeV(atual - dataF < 360000);
    }, [time]);

    useEffect(() => {
        if (polylineFiltro.length > 0 && talhaoNome !== "all") {
            let opeT = polylineFiltro.map((p: any) => {
                let eKey: IEquipamento =
                    equipamentos[
                        equipamentos.findIndex(
                            (e: IEquipamento) => e.id_equipamento === p.id
                        )
                    ];
                let diasList: any = [];
                let nOpera: any = [];
                let nSafra: any = [];
                let nCultura: any = [];
                p.polyline.filter((d: any) => {
                    if (
                        diasList.findIndex(
                            (v: any) => v === d.timestamp.split("T")[0]
                        ) === -1
                    ) {
                        diasList.push(d.timestamp.split("T")[0]);
                    }
                    if (d.tipo_operacao_temp) {
                        if (
                            nOpera.findIndex(
                                (o: any) => o === d.tipo_operacao_temp
                            ) === -1
                        ) {
                            nOpera.push(d.tipo_operacao_temp);
                        }
                    } else {
                        if (
                            nOpera.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nOpera.push("naoIdentificado");
                        }
                    }
                    if (d.cultura_temp) {
                        if (
                            nCultura.findIndex(
                                (o: any) => o === d.cultura_temp
                            ) === -1
                        ) {
                            nCultura.push(d.cultura_temp);
                        }
                    } else {
                        if (
                            nCultura.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nCultura.push("naoIdentificado");
                        }
                    }
                    if (d.safra_temp) {
                        if (
                            nSafra.findIndex((o: any) => o === d.safra_temp) ===
                            -1
                        ) {
                            nSafra.push(d.safra_temp);
                        }
                    } else {
                        if (
                            nSafra.findIndex(
                                (o: any) => o === "naoIdentificado"
                            ) === -1
                        ) {
                            nSafra.push("naoIdentificado");
                        }
                    }
                });
                return {
                    equipa: eKey,
                    dias: diasList.map((d: any) => {
                        return new Date(d)
                            .toJSON()
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join("/");
                    }),
                    ope: {
                        nOpera,
                        nCultura,
                        nSafra,
                    },
                };
            });

            setPolyT(opeT);
        } else setPolyT([]);
    }, [polylineFiltro, talhaoNome, equipamentos]);

    useEffect(() => {
        if (fazenda.shape !== "") {
            //refatoração cód 01/08
            if (talhaoNome === "all") {
                let geoFaz = JSON.parse(fazenda.shape);
                let atual = new Date().getTime();
                //filtra os equipamentos
                let equipa = equipamentos.filter((e: any) => {
                    if (
                        e.ultima_operacao !== undefined &&
                        e.estado !== "-1" &&
                        e.ultima_operacao.motorON
                    ) {
                        return (
                            e.ultima_operacao.fazenda_temp ===
                                fazenda.id_fazenda &&
                            atual -
                                new Date(
                                    e.ultima_operacao?.timestamp
                                ).getTime() <
                                360000
                        );
                    } else {
                        return false;
                    }
                });
                let opera: any = [];
                let sfr: any = [];
                let cult: any = [];

                let listaTalhao: any = [];
                let listaTalhaoT: any = [];

                if (talhoesFiltro.length > 0) {
                    //verifica se tem talhão cadastrado
                    listaTalhaoT = talhoesFiltro.map((g: any) => {
                        //filtra os talhoes para verificar quais da lista de talhões cadastrados tem informação de coordenadas
                        if (g.coordenadas !== null) {
                            //se tiver coordenadas o retorno vai ser:
                            //todo o objeto de talhão + o objeto de coordenada do talhão com as coordenadas convertidas + type;
                            return {
                                ...g,
                                coordenadas: {
                                    ...JSON.parse(g.coordenadas),
                                    type: "Polygon",
                                },
                            };
                        } else {
                            //senão o retorno vai ser:
                            //o objeto de talhão sem nenhuma alteração
                            return {
                                ...g,
                            };
                        }
                    });
                } else {
                    //senão puxa as insformações de talhão do shp da fazenda
                    listaTalhao = JSON.parse(fazenda.shape);
                }

                let talhao: any = [];
                let talhaoT: any = [];

                if (equipa.length > 0) {
                    //verifica se a lista de equipamento filtrado em operação atual é > 0
                    if (listaTalhaoT.length > 0) {
                        //verifica se a lista filtrada dos talhoes é maior que 0
                        talhaoT = listaTalhaoT.filter((g: any) => {
                            return equipa.some((e: IEquipamento) => {
                                if (e.ultima_operacao.tipo_operacao_temp) {
                                    if (
                                        opera.findIndex(
                                            (o: any) =>
                                                o ===
                                                e.ultima_operacao
                                                    .tipo_operacao_temp
                                        ) === -1
                                    ) {
                                        opera.push(
                                            e.ultima_operacao.tipo_operacao_temp
                                        );
                                    }
                                } else {
                                    if (
                                        opera.findIndex(
                                            (o: any) => o === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        opera.push("naoIdentificado");
                                    }
                                }
                                if (e.ultima_operacao.cultura_temp) {
                                    if (
                                        cult.findIndex(
                                            (c: any) =>
                                                c ===
                                                e.ultima_operacao.cultura_temp
                                        ) === -1
                                    ) {
                                        cult.push(
                                            e.ultima_operacao.cultura_temp
                                        );
                                    }
                                } else {
                                    if (
                                        cult.findIndex(
                                            (c: any) => c === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        cult.push("naoIdentificado");
                                    }
                                }
                                if (e.ultima_operacao.safra_temp) {
                                    if (
                                        sfr.findIndex(
                                            (s: any) =>
                                                s ===
                                                e.ultima_operacao.safra_temp
                                        ) === -1
                                    ) {
                                        sfr.push(e.ultima_operacao.safra_temp);
                                    }
                                } else {
                                    if (
                                        sfr.findIndex(
                                            (s: any) => s === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        sfr.push("naoIdentificado");
                                    }
                                }
                                if (
                                    e.ultima_operacao.talhao_temp !== undefined
                                ) {
                                    //valida se teve apontamento de talhão na ultima op
                                    return (
                                        g.id_talhao ===
                                        e.ultima_operacao.talhao_temp
                                    );
                                } else {
                                    //senão valida se o talhão tem coordenadas cadastradas
                                    if (g.coordenadas !== null) {
                                        return geoContains(g.coordenadas, [
                                            Number(e.ultima_operacao.longitude),
                                            Number(e.ultima_operacao.latitude),
                                        ]); //retorna o talhão que tiver as coordenadas referentes as coordenadas da ultima operação do equipamento (valida através da função geoContains)
                                    } else return false;
                                }
                            });
                        });
                    } else if (listaTalhao.length > 0) {
                        //se a lista filtrada for vazia verifica a lista dos talhões do shp
                        talhao = geoFaz.features.filter((g: any) => {
                            return equipa.some((e: IEquipamento) => {
                                if (e.ultima_operacao.tipo_operacao_temp) {
                                    if (
                                        opera.findIndex(
                                            (o: any) =>
                                                o ===
                                                e.ultima_operacao
                                                    .tipo_operacao_temp
                                        ) === -1
                                    ) {
                                        opera.push(
                                            e.ultima_operacao.tipo_operacao_temp
                                        );
                                    }
                                } else {
                                    if (
                                        opera.findIndex(
                                            (o: any) => o === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        opera.push("naoIdentificado");
                                    }
                                }
                                if (e.ultima_operacao.cultura_temp) {
                                    if (
                                        cult.findIndex(
                                            (c: any) =>
                                                c ===
                                                e.ultima_operacao.cultura_temp
                                        ) === -1
                                    ) {
                                        cult.push(
                                            e.ultima_operacao.cultura_temp
                                        );
                                    }
                                } else {
                                    if (
                                        cult.findIndex(
                                            (c: any) => c === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        cult.push("naoIdentificado");
                                    }
                                }
                                if (e.ultima_operacao.safra_temp) {
                                    if (
                                        sfr.findIndex(
                                            (s: any) =>
                                                s ===
                                                e.ultima_operacao.safra_temp
                                        ) === -1
                                    ) {
                                        sfr.push(e.ultima_operacao.safra_temp);
                                    }
                                } else {
                                    if (
                                        sfr.findIndex(
                                            (s: any) => s === "naoIdentificado"
                                        ) === -1
                                    ) {
                                        sfr.push("naoIdentificado");
                                    }
                                }
                                return (
                                    //usa a função geoContains com as coordenadas do talhão selecionado e as coordenadas da ultima operação do equipamento
                                    geoContains(g, [
                                        Number(e.ultima_operacao.longitude),
                                        Number(e.ultima_operacao.latitude),
                                    ]) === true
                                );
                            });
                        });
                    }
                }

                let infoTalhoes: any = [];
                if (talhaoT.length > 0) {
                    talhaoT.forEach((t: any) => {
                        let equipaPorT: any = [];
                        equipa.forEach((e: any) => {
                            if (e.ultima_operacao.talhao_temp) {
                                if (
                                    t.id_talhao ===
                                    e.ultima_operacao.talhao_temp
                                ) {
                                    equipaPorT.push(e);
                                }
                            } else if (t.coordenadas !== null) {
                                if (
                                    geoContains(t.coordenadas, [
                                        Number(e.ultima_operacao.longitude),
                                        Number(e.ultima_operacao.latitude),
                                    ])
                                ) {
                                    equipaPorT.push(e);
                                }
                            }
                        });
                        infoTalhoes.push({
                            nome: t.nome_talhao,
                            equipamentos: equipaPorT,
                        });
                    });
                    setEquipaTalhoes(infoTalhoes);
                } else if (talhao.length > 0) {
                    talhao.forEach((t: any) => {
                        let equipaPorT: any = [];
                        equipa.forEach((e: any) => {
                            if (
                                geoContains(t, [
                                    Number(e.ultima_operacao.longitude),
                                    Number(e.ultima_operacao.latitude),
                                ])
                            ) {
                                equipaPorT.push(e);
                            }
                        });
                        infoTalhoes.push({
                            nome: t.properties[JSON.parse(fazenda.campo).nome],
                            equipamentos: equipaPorT,
                        });
                    });
                    setEquipaTalhoes(infoTalhoes);
                } else setEquipaTalhoes([]);
            }
        }
    }, [fazenda, talhaoNome, operacoes]);

    if (equipT.length === 0) {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#FFF",
                        textAlign: "left",
                        paddingRight: "20px",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #FFF",
                    }}
                >
                    <div
                        style={{
                            paddingTop: "15px",
                            fontSize: "30px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            Talhão: {talhaoNome === "all" && "Todos"}
                            {talhaoNome !== "all" && talhaoAtual}
                            <div
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                }}
                            >
                                Área: {area} | Fazenda: {fazenda.nome}
                            </div>
                        </div>
                        <div style={{ fontSize: "50px" }}>
                            <i className="fas fa-layer-group"></i>{" "}
                        </div>
                    </div>
                </div>
                {talhaoNome === "all" ? (
                    equipaTalhoes.length > 0 ? (
                        <ContentOverflow>
                            {equipaTalhoes.map((t: any) => {
                                return (
                                    <div
                                        style={{
                                            borderBottom: "1px solid #FFF",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t.nome}
                                        </div>
                                        <div>
                                            {t.equipamentos.map(
                                                (e: IEquipamento) => {
                                                    return (
                                                        <div>
                                                            O equipamento{" "}
                                                            {
                                                                e.numero_patrimonio
                                                            }{" "}
                                                            - {e.descricao} está
                                                            executando a
                                                            operação{" "}
                                                            {e.ultima_operacao
                                                                .tipo_operacao_temp
                                                                ? operacoes[
                                                                      operacoes.findIndex(
                                                                          (
                                                                              o: any
                                                                          ) =>
                                                                              o.id_tipo_operacao ===
                                                                              e
                                                                                  .ultima_operacao
                                                                                  .tipo_operacao_temp
                                                                      )
                                                                  ].descricao
                                                                : "Não Identificado"}{" "}
                                                            {e.ultima_operacao
                                                                .implemento_temp &&
                                                                "com o implemento " +
                                                                    implementos[
                                                                        implementos.findIndex(
                                                                            (
                                                                                i: IImplemento
                                                                            ) =>
                                                                                i.id_implemento ===
                                                                                e
                                                                                    .ultima_operacao
                                                                                    .implemento_temp
                                                                        )
                                                                    ]
                                                                        .descricao}{" "}
                                                            com a cultura{" "}
                                                            {e.ultima_operacao
                                                                .cultura_temp
                                                                ? culturas[
                                                                      culturas.findIndex(
                                                                          (
                                                                              c: any
                                                                          ) =>
                                                                              c.id_cultura ===
                                                                              e
                                                                                  .ultima_operacao
                                                                                  .cultura_temp
                                                                      )
                                                                  ].nomeCultura
                                                                : "Não Identificado"}
                                                            .
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            {/* <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <div>Talhões com equipamentos</div>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #fff",
                                padding: "15px 5px",
                            }}
                        >
                            {equipaTalhoes}
                        </div>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <div>Operações</div>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #fff",
                                padding: "15px 5px",
                            }}
                        >
                            {opTalhoes}
                        </div>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <div>Culturas</div>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #fff",
                                padding: "15px 5px",
                            }}
                        >
                            {cultTalhoes}
                        </div>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <div>Safras</div>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #fff",
                                padding: "15px 5px",
                            }}
                        >
                            {sfrTalhoes}
                        </div> */}
                        </ContentOverflow>
                    ) : (
                        <ContentOverflow>
                            <div className="not-found">
                                <InfoCircleFilled
                                    style={{
                                        fontSize: "50px",
                                        color: "#0C46E8",
                                        paddingBottom: "20px",
                                        textAlign: "center",
                                    }}
                                />
                                Não há informações atuais.
                            </div>
                        </ContentOverflow>
                    )
                ) : polyT.length > 0 ? (
                    <ContentOverflow>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            Histórico de operações no talhão:
                        </div>
                        {polyT.map((p: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #FFF",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {p.equipa.numero_patrimonio} -{" "}
                                        {p.equipa.descricao}
                                    </div>
                                    <div
                                        style={{
                                            maxWidth: "400px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                overflowWrap: "break-word",
                                                wordWrap: "break-word",
                                                hyphens: "auto",
                                            }}
                                        >
                                            Realizou operações:{" "}
                                            {p.ope.nOpera.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 799
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            operacoes[
                                                                operacoes.findIndex(
                                                                    (o: any) =>
                                                                        o.id_tipo_operacao ===
                                                                        e
                                                                )
                                                            ].descricao
                                                        );
                                                    }
                                                }
                                            )}
                                            ; Cultura:{" "}
                                            {p.ope.nCultura.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 12
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            culturas[
                                                                culturas.findIndex(
                                                                    (o: any) =>
                                                                        o.id_cultura ===
                                                                        e
                                                                )
                                                            ].nomeCultura
                                                        );
                                                    }
                                                }
                                            )}
                                            ; Safra:{" "}
                                            {p.ope.nSafra.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 3
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            safras[
                                                                safras.findIndex(
                                                                    (o: any) =>
                                                                        o.id_safra ===
                                                                        e
                                                                )
                                                            ].descricao
                                                        );
                                                    }
                                                }
                                            )}
                                            . No(s) dia(s):{" "}
                                            {p.dias.map(
                                                (e: any, index: any) => {
                                                    return (
                                                        (index ? ", " : "") + e
                                                    );
                                                }
                                            )}
                                            .
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </ContentOverflow>
                ) : (
                    <ContentOverflow>
                        <div className="not-found">
                            <InfoCircleFilled
                                style={{
                                    fontSize: "50px",
                                    color: "#0C46E8",
                                    paddingBottom: "20px",
                                    textAlign: "center",
                                }}
                            />
                            Não há informações sobre o talhão selecionado.
                        </div>
                    </ContentOverflow>
                )}
            </>
        );
    } else {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#FFF",
                        textAlign: "left",
                        paddingRight: "20px",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #FFF",
                    }}
                >
                    <div
                        style={{
                            paddingTop: "15px",
                            fontSize: "30px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            Talhão: {talhaoNome === "all" && "Todos"}
                            {talhaoNome !== "all" && talhaoAtual}
                            <div
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                }}
                            >
                                Área: {area} | Fazenda: {fazenda.nome}
                            </div>
                        </div>
                        <div style={{ fontSize: "50px" }}>
                            <i className="fas fa-layer-group"></i>{" "}
                        </div>
                    </div>
                </div>

                {rangeV ? (
                    <ContentOverflow>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <i
                                className="fas fa-tractor"
                                style={{ marginRight: "10px" }}
                            ></i>
                            Equipamentos no talhão:
                        </div>
                        {equipT.map((e: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #fff",
                                        padding: "15px 5px",
                                    }}
                                    key={uuidv4()}
                                >
                                    {e.numero_patrimonio} - {e.descricao}
                                </div>
                            );
                        })}
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <i
                                className="fas fa-hard-hat"
                                style={{ marginRight: "10px" }}
                            ></i>
                            Operações no talhão:
                        </div>

                        {operaT.map((e: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #fff",
                                        padding: "15px 5px",
                                    }}
                                    key={uuidv4()}
                                >
                                    {e === "naoIdentificado"
                                        ? "Não Identificado"
                                        : operacoes[
                                              operacoes.findIndex(
                                                  (o: any) =>
                                                      o.id_tipo_operacao === e
                                              )
                                          ].descricao}
                                </div>
                            );
                        })}
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <i
                                className="fas fa-seedling"
                                style={{ marginRight: "10px" }}
                            ></i>
                            Culturas no talhão:
                        </div>

                        {culturaT.map((e: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #fff",
                                        padding: "15px 5px",
                                    }}
                                    key={uuidv4()}
                                >
                                    {e === "naoIdentificado"
                                        ? "Não Identificado"
                                        : culturas[
                                              culturas.findIndex(
                                                  (o: any) => o.id_cultura === e
                                              )
                                          ].nomeCultura}
                                </div>
                            );
                        })}
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            <i
                                className="fas fa-stopwatch"
                                style={{ marginRight: "10px" }}
                            ></i>
                            Safras no talhão:
                        </div>

                        {safraT.map((e: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #fff",
                                        padding: "15px 5px",
                                    }}
                                    key={uuidv4()}
                                >
                                    {e === "naoIdentificado"
                                        ? "Não Identificado"
                                        : safras[
                                              safras.findIndex(
                                                  (o: any) => o.id_safra === e
                                              )
                                          ].descricao}
                                </div>
                            );
                        })}
                    </ContentOverflow>
                ) : polyT.length > 0 ? (
                    <ContentOverflow>
                        <div
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            Histórico de operações no talhão:
                        </div>
                        {polyT.map((p: any) => {
                            return (
                                <div
                                    style={{
                                        borderBottom: "1px solid #FFF",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {p.equipa.numero_patrimonio} -{" "}
                                        {p.equipa.descricao}
                                    </div>
                                    <div>
                                        <div>
                                            Realizou operações:{" "}
                                            {p.ope.nOpera.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 799
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            operacoes[
                                                                operacoes.findIndex(
                                                                    (o: any) =>
                                                                        o.id_tipo_operacao ===
                                                                        e
                                                                )
                                                            ].descricao
                                                        );
                                                    }
                                                }
                                            )}
                                            ; Cultura:{" "}
                                            {p.ope.nCultura.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 12
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            culturas[
                                                                culturas.findIndex(
                                                                    (o: any) =>
                                                                        o.id_cultura ===
                                                                        e
                                                                )
                                                            ].nomeCultura
                                                        );
                                                    }
                                                }
                                            )}
                                            ; Safra:{" "}
                                            {p.ope.nSafra.map(
                                                (e: any, index: any) => {
                                                    if (
                                                        e ===
                                                            "naoIdentificado" ||
                                                        e === 3
                                                    ) {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            "Não Identificado"
                                                        );
                                                    } else {
                                                        return (
                                                            (index
                                                                ? ", "
                                                                : "") +
                                                            safras[
                                                                safras.findIndex(
                                                                    (o: any) =>
                                                                        o.id_safra ===
                                                                        e
                                                                )
                                                            ].descricao
                                                        );
                                                    }
                                                }
                                            )}
                                            . No(s) dia(s): {p.dias + ""}.
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </ContentOverflow>
                ) : (
                    <ContentOverflow>
                        <div className="not-found">
                            <InfoCircleFilled
                                style={{
                                    fontSize: "50px",
                                    color: "#0C46E8",
                                    paddingBottom: "20px",
                                    textAlign: "center",
                                }}
                            />
                            Não há informações sobre o talhão no período
                            selecionado.
                        </div>
                    </ContentOverflow>
                )}
            </>
        );
    }
}
// }

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        fazendas: store.fazendas,
        talhaoNome: store.talhao,
        equipamento: store.equipamento,
        equipamentos: store.equipamentos,
        tipos: store.attrEquipamento.tipos,
        operacoes: store.tiposOp,
        safras: store.safras,
        culturas: store.culturas,

        talhoes: store.talhoesTablet,
        talhoesFiltro: store.talhaoFiltro,

        polylineFiltro: store.polylineFiltro,
        time: store.time,

        implementos: store.implementos,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ setFocus }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Talhao);
