import React from "react";
import ReactDOM from "react-dom";
import "./basic.css";
//import "antd/dist/antd.css"; //
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, Store } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { CustomProvider } from "rsuite";
import ptBR from "rsuite/locales/pt_BR";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const themes = {
    dark: process.env.PUBLIC_URL + "/dark-theme.css",
    light: process.env.PUBLIC_URL + "/light-theme.css",
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>
            <PersistGate
                loading={<div>Carregando...</div>}
                persistor={persistor}
            >
                <CustomProvider locale={ptBR}>
                    <ThemeSwitcherProvider
                        themeMap={themes}
                        defaultTheme="light"
                    >
                        <App />
                    </ThemeSwitcherProvider>
                </CustomProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
