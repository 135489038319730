import { bindActionCreators } from "redux";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStore } from "../../../store";
import { connect } from "react-redux";
import { Modal, Table, Tooltip } from "antd";
import { IEquipamento } from "../../../reducers/EquipamentoReducer";
import styled from "styled-components";
import { IAlerta, IAlertaList } from "../../../reducers/AlertaReducer";
import { ITipoOpList } from "../../../reducers/TipoOpReducer";
import { IOperador, IOperadorList } from "../../../reducers/OperadorReducer";
import { useEffect } from "react";
import { ITalhaoTablet } from "../../../store/types";
import {
    IImplemento,
    IImplementoList,
} from "../../../reducers/ImplementoReducer";

const EstiloBoxTitulo = styled.div`
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
`;

interface ListaAlertasProps {
    tema: ITheme;
    openAlertaModal: boolean;
    setOpenAlertaModal: Function;
    equipamento: IEquipamento;
    alertaList: any;
    dataI: any;
    dataF: any;

    tiposOp: ITipoOpList;
    operadores: IOperadorList;
    talhoes: ITalhaoTablet[];
    implementos: IImplementoList;
}

function ListaAlertas(props: ListaAlertasProps) {
    const { tiposOp, operadores, talhoes, implementos } = props;

    const dataSource: IAlerta[] = props.alertaList.map(
        (i: IAlerta, index: number) => {
            return { ...i, key: index };
        }
    );

    const colunas: any = [
        {
            key: "1",
            title: "Data do Alerta",
            dataIndex: "timestamp",
            width: 170,
            render: (text: any) => {
                let d;
                if (text) {
                    d = `${text.split("T")[0].split("-")[2]}/${
                        text.split("T")[0].split("-")[1]
                    }/${text.split("T")[0].split("-")[0]} - ${new Date(
                        text
                    ).toLocaleTimeString("pt-BR")}
                    `;
                }

                return <>{d}</>;
            },
            sorter: (a: any, b: any) => {
                let dA = new Date(a.timestamp).getTime();
                let dB = new Date(b.timestamp).getTime();
                return dA - dB;
            },
            sortDirections: ["ascend", "descend"],
            showSorterTooltip: false,
        },
        {
            key: "2",
            title: "Alerta",
            dataIndex: "txtalerta",
            ellipsis: {
                showTitle: false,
            },
            render: (text: any) => {
                let a;

                if (text) {
                    a = `${text.split("-")[1]}
                    `;
                }

                return <Tooltip title={a}>{a}</Tooltip>;
            },
        },
        {
            key: "3",
            title: "Tipo de Operação",
            dataIndex: "tipo_operacao_temp",
            width: 150,
            render: (ope: any) => {
                let a;

                if (ope) {
                    a =
                        tiposOp[
                            tiposOp.findIndex(
                                (o: any) => o.id_tipo_operacao === ope
                            )
                        ].descricao;
                } else a = "Não Identificado";

                return <>{a}</>;
            },
        },
        {
            key: "4",
            title: "Operador",
            dataIndex: "operador_temp",
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: (operador: any) => {
                let a;

                if (operador) {
                    if (
                        operadores.findIndex(
                            (o: IOperador) => o.id_operador === operador
                        ) < 0
                    ) {
                        a = "Operador não encontrado";
                    } else {
                        a =
                            operadores[
                                operadores.findIndex(
                                    (o: IOperador) => o.id_operador === operador
                                )
                            ].nome;
                    }
                } else a = "Não Identificado";

                return <Tooltip title={a}>{a}</Tooltip>;
            },
        },
        {
            key: "5",
            title: "Talhão",
            dataIndex: "talhao_temp",
            width: 80,
            render: (t: any) => {
                let a;

                if (t) {
                    a =
                        talhoes[
                            talhoes.findIndex(
                                (e: ITalhaoTablet) => e.id_talhao === t
                            )
                        ].nome_talhao;
                } else a = "Não Identificado";

                return <>{a}</>;
            },
        },
        // {
        //     key: "6",
        //     title: "Implemento",
        //     dataIndex: "implemento_temp",
        //     width: 100,
        //     render: (i: any) => {
        //         let a;

        //         if (i) {
        //             a =
        //                 implementos[
        //                     implementos.findIndex(
        //                         (e: IImplemento) => e.id_implemento === i
        //                     )
        //                 ].descricao +
        //                 " - " +
        //                 implementos[
        //                     implementos.findIndex(
        //                         (e: IImplemento) => e.id_implemento === i
        //                     )
        //                 ].numero_patrimonio;
        //         } else a = "";

        //         return <>{a}</>;
        //     },
        // },
    ];

    return (
        <Modal
            destroyOnClose={true}
            width="900px"
            visible={props.openAlertaModal}
            title={"Lista de Alertas"}
            maskStyle={{ backdropFilter: "blur(3px)" }}
            bodyStyle={{
                height: "400px",
                overflowY: "scroll",
                overflowX: "hidden",
                padding: "15px",
            }}
            footer={null}
            centered={true}
            onCancel={() => props.setOpenAlertaModal(false)}
        >
            <div
                style={{
                    height: "30px",
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "10px",
                    columnGap: "10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <EstiloBoxTitulo>Periodo:</EstiloBoxTitulo>
                    <div
                        style={{
                            marginLeft: "15px",
                            fontSize: "16px",
                        }}
                    >
                        {props.dataI.toLocaleDateString()} até{" "}
                        {props.dataF.toLocaleDateString()}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <EstiloBoxTitulo>Equipamento:</EstiloBoxTitulo>
                    <div
                        style={{
                            marginLeft: "15px",
                            fontSize: "16px",
                        }}
                    >
                        {props.equipamento.numero_patrimonio +
                            " - " +
                            props.equipamento.descricao}
                    </div>
                </div>
            </div>
            <Table
                size="small"
                columns={colunas}
                dataSource={dataSource}
                sortDirections={[null]}
                // scroll={{ y: 300 }}
                pagination={{
                    showSizeChanger: false,
                    defaultPageSize: 7,
                }}
                style={{
                    padding: "2px",
                }}
            ></Table>
        </Modal>
    );
}

function mapStateToProps(store: IStore) {
    return {
        tiposOp: store.tiposOp,
        operadores: store.operadores,
        talhoes: store.talhoesTablet,
        implementos: store.implementos,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaAlertas);
