//Métodos para os operadores

import { message } from "antd";
import {
    IOperador,
    IOperadorList,
    IOperadorModule,
} from "../reducers/OperadorReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_LIST_OPERADOR, SET_OPERADOR } from "./actionTypes";

export const setOperador = (value: IOperador) => ({
    type: SET_OPERADOR,
    payload: value,
});

export const setListOperador = (value: IOperadorList) => ({
    type: SET_LIST_OPERADOR,
    payload: value,
});

export interface IOperadorModuleAction
    extends IOperadorModule,
        ReadableStream {}

export const getOperador = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "operador"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/operador/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "operador", res.erro));
                } else {
                    dispatch(setListOperador(res));
                    dispatch(setStatusSuccess("get", "operador"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "operador", error.data));
            });
    };
};

export const adicionarOperador = (value: IOperadorModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "operador"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/operador/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "operador", res.erro));
                } else {
                    dispatch(getOperador());
                    dispatch(setStatusSuccess("add", "operador"));
                    message.success("Operador adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "operador", error.data));
            });
    };
};

export const deletarOperador = (operador: IOperadorModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "operador"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/operador/` + operador, {
            method: "delete",
            headers: {
                "Content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "operador", res.erro));
                } else {
                    dispatch(getOperador());
                    dispatch(setStatusSuccess("del", "operador"));
                    message.success("O operador selecionado foi excluído.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "operador", error.data));
            });
    };
};

export const editarOperador = (value: IOperadorModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "operador"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/operador/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "operador", res.erro));
                } else {
                    dispatch(getOperador());
                    dispatch(setStatusSuccess("edit", "operador"));
                    message.success("Operador atualizado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "operador", error.data));
            });
    };
};
