import { IStatusList } from "../../../../store/types";
import { setStatusNull } from "../../../../store/actions";
import { editarUsuario } from "../../../../store/userActions";
import { IStore } from "../../../../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Popover,
    Row,
    Switch,
    TimePicker,
    Tooltip,
} from "antd";
import {
    CloseOutlined,
    CheckOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import ReactInputMask from "react-input-mask";
import moment from "moment";

interface IEditarUserProps {
    loader: IStatusList;
    setModal?: any;
    setStatusNull: any;

    editarUsuario?: any;
    cont?: any;
    tema?: any;
}

function EditarUsuario(props: IEditarUserProps) {
    const { editarUsuario, loader, setModal, setStatusNull } = props;
    const [alerta, setAlerta] = useState(props.cont.envia_alerta);

    const [newSenha, setNewSenha] = useState(false);
    const [validoSenha, setValidoSenha] = useState(false);

    const [horario, setHorario] = useState(
        props.cont.hora_rel_diario ? props.cont.hora_rel_diario : "08:00"
    );
    const [phones, setPhones] = useState(props.cont.fone_contato.split(";"));

    const format = "HH:mm";

    const handleDisable = useCallback(() => {
        if (validoSenha === true) {
            return false;
        }
        return true;
    }, [validoSenha]);

    // const cellphoneMask = "(00) 0 0000-0000";

    // const mask = React.useMemo(
    //     () => [
    //         {
    //             mask: cellphoneMask,
    //             lazy: false,
    //         },
    //     ],
    //     []
    // );

    const onChange = (e: any) => {
        const value = e.target.value;
        const phonesArray = value.split(";").map((p: any) => "55" + p.trim());
        setPhones(phonesArray);
    };

    useEffect(() => {
        if (loader.usuario?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "usuario");
        }
    }, [loader]);

    return (
        <>
            <Form
                layout="vertical"
                initialValues={{
                    ...props.cont,
                    // fone_contato: props.cont.fone_contato
                    //     ? props.cont.fone_contato.split("55")[1]
                    //     : "",
                    fone_contato: phones
                        .map((f: any) => f.split("55")[1])
                        .join(";"),
                    hora_rel_diario: moment(horario, format),
                }}
                onFinish={(v: any) => {
                    let obj;
                    if (newSenha) {
                        obj = {
                            id_user: props.cont.id_user,
                            cliente_temp: props.cont.cliente_temp,
                            nome: v.nome,
                            email: props.cont.email,
                            pass: v.pass,
                            since: props.cont.since,
                            preferencia: props.cont.preferencia,
                            email_alexa: v.email_alexa,
                            nome_contato: v.nome_contato,
                            fone_contato: phones.join(";"),
                            envia_alerta: alerta,
                            hora_rel_diario: horario,
                        };
                    } else {
                        obj = {
                            id_user: props.cont.id_user,
                            cliente_temp: props.cont.cliente_temp,
                            nome: v.nome,
                            email: props.cont.email,
                            since: props.cont.since,
                            preferencia: props.cont.preferencia,
                            email_alexa: v.email_alexa,
                            nome_contato: v.nome_contato,
                            fone_contato: phones.join(";"),
                            envia_alerta: alerta,
                            hora_rel_diario: horario,
                        };
                    }
                    editarUsuario(obj);
                    props.setModal(false);
                }}
            >
                {/* <Form.Item name="id_user" style={{ display: "none" }}>
                    <Input type="hidden" name="id_user"></Input>
                </Form.Item>
                <Form.Item name="cliente_temp" style={{ display: "none" }}>
                    <Input type="hidden" name="cliente_temp"></Input>
                </Form.Item> */}

                <div style={{ marginBottom: "10px" }}>Dados para acesso:</div>

                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome"
                        >
                            <Input placeholder="Nome do usuário" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item label="Email:" name="email">
                            <Input
                                readOnly
                                type="email"
                                style={{
                                    fontStyle: "italic",
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <div
                            style={{
                                display: "flex",
                                flexGrow: "2",
                                marginRight: "10px",
                                justifyContent: "flex-end",
                                marginBottom: "-15px",
                            }}
                        >
                            {" "}
                            {/* <Popover
                                title="Redefinir Senha"
                                trigger="hover"
                                color={props.tema && "#424242"}
                                content={
                                    <div>
                                        Para redefinir sua senha <br></br>
                                        digite uma nova senha.
                                        <br></br> Nesse caso você terá que{" "}
                                        <br></br>logar novamente na <br></br>{" "}
                                        plataforma.
                                    </div>
                                }
                            >
                                
                            </Popover> */}
                            <InfoCircleOutlined />
                        </div>
                        <Tooltip
                            placement="topRight"
                            title="Para redefinir sua senha
                                        digite uma nova senha. Nesse caso você terá que
                                        logar novamente na
                                        plataforma."
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: newSenha,
                                        message: "Coloque uma senha",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (newSenha) {
                                                if (!value) {
                                                    return (
                                                        setValidoSenha(false),
                                                        Promise.reject()
                                                    );
                                                }

                                                if (value.length !== 8) {
                                                    return (
                                                        setValidoSenha(false),
                                                        Promise.reject(
                                                            "A senha deve conter 8 digitos"
                                                        )
                                                    );
                                                }
                                                setValidoSenha(true);

                                                return Promise.resolve();
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    }),
                                ]}
                                label="Senha:"
                                name="pass"
                            >
                                <Input.Password
                                    onChange={(e: any) => {
                                        if (e.target.value.length > 0) {
                                            setNewSenha(true);
                                        } else setNewSenha(false);
                                    }}
                                    maxLength={8}
                                    placeholder="Redefinir senha"
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <div style={{ marginBottom: "10px" }}>Dados para contato:</div>
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        {" "}
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um nome para contato",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome_contato"
                        >
                            <Input placeholder="Nome do contato" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um e-mail para contato",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Email:"
                            name="email_alexa"
                        >
                            <Input
                                type="email"
                                placeholder="E-mail para contato"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <div
                            style={{
                                display: "flex",
                                flexGrow: "2",
                                marginRight: "10px",
                                justifyContent: "flex-end",
                                marginBottom: "-15px",
                            }}
                        >
                            {" "}
                            <InfoCircleOutlined />
                        </div>
                        <Tooltip
                            placement="topRight"
                            title='Para adicionar mais de um número de celular utilize " ; "'
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Coloque um telefone para contato",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                label="Celular:"
                                name="fone_contato"
                            >
                                <Input
                                    // mask="(99) 9 9999-9999"
                                    id="fone_contato"
                                    placeholder="(99) 9 9999-9999"
                                    onChange={onChange}
                                    // className={props.tema ? "phone-dark" : "phone"}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item
                            name="envia_alerta"
                            label="Envio de Notificações"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "15px",
                                    alignItems: "baseline",
                                    // marginTop: "25px",
                                    marginBottom: "0px",
                                }}
                            >
                                <div>
                                    <Switch
                                        checked={alerta}
                                        onChange={(x: any) => setAlerta(x)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </div>
                                <div
                                    onClick={() => setAlerta(!alerta)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Receber notificações no celular
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="hora_rel_diario"
                            label="Horário para envio de relatório:"
                            rules={[
                                {
                                    required: alerta,
                                    message: "Selecione um horário",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <TimePicker
                                placeholder="Selecione"
                                // defaultValue={moment(horario, format)}
                                onChange={(e: any, value: any) =>
                                    setHorario(value)
                                }
                                format={format}
                                showNow={false}
                                minuteStep={15}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={newSenha && handleDisable()}
                        block
                        loading={loader.usuario?.edit?.loading}
                    >
                        Salvar usuário
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarUsuario,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarUsuario);
