import { Button, message, Modal, Popconfirm, Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IFazendaList } from "../../../reducers/FazendaReducer";
import { IStore } from "../../../store";
import { getServidor, deletarServidor } from "../../../store/servidorActions";
import { setStatusNull } from "../../../store/actions";
import { IServidorList } from "../../../reducers/ServidorReducer";
import AdicionarEquipamento from "./Equipamentos/AdicionarEquipamento";
import AdicionarServidor from "./Servidores/AdicionarServidor";
import EditarServidor from "./Servidores/EditarServidor";
import { IStatusList } from "../../../store/types";
import { IAntenaList } from "../../../reducers/AntenaReducer";
import AdicionarAntena from "./Antenas/AdicionarAntena";
import EditarAntena from "./Antenas/EditarAntena";
import { getAntenas } from "../../../store/antenaActions";
import { ITheme } from "../../../reducers/ThemeReducer";

const { Option } = Select;

interface PropsServidores {
    fazendaListState: IFazendaList;
    servidores: IServidorList;
    servidorStatus: IStatusList;
    antenas: IAntenaList;
    theme: ITheme;

    getServidor: Function;
    deletarServidor: Function;
    setStatusNull: Function;
    getAntenas: Function;
}

function Servidores(props: PropsServidores) {
    const {
        fazendaListState,
        servidores,
        servidorStatus,
        antenas,
        getServidor,
        deletarServidor,
        setStatusNull,
        getAntenas,
        theme,
    } = props;

    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [which, setWhich] = useState(0);
    const [faz, setFaz] = useState("");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);
    const [listServidores, setListServidores] = useState([]);
    const [loadingServidores, setLoadingServidores] = useState(false);

    function handleSelectFaz(value: any) {
        setFaz(value);
        setWhich(0);
    }

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Servidor",
            conteudo: 1,
        });
        setModal(true);
    }

    function handleEditar(id: any) {
        setConfigModal({
            titulo: "Editar Servidor",
            conteudo: 2,
        });

        setModal(true);
    }

    function handleExcluir(id: any) {
        const servKey = servidores.findIndex(
            (serv: any) => serv.id_local_server === id
        );
        setConfirmLoading(true);
        deletarServidor(servidores[servKey].fazenda_temp, which);
    }

    useEffect(() => {
        if (faz === "") {
            setListServidores([]);
        } else {
            setLoadingServidores(true);
            fetch(
                `${process.env.REACT_APP_BASE_URL_API}api/localserver/` + faz,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log("não conectou");
                    }
                })
                .then(res => {
                    if (res.erro) {
                        message.error("Ocorreu um erro na conexão.");
                    } else {
                        setListServidores(res);
                    }
                    setLoadingServidores(false);
                })
                .catch(error => {
                    message.error("Ocorreu um erro na conexão " + error.data);
                    setLoadingServidores(false);
                });
        }
    }, [faz]);

    useMemo(() => {
        if (servidorStatus.servidor?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "servidor");
        }
        if (faz !== "") getServidor(faz);
    }, [servidorStatus.servidor?.del?.success, faz, getServidor]);

    const desativaAdd = useCallback(() => {
        if (listServidores.length > 0) {
            return true;
        }
        if (faz === "") {
            return true;
        }
        return false;
    }, [listServidores, faz]);

    return (
        <>
            <div style={{ height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "start" }}>
                    <div
                        style={{
                            paddingRight: "10px",
                        }}
                    >
                        Servidores em uso na fazenda:
                    </div>
                    <div>
                        <Select
                            showSearch
                            style={{
                                width: "200px",
                            }}
                            size="small"
                            onSelect={handleSelectFaz}
                        >
                            {fazendaListState.map((f: any) => {
                                return (
                                    <Option
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {loadingServidores ? (
                                <>Buscando...</>
                            ) : (
                                <>
                                    {listServidores.map((f: any) => {
                                        let classN = theme
                                            ? "select-expand-li-dark"
                                            : "select-expand-li";
                                        if (f.id_local_server === which) {
                                            classN += " active";
                                        }
                                        return (
                                            <li
                                                key={f.id_local_server}
                                                className={classN}
                                                onClick={() =>
                                                    setWhich(f.id_local_server)
                                                }
                                                onDoubleClick={() =>
                                                    handleEditar(
                                                        f.id_local_server
                                                    )
                                                }
                                            >
                                                {f.descricao}
                                            </li>
                                        );
                                    })}
                                    <li
                                        style={{ flex: 1 }}
                                        onClick={() => setWhich(0)}
                                    ></li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                            disabled={desativaAdd()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir o servidor selecionado?"
                            onConfirm={() => handleExcluir(which)}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                // disabled={which === 0 ? true : false}
                                disabled={true}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                        <Button
                            disabled={which === 0}
                            onClick={() => {
                                navigator.clipboard.writeText(String(which));
                            }}
                            style={{ width: "100%" }}
                        >
                            Copiar Id
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
                //forceRender={true}
            >
                {configModal.conteudo === 1 && (
                    <AdicionarServidor setModal={setModal} />
                )}
                {configModal.conteudo === 2 && (
                    <EditarServidor
                        setModal={setModal}
                        cont={
                            listServidores[
                                listServidores.findIndex(
                                    (s: any) => s.id_local_server === which
                                )
                            ]
                        }
                        antenas={antenas}
                        getAntenas={getAntenas}
                    />
                )}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendaListState: store.fazendas,
        servidores: store.servidores,
        servidorStatus: store.loader,
        antenas: store.antenasConfig,
        theme: store.theme,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getServidor,
            deletarServidor,
            setStatusNull,
            getAntenas,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Servidores);
