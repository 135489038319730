import React, { createContext } from "react";
import { MappedTypeNode } from "typescript";

const global = {
    api_url:
        "https://maps.google.com/maps/api/js?key=" +
        process.env.REACT_APP_KEY_API,
    token: "",
};

export const MapContext: any = createContext({});

interface MapProviderProps {
    children: any;
}

export function MapProvider(props: MapProviderProps) {
    const map: any = null;
    var shp: any = [];
    var labels: any = [];
    var data: any = [];

    return (
        <MapContext.Provider
            value={{
                global: global,
                map: map,
                shp: shp,
                labels: labels,
                data: data,
            }}
        >
            {props.children}
        </MapContext.Provider>
    );
}
