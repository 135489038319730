import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { IStore } from "../../store";
import styled, { keyframes } from "styled-components";

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartData,
    PointElement,
    LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Line, Pie } from "react-chartjs-2";
import { IPolylineList } from "../../reducers/PolylineReducer";
import { IEquipamentoList } from "../../reducers/EquipamentoReducer";
import { IFazenda } from "../../reducers/FazendaReducer";
import { ITime } from "../../reducers/TimeReducer";
import { ITipoOp } from "../../reducers/TipoOpReducer";
import { ICultura } from "../../reducers/CulturaReducer";
import { ISafra } from "../../reducers/SafraReducer";
import { Button, Result } from "antd";
import { IRelatorio } from "../../reducers/RelatorioReducer";
import {
    criarPDF,
    converteHoras,
    segParaHoras,
    mediaConsumo,
} from "../../config/helpers";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Spinner } from "../../styles/Main";
import { setStatusStart, setStatusSuccess } from "../../store/actions";
import { NunitoFont } from "../../fonts";
import { ITheme } from "../../reducers/ThemeReducer";
import { IStatusList, ITalhaoTablet } from "../../store/types";
import { IOperador } from "../../reducers/OperadorReducer";

const showDash = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

const hideDash = keyframes`
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;

const animaIniciar = keyframes`
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.4;
    }
    100%{
        opacity: 1;
    }
`;

interface AnimaInterface {
    view?: boolean;
    expPdf?: boolean;
}

const AnimaDash = styled.div<AnimaInterface>`
    opacity: ${props => (props.view ? "1" : props.expPdf ? "1" : "0")};
    transition: opacity 0.5s linear;
    height: calc(100vh - 50px);

    & .util-menu-dashboard:hover {
        color: #666666;
        cursor: pointer;
    }

    & .iniciar-dash {
        animation: ${animaIniciar} 0.8s linear infinite;
    }
`;

const EstiloGeralRelatorios = styled.div`
    color: rgb(28, 28, 28);
    font-size: 20px;
    /* animation: ${showDash} 0.8s linear 1; */

    & > div:last-child {
        height: calc(100vh + 10vh);
    }
`;

interface ItemTabelaInterface {
    view?: boolean | undefined;
    tema?: boolean;
}

const EstiloItemTabelaResumo = styled.div<ItemTabelaInterface>`
    display: ${props => (props.view ? "flex" : "none")};
    justify-content: space-between;
    width: 45%;
    border-bottom: 1px solid #c1c1c1;
    padding: 5px 5px;
    color: ${props => (props.tema ? "#d9d9d9" : "#1c1c1c")};

    & div:first-child {
        font-weight: bold;
    }
`;

interface IRelatoriosProps {
    polylines: IPolylineList;
    equipamentos: IEquipamentoList;
    fazenda: IFazenda;
    time: ITime;
    tipoOp: ITipoOp;
    polyFiltro: any;
    cultura: ICultura;
    safra: ISafra;
    relatorio: IRelatorio;
    theme: ITheme;

    talhao: any;
    operador: IOperador;
    talhoesFiltro: ITalhaoTablet[];

    scroll?: any;
    setScroll?: any;
    referencia?: any;

    loader: IStatusList;

    setStatusStart: Function;
    setStatusSuccess: Function;

    tipoOpParada: any;
}

function Relatorios(props: IRelatoriosProps) {
    const {
        polylines,
        equipamentos,
        fazenda,
        time,
        tipoOp,
        polyFiltro,
        cultura,
        safra,
        relatorio,
        theme,

        talhao,
        operador,
        talhoesFiltro,

        loader,

        setStatusStart,
        setStatusSuccess,

        tipoOpParada,
    } = props;

    const [showComponent, setShowComponent] = useState(Object);
    const [areass, setAreas] = useState(Object);
    const [velocidadeMedia, setVelocidadeMedia] = useState(0);
    const [velocidadeMax, setVelocidadeMax] = useState(0);

    const [rpmMedia, setRpmMedia] = useState(0);
    const [rpmMax, setRpmMax] = useState(0);

    const [alertasTotal, setAlertasTotal] = useState(0);

    const [tempoTotal, setTempoTotal] = useState(0);
    const [tempoOpe, setTempoOpe] = useState(0);
    const [tempoParado, setTempoParado] = useState(0);
    const [tempoDesloc, setTempoDesloc] = useState(0);
    const [consumoTotal, setConsumoTotal] = useState(0);
    const [consumoOpe, setConsumoOpe] = useState(0);
    const [consumoParado, setConsumoParado] = useState(0);
    const [consumoDesloc, setConsumoDesloc] = useState(0);

    const [haTotal, setHaTotal] = useState(0);
    const [haOpe, setHaOpe] = useState(0);
    const [haNaoId, setHaNaoId] = useState(0);
    const [kmNaoId, setKmNaoId] = useState(0);
    const [kmDesloc, setKmDesloc] = useState(0);

    const [graficoTempo, setGraficoTempo] = useState(Object);
    const [graficoMedia, setGraficoMedia] = useState(Object);
    const [graficoConsumo, setGraficoConsumo] = useState(Object);
    const [showGrafico, setShowGrafico] = useState(false);

    const [graficoParada, setGraficoParada] = useState(Object);

    const [grafParadas, setgrafParadas] = useState<ChartData<"bar">>({
        datasets: [],
    });

    const [expPdf, setExpPdf] = useState(false);

    const [talhaoAtual, setTalhaoAtual] = useState<any>("");

    const [opeAtual, setOpeAtual] = useState<any>();

    useEffect(() => {
        if (Object.keys(tipoOp).length > 0) {
            setOpeAtual(
                tipoOp.id_tipo_operacao === 0
                    ? "Não Identificado"
                    : tipoOp.descricao
            );
        } else {
            if (Object.keys(tipoOpParada).length > 0) {
                setOpeAtual(
                    tipoOpParada.id_tipo_operacao === 0
                        ? "Não Identificado"
                        : tipoOpParada.descricao
                );
            } else {
                setOpeAtual("Todos");
            }
        }
    }, [tipoOp, tipoOpParada]);

    useEffect(() => {
        if (fazenda.nome !== "") {
            if (talhao !== "all") {
                let talKey: any;
                if (talhoesFiltro.length > 0) {
                    talKey = talhoesFiltro.findIndex(
                        (t: any) => t.id_talhao === talhao
                    );
                }

                if (talKey > -1) {
                    setTalhaoAtual(talhoesFiltro[talKey].nome_talhao);
                } else if (
                    JSON.parse(fazenda.shape).features.findIndex(
                        (f: any) => f.properties.id_agrochip === talhao
                    ) > -1
                ) {
                    setTalhaoAtual(
                        JSON.parse(fazenda.shape).features[
                            JSON.parse(fazenda.shape).features.findIndex(
                                (f: any) => f.properties.id_agrochip === talhao
                            )
                        ].properties[JSON.parse(fazenda.campo).nome]
                    );
                }
            } else {
                setTalhaoAtual("Todos");
            }
        }
    }, [talhao, fazenda, talhoesFiltro]);

    useEffect(() => {
        if (props.referencia !== "undefined") {
            var areas: any = [];

            for (const r of props.referencia.current.children[2].children) {
                areas.push({
                    element: r,
                    position: r.offsetTop - 99,
                    height: r.offsetHeight,
                    bottom: r.offsetHeight + r.offsetTop - 99,
                });
            }
            const elementoAtual = areas.findIndex((e: any) => {
                return (
                    props.scroll + 2 >= e.position &&
                    props.scroll + 2 < e.bottom
                );
            });

            setAreas(areas);
            setShowComponent({
                areaAtual: areas[elementoAtual],
                index: elementoAtual,
            });
        }
    }, [props.referencia, props.scroll]);

    useEffect(() => {
        setTimeout(() => {
            setShowGrafico(true);
        }, 500);
    }, [time]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ChartjsPluginStacked100
    );

    ChartJS.register(ChartDataLabels);

    const data: ChartData<"bar"> = {
        labels: graficoTempo.labels,

        datasets: [
            {
                label: "Tempo Produtivo",
                data: graficoTempo.dataOpe,
                backgroundColor: ["#205fad", "#205fad", "#205fad"],
                borderColor: ["#205fad", "#205fad", "#205fad"],
                borderWidth: 1,
            },
            {
                label: "Tempo Deslocamento",
                data: graficoTempo.dataDesloc,
                backgroundColor: ["#75c043", "#75c043", "#75c043"],
                borderColor: ["#75c043", "#75c043", "#75c043"],
                borderWidth: 1,
            },
            {
                label: "Tempo Parado",
                data: graficoTempo.dataParado,

                backgroundColor: ["#bcd631", "#bcd631", "#bcd631"],
                borderColor: ["#bcd631", "#bcd631", "#bcd631"],
                borderWidth: 1,
            },
        ],
    };

    const dataLine: ChartData<"line"> = {
        labels: graficoMedia.labels,
        datasets: [
            {
                label: "Média Rpm x 100",
                data: graficoMedia.dataRpm,
                borderColor: "#75c043",
                backgroundColor: "#75c043",
            },
            {
                label: "Média Velocidade",
                data: graficoMedia.dataVel,
                borderColor: "#bcd631",
                backgroundColor: "#bcd631",
            },
            {
                label: "Média Consumo",
                data: graficoMedia.dataCons,
                borderColor: "#008ed1",
                backgroundColor: "#008ed1",
            },
        ],
    };

    const dataBar: ChartData<"bar"> = {
        labels: graficoConsumo.labels,
        datasets: [
            {
                label: "Consumo Deslocamento",
                data: graficoConsumo.dataCDesloc,
                backgroundColor: ["#205fad", "#205fad", "#205fad"],
                borderColor: ["#205fad", "#205fad", "#205fad"],
                borderWidth: 1,
            },
            {
                label: "Consumo Parado/Ligado",
                data: graficoConsumo.dataCP,
                backgroundColor: ["#bcd631", "#bcd631", "#bcd631"],
                borderColor: ["#bcd631", "#bcd631", "#bcd631"],
                borderWidth: 1,
            },
            {
                label: "Consumo Operando",
                data: graficoConsumo.dataCOp,
                backgroundColor: ["#008ed1", "#008ed1", "#008ed1"],
                borderColor: ["#008ed1", "#008ed1", "#008ed1"],
                borderWidth: 1,
            },
            {
                label: "Consumo Total",
                data: graficoConsumo.dataCTot,

                backgroundColor: ["#75c043", "#75c043", "#75c043"],
                borderColor: ["#75c043", "#75c043", "#75c043"],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        let datasetMontado: any[] = [];
        let colors = [
            "#75c043",
            "#093061",
            "#bcd631",
            "#008ed1",
            "#006830",
            "#205fad",
            "#ABFF73",
            "#91C1FB",
            "#45E329",
            "#2212E0",
            "#00B554",
            "#0C68E8",
            "#E5FF54",
            "#261C94",
            "#36B571",
            "#2266BA",
            "#69BF30",
        ];
        if (graficoParada.equip !== undefined) {
            graficoParada.equip.forEach((g: any, i: any = 0) => {
                datasetMontado.push({
                    label: g.tipo_parada,
                    data: g.tempo_parado,
                    backgroundColor: colors[i],
                    // borderColor:
                    //     colors[Math.floor(Math.random() * colors.length)],
                    borderWidth: 1,
                });
            });
            setgrafParadas({
                labels: graficoParada.labels,
                datasets: datasetMontado,
            });
        }
    }, [graficoParada]);

    useEffect(() => {
        let velMediaEquipa = 0;
        let velMaxEquipa = 0;
        let rpmMediaEquipa = 0;
        let rpmMaxEquipa = 0;
        let qntAlertasEquipa = 0;
        let tempoTotalEquipa = 0;
        let tempoOpeEquipa = 0;
        let tempoParadoEquipa = 0;
        let tempoDeslocEquipa = 0;
        let consumoTotalEquipa = 0;
        let consumoOpeEquipa = 0;
        let consumoParadoEquipa = 0;
        let consumoDeslocEquipa = 0;
        let haTotalEquipa = 0;
        let haOpeEquipa = 0;
        let haNaoIdEquipa = 0;
        let kmNaoIdEquipa = 0;
        let kmDeslocEquipa = 0;

        let rpmGraf: any = [];
        let velGraf: any = [];
        let consumoGraf: any = [];
        let labelsGrafTempo: any = [];
        let dataGrafTempoParado: any = [];
        let dataGrafTempoOpe: any = [];
        let dataGrafTempoDesloc: any = [];
        let dataConsumoParado: any = [];
        let dataConsumoOpe: any = [];
        let dataConsumoDesloc: any = [];
        let dataConsumoTotal: any = [];
        let labelsGrafParada: any = [];
        let equiGrafParada: any = [];
        let paradaArray: any = [];
        let paradaEquipIdArray: any = [];
        let paradaTipoArray: any = [];
        if (relatorio.ope !== undefined) {
            relatorio.ope.forEach((e: any) => {
                qntAlertasEquipa += e.alertas;
            });

            setAlertasTotal(qntAlertasEquipa);
        }
        if (relatorio.rel !== undefined) {
            relatorio.rel.forEach((e: any) => {
                velMediaEquipa += e.tempoParado.velocidade_media;
                velGraf.push(e.tempoParado.velocidade_media);
                if (velMaxEquipa < e.tempoParado.velocidade_maxima) {
                    velMaxEquipa = e.tempoParado.velocidade_maxima;
                }
                rpmMediaEquipa += e.tempoParado.rpm_media;
                rpmGraf.push(e.tempoParado.rpm_media / 100);
                if (rpmMaxEquipa < e.tempoParado.rpm_maxima) {
                    rpmMaxEquipa = e.tempoParado.rpm_maxima;
                }

                tempoTotalEquipa += e.tempoParado.tempo_total;
                tempoParadoEquipa += e.tempoParado.tempo_parado;
                tempoDeslocEquipa += e.tempoParado.tempo_total_deslocamento;

                consumoTotalEquipa += e.tempoParado.consumo_total;
                consumoParadoEquipa += e.tempoParado.consumo_parado;
                consumoDeslocEquipa += e.tempoParado.consumo_total_deslocamento;
                consumoGraf.push(
                    mediaConsumo(
                        segParaHoras(e.tempoParado.tempo_total, "horas"),
                        e.tempoParado.consumo_total
                    ).toFixed(2)
                    // isNaN(
                    //     e.tempoParado.consumo_total / e.tempoParado.tempo_total
                    // )
                    //     ? 0
                    //     : e.tempoParado.consumo_total /
                    //           e.tempoParado.tempo_total
                );

                haTotalEquipa += e.tempoParado.ha_total;
                haOpeEquipa += e.tempoParado.ha_ope;
                haNaoIdEquipa += e.tempoParado.ha_nao_id;
                kmNaoIdEquipa += e.tempoParado.km_nao_id;
                kmDeslocEquipa += e.tempoParado.km_deslocamento;
            });
            tempoOpeEquipa =
                tempoTotalEquipa - tempoParadoEquipa - tempoDeslocEquipa;
            consumoOpeEquipa =
                consumoTotalEquipa - consumoParadoEquipa - consumoDeslocEquipa;

            let mediaVel = (velMediaEquipa / relatorio.rel.length).toFixed(2);
            let mediaRpm = (rpmMediaEquipa / relatorio.rel.length).toFixed(2);

            setTempoTotal(parseFloat(tempoTotalEquipa.toFixed(2)));
            setTempoOpe(parseFloat(tempoOpeEquipa.toFixed(2)));
            setTempoParado(parseFloat(tempoParadoEquipa.toFixed(2)));
            setTempoDesloc(parseFloat(tempoDeslocEquipa.toFixed(2)));

            setConsumoTotal(parseFloat(consumoTotalEquipa.toFixed(2)));
            setConsumoOpe(parseFloat(consumoOpeEquipa.toFixed(2)));
            setConsumoParado(parseFloat(consumoParadoEquipa.toFixed(2)));
            setConsumoDesloc(parseFloat(consumoDeslocEquipa.toFixed(2)));

            setHaTotal(parseFloat(haTotalEquipa.toFixed(2)));
            setHaOpe(parseFloat(haOpeEquipa.toFixed(2)));
            setHaNaoId(parseFloat(haNaoIdEquipa.toFixed(2)));
            setKmNaoId(parseFloat(kmNaoIdEquipa.toFixed(2)));
            setKmDesloc(parseFloat(kmDeslocEquipa.toFixed(2)));

            setVelocidadeMedia(parseFloat(mediaVel));
            setVelocidadeMax(velMaxEquipa);
            setRpmMedia(parseFloat(mediaRpm));
            setRpmMax(rpmMaxEquipa);
        }

        if (relatorio.rel !== undefined) {
            relatorio.rel.forEach((e: any) => {
                let equipaGraf =
                    equipamentos[
                        equipamentos.findIndex(
                            (i: any) => i.id_equipamento === e.id
                        )
                    ].numero_patrimonio +
                    "-" +
                    equipamentos[
                        equipamentos.findIndex(
                            (i: any) => i.id_equipamento === e.id
                        )
                    ].descricao;

                // let porcTempoParado = Math.round(
                //     (e.tempoParado.tempo_parado * 100) /
                //         e.tempoParado.tempo_total
                // );
                let porcTempoParado = e.tempoParado.tempo_parado;
                let porcTempoDesloc = e.tempoParado.tempo_total_deslocamento;
                let porcTempoOpe =
                    e.tempoParado.tempo_total -
                    porcTempoParado -
                    porcTempoDesloc;
                // let porcTempoOpe = 100 - porcTempoParado;
                if (porcTempoParado === 0 && e.tempoParado.tempo_total === 0) {
                    // porcTempoParado = 1;
                    porcTempoParado = 0;
                    porcTempoOpe = 0;
                }
                if (e.tipoParada !== undefined) {
                    e.tipoParada.forEach((p: any) => {
                        if (p.tempo_parado >= 60) {
                            if (
                                labelsGrafParada.findIndex(
                                    (z: any) => z === equipaGraf
                                ) === -1
                            ) {
                                paradaEquipIdArray.push(e.id);
                                labelsGrafParada.push(equipaGraf);
                                paradaArray.push(0);
                            }
                        }
                    });
                    e.tipoParada.forEach((p: any) => {
                        if (p.tempo_parado >= 60) {
                            if (
                                equiGrafParada.findIndex(
                                    (z: any) => z.tipo_parada === p.tipo_parada
                                ) === -1
                            ) {
                                paradaTipoArray.push(p.tipo_parada);
                                equiGrafParada.push({
                                    tipo_parada: p.tipo_parada,
                                    tempo_parado: paradaArray,
                                });
                            }
                        }
                    });
                }

                labelsGrafTempo.push(equipaGraf);
                dataGrafTempoParado.push(
                    porcTempoParado >= 60 && porcTempoParado
                );
                dataGrafTempoOpe.push(porcTempoOpe >= 60 && porcTempoOpe);
                dataGrafTempoDesloc.push(
                    porcTempoDesloc >= 60 && porcTempoDesloc
                );

                dataConsumoOpe.push(
                    e.tempoParado.consumo_total -
                        e.tempoParado.consumo_parado -
                        e.tempoParado.consumo_total_deslocamento
                );
                dataConsumoParado.push(e.tempoParado.consumo_parado);
                dataConsumoTotal.push(e.tempoParado.consumo_total);
                dataConsumoDesloc.push(
                    e.tempoParado.consumo_total_deslocamento
                );
            });
        }
        if (relatorio.rel !== undefined) {
            let equiGrafParadaTempo = JSON.parse(
                JSON.stringify(equiGrafParada)
            );
            relatorio.rel.forEach((e: any) => {
                e.tipoParada.forEach((p: any) => {
                    if (p.tempo_parado >= 60) {
                        let indexTipoParada = equiGrafParada.findIndex(
                            (f: any) => f.tipo_parada === p.tipo_parada
                        );
                        let indexTempoParado = paradaEquipIdArray.findIndex(
                            (tp: any) => e.id === tp
                        );

                        equiGrafParadaTempo[indexTipoParada].tempo_parado[
                            indexTempoParado
                        ] = p.tempo_parado;
                    }
                });
            });
            setGraficoParada({
                equip: equiGrafParadaTempo,
                labels: labelsGrafParada,
            });
        }

        setGraficoTempo({
            labels: labelsGrafTempo,
            dataParado: dataGrafTempoParado,
            dataOpe: dataGrafTempoOpe,
            dataDesloc: dataGrafTempoDesloc,
        });
        setGraficoMedia({
            labels: labelsGrafTempo,
            dataCons: consumoGraf,
            dataRpm: rpmGraf,
            dataVel: velGraf,
        });
        setGraficoConsumo({
            labels: labelsGrafTempo,
            dataCP: dataConsumoParado,
            dataCOp: dataConsumoOpe,
            dataCTot: dataConsumoTotal,
            dataCDesloc: dataConsumoDesloc,
        });
    }, [relatorio]);

    const handleDownloadPdf = async () => {
        setExpPdf(true);
        setStatusStart("get", "pdf");

        if (props.referencia !== undefined) {
            var footerHeight = 0;
            const pdf = new jsPDF("l", "px", "a4", true);
            for (const r of props.referencia.current.children[2].children) {
                const canvas = await html2canvas(r, {
                    backgroundColor: theme ? "#303030" : "#FFFFFF",
                });
                pdf.setLineWidth(0);
                pdf.setFillColor(theme ? "#303030" : "#FFFFFF");
                pdf.rect(0, 0, 800, 600, "F");
                const data = canvas.toDataURL("image/png");

                const imgProperties = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight =
                    (imgProperties.height * pdfWidth) / imgProperties.width +
                    49;
                pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);

                //FOOTER

                let str =
                    "Página " + pdf.getCurrentPageInfo().pageNumber + " de";

                let dataFooter = new Image();
                dataFooter.src = "/Footer_PDF.jpeg";
                const footerPropperties = pdf.getImageProperties(dataFooter);
                const footerWidht = pdf.internal.pageSize.getWidth();
                footerHeight =
                    (footerPropperties.height * footerWidht) /
                    footerPropperties.width;
                pdf.addImage(
                    dataFooter,
                    "jpeg",
                    0,
                    pdf.internal.pageSize.getHeight() - footerHeight,
                    footerWidht,
                    footerHeight
                );

                pdf.setTextColor("#FFF");
                pdf.addFileToVFS("NunitoSans-Regular.ttf", NunitoFont);
                pdf.addFont("NunitoSans-Regular.ttf", "NunitoFont", "normal");
                pdf.setFont("NunitoFont");
                pdf.setFontSize(12);
                if (pdf.getNumberOfPages() === 1) {
                    pdf.text(
                        `${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`,

                        Number(pdf.internal.pageSize.getWidth() / 10),
                        Number(
                            pdf.internal.pageSize.getHeight() -
                                footerHeight / 2 +
                                5
                        )
                    );
                }
                if (pdf.getNumberOfPages() > 1) {
                    pdf.text(
                        str,
                        Number(pdf.internal.pageSize.getWidth() / 10),
                        Number(
                            pdf.internal.pageSize.getHeight() -
                                footerHeight / 2 +
                                5
                        )
                    );
                }

                if (
                    r !==
                    props.referencia.current.children[2].children[
                        props.referencia.current.children[2].children.length - 1
                    ]
                ) {
                    pdf.addPage();
                }
            }
            let totalDePG = pdf.getNumberOfPages();
            for (let i: any = 2; i <= pdf.getNumberOfPages(); i = i + 1) {
                pdf.setPage(i);
                pdf.setFillColor(0, 0, 0);
                pdf.text(
                    String(totalDePG),
                    Number(pdf.internal.pageSize.getWidth() / 10 + 50),
                    Number(
                        pdf.internal.pageSize.getHeight() - footerHeight / 2 + 5
                    )
                );
            }

            pdf.save("Relatorios_Dashboard_Agrochip.pdf");
        }

        setExpPdf(false);
        setStatusSuccess("get", "pdf");

        // const element = props.referencia.current.children[1].current;
        // if (element.children !== undefined) {
        // }
        //

        // const pdf = new jsPDF();
        // let newElementA = [];

        // for (let i: any = 0; i < element.children.lenght; i++) {
        //
        //     let canvas = await html2canvas(element.children[i]);
        //     newElementA.push(canvas.toDataURL("image/png", 1.0));
        // }
        //
        // for (let i: any = 0; i < element.children.lenght; i++) {
        //     if (i === 0) {
        //         pdf.addImage(element.children[i]);
        //     } else {
        //         pdf.addPage();
        //         pdf.addImage(element.children[i]);
        //     }
        // }
        // // pdf.setPage(1)
        // // pdf.addPage();

        // let imgProperties = pdf.getImageProperties(data);
        // // imgProperties["transparency"] = 10000;
        //
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight =
        //     (imgProperties.height * pdfWidth) / imgProperties.width;

        // pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        // pdf.save("print.pdf");
    };

    const dataI = new Date(time.i);
    const dataF = new Date(time.f);
    const printRef: any = useRef();
    return (
        <>
            <div>
                <Button
                    style={{
                        position: "fixed",
                        display:
                            showComponent.index <= 0 ? "none" : "inline-block",
                        top: 60,
                        right: 20,
                        zIndex: 1023,
                        color: "black",
                    }}
                    size="large"
                    type="link"
                    icon={
                        <i
                            style={{
                                fontSize: "25px",
                                color: "#5c5c5c",
                                //textShadow: "1px 0px 5px white",
                            }}
                            className="fas fa-angle-double-up"
                        ></i>
                    }
                    shape="circle"
                    onClick={() => {
                        if (showComponent.index > 0) {
                            props.referencia.current.scrollTo({
                                behavior: "smooth",
                                top:
                                    areass[showComponent.index - 1].position +
                                    60,
                                left: 0,
                            });
                        }
                    }}
                ></Button>
                <Button
                    onClick={() => {
                        props.referencia.current.scrollTo({
                            behavior: "smooth",
                            top: areass[showComponent.index + 1].position + 60,
                            left: 0,
                        });
                    }}
                    style={{
                        position: "fixed",
                        display:
                            areass[showComponent.index + 1] === undefined
                                ? "none"
                                : "inline-block",
                        bottom: 0,
                        right: 20,
                        zIndex: 1023,
                        color: "black",
                    }}
                    size="large"
                    type="link"
                    icon={
                        <i
                            style={{
                                fontSize: "25px",
                                color: "#5c5c5c",
                                //textShadow: "0px 0px 5px #000000",
                            }}
                            className="fas fa-angle-double-down"
                        ></i>
                    }
                    shape="circle"
                ></Button>
            </div>
            <div
                style={{
                    fontSize: "18px",
                    marginTop: "10px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    fontWeight: "bold",
                    borderBottom: "1px solid #C1C1C1",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div>Dashboard</div>
                <div
                    style={{
                        cursor:
                            polyFiltro.length > 0 ? "pointer" : "not-allowed",
                    }}
                    className="util-menu-dashboard"
                    //onClick={() => PDFViewer()}
                    onClick={() => {
                        polyFiltro.length > 0 && handleDownloadPdf();
                    }}
                >
                    <i
                        className="far fa-file-pdf"
                        style={{ marginRight: "10px" }}
                    ></i>
                    Exportar para PDF
                </div>
            </div>
            <EstiloGeralRelatorios ref={printRef}>
                <AnimaDash view={showComponent.index <= 0} expPdf={expPdf}>
                    <div
                        style={{
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-100px",
                            marginBottom: "20px",
                            bottom: "0",
                            width: "200px",
                            display: showComponent.index <= 0 ? "flex" : "none",
                            justifyContent: "center",
                            columnGap: "20px",
                            fontSize: "40px",
                            cursor:
                                showComponent.index <= 0 ? "pointer" : "auto",
                        }}
                        className="iniciar-dash"
                        onClick={() =>
                            showComponent.index <= 0 &&
                            props.referencia.current.scrollTo({
                                behavior: "smooth",
                                top:
                                    areass[showComponent.index + 1].position +
                                    60,
                                left: 0,
                            })
                        }
                    >
                        <div>
                            <i className="fas fa-arrow-down"></i>
                        </div>
                        <div>Iniciar</div>
                    </div>
                    <div
                        style={{
                            height: "25vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "10px 90px",
                            paddingTop: "45vh",
                        }}
                    >
                        <img
                            src={
                                theme
                                    ? process.env.REACT_APP_BASE_URL_ROOT +
                                      "logo-dashboard-dark.png"
                                    : process.env.REACT_APP_BASE_URL_ROOT +
                                      "logo-dashboard.png"
                            }
                            alt=""
                            style={{ width: "calc(100vh + 200px)" }}
                        />{" "}
                    </div>
                </AnimaDash>
                <AnimaDash view={showComponent.index === 1} expPdf={expPdf}>
                    <div
                        style={{
                            borderTop: "1px solid #C1C1C1",
                            marginLeft: "20px",
                            marginRight: "20px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                height:
                                    polyFiltro.length === 0
                                        ? "700px"
                                        : "calc(100% + 1)",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                    background:
                                        "linear-gradient(90deg, rgba(47,53,126,1) 0%, rgba(1,4,37,1) 100%)",
                                    color: "#fff",
                                    marginLeft: "-20px",
                                    marginRight: "-20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "20px",
                                }}
                            >
                                <div style={{ width: "70%" }}>
                                    <i
                                        className="fas fa-paste"
                                        style={{ marginRight: "20px" }}
                                    ></i>
                                    Resumo de operações:
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "calc(100vh - 200px)",
                                    alignItems: "center",
                                }}
                            >
                                {loader.relatorio?.get?.loading ? (
                                    <>Carregando...</>
                                ) : (
                                    <div
                                        style={{
                                            width: "70%",
                                            display: "flex",
                                            columnGap: "70px",
                                            flexWrap: "wrap",
                                            marginTop: "25px",
                                        }}
                                        className={
                                            theme
                                                ? "relatorio-resumo-zebrado-dark"
                                                : "relatorio-resumo-zebrado"
                                        }
                                    >
                                        <EstiloItemTabelaResumo
                                            view={true}
                                            tema={theme}
                                        >
                                            <div>Periodo</div>
                                            <div>
                                                {dataI.toLocaleDateString()} até{" "}
                                                {dataF.toLocaleDateString()}
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Tempo total</div>
                                            <div>
                                                {
                                                    segParaHoras(
                                                        tempoTotal,
                                                        "horas"
                                                    ).h
                                                }
                                                h
                                                {
                                                    segParaHoras(
                                                        tempoTotal,
                                                        "horas"
                                                    ).m
                                                }
                                                min
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            view={true}
                                            tema={theme}
                                        >
                                            <div>Equipamentos em Operação</div>
                                            <div>{polyFiltro.length}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Tempo em Operação</div>
                                            <div>
                                                {
                                                    segParaHoras(
                                                        tempoOpe,
                                                        "horas"
                                                    ).h
                                                }
                                                h
                                                {
                                                    segParaHoras(
                                                        tempoOpe,
                                                        "horas"
                                                    ).m
                                                }
                                                min
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Tipo de Operação</div>
                                            <div>{opeAtual}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Tempo Parado/Ligado</div>
                                            <div>
                                                {
                                                    segParaHoras(
                                                        tempoParado,
                                                        "horas"
                                                    ).h
                                                }
                                                h
                                                {
                                                    segParaHoras(
                                                        tempoParado,
                                                        "horas"
                                                    ).m
                                                }
                                                min
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Cultura</div>
                                            <div>
                                                {Object.keys(cultura).length ===
                                                0
                                                    ? "Todos"
                                                    : cultura.nomeCultura !==
                                                      undefined
                                                    ? cultura.nomeCultura
                                                    : "Não identificado"}
                                            </div>
                                        </EstiloItemTabelaResumo>{" "}
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Tempo em Deslocamento</div>
                                            <div>
                                                {
                                                    segParaHoras(
                                                        tempoDesloc,
                                                        "horas"
                                                    ).h
                                                }
                                                h
                                                {
                                                    segParaHoras(
                                                        tempoDesloc,
                                                        "horas"
                                                    ).m
                                                }
                                                min
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Safra</div>
                                            <div>
                                                {Object.keys(safra).length === 0
                                                    ? "Todos"
                                                    : safra.descricao !==
                                                      undefined
                                                    ? safra.descricao
                                                    : "Não identificado"}
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Consumo total</div>
                                            <div>{consumoTotal}L</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Talhão</div>
                                            <div>{talhaoAtual}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Consumo em Operação</div>
                                            <div>{consumoOpe}L</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Operador</div>
                                            <div>
                                                {Object.keys(operador)
                                                    .length === 0
                                                    ? "Todos"
                                                    : operador.nome !==
                                                      undefined
                                                    ? operador.nome
                                                    : "Não identificado"}
                                            </div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Consumo Parado/Ligado</div>
                                            <div>{consumoParado}L</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Velocidade média</div>
                                            <div>{velocidadeMedia}km/h</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Consumo em Deslocamento</div>
                                            <div>{consumoDesloc}L</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Velocidade máxima</div>
                                            <div>{velocidadeMax}km/h</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Hectares totais</div>
                                            <div>{haTotal}ha</div>{" "}
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>RPM média</div>
                                            <div>{rpmMedia}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Hectares em Operação</div>
                                            <div>{haOpe}ha</div>{" "}
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>RPM máxima</div>
                                            <div>{rpmMax}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Hectares Não Identificado</div>
                                            <div>{haNaoId}ha</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Km Não Identificado</div>
                                            <div>{kmNaoId}km</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Alertas</div>
                                            <div>{alertasTotal}</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        >
                                            <div>Km Deslocamento</div>
                                            <div>{kmDesloc}km</div>
                                        </EstiloItemTabelaResumo>
                                        <EstiloItemTabelaResumo
                                            tema={theme}
                                            view={polyFiltro.length > 0}
                                        ></EstiloItemTabelaResumo>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </AnimaDash>
                {loader.relatorio?.get?.success && polyFiltro.length > 0 && (
                    <>
                        {showGrafico ? (
                            <>
                                {" "}
                                <AnimaDash
                                    view={showComponent.index === 2}
                                    expPdf={expPdf}
                                >
                                    <div
                                        style={{
                                            borderTop: "1px solid #C1C1C1",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "calc(100% + 2)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    marginBottom: "20px",
                                                    background:
                                                        "linear-gradient(90deg, rgba(47,53,126,1) 0%, rgba(1,4,37,1) 100%)",
                                                    color: "#fff",
                                                    marginLeft: "-20px",
                                                    marginRight: "-20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "20px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "70%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fab fa-algolia"
                                                            style={{
                                                                marginRight:
                                                                    "15px",
                                                            }}
                                                        ></i>
                                                        Tempo em situação:
                                                    </div>
                                                    <div>
                                                        <i
                                                            className="fas fa-hard-hat"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {opeAtual}
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fas fa-seedling"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {Object.keys(cultura)
                                                            .length === 0
                                                            ? "Todos"
                                                            : cultura.nomeCultura !==
                                                              undefined
                                                            ? cultura.nomeCultura
                                                            : "Não identificado"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "calc(100vh - 200px)",
                                                    display: "flex",
                                                    columnGap: "70px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ width: "70%" }}>
                                                    {showComponent ? (
                                                        <Bar
                                                            data={data}
                                                            options={{
                                                                color: theme
                                                                    ? "#d9d9d9"
                                                                    : "#000",
                                                                indexAxis: "y",
                                                                scales: {
                                                                    y: {
                                                                        grid: {
                                                                            borderColor:
                                                                                theme
                                                                                    ? "#d9d9d9"
                                                                                    : "#a1a1a1",
                                                                            display:
                                                                                false,
                                                                        },
                                                                        stacked:
                                                                            true,
                                                                        ticks: {
                                                                            font: {
                                                                                weight: "bold",
                                                                            },
                                                                            color: theme
                                                                                ? "#d9d9d9"
                                                                                : "#000",
                                                                        },
                                                                    },
                                                                    x: {
                                                                        grid: {
                                                                            borderColor:
                                                                                theme
                                                                                    ? "#d9d9d9"
                                                                                    : "#a1a1a1",
                                                                            display:
                                                                                false,
                                                                        },
                                                                        stacked:
                                                                            true,
                                                                        ticks: {
                                                                            color: theme
                                                                                ? "#d9d9d9"
                                                                                : "#000",
                                                                            callback:
                                                                                (
                                                                                    v: any
                                                                                ) => {
                                                                                    return (
                                                                                        v +
                                                                                        "%"
                                                                                    );
                                                                                },
                                                                        },
                                                                        max: 100,
                                                                    },
                                                                },
                                                                plugins: {
                                                                    datalabels:
                                                                        {
                                                                            backgroundColor:
                                                                                theme
                                                                                    ? "rgba(255, 255, 255, 0.5)"
                                                                                    : "rgba(0, 0, 0, 0.5)",
                                                                            borderRadius: 4,
                                                                            color: theme
                                                                                ? "#000"
                                                                                : "white",
                                                                            font: {
                                                                                weight: "bold",
                                                                            },
                                                                            align: "center",
                                                                            anchor: "center",
                                                                            display:
                                                                                (
                                                                                    context: any
                                                                                ) => {
                                                                                    let visivel =
                                                                                        context.dataset.data.map(
                                                                                            (
                                                                                                v: any
                                                                                            ) =>
                                                                                                v >
                                                                                                0
                                                                                        );
                                                                                    return visivel.map(
                                                                                        (
                                                                                            v: any
                                                                                        ) =>
                                                                                            v
                                                                                    );
                                                                                },
                                                                            formatter:
                                                                                (
                                                                                    value: any,
                                                                                    context: any
                                                                                ) => {
                                                                                    let teste =
                                                                                        context
                                                                                            .chart
                                                                                            .data
                                                                                            .originalData[
                                                                                            context
                                                                                                .datasetIndex
                                                                                        ];
                                                                                    return (
                                                                                        segParaHoras(
                                                                                            teste[
                                                                                                context
                                                                                                    .dataIndex
                                                                                            ],
                                                                                            "horas"
                                                                                        )
                                                                                            .h +
                                                                                        "h" +
                                                                                        segParaHoras(
                                                                                            teste[
                                                                                                context
                                                                                                    .dataIndex
                                                                                            ],
                                                                                            "horas"
                                                                                        )
                                                                                            .m +
                                                                                        "min"
                                                                                    );
                                                                                },
                                                                        },
                                                                    stacked100:
                                                                        {
                                                                            enable: true,
                                                                        },
                                                                    tooltip: {
                                                                        callbacks:
                                                                            {
                                                                                label: (
                                                                                    l: any
                                                                                ) => {
                                                                                    let tempoEqui =
                                                                                        segParaHoras(
                                                                                            l.raw,
                                                                                            "horas"
                                                                                        );
                                                                                    return (
                                                                                        l.formattedValue +
                                                                                        "%. Tempo: " +
                                                                                        tempoEqui.h +
                                                                                        "h" +
                                                                                        tempoEqui.m
                                                                                    );
                                                                                },
                                                                            },
                                                                        xAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimaDash>
                                <AnimaDash
                                    view={showComponent.index === 3}
                                    expPdf={expPdf}
                                >
                                    <div
                                        style={{
                                            borderTop: "1px solid #C1C1C1",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "calc(100% + 2)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    marginBottom: "20px",
                                                    background:
                                                        "linear-gradient(90deg, rgba(47,53,126,1) 0%, rgba(1,4,37,1) 100%)",
                                                    color: "#fff",
                                                    marginLeft: "-20px",
                                                    marginRight: "-20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "20px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "70%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fab fa-algolia"
                                                            style={{
                                                                marginRight:
                                                                    "15px",
                                                            }}
                                                        ></i>
                                                        Tempo por parada:
                                                    </div>
                                                    <div>
                                                        <i
                                                            className="fas fa-hard-hat"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {opeAtual}
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fas fa-seedling"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {Object.keys(cultura)
                                                            .length === 0
                                                            ? "Todos"
                                                            : cultura.nomeCultura !==
                                                              undefined
                                                            ? cultura.nomeCultura
                                                            : "Não identificado"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "calc(100vh - 200px)",
                                                    display: "flex",
                                                    columnGap: "70px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ width: "70%" }}>
                                                    {showComponent ? (
                                                        <Bar
                                                            data={grafParadas}
                                                            options={{
                                                                color: theme
                                                                    ? "#d9d9d9"
                                                                    : "#000",
                                                                //indexAxis: "y",

                                                                scales: {
                                                                    x: {
                                                                        grid: {
                                                                            borderColor:
                                                                                theme
                                                                                    ? "#d9d9d9"
                                                                                    : "#a1a1a1",
                                                                            display:
                                                                                false,
                                                                        },
                                                                        // stacked:
                                                                        //     true,
                                                                        ticks: {
                                                                            font: {
                                                                                weight: "bold",
                                                                            },
                                                                            color: theme
                                                                                ? "#d9d9d9"
                                                                                : "#000",
                                                                        },
                                                                    },
                                                                    y: {
                                                                        grid: {
                                                                            borderColor:
                                                                                theme
                                                                                    ? "#d9d9d9"
                                                                                    : "#a1a1a1",
                                                                            display:
                                                                                false,
                                                                        },
                                                                        // stacked:
                                                                        //     true,
                                                                        ticks: {
                                                                            color: theme
                                                                                ? "#d9d9d9"
                                                                                : "#000",
                                                                            callback:
                                                                                (
                                                                                    v: any
                                                                                ) => {
                                                                                    return (
                                                                                        v +
                                                                                        "%"
                                                                                    );
                                                                                },
                                                                        },
                                                                        max: 100,
                                                                    },
                                                                },
                                                                plugins: {
                                                                    stacked100:
                                                                        {
                                                                            enable: true,
                                                                        },
                                                                    datalabels:
                                                                        {
                                                                            backgroundColor:
                                                                                theme
                                                                                    ? "rgba(255, 255, 255, 0.5)"
                                                                                    : "rgba(0, 0, 0, 0.5)",
                                                                            borderRadius: 4,
                                                                            color: theme
                                                                                ? "#000"
                                                                                : "white",
                                                                            font: {
                                                                                weight: "bold",
                                                                            },
                                                                            align: "top",
                                                                            anchor: "center",
                                                                            rotation: 90,
                                                                            display:
                                                                                (
                                                                                    context: any
                                                                                ) => {
                                                                                    let visivel =
                                                                                        context.dataset.data.map(
                                                                                            (
                                                                                                v: any
                                                                                            ) =>
                                                                                                v >
                                                                                                0
                                                                                        );
                                                                                    return visivel.map(
                                                                                        (
                                                                                            v: any
                                                                                        ) =>
                                                                                            v
                                                                                    );
                                                                                },
                                                                            formatter:
                                                                                (
                                                                                    value: any,
                                                                                    context: any
                                                                                ) => {
                                                                                    let teste =
                                                                                        context
                                                                                            .chart
                                                                                            .data
                                                                                            .originalData[
                                                                                            context
                                                                                                .datasetIndex
                                                                                        ];
                                                                                    return (
                                                                                        segParaHoras(
                                                                                            teste[
                                                                                                context
                                                                                                    .dataIndex
                                                                                            ],
                                                                                            "horas"
                                                                                        )
                                                                                            .h +
                                                                                        "h" +
                                                                                        segParaHoras(
                                                                                            teste[
                                                                                                context
                                                                                                    .dataIndex
                                                                                            ],
                                                                                            "horas"
                                                                                        )
                                                                                            .m +
                                                                                        "min"
                                                                                    );
                                                                                },
                                                                        },
                                                                    tooltip: {
                                                                        callbacks:
                                                                            {
                                                                                label: (
                                                                                    l: any
                                                                                ) => {
                                                                                    let tempoEqui =
                                                                                        segParaHoras(
                                                                                            l.raw,
                                                                                            "horas"
                                                                                        );
                                                                                    return (
                                                                                        l.formattedValue +
                                                                                        "%. Tempo: " +
                                                                                        tempoEqui.h +
                                                                                        "h" +
                                                                                        tempoEqui.m +
                                                                                        ". " +
                                                                                        l
                                                                                            .dataset
                                                                                            .label
                                                                                    );
                                                                                },
                                                                            },
                                                                        xAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimaDash>
                                <AnimaDash
                                    view={showComponent.index === 4}
                                    expPdf={expPdf}
                                >
                                    <div
                                        style={{
                                            borderTop: "1px solid #C1C1C1",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                height: "calc(100% + 2)",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    marginBottom: "20px",
                                                    background:
                                                        "linear-gradient(90deg, rgba(47,53,126,1) 0%, rgba(1,4,37,1) 100%)",
                                                    color: "#fff",
                                                    marginLeft: "-20px",
                                                    marginRight: "-20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "20px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "70%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <i
                                                            className="fas fa-tachometer-alt"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>{" "}
                                                        Média: RPM x Consumo x
                                                        Velocidade:
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fas fa-hard-hat"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {opeAtual}
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fas fa-seedling"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {Object.keys(cultura)
                                                            .length === 0
                                                            ? "Todos"
                                                            : cultura.nomeCultura !==
                                                              undefined
                                                            ? cultura.nomeCultura
                                                            : "Não identificado"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "calc(100vh - 200px)",
                                                    display: "flex",
                                                    columnGap: "70px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ width: "70%" }}>
                                                    <Line
                                                        data={dataLine}
                                                        options={{
                                                            borderColor: theme
                                                                ? "#d9d9d9"
                                                                : "#000",
                                                            color: theme
                                                                ? "#d9d9d9"
                                                                : "#000",
                                                            scales: {
                                                                x: {
                                                                    grid: {
                                                                        borderColor:
                                                                            theme
                                                                                ? "#d9d9d9"
                                                                                : "#a1a1a1",
                                                                        display:
                                                                            false,
                                                                    },
                                                                    ticks: {
                                                                        font: {
                                                                            weight: "bold",
                                                                        },
                                                                        padding: 25,
                                                                        color: theme
                                                                            ? "#d9d9d9"
                                                                            : "#000",
                                                                    },
                                                                },
                                                                y: {
                                                                    ticks: {
                                                                        color: theme
                                                                            ? "#d9d9d9"
                                                                            : "#000",
                                                                    },
                                                                    grid: {
                                                                        borderColor:
                                                                            theme
                                                                                ? "#d9d9d9"
                                                                                : "#a1a1a1",
                                                                        display:
                                                                            false,
                                                                    },
                                                                },
                                                            },
                                                            plugins: {
                                                                datalabels: {
                                                                    backgroundColor:
                                                                        theme
                                                                            ? "rgba(255, 255, 255, 0.5)"
                                                                            : "rgba(0, 0, 0, 0.5)",
                                                                    borderRadius: 4,
                                                                    color: theme
                                                                        ? "#000"
                                                                        : "white",
                                                                    font: {
                                                                        weight: "bold",
                                                                    },
                                                                    align: "left",
                                                                    anchor: "center",
                                                                    display: (
                                                                        context: any
                                                                    ) => {
                                                                        let visivel =
                                                                            context.dataset.data.map(
                                                                                (
                                                                                    v: any
                                                                                ) =>
                                                                                    v >
                                                                                    0
                                                                            );
                                                                        return visivel.map(
                                                                            (
                                                                                v: any
                                                                            ) =>
                                                                                v
                                                                        );
                                                                    },
                                                                    formatter: (
                                                                        value: any,
                                                                        context: any
                                                                    ) => {
                                                                        if (
                                                                            context
                                                                                .dataset
                                                                                .label ===
                                                                            "Média Rpm x 100"
                                                                        ) {
                                                                            return (
                                                                                value.toFixed(
                                                                                    2
                                                                                ) +
                                                                                " (x100)"
                                                                            );
                                                                        }
                                                                        if (
                                                                            context
                                                                                .dataset
                                                                                .label ===
                                                                            "Média Velocidade"
                                                                        ) {
                                                                            return (
                                                                                value.toFixed(
                                                                                    2
                                                                                ) +
                                                                                " km/h"
                                                                            );
                                                                        }
                                                                        if (
                                                                            context
                                                                                .dataset
                                                                                .label ===
                                                                            "Média Consumo"
                                                                        ) {
                                                                            return (
                                                                                value +
                                                                                " L/h"
                                                                            );
                                                                        }
                                                                        return context;
                                                                    },
                                                                },
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: (
                                                                            l: any
                                                                        ) => {
                                                                            if (
                                                                                l
                                                                                    .dataset
                                                                                    .label ===
                                                                                "Média Rpm x 100"
                                                                            ) {
                                                                                return (
                                                                                    l.formattedValue +
                                                                                    " (x100)"
                                                                                );
                                                                            }
                                                                            if (
                                                                                l
                                                                                    .dataset
                                                                                    .label ===
                                                                                "Média Velocidade"
                                                                            ) {
                                                                                return (
                                                                                    l.formattedValue +
                                                                                    " km/h"
                                                                                );
                                                                            }
                                                                            if (
                                                                                l
                                                                                    .dataset
                                                                                    .label ===
                                                                                "Média Consumo"
                                                                            ) {
                                                                                return (
                                                                                    l.formattedValue +
                                                                                    " L/h"
                                                                                );
                                                                            }
                                                                            return l;
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimaDash>
                                <AnimaDash
                                    view={showComponent.index === 5}
                                    expPdf={expPdf}
                                >
                                    <div
                                        style={{
                                            borderTop: "1px solid #C1C1C1",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                height: "calc(100% + 2)",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    marginBottom: "20px",
                                                    background:
                                                        "linear-gradient(90deg, rgba(47,53,126,1) 0%, rgba(1,4,37,1) 100%)",
                                                    color: "#fff",
                                                    marginLeft: "-20px",
                                                    marginRight: "-20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "20px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "70%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <i
                                                            className="fas fa-gas-pump"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        Consumo de Combustivel
                                                        (L):
                                                    </div>
                                                    <div>
                                                        <i
                                                            className="fas fa-hard-hat"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {opeAtual}
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        <i
                                                            className="fas fa-seedling"
                                                            style={{
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        {Object.keys(cultura)
                                                            .length === 0
                                                            ? "Todos"
                                                            : cultura.nomeCultura !==
                                                              undefined
                                                            ? cultura.nomeCultura
                                                            : "Não identificado"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "calc(100vh - 200px)",
                                                    display: "flex",
                                                    columnGap: "70px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ width: "70%" }}>
                                                    <Bar
                                                        data={dataBar}
                                                        options={{
                                                            borderColor: theme
                                                                ? "#d9d9d9"
                                                                : "#000",
                                                            color: theme
                                                                ? "#d9d9d9"
                                                                : "#000",
                                                            scales: {
                                                                x: {
                                                                    grid: {
                                                                        borderColor:
                                                                            theme
                                                                                ? "#d9d9d9"
                                                                                : "#a1a1a1",
                                                                        display:
                                                                            false,
                                                                    },
                                                                    ticks: {
                                                                        font: {
                                                                            weight: "bold",
                                                                        },

                                                                        color: theme
                                                                            ? "#d9d9d9"
                                                                            : "#000",
                                                                    },
                                                                },
                                                                y: {
                                                                    ticks: {
                                                                        color: theme
                                                                            ? "#d9d9d9"
                                                                            : "#000",
                                                                    },
                                                                    grid: {
                                                                        borderColor:
                                                                            theme
                                                                                ? "#d9d9d9"
                                                                                : "#a1a1a1",
                                                                        display:
                                                                            false,
                                                                    },
                                                                },
                                                            },
                                                            plugins: {
                                                                datalabels: {
                                                                    backgroundColor:
                                                                        theme
                                                                            ? "rgba(255, 255, 255, 0.5)"
                                                                            : "rgba(0, 0, 0, 0.5)",
                                                                    borderRadius: 4,
                                                                    color: theme
                                                                        ? "#000"
                                                                        : "white",
                                                                    display: (
                                                                        context: any
                                                                    ) => {
                                                                        let visivel =
                                                                            context.dataset.data.map(
                                                                                (
                                                                                    v: any
                                                                                ) =>
                                                                                    v >
                                                                                    0
                                                                            );
                                                                        return visivel.map(
                                                                            (
                                                                                v: any
                                                                            ) =>
                                                                                v
                                                                        );
                                                                    },

                                                                    font: {
                                                                        weight: "bold",
                                                                    },
                                                                    align: "top",
                                                                    anchor: "center",
                                                                    rotation: 90,
                                                                    formatter: (
                                                                        value: any,
                                                                        context: any
                                                                    ) => {
                                                                        return (
                                                                            value.toFixed(
                                                                                2
                                                                            ) +
                                                                            " L"
                                                                        );
                                                                    },
                                                                },
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: (
                                                                            l: any
                                                                        ) => {
                                                                            return (
                                                                                l.formattedValue +
                                                                                "L"
                                                                            );
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimaDash>
                            </>
                        ) : (
                            <Spinner view={true}>
                                <i
                                    className="fas fa-spinner"
                                    style={{
                                        fontSize: "50px",
                                        color: "#FFF",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "base-line",
                                        paddingLeft: "0px",
                                    }}
                                ></i>
                            </Spinner>
                        )}
                    </>
                )}
            </EstiloGeralRelatorios>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        polylines: store.polylines,
        equipamentos: store.equipamentos,
        fazenda: store.fazenda,
        time: store.time,
        tipoOp: store.tipoOp,
        polyFiltro: store.polylineFiltro,
        cultura: store.cultura,
        safra: store.safra,
        relatorio: store.relatorio,
        theme: store.theme,
        loader: store.loader,

        operador: store.operador,
        talhao: store.talhao,
        talhoesFiltro: store.talhaoFiltro,

        tipoOpParada: store.tipoOpParada,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setStatusSuccess,
            setStatusStart,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Relatorios);
