import { Input, Form, Button, Col, Row, Switch } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { setStatusNull } from "../../../../store/actions";
import { editarOperador } from "../../../../store/operadorAction";
import { IStatusList } from "../../../../store/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

interface AdicionarOperadoresProps {
    status: IStatusList;
    setModal?: any;
    cont?: any;
    editarOperador?: any;
    setStatusNull: any;
}
function EditarOperador(props: AdicionarOperadoresProps) {
    const { editarOperador, status, setModal, setStatusNull } = props;
    const [validoNome, setValidoNome] = useState(true);
    const [validoSenha, setValidoSenha] = useState(true);

    const [estado, setEstado] = useState(props.cont.ativo);

    const handleDisable = useCallback(() => {
        if (validoNome === true && validoSenha === true) {
            return false;
        }
        return true;
    }, [validoSenha, validoNome]);

    useEffect(() => {
        if (status.operador?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "operador");
        }
    });

    return (
        <>
            <Form
                onChange={() => {}}
                initialValues={props.cont}
                layout="vertical"
                onFinish={(v: any) => {
                    let obj = {
                        id_operador: props.cont.id_operador,
                        nome: v.nome,
                        senha: v.senha,
                        ativo: estado,
                        cod_erp: props.cont.cod_erp,
                    };
                    editarOperador(obj);
                    props.setModal(false);
                }}
            >
                <Form.Item name="id_operador" style={{ display: "none" }}>
                    <Input type="hidden" name="id_operador"></Input>
                </Form.Item>{" "}
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome"
                        >
                            <Input placeholder="Digite o nome do operador" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque uma senha",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject()
                                            );
                                        }
                                        if (isNaN(value)) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject(
                                                    "Use apenas numeros"
                                                )
                                            );
                                        }
                                        if (value.length !== 4) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject(
                                                    "A senha deve conter 4 digitos"
                                                )
                                            );
                                        }

                                        setValidoSenha(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Senha:"
                            name="senha"
                        >
                            <Input.Password
                                maxLength={4}
                                placeholder="Digite a senha do operador"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item name="ativo" label="">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "15px",
                                    alignItems: "baseline",
                                }}
                            >
                                <div>
                                    <Switch
                                        checked={estado}
                                        onChange={(x: any) => setEstado(x)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </div>
                                <div
                                    onClick={() => setEstado(!estado)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Ativo
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={status.operador?.edit?.loading}
                    >
                        Salvar operador
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendaList: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarOperador,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarOperador);
