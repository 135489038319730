import { SET_SERVIDOR, SET_LIST_SERVIDOR } from "../store/actionTypes";

export type IServidor = string;
const Servidor: IServidor = "";

export interface IServidorModule {
    id_local_server: string;
    ultimo_timestamp: string;
    descricao: string;
    fazenda_temp: string;
    ip_local_server: string;
}

export type IServidorList = IServidorModule[];
const ServidorList: IServidorList = [];

export interface IServidorReducer {
    type: any;
    payload: IServidor;
}
export const servidorReducer = (state = Servidor, action: IServidorReducer) => {
    switch (action.type) {
        case SET_SERVIDOR:
            return action.payload;
        default:
            return state;
    }
};

export interface IServidorListReducer {
    type: any;
    payload: IServidorList;
}
export const servidorListReducer = (
    state = ServidorList,
    action: IServidorListReducer
) => {
    switch (action.type) {
        case SET_LIST_SERVIDOR:
            return action.payload;
        default:
            return state;
    }
};
