import {
    //ANTENA
    SET_ANTENA,
    SET_LIST_ANTENA,
    SET_LIST_ANTENA_CONFIG,
} from "./actionTypes";

import { IAntena, IAntenaList } from "../reducers/AntenaReducer";
import { IFazenda } from "../reducers/FazendaReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";

import { message } from "antd";
import { IServidor } from "../reducers/ServidorReducer";

/////////////////////////////////////////////////////

//Métodos para as antenas

export const setAntena = (value: IAntena) => ({
    type: SET_ANTENA,
    payload: value,
});

export const setListAntena = (value: IAntenaList) => ({
    type: SET_LIST_ANTENA,
    payload: value,
});

export const setListAntenaConfig = (value: IAntenaList) => ({
    type: SET_LIST_ANTENA_CONFIG,
    payload: value,
});

export const getAntenas = (servidor: any, config: boolean) => {
    return (dispatch: any) => {
        if (config) {
            dispatch(setStatusStart("get", "antena"));
            //dispatch(setListAntenaConfig([]));
        } else {
            dispatch(setStatusStart("get", "antena"));
            //dispatch(setListAntena([]));
        }

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/antena/` + servidor, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "antena", res.erro));
                } else {
                    if (config) {
                        dispatch(setListAntenaConfig(res));
                        dispatch(setStatusSuccess("get", "antena"));
                    } else {
                        dispatch(setListAntena(res));
                        dispatch(setStatusSuccess("get", "antena"));
                    }
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "antena", error.data));
            });
    };
};

export interface IAddAntena extends ReadableStream {
    nome: string;
    gateway_id: string;
    gateway_profile_id: string;
    service_profile_id: string;
    local_server_temp: string;
}

export const adicionarAntena = (value: IAddAntena) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "antena"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/antena/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "antena", res.erro));
                } else {
                    message.success("Antena adicionada com sucesso.");
                    dispatch(getAntenas(value.local_server_temp, true));
                    dispatch(setStatusSuccess("add", "antena"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "mensagem", error.data));
            });
    };
};

export const deletarAntena = (serv: IServidor, value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "antena"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/antena/` +
                serv +
                "/" +
                value,
            {
                method: "delete",
                headers: {
                    "Content-Type": "text/plain",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "antena", res.erro));
                } else {
                    message.success("A antena selecionada foi excluída.");
                    dispatch(getAntenas(serv, true));
                    dispatch(setStatusSuccess("del", "antena"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "antena", error.data));
            });
    };
};

export interface IEditarAntena extends ReadableStream {
    id_antena: string;
    nome: string;
    gateway_id: string;
    gateway_profile_id: string;
    service_profile_id: string;
    local_server_temp: string;
}

export const editarAntena = (value: IEditarAntena) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "antena"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/antena/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "antena", res.erro));
                } else {
                    message.success("Antena atualizada com sucesso.");
                    dispatch(getAntenas(value.local_server_temp, true));
                    dispatch(setStatusSuccess("edit", "antena"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "antena", error.data));
            });
    };
};
