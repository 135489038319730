import { SET_IMPLEMENTO, SET_LIST_IMPLEMENTO } from "../store/actionTypes";

export interface IImplementoModule {
    id_implemento?: string;
    descricao?: string;
    numero_patrimonio?: string;
    tipo?: string;
    estado?: string;
    largura?: number;
    cod_erp?: string;
}

export type IImplemento = IImplementoModule;
const Implemento: IImplemento = {};

export type IImplementoList = IImplementoModule[];
const ImplementoList: IImplementoList = [];

export interface IAttrImplemento {
    estado: any[];
    tipos: any[];
}

const AttrImplemento: IAttrImplemento = {
    estado: [
        {
            displayName: "Inativo",
            value: "-1",
            optionClass: "inativo",
        },
        {
            displayName: "Ativo",
            value: "1",
            optionClass: "ativo",
        },
        {
            displayName: "Manutenção",
            value: "0",
            optionClass: "manutencao",
        },
    ],
    tipos: [
        { id: 1, nome: "Arado de disco" },
        { id: 2, nome: "Semeadora" },
        { id: 3, nome: "Grade" },
        { id: 4, nome: "Subsolador" },
        { id: 5, nome: "Pulverizador" },
        { id: 6, nome: "Rolo faca" },
        { id: 7, nome: "Enxada rotativa" },
        { id: 8, nome: "Terraceador" },
        { id: 9, nome: "Fertilizador" },
        { id: 10, nome: "Plataforma de Corte" },
    ],
};

export const attrImplemento = (state = AttrImplemento, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};

export interface IImplementoReducer {
    type: any;
    payload: IImplemento;
}

export const implementoReducer = (
    state = Implemento,
    action: IImplementoReducer
) => {
    switch (action.type) {
        case SET_IMPLEMENTO:
            return action.payload;
        default:
            return state;
    }
};

export interface IImplementoListReducer {
    type: any;
    payload: IImplementoList;
}

export const implementoListReducer = (
    state = ImplementoList,
    action: IImplementoListReducer
) => {
    switch (action.type) {
        case SET_LIST_IMPLEMENTO:
            return action.payload;
        default:
            return state;
    }
};
