import {
    //SERVIDOR
    SET_SERVIDOR,
    SET_LIST_SERVIDOR,
} from "./actionTypes";

import { IServidor, IServidorList } from "../reducers/ServidorReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";

import { message } from "antd";

/////////////////////////////////////////////////////

//Métodos para os servidores

export const setServidor = (value: IServidor) => ({
    type: SET_SERVIDOR,
    payload: value,
});

export const setListServidor = (value: IServidorList) => ({
    type: SET_LIST_SERVIDOR,
    payload: value,
});

export const getServidor = (fazenda: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "servidor"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/localserver/` + fazenda,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "servidor", res.erro));
                } else {
                    dispatch(setListServidor(res));
                    dispatch(setStatusSuccess("get", "servidor"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "servidor", error.data));
            });
    };
};

export interface IAddServidor extends ReadableStream {
    ultimo_timestamp: string;
    descricao: string;
    fazenda_temp: string;
}

export const adicionarServidor = (value: IAddServidor) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "servidor"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/localserver/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "servidor", res.erro));
                } else {
                    message.success("Servidor adicionado com sucesso.");
                    dispatch(getServidor(value.fazenda_temp));
                    dispatch(setStatusSuccess("add", "servidor"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "servidor", error.data));
            });
    };
};

export const deletarServidor = (faz: any, value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "servidor"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/localserver/` +
                faz +
                "/" +
                value,
            {
                method: "delete",
                headers: {
                    "Content-Type": "text/plain",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "servidor", res.erro));
                } else {
                    message.success("O servidor selecionado foi excluído.");
                    dispatch(getServidor(faz));
                    dispatch(setStatusSuccess("del", "servidor"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "servidor", error.data));
            });
    };
};

export interface IEditarServidor extends ReadableStream {
    id_local_server: string;
    ultimo_timestamp: string;
    descricao: string;
    fazenda_temp: string;
}

export const editarServidor = (value: IEditarServidor) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "servidor"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/localserver/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "servidor", res.erro));
                } else {
                    message.success("Servidor atualizado com sucesso.");
                    dispatch(getServidor(value.fazenda_temp));
                    dispatch(setStatusSuccess("edit", "servidor"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "servidor", error.data));
            });
    };
};
