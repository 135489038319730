import {
    SET_ANTENA,
    SET_LIST_ANTENA,
    SET_LIST_ANTENA_CONFIG,
} from "../store/actionTypes";

export type IAntena = string;
const Antena: IAntena = "";

export interface IAntenaModule {
    id_antena: string;
    nome: string;
    gateway_id: string;
    gateway_profile_id: string;
    service_profile_id: string;
    coordenadas: string;
    id_local_server: string;
    ultimo_timestamp: string;
}

export type IAntenaList = IAntenaModule[];
const AntenaList: IAntenaList = [];

const AntenaListConfig: IAntenaList = [];

export interface IAntenaReducer {
    type: any;
    payload: IAntena;
}
export const antenaReducer = (state = Antena, action: IAntenaReducer) => {
    switch (action.type) {
        case SET_ANTENA:
            return action.payload;
        default:
            return state;
    }
};

export interface IAntenaListReducer {
    type: any;
    payload: IAntenaList;
}
export const antenaListReducer = (
    state = AntenaList,
    action: IAntenaListReducer
) => {
    switch (action.type) {
        case SET_LIST_ANTENA:
            return action.payload;
        default:
            return state;
    }
};

export const antenaListConfigReducer = (
    state = AntenaListConfig,
    action: IAntenaListReducer
) => {
    switch (action.type) {
        case SET_LIST_ANTENA_CONFIG:
            return action.payload;
        default:
            return state;
    }
};
