import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IStore } from "../../store";
import { IEquipamento } from "../../reducers/EquipamentoReducer";
import { useCallback, useEffect, useState } from "react";
import { Popconfirm, Skeleton, Switch, Tooltip } from "antd";
import { setFocus, setRefresh } from "../../store/actions";
import { getOneEquipamento } from "../../store/equipamentoActions";
import { IPolylineList } from "../../reducers/PolylineReducer";
import { InfoCircleFilled } from "@ant-design/icons";
import { ContentOverflow, Spinner } from "../../styles/Main";
import {
    mediaConsumo,
    getAlertas,
    segParaHoras,
    formataTexto,
} from "../../config/helpers";
import { v4 as uuidv4 } from "uuid";

import styled from "styled-components";
import MultiToggle from "react-multi-toggle";
import { IRelatorio } from "../../reducers/RelatorioReducer";
import ReactSpeedometer from "react-d3-speedometer";
import { ITime } from "../../reducers/TimeReducer";
import { ISafraList } from "../../reducers/SafraReducer";
import { ICulturaList } from "../../reducers/CulturaReducer";
import { ITipoOpList } from "../../reducers/TipoOpReducer";
import ModalEquipa from "./Equipamento/ModalEquipa";
import { IProcessList } from "../../reducers/ProcessReducer";
import { IFazenda, IFazendaList } from "../../reducers/FazendaReducer";
import { geoContains } from "d3-geo";
import { ativarProcess } from "../../store/actions";
import { IMenu } from "../../reducers/MenuReducer";
import { setTalhao } from "../../store/fazendaActions";
import { ITheme } from "../../reducers/ThemeReducer";
import { IOperadorList } from "../../reducers/OperadorReducer";
import { IAlertaRender } from "../../reducers/AlertaReducer";
import { IStatusList, ITalhaoTablet } from "../../store/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { IImplemento, IImplementoList } from "../../reducers/ImplementoReducer";
import ModalAlertasEquipa from "./Equipamento/ModalAlertasEquipa";

const EstiloItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #cccccc;

    & div:first-child {
        font-weight: bold;
    }
`;

const NomeEquipa = styled.div`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: -10px;
    margin-right: 20px;

    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: break-word;
    display: -webkit-box; /* necessário para line-clamp funcionar */
    -webkit-line-clamp: 2; /* ajusta o valor para o número de linhas */
    -webkit-box-orient: vertical;
`;

interface IEquipamentoProps {
    equipamento: IEquipamento;
    polylines: IPolylineList;
    relatorios: IRelatorio;
    time: ITime;
    safras: ISafraList;
    culturas: ICulturaList;
    tiposOp: ITipoOpList;
    procs: IProcessList;
    fazenda: IFazenda;
    menu: IMenu;
    operadores: IOperadorList;
    alertasRender: IAlertaRender;

    setFocus: Function;
    setRefresh: Function;
    getOneEquipamento: Function;
    ativarProcess: Function;
    setTalhao: Function;
    tema: ITheme;

    loader: IStatusList;

    talhoesFiltro: ITalhaoTablet[];
    fazendas: IFazendaList;
    implementos: IImplementoList;
}
let timeout: any = null;
function Equipamento(props: IEquipamentoProps) {
    const {
        alertasRender,
        equipamento,
        polylines,
        relatorios,
        safras,
        culturas,
        tiposOp,
        setFocus,
        setRefresh,
        getOneEquipamento,
        time,
        procs,
        fazenda,
        ativarProcess,
        menu,
        setTalhao,
        tema,
        operadores,
        loader,

        talhoesFiltro,
        fazendas,
        implementos,
    } = props;

    const [refresher, setRefresher] = useState("");
    const [visual, setVisual] = useState("ultimo");
    const [alertaList, setAlertaList] = useState(Object);
    const [alterouData, setAlterou] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [diasList, setDiasList] = useState([]);

    const [diaSelec, setDiaSelec] = useState("");
    const [realTime, setRealTime] = useState(false);

    const [corSinal, setCorSinal] = useState("");

    const [habilitaToggle, setHabilitaToggle] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [openAlertaModal, setOpenAlertaModal] = useState(false);

    const [opesList, setOpesList] = useState([]);

    const dados = useCallback(() => {
        let retorno: any = {
            rel: [],
            ope: [],
        };
        if (relatorios.rel !== undefined) {
            retorno.rel = relatorios.rel[0];
        }
        if (relatorios.ope !== undefined) {
            retorno.ope = relatorios.ope[0];
        }

        return retorno;
    }, [relatorios]);

    const handleFrameMenor = useCallback(() => {
        let teste: any;
        if (dados().ope !== undefined) {
            if (
                dados().ope.operacoes !== undefined &&
                Object.keys(dados().ope.operacoes).length > 0
            ) {
                dados().ope.operacoes.forEach((o: any) => {
                    if (
                        o.pressaoOleo !== undefined ||
                        o.temAgua !== undefined ||
                        o.cargaMotor !== undefined ||
                        o.torqueMotor !== undefined
                    ) {
                        teste = false;
                    } else {
                        teste = true;
                    }
                });
            }
        }
        return teste;
    }, [dados]);

    function formatTime(data: Date) {
        return (
            data.toLocaleDateString("pt-BR", {
                weekday: "long",
                day: "2-digit",
                month: "long",
                year: "numeric",
            }) +
            " - " +
            data.toLocaleTimeString("pt-BR")
        );
    }

    function handleVisual(value: any) {
        if (habilitaToggle && value === "historico") {
            setVisual(value);
            setRealTime(false);
            setFocus({
                view: 5,
                subView: null,
            });
        }

        if (value === "ultimo") {
            setVisual(value);
            setFocus({
                view: 3,
                subView: null,
            });
            setShowAlert(false);
        }
    }

    useEffect(() => {
        if (
            equipamento.last_seen &&
            equipamento.last_seen_type &&
            equipamento.ultima_operacao
        ) {
            let atual = new Date().getTime();
            let ult = new Date(equipamento.last_seen).getTime();

            if (equipamento.ultima_operacao.motorON) {
                if (atual - ult > 360000) {
                    setCorSinal("rgba(255, 255, 255, 0.2)");
                } else {
                    setCorSinal("green");
                }
            } else setCorSinal("rgba(255, 255, 255, 0.2)");
        } else {
            setCorSinal("rgba(255, 255, 255, 0.2)");
        }
    }, [equipamento]);

    useEffect(() => {
        if (loader.polyline?.get?.success === true) {
            if (visual === "historico" && polylines.length > 0) {
                setAlterou(true);
                setShowAlert(false);
            } else {
                setVisual("ultimo");
                setAlterou(false);
                setFocus({
                    view: 3,
                    subView: null,
                });
                setShowAlert(false);
            }
        }
    }, [loader.polyline?.get?.success, polylines.length]);

    useEffect(() => {
        // if (equipamento.id_equipamento !== "") {
        setVisual("ultimo");
        setFocus({
            view: 3,
            subView: null,
        });
        setAlterou(false);
        setHabilitaToggle(false);
        setRealTime(false);
        setShowAlert(false);
        // }
    }, [equipamento.id_equipamento, setFocus]);

    useEffect(() => {
        if (showAlert) {
            setFocus({
                view: 5,
                subView: 1,
            });
        }
        // else {
        //     setFocus({
        //         view: 5,
        //         subView: null,
        //     });
        // }
    }, [showAlert, setFocus]);

    useEffect(() => {
        setAlertaList(
            alertasRender
                .sort((i: any, f: any) => {
                    let a: any = new Date(i.timestamp);
                    let b: any = new Date(f.timestamp);

                    return b.getTime() - a.getTime();
                })
                .filter((a: any, b: any) => {
                    return b < 5;
                })
        );
    }, [alertasRender]);

    useEffect(() => {
        if (realTime) {
            getOneEquipamento(equipamento.id_equipamento);
        }
    }, [realTime, refresher]);

    useEffect(() => {
        let dias: any = [];

        if (dados().ope !== undefined) {
            if (
                dados().ope.operacoes !== undefined &&
                Object.keys(dados().ope.operacoes).length > 0
            ) {
                dados().ope.operacoes.forEach((d: any) => {
                    if (
                        dias.findIndex(
                            (v: any) => v === d.timestamp.split("T")[0]
                        ) === -1
                    ) {
                        dias.push(d.timestamp.split("T")[0]);
                    }
                });
            }
        }

        setDiasList(dias);
        setDiaSelec(dias[dias.length - 1]);
    }, [dados]);

    useEffect(() => {
        let tipoOps: any = [];

        if (dados().ope !== undefined) {
            if (
                dados().ope.operacoes !== undefined &&
                Object.keys(dados().ope.operacoes).length > 0
            ) {
                dados().ope.operacoes.forEach((d: any) => {
                    if (d.tipo_operacao_temp) {
                        if (
                            tipoOps.findIndex(
                                (v: any) => v === d.tipo_operacao_temp
                            ) === -1
                        ) {
                            tipoOps.push(d.tipo_operacao_temp);
                        }
                    }
                });
            }
        }
        setOpesList(tipoOps);
    }, [dados]);

    useEffect(() => {
        return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
        if (realTime === true) {
            timeout = setInterval(() => {
                setRefresher(uuidv4());
            }, 5000);
        }
    }, [realTime]);

    useEffect(() => {
        if (realTime !== true) {
            clearInterval(timeout);
        }
    }, [realTime]);

    useEffect(() => {
        if (realTime === true) {
            setHabilitaToggle(polylines.length > 0);
        } else {
            if (loader.polyline?.get?.success === true) {
                setHabilitaToggle(polylines.length > 0);
            } else {
                setHabilitaToggle(false);
            }
        }
    }, [polylines, realTime, loader.polyline?.get]);

    const haTalhao = useCallback(() => {
        if (talhoesFiltro.length > 0) {
            if (equipamento.ultima_operacao.talhao_temp) {
                return talhoesFiltro.findIndex(
                    (t: any) =>
                        t.id_talhao === equipamento.ultima_operacao.talhao_temp
                );
            } else {
                let listaTalhao = talhoesFiltro.filter((g: any) => {
                    if (g.coordenadas !== null) {
                        return {
                            ...g,
                            coordenadas: {
                                ...JSON.parse(g.coordenadas),
                                type: "Polygon",
                            },
                        };
                    }
                });

                return listaTalhao.findIndex((t: any) => {
                    if (
                        geoContains(t.coordenadas, [
                            Number(equipamento.ultima_operacao.longitude),
                            Number(equipamento.ultima_operacao.latitude),
                        ])
                    )
                        return t;
                });
            }
        } else {
            return -1;
        }
    }, [equipamento, talhoesFiltro]);

    const talhaoAtual = useCallback(() => {
        if (fazenda.id_fazenda !== "") {
            let geoFaz = JSON.parse(fazenda.shape);
            let talhao = geoFaz.features.filter((g: any) => {
                return (
                    geoContains(g, [
                        Number(equipamento.ultima_operacao.longitude),
                        Number(equipamento.ultima_operacao.latitude),
                    ]) === true
                );
            });
            if (talhao.length > 0) {
                return talhao[0].properties;
            } else {
                return false;
            }
        }
    }, [fazenda, equipamento]);

    if (equipamento.id_equipamento === "") {
        return (
            <ContentOverflow>
                <div className="not-found">
                    <InfoCircleFilled
                        style={{
                            fontSize: "50px",
                            color: "#0C46E8",
                            paddingBottom: "20px",
                        }}
                    />
                    Nenhum equipamento foi selecionado.
                </div>
            </ContentOverflow>
        );
    } else {
        if (
            equipamento.ultima_operacao !== undefined &&
            equipamento.estado !== "-1"
        ) {
            return (
                <div
                    style={{
                        flex: 1,
                        color: "#FFF",
                    }}
                >
                    <div
                        style={{
                            borderBottom: "1px solid #FFF",
                            paddingBottom: "10px",
                        }}
                    >
                        <Tooltip
                            placement="bottom"
                            title={
                                <>
                                    {equipamento.descricao} -{" "}
                                    {equipamento.numero_patrimonio}
                                </>
                            }
                        >
                            <NomeEquipa>{equipamento.descricao}</NomeEquipa>
                            <div
                                style={{
                                    marginRight: "20px",
                                    marginTop: "5px",
                                }}
                            >
                                {equipamento.numero_patrimonio}
                            </div>{" "}
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            paddingRight: "15px",
                            paddingTop: "10px",
                            paddingBottom: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                width: "300px",
                            }}
                        >
                            {" "}
                            <Popconfirm
                                placement="bottom"
                                title={
                                    <div>
                                        Podem ter atualizações,
                                        <br />
                                        devido a alterações nos filtros. Quer
                                        atualizar?
                                    </div>
                                }
                                okText="Sim"
                                cancelButtonProps={{
                                    type: "text",
                                    style: { display: "none" },
                                }}
                                onConfirm={() => {
                                    setRefresh(uuidv4());
                                    setFocus({ view: 5, subView: null });
                                    setAlterou(false);
                                }}
                                visible={alterouData}
                            >
                                <MultiToggle
                                    className={
                                        habilitaToggle
                                            ? "equipa-toggle"
                                            : "equipa-toggle-disabled"
                                    }
                                    options={[
                                        {
                                            displayName: "Ultimo",
                                            value: "ultimo",
                                            optionClass: "ultimo",
                                        },
                                        {
                                            displayName: "Historico",
                                            value: "historico",
                                            optionClass: "historico",
                                        },
                                    ]}
                                    selectedOption={visual}
                                    onSelectOption={handleVisual}
                                />
                            </Popconfirm>
                        </div>{" "}
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                fontSize: "15px",
                                marginLeft: "-65px",
                                marginTop: "5px",
                            }}
                        >
                            <Spinner
                                view={
                                    realTime !== true &&
                                    loader.polyline?.get?.loading
                                }
                            >
                                <i className="fas fa-spinner"></i>
                            </Spinner>
                        </div>
                        <div
                            style={{
                                paddingTop: "10px",
                                marginRight: "10px",
                            }}
                        >
                            {visual === "ultimo" ? (
                                <Tooltip
                                    title="Visualização em tempo real"
                                    placement="bottomLeft"
                                >
                                    {realTime ? (
                                        <Spinner view={true}>
                                            <i
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "base-line",
                                                    paddingLeft: "0px",
                                                    fontSize: "20px",
                                                    cursor: "pointer",

                                                    marginBottom: "5px",
                                                }}
                                                className="fas fa-sync"
                                                onClick={() => {
                                                    setRefresher(uuidv4());

                                                    setRealTime(!realTime);
                                                }}
                                            ></i>
                                        </Spinner>
                                    ) : (
                                        <i
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "base-line",
                                                paddingLeft: "0px",
                                                fontSize: "20px",
                                                cursor: "pointer",

                                                marginBottom: "5px",
                                            }}
                                            className="fas fa-sync"
                                            onClick={() => {
                                                setRefresher(uuidv4());

                                                setRealTime(!realTime);
                                            }}
                                        ></i>
                                    )}
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title="Visualizar dashboard"
                                    placement="bottomLeft"
                                >
                                    <i
                                        style={{
                                            display: "flex",

                                            fontSize: "25px",
                                            cursor:
                                                loader.relatorioOne?.get
                                                    ?.success === true &&
                                                Object.keys(dados().rel)
                                                    .length > 0
                                                    ? "pointer"
                                                    : "not-allowed",
                                        }}
                                        className="fas fa-chart-line"
                                        onClick={() => {
                                            loader.relatorioOne?.get
                                                ?.success === true &&
                                                Object.keys(dados().rel)
                                                    .length > 0 &&
                                                setOpenModal(true);
                                        }}
                                    ></i>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div
                        className="scroll-sidebar"
                        style={{
                            overflow: "auto",
                            height: "calc(100vh - 325px)",
                        }}
                    >
                        {visual === "ultimo" ? (
                            <>
                                <div
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        display: "flex",

                                        marginTop: "10px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    <i
                                        className="fas fa-history"
                                        style={{
                                            marginRight: "5px",
                                            marginTop: "3px",
                                        }}
                                    ></i>
                                    Último Registro:
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "50%",
                                                backgroundColor: equipamento
                                                    .ultima_operacao?.motorON
                                                    ? "green"
                                                    : "red",
                                                height: "15px",
                                                width: "15px",
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {equipamento.ultima_operacao
                                                ?.motorON
                                                ? "Ligado"
                                                : "Desligado"}
                                        </div>
                                    </div>{" "}
                                    <div
                                        style={{
                                            display: "flex",

                                            flexDirection: "row",
                                        }}
                                    >
                                        <div style={{ marginRight: "15px" }}>
                                            <i
                                                className="fas fa-signal"
                                                style={{
                                                    color: corSinal,
                                                    fontSize: "18px",
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        textAlign: "left",
                                        borderBottom: "1px solid #FFF",
                                        paddingBottom: "15px",
                                    }}
                                >
                                    <i
                                        className="far fa-clock"
                                        style={{ marginRight: "5px" }}
                                    ></i>
                                    {formatTime(
                                        new Date(
                                            equipamento.ultima_operacao.timestamp
                                        )
                                    )}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        borderBottom: "1px solid #FFF",
                                        paddingBottom: "15px",
                                        alignItems: "baseline",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <i
                                            className="fas fa-warehouse"
                                            style={{
                                                marginRight: "5px",
                                                marginTop: "2px",
                                            }}
                                        ></i>
                                        Fazenda:
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            fontSize: "16px",
                                            marginRight: "15px",
                                        }}
                                    >
                                        {equipamento.ultima_operacao
                                            .fazenda_temp ? (
                                            <>
                                                {" "}
                                                {fazendas.findIndex(
                                                    (f: IFazenda) =>
                                                        f.id_fazenda ===
                                                        equipamento
                                                            .ultima_operacao
                                                            .fazenda_temp
                                                ) < 0 ? (
                                                    <div>
                                                        Fazenda não identificada
                                                    </div>
                                                ) : (
                                                    <>
                                                        {
                                                            fazendas[
                                                                fazendas.findIndex(
                                                                    (
                                                                        f: IFazenda
                                                                    ) =>
                                                                        f.id_fazenda ===
                                                                        equipamento
                                                                            .ultima_operacao
                                                                            .fazenda_temp
                                                                )
                                                            ].nome
                                                        }
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>Não Identificado</>
                                        )}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        borderBottom: "1px solid #FFF",
                                        paddingBottom: "15px",
                                        alignItems: "baseline",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <i
                                            className="fas fa-user-cog"
                                            style={{
                                                marginRight: "5px",
                                                marginTop: "2px",
                                            }}
                                        ></i>
                                        Operador:
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            fontSize: "16px",
                                            marginRight: "15px",
                                        }}
                                    >
                                        {equipamento.ultima_operacao
                                            .operador_temp ? (
                                            <>
                                                {" "}
                                                {operadores.findIndex(
                                                    (o: any) =>
                                                        o.id_operador ===
                                                        equipamento
                                                            .ultima_operacao
                                                            .operador_temp
                                                ) < 0 ? (
                                                    <div>
                                                        Operador não encontrado
                                                    </div>
                                                ) : (
                                                    <>
                                                        {
                                                            operadores[
                                                                operadores.findIndex(
                                                                    (o: any) =>
                                                                        o.id_operador ===
                                                                        equipamento
                                                                            .ultima_operacao
                                                                            .operador_temp
                                                                )
                                                            ].nome
                                                        }
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>Não Identificado</>
                                        )}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                        marginRight: "15px",
                                    }}
                                >
                                    <div>
                                        <ReactSpeedometer
                                            minValue={0}
                                            maxValue={60}
                                            ringWidth={50}
                                            value={
                                                equipamento.ultima_operacao
                                                    ?.velocidade
                                            }
                                            needleColor="white"
                                            customSegmentStops={[
                                                0, 10, 20, 30, 40, 50, 60,
                                            ]}
                                            needleTransitionDuration={100}
                                            width={170}
                                            height={120}
                                            textColor="white"
                                            labelFontSize="10px"
                                            valueTextFontSize="14px"
                                            startColor="#00A300"
                                            endColor="red"
                                            currentValueText="${value}Km/h"
                                        />
                                    </div>

                                    <div>
                                        <ReactSpeedometer
                                            minValue={0}
                                            maxValue={10}
                                            ringWidth={50}
                                            value={
                                                equipamento.ultima_operacao
                                                    ?.rpm !== undefined &&
                                                equipamento.ultima_operacao
                                                    ?.rpm > 0
                                                    ? equipamento
                                                          .ultima_operacao
                                                          ?.rpm / 1000
                                                    : 0
                                            }
                                            needleColor="white"
                                            customSegmentStops={[
                                                0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                                                10,
                                            ]}
                                            needleTransitionDuration={600}
                                            width={170}
                                            height={120}
                                            textColor="white"
                                            labelFontSize="10px"
                                            valueTextFontSize="14px"
                                            startColor="#FFFF00"
                                            endColor="red"
                                            currentValueText="RPM x1000"
                                        />
                                        <div style={{ marginTop: "-15px" }}>
                                            {equipamento.ultima_operacao?.rpm}{" "}
                                            RPM
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                        marginRight: "15px",
                                    }}
                                >
                                    <div>
                                        <ReactSpeedometer
                                            minValue={0}
                                            maxValue={120}
                                            ringWidth={10}
                                            segments={6}
                                            forceRender={true}
                                            value={
                                                equipamento.ultima_operacao
                                                    ?.temAgua
                                            }
                                            needleColor="white"
                                            needleTransitionDuration={100}
                                            width={170}
                                            height={120}
                                            textColor="white"
                                            labelFontSize="10px"
                                            valueTextFontSize="14px"
                                            endColor="red"
                                            startColor="transparent"
                                            currentValueText="${value} °C"
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <ReactSpeedometer
                                                minValue={0}
                                                maxValue={100}
                                                ringWidth={10}
                                                segments={10}
                                                forceRender={true}
                                                value={
                                                    equipamento.ultima_operacao
                                                        ?.pressaoOleo
                                                        ? parseFloat(
                                                              equipamento.ultima_operacao?.pressaoOleo.toFixed(
                                                                  2
                                                              )
                                                          )
                                                        : 0
                                                }
                                                needleColor="white"
                                                needleTransitionDuration={100}
                                                width={170}
                                                height={120}
                                                textColor="white"
                                                labelFontSize="10px"
                                                valueTextFontSize="14px"
                                                currentValueText="${value} psi"
                                                endColor="grey"
                                                startColor="transparent"
                                            />
                                        </div>
                                        {/* <div>
                                            {" "}
                                            <i
                                                style={{
                                                    fontSize: "90px",
                                                    cursor: "pointer",
                                                }}
                                                className="fas fa-layer-group"
                                                onClick={() => {
                                                    if (talhaoAtual()) {
                                                        setFocus({
                                                            view: 2,
                                                            subview: null,
                                                        });
                                                        ativarProcess({
                                                            menuItem:
                                                                menu[
                                                                    menu.findIndex(
                                                                        (
                                                                            m: any
                                                                        ) =>
                                                                            m.id ===
                                                                            1
                                                                    )
                                                                ],
                                                            processList: procs,
                                                        });

                                                        setTalhao(
                                                            talhaoAtual()[
                                                                "id_agrochip"
                                                            ]
                                                        );
                                                    }
                                                }}
                                            ></i>
                                        </div> 
                                        <div
                                            style={{
                                                fontWeight: "bold",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {talhaoAtual()
                                                ? talhaoAtual()[
                                                      JSON.parse(fazenda.campo)
                                                          .nome
                                                  ]
                                                : "Nenhum"}
                                        </div> */}
                                    </div>
                                </div>

                                {/* <div
                                    style={{
                                        marginTop: "15px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        paddingRight: "20px",
                                        columnGap: 15,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "50%",
                                                backgroundColor: equipamento
                                                    .ultima_operacao?.motorON
                                                    ? "green"
                                                    : "red",
                                                height: "15px",
                                                width: "15px",
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                marginTop: "-3px",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {equipamento.ultima_operacao
                                                ?.motorON
                                                ? "Ligado"
                                                : "Desligado"}
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <i
                                            className="fas fa-gas-pump"
                                            style={{ fontSize: "15px" }}
                                        ></i>
                                        <div
                                            style={{
                                                marginTop: "-3px",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {
                                                equipamento.ultima_operacao
                                                    ?.nivelComb
                                            }
                                            %
                                        </div>
                                    </div>
                                </div> */}
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        columnGap: "15px",
                                        marginTop: "15px",
                                        fontSize: "16px",
                                        borderTop: "1px solid #FFF",
                                        paddingTop: "15px",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "95%",
                                        }}
                                    >
                                        {equipamento.ultima_operacao !==
                                            undefined && (
                                            <>
                                                <EstiloItem>
                                                    <div>Talhão:</div>
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                haTalhao() > -1
                                                            ) {
                                                                setFocus({
                                                                    view: 2,
                                                                    subview:
                                                                        null,
                                                                });
                                                                ativarProcess({
                                                                    menuItem:
                                                                        menu[
                                                                            menu.findIndex(
                                                                                (
                                                                                    m: any
                                                                                ) =>
                                                                                    m.id ===
                                                                                    1
                                                                            )
                                                                        ],
                                                                    processList:
                                                                        procs,
                                                                });

                                                                setTalhao(
                                                                    talhoesFiltro[
                                                                        haTalhao()
                                                                    ].id_talhao
                                                                );
                                                            } else if (
                                                                talhaoAtual()
                                                            ) {
                                                                setFocus({
                                                                    view: 2,
                                                                    subview:
                                                                        null,
                                                                });
                                                                ativarProcess({
                                                                    menuItem:
                                                                        menu[
                                                                            menu.findIndex(
                                                                                (
                                                                                    m: any
                                                                                ) =>
                                                                                    m.id ===
                                                                                    1
                                                                            )
                                                                        ],
                                                                    processList:
                                                                        procs,
                                                                });

                                                                setTalhao(
                                                                    talhaoAtual()[
                                                                        "id_agrochip"
                                                                    ]
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {haTalhao() > -1
                                                            ? talhoesFiltro[
                                                                  haTalhao()
                                                              ].nome_talhao
                                                            : talhaoAtual()
                                                            ? talhaoAtual()[
                                                                  JSON.parse(
                                                                      fazenda.campo
                                                                  ).nome
                                                              ]
                                                            : "Não identificado"}
                                                    </div>
                                                </EstiloItem>
                                                <EstiloItem>
                                                    <div>Tipo de Operação:</div>
                                                    <div>
                                                        {equipamento
                                                            .ultima_operacao
                                                            .tipo_operacao_temp
                                                            ? tiposOp[
                                                                  tiposOp.findIndex(
                                                                      (
                                                                          o: any
                                                                      ) =>
                                                                          o.id_tipo_operacao ===
                                                                          equipamento
                                                                              .ultima_operacao
                                                                              .tipo_operacao_temp
                                                                  )
                                                              ].descricao
                                                            : "Não Identificado"}
                                                    </div>
                                                </EstiloItem>
                                                {equipamento.ultima_operacao
                                                    .implemento_temp && (
                                                    <EstiloItem>
                                                        <div>Implemento:</div>
                                                        <div>
                                                            {
                                                                implementos[
                                                                    implementos.findIndex(
                                                                        (
                                                                            i: IImplemento
                                                                        ) =>
                                                                            i.id_implemento ===
                                                                            equipamento
                                                                                .ultima_operacao
                                                                                .implemento_temp
                                                                    )
                                                                ].descricao
                                                            }
                                                        </div>
                                                    </EstiloItem>
                                                )}
                                                {equipamento.ultima_operacao
                                                    .tipo_operacao_parada_temp !==
                                                    undefined && (
                                                    <EstiloItem>
                                                        <div>
                                                            Motivo de Parada:
                                                        </div>
                                                        <div>
                                                            {equipamento
                                                                .ultima_operacao
                                                                .tipo_operacao_parada_temp !==
                                                            0
                                                                ? tiposOp[
                                                                      tiposOp.findIndex(
                                                                          (
                                                                              o: any
                                                                          ) =>
                                                                              o.id_tipo_operacao ===
                                                                              equipamento
                                                                                  .ultima_operacao
                                                                                  .tipo_operacao_parada_temp
                                                                      )
                                                                  ].descricao
                                                                : "Não Identificado"}
                                                        </div>
                                                    </EstiloItem>
                                                )}
                                                {equipamento.ultima_operacao
                                                    .alerta && (
                                                    <EstiloItem>
                                                        <div> Alerta: </div>
                                                        <div>
                                                            {formataTexto(
                                                                equipamento
                                                                    .ultima_operacao
                                                                    .txtalerta
                                                            )}
                                                        </div>
                                                    </EstiloItem>
                                                )}

                                                <EstiloItem>
                                                    <div>Cultura:</div>
                                                    <div>
                                                        {equipamento
                                                            .ultima_operacao
                                                            .cultura_temp
                                                            ? culturas[
                                                                  culturas.findIndex(
                                                                      (
                                                                          o: any
                                                                      ) =>
                                                                          o.id_cultura ===
                                                                          equipamento
                                                                              .ultima_operacao
                                                                              .cultura_temp
                                                                  )
                                                              ].nomeCultura
                                                            : "Não Identificado"}
                                                    </div>
                                                </EstiloItem>
                                                <EstiloItem>
                                                    <div>Safra:</div>
                                                    <div>
                                                        {equipamento
                                                            .ultima_operacao
                                                            .safra_temp
                                                            ? safras[
                                                                  safras.findIndex(
                                                                      (
                                                                          o: any
                                                                      ) =>
                                                                          o.id_safra ===
                                                                          equipamento
                                                                              .ultima_operacao
                                                                              .safra_temp
                                                                  )
                                                              ].descricao
                                                            : "Não Identificado"}
                                                    </div>
                                                </EstiloItem>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {polylines && polylines.length > 0 ? (
                                    <>
                                        <div
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                marginTop: "10px",
                                                display: "flex",
                                                paddingTop: "0px",
                                            }}
                                        >
                                            <i
                                                className="fas fa-chart-pie"
                                                style={{
                                                    marginRight: "5px",
                                                    marginTop: "3px",
                                                }}
                                            ></i>{" "}
                                            Resumo de operações no periodo:
                                        </div>

                                        {Object.keys(dados().rel).length > 0 ? (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        columnGap: "20px",
                                                        marginTop: "15px",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                    >
                                                        <EstiloItem>
                                                            <div>Consumo:</div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.consumo_total.toFixed(
                                                                        2
                                                                    )}
                                                                    L
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Média cons.:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {mediaConsumo(
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_total,
                                                                            "horas"
                                                                        ),
                                                                        dados()
                                                                            .rel
                                                                            .tempoParado
                                                                            .consumo_total
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                    L/h
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>T. total:</div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_total,
                                                                            "horas"
                                                                        ).h
                                                                    }
                                                                    h
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_total,
                                                                            "horas"
                                                                        ).m
                                                                    }
                                                                    min
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                T. operação:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_total -
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_parado -
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_total_deslocamento,

                                                                            "horas"
                                                                        ).h
                                                                    }
                                                                    h
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_total -
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_parado -
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_total_deslocamento,

                                                                            "horas"
                                                                        ).m
                                                                    }
                                                                    min
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        {dados().rel.tempoParado
                                                            .tempo_total_deslocamento >
                                                            60 && (
                                                            <EstiloItem>
                                                                <div>
                                                                    T. desloc.:
                                                                </div>
                                                                <Skeleton
                                                                    active
                                                                    loading={
                                                                        loader
                                                                            .relatorioOne
                                                                            ?.get
                                                                            ?.loading
                                                                    }
                                                                    paragraph={
                                                                        false
                                                                    }
                                                                >
                                                                    <div>
                                                                        {
                                                                            segParaHoras(
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_total_deslocamento,

                                                                                "horas"
                                                                            ).h
                                                                        }
                                                                        h
                                                                        {
                                                                            segParaHoras(
                                                                                dados()
                                                                                    .rel
                                                                                    .tempoParado
                                                                                    .tempo_total_deslocamento,

                                                                                "horas"
                                                                            ).m
                                                                        }
                                                                        min
                                                                    </div>
                                                                </Skeleton>
                                                            </EstiloItem>
                                                        )}
                                                        <EstiloItem>
                                                            <div>
                                                                T. parado:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_parado,

                                                                            "horas"
                                                                        ).h
                                                                    }
                                                                    h
                                                                    {
                                                                        segParaHoras(
                                                                            dados()
                                                                                .rel
                                                                                .tempoParado
                                                                                .tempo_parado,
                                                                            "horas"
                                                                        ).m
                                                                    }
                                                                    min
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Temp. Média:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.temperatura_media.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    °C
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Temp. Máximo:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        dados()
                                                                            .rel
                                                                            .tempoParado
                                                                            .temperatura_maxima
                                                                    }{" "}
                                                                    °C
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "50%",
                                                            marginRight: "20px",
                                                        }}
                                                    >
                                                        <EstiloItem>
                                                            <div>
                                                                RPM Média:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.rpm_media.toFixed(
                                                                        2
                                                                    )}
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                RPM Máximo:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        dados()
                                                                            .rel
                                                                            .tempoParado
                                                                            .rpm_maxima
                                                                    }
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Vel. Média:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.velocidade_media.toFixed(
                                                                        2
                                                                    )}
                                                                    Km/h
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Vel. Máxima:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        dados()
                                                                            .rel
                                                                            .tempoParado
                                                                            .velocidade_maxima
                                                                    }
                                                                    Km/h
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Pressão Média:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.pressao_media.toFixed(
                                                                        2
                                                                    )}
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Pressão Máx:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.pressao_maxima.toFixed(
                                                                        2
                                                                    )}
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        {alertasRender.length >
                                                            0 && (
                                                            <EstiloItem>
                                                                <div>
                                                                    Alertas:
                                                                </div>
                                                                <Skeleton
                                                                    active
                                                                    loading={
                                                                        loader
                                                                            .relatorioOne
                                                                            ?.get
                                                                            ?.loading
                                                                    }
                                                                    paragraph={
                                                                        false
                                                                    }
                                                                >
                                                                    <div>
                                                                        {
                                                                            alertasRender.length
                                                                        }
                                                                    </div>
                                                                </Skeleton>
                                                            </EstiloItem>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        fontSize: "16px",
                                                        padding:
                                                            "5px 0 10px 1.5px",
                                                        borderBottom:
                                                            "1px solid #FFF",
                                                        columnGap: "10px",
                                                        alignItems: "baseline",
                                                    }}
                                                >
                                                    {alertasRender.length >
                                                        0 && (
                                                        <>
                                                            <div>
                                                                <Switch
                                                                    defaultChecked
                                                                    checked={
                                                                        showAlert
                                                                    }
                                                                    onChange={(
                                                                        x: any
                                                                    ) => {
                                                                        if (
                                                                            x ===
                                                                            false
                                                                        ) {
                                                                            setFocus(
                                                                                {
                                                                                    view: 5,
                                                                                    subView:
                                                                                        null,
                                                                                }
                                                                            );
                                                                        }
                                                                        setShowAlert(
                                                                            x
                                                                        );
                                                                    }}
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    setShowAlert(
                                                                        !showAlert
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Visualizar
                                                                alertas no mapa
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "20px",
                                                        fontWeight: "bold",
                                                        marginTop: "15px",
                                                        display: "flex",
                                                        paddingTop: "0px",
                                                    }}
                                                >
                                                    <i
                                                        className="fas fa-route"
                                                        style={{
                                                            marginRight: "5px",
                                                            marginTop: "1px",
                                                        }}
                                                    ></i>
                                                    Hectares e Km registrados:
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        columnGap: "15px",
                                                        marginTop: "15px",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            marginRight: "20px",
                                                        }}
                                                    >
                                                        <EstiloItem>
                                                            <div>
                                                                Hec. total:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.ha_total.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    ha
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Hec. em
                                                                Operação:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.ha_ope.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    ha
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Hec. Não
                                                                Identificado:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.ha_nao_id.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    ha
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Km Não
                                                                Identificado:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.km_nao_id.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    km
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                        <EstiloItem>
                                                            <div>
                                                                Km deslocamento:
                                                            </div>
                                                            <Skeleton
                                                                active
                                                                loading={
                                                                    loader
                                                                        .relatorioOne
                                                                        ?.get
                                                                        ?.loading
                                                                }
                                                                paragraph={
                                                                    false
                                                                }
                                                            >
                                                                <div>
                                                                    {dados().rel.tempoParado.km_deslocamento.toFixed(
                                                                        2
                                                                    )}{" "}
                                                                    km
                                                                </div>
                                                            </Skeleton>
                                                        </EstiloItem>
                                                    </div>
                                                </div>
                                                {opesList.length > 0 && (
                                                    <>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "bold",
                                                                marginTop:
                                                                    "15px",
                                                                display: "flex",
                                                                paddingTop:
                                                                    "0px",
                                                            }}
                                                        >
                                                            <i
                                                                className="fas fa-hard-hat"
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                    marginTop:
                                                                        "4px",
                                                                }}
                                                            ></i>
                                                            Tipos de Operações
                                                            registrados:
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                columnGap:
                                                                    "15px",
                                                                marginTop:
                                                                    "15px",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    marginRight:
                                                                        "20px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        marginBottom:
                                                                            "10px",
                                                                        borderBottom:
                                                                            "1px solid #cccccc",
                                                                    }}
                                                                >
                                                                    {opesList.map(
                                                                        (
                                                                            i: any,
                                                                            index: any
                                                                        ) => {
                                                                            return (
                                                                                (index
                                                                                    ? ", "
                                                                                    : "") +
                                                                                tiposOp[
                                                                                    tiposOp.findIndex(
                                                                                        (
                                                                                            o: any
                                                                                        ) =>
                                                                                            o.id_tipo_operacao ===
                                                                                            i
                                                                                    )
                                                                                ]
                                                                                    .descricao
                                                                            );
                                                                        }
                                                                    )}{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {alertaList.length > 0 && (
                                                    <>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "bold",
                                                                marginTop:
                                                                    "15px",
                                                                display: "flex",
                                                                paddingTop:
                                                                    "0px",
                                                            }}
                                                        >
                                                            <i
                                                                className="fas fa-exclamation-triangle"
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                    marginTop:
                                                                        "1px",
                                                                }}
                                                            ></i>
                                                            Últimos 5 alertas
                                                            registrados:
                                                        </div>
                                                        <Tooltip
                                                            title="Visualizar a lista completa de alertas"
                                                            placement="rightTop"
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "start",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    setOpenAlertaModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Lista completa
                                                                dos alertas
                                                                <i
                                                                    className="fas fa-search"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </Tooltip>

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                columnGap:
                                                                    "15px",
                                                                marginTop:
                                                                    "15px",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    marginRight:
                                                                        "20px",
                                                                }}
                                                            >
                                                                {alertaList.map(
                                                                    (
                                                                        i: any
                                                                    ) => {
                                                                        let d: any =
                                                                            new Date(
                                                                                i.timestamp
                                                                            );
                                                                        return (
                                                                            <>
                                                                                <EstiloItem>
                                                                                    <div>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <>
                                                                                                    <i
                                                                                                        className="far fa-clock"
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                "10px",
                                                                                                        }}
                                                                                                    ></i>
                                                                                                    {d.toLocaleDateString(
                                                                                                        "pt-BR",
                                                                                                        {
                                                                                                            weekday:
                                                                                                                "long",
                                                                                                            day: "2-digit",
                                                                                                            month: "long",
                                                                                                            year: "numeric",
                                                                                                            hour12: true,
                                                                                                        }
                                                                                                    )}
                                                                                                    {
                                                                                                        " - "
                                                                                                    }
                                                                                                    {d.toLocaleTimeString(
                                                                                                        "pt-BR"
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            {d.toLocaleDateString(
                                                                                                "pt-BR"
                                                                                            )}
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div>
                                                                                        {formataTexto(
                                                                                            i.txtalerta
                                                                                        )}
                                                                                    </div>
                                                                                </EstiloItem>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {loader.relatorioOne?.get
                                                    ?.success === true && (
                                                    <ModalEquipa
                                                        tema={tema}
                                                        openModal={openModal}
                                                        setOpenModal={
                                                            setOpenModal
                                                        }
                                                        mediaConsumo={mediaConsumo(
                                                            segParaHoras(
                                                                dados().rel
                                                                    .tempoParado
                                                                    .tempo_total,
                                                                "horas"
                                                            ),
                                                            dados().rel
                                                                .tempoParado
                                                                .consumo_total
                                                        ).toFixed(2)}
                                                        horas={
                                                            segParaHoras(
                                                                dados().rel
                                                                    .tempoParado
                                                                    .tempo_total,
                                                                "horas"
                                                            ).h
                                                        }
                                                        minutos={
                                                            segParaHoras(
                                                                dados().rel
                                                                    .tempoParado
                                                                    .tempo_total,
                                                                "horas"
                                                            ).m
                                                        }
                                                        numAlertas={
                                                            alertasRender.length
                                                        }
                                                        rpmMedia={parseFloat(
                                                            dados().rel.tempoParado.rpm_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        rpmMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .rpm_maxima
                                                        }
                                                        velMedia={parseFloat(
                                                            dados().rel.tempoParado.velocidade_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        velMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .velocidade_maxima
                                                        }
                                                        velMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .velocidade_minima
                                                        }
                                                        rpmMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .rpm_minima
                                                        }
                                                        pressaoMedia={parseFloat(
                                                            dados().rel.tempoParado.pressao_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        pressaoMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .pressao_maxima
                                                        }
                                                        pressaoMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .pressao_minima
                                                        }
                                                        tempMedia={parseFloat(
                                                            dados().rel.tempoParado.temperatura_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        tempMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .temperatura_maxima
                                                        }
                                                        tempMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .temperatura_minima
                                                        }
                                                        cargaMedia={parseFloat(
                                                            dados().rel.tempoParado.carga_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        cargaMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .carga_maxima
                                                        }
                                                        cargaMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .carga_minima
                                                        }
                                                        torqMedia={parseFloat(
                                                            dados().rel.tempoParado.torque_media.toFixed(
                                                                2
                                                            )
                                                        )}
                                                        torqMax={
                                                            dados().rel
                                                                .tempoParado
                                                                .torque_maxima
                                                        }
                                                        torqMin={
                                                            dados().rel
                                                                .tempoParado
                                                                .torque_minima
                                                        }
                                                        time={time}
                                                        dadosRel={dados().rel}
                                                        dadosOpe={dados().ope}
                                                        equipamento={
                                                            equipamento
                                                        }
                                                        dias={diasList}
                                                        setDiaSelec={
                                                            setDiaSelec
                                                        }
                                                        diaSelec={diaSelec}
                                                        frameMenor={handleFrameMenor()}
                                                    />
                                                )}
                                                <ModalAlertasEquipa
                                                    tema={tema}
                                                    openAlertaModal={
                                                        openAlertaModal
                                                    }
                                                    setOpenAlertaModal={
                                                        setOpenAlertaModal
                                                    }
                                                    equipamento={equipamento}
                                                    alertaList={alertasRender}
                                                    dataI={new Date(time.i)}
                                                    dataF={new Date(time.f)}
                                                />
                                            </>
                                        ) : (
                                            <ContentOverflow>
                                                <div className="not-found">
                                                    Equipamento fora da rede,
                                                    não foi possível carregar as
                                                    informações.
                                                </div>
                                            </ContentOverflow>
                                        )}
                                    </>
                                ) : (
                                    setVisual("ultimo")
                                )}
                            </>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <ContentOverflow>
                    <div className="not-found">
                        <InfoCircleFilled
                            style={{
                                fontSize: "50px",
                                color: "#0C46E8",
                                paddingBottom: "20px",
                            }}
                        />
                        Aparentemente, esse equipamento não possui registros
                        operacionais.
                    </div>
                </ContentOverflow>
            );
        }
    }
}

function mapStateToProps(store: IStore) {
    return {
        alertasRender: store.alertaRender,
        equipamento: store.equipamento,
        polylines: store.polylines,
        relatorios: store.relOne,
        time: store.time,
        culturas: store.culturas,
        safras: store.safras,
        tiposOp: store.tiposOp,
        procs: store.processos,
        fazenda: store.fazenda,
        menu: store.menu,
        tema: store.theme,
        operadores: store.operadores,
        loader: store.loader,

        talhoesFiltro: store.talhaoFiltro,
        fazendas: store.fazendas,
        implementos: store.implementos,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setFocus,
            getOneEquipamento,
            setRefresh,
            ativarProcess,
            setTalhao,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipamento);
