import { SET_THEME } from "../store/actionTypes";

export type ITheme = boolean;

export interface IThemeReducer {
    type: any;
    payload: ITheme;
}
const Theme: ITheme = false;
export const themeReducer = (state = Theme, action: IThemeReducer) => {
    switch (action.type) {
        case SET_THEME:
            return action.payload;
        default:
            return state;
    }
};
