import { Menu } from "antd";

const { SubMenu } = Menu;

export default function MenuOperacional(props: any) {
    return (
        <Menu
            mode="horizontal"
            onClick={(a: any) => {
                switch (a.key) {
                    case "filtros:1":
                        props.setVisivel(true);
                        props.setDialog(1);
                        break;
                    default:
                        break;
                }
            }}
            triggerSubMenuAction="click"
            selectable={false}
        >
            <SubMenu key="filtros" title="Filtros" popupOffset={[-15, 0]}>
                <Menu.Item key="filtros:1">
                    <i
                        style={{ height: "20px", marginRight: "10px" }}
                        className="fas fa-filter"
                    ></i>
                    Editar Filtros
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
}
