import { Button, Divider, Modal, message } from "antd";
import { useEffect, useState, useRef, MutableRefObject } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { uploadAvatar, logoutReset } from "../../store/userActions";
import { loginProcessLogOut } from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Avatar from "react-avatar-edit";
import axios from "axios";
import { IStore } from "../../store";

interface cameraOverlayProps {
    visivel: any;
    tema: boolean;
}

const CameraOverlay = styled.div<cameraOverlayProps>`
    position: absolute;
    background-color: ${props =>
        props.tema ? "rgba(48, 48, 48, 0.6)" : "rgba(255, 255, 255, 0.6)"};
    backdrop-filter: blur(2px);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: ${props => (props.visivel ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & i {
        font-size: 75px;
    }
`;

function MenuUser(props: any) {
    const { uploadAvatar, logoutReset, loginProcessLogOut, theme } = props;
    const [avatarHover, setAvatarHover] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [image, setImage] = useState<any>(null);
    const [imageCrop, setImageCrop] = useState(null);
    const [, updateState] = useState();
    const [haAvatar, setHaAvatar] = useState(false);

    const formUpload = useRef() as any;

    function handleUpload() {
        formUpload.current.click();
    }

    useEffect(() => {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_BASE_URL_API +
                "api/user/avatar/" +
                props.authState.user.id,
        })
            // .then(res =>
            // .catch(err =>
            .then(res => setHaAvatar(true))
            .catch(err => setHaAvatar(false));
    }, []);

    function dataURLtoFile(dataurl: any, filename: any) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename + "." + mime.split("/")[1], {
            type: mime,
        });
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "10px 20px",
                    width: "250px",
                    fontSize: "25px",
                }}
            >
                <div
                    style={{
                        fontSize: "150px",
                        display: "flex",
                        width: "150px",
                        height: "150px",
                        position: "relative",
                        cursor: "pointer",
                    }}
                    onMouseOut={() => {
                        setAvatarHover(false);
                    }}
                    onMouseOver={() => {
                        setAvatarHover(true);
                    }}
                    onClick={handleUpload}
                >
                    <CameraOverlay visivel={avatarHover} tema={theme}>
                        <i className="fas fa-camera"></i>
                    </CameraOverlay>
                    <input
                        title="file"
                        type="file"
                        ref={formUpload}
                        style={{ display: "none" }}
                        accept=".png,.jpg,.gif,.tiff,.heic,.svg"
                        onChange={(e: any) => {
                            setImage(URL.createObjectURL(e.target.files[0]));
                            setModal(true);
                        }}
                    />
                    {haAvatar ? (
                        <img
                            style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                            }}
                            src={
                                process.env.REACT_APP_BASE_URL_API +
                                "api/user/avatar/" +
                                props.authState.user.id
                            }
                            alt="não carregou"
                        />
                    ) : (
                        <i className="fas fa-user-circle"></i>
                    )}
                </div>
                <div
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        width: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {props.authState.user.nome}
                </div>
                <Divider
                    style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                />
                <div
                    style={{
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        width: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {props.authState.user.email}
                </div>
                <div>
                    <Button
                        type="primary"
                        style={{
                            marginTop: "30px",
                        }}
                        onClick={() => {
                            logoutReset();
                            //loginProcessLogOut();
                        }}
                    >
                        <i
                            className="fas fa-sign-out-alt"
                            style={{
                                marginRight: "10px",
                            }}
                        ></i>
                        Sair
                    </Button>
                </div>
            </div>
            <Modal
                title="Edite a sua foto"
                visible={modal}
                cancelText="Cancelar"
                okText="Fazer Upload"
                closable={false}
                zIndex={2000}
                bodyStyle={{
                    height: "400px",
                }}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                confirmLoading={modalLoading}
                onOk={() => {
                    const dataObj = new FormData();
                    dataObj.append("file", dataURLtoFile(imageCrop, "avatar"));

                    axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL_API}api/user/avatar/` +
                                props.authState.user.id,
                            dataObj,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + props.authState.token,
                                },
                            }
                        )
                        .then(response => {
                            if (!response.data.error) {
                                setModal(false);
                                setTimeout(() => {
                                    props.setMenuUserKey(uuidv4());
                                    message.success(
                                        "O seu avatar foi alterado com sucesso."
                                    );
                                }, 500);
                            }
                        })
                        .catch(error => {});
                }}
            >
                {" "}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            maxHeight: "300px",
                        }}
                    >
                        <Avatar
                            src={image}
                            width={400}
                            height={300}
                            onCrop={(img: any) => {
                                setImageCrop(img);
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        theme: store.theme,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        { uploadAvatar, logoutReset, loginProcessLogOut },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuUser);
