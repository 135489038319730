import { message } from "antd";
import { IPreferencia } from "../reducers/PreferenciaReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_PREFERENCIA } from "./actionTypes";

// Métodos para as preferências

export const setPreferencia = (value: IPreferencia) => ({
    type: SET_PREFERENCIA,
    payload: value,
});

export const getPreferencia = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "preferencia"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/preferencia/`, {
            method: "GET",
            headers: {
                "content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then((res: any) => {
                if (typeof res !== "undefined") {
                    if (res.erro) {
                        dispatch(
                            setStatusError("get", "preferencia", res.erro)
                        );
                    } else {
                        dispatch(
                            setPreferencia(JSON.parse(res[0].preferencia))
                        );
                        dispatch(setStatusSuccess("get", "preferencia"));
                    }
                }
            });
    };
};

export interface IEditPreferencia {
    preferencia: string;
}

export const editarPreferencia = (value: IEditPreferencia) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "preferencia"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/preferencia/`, {
            method: "PUT",
            headers: {
                "content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "preferencia", res.erro));
                } else {
                    dispatch(getPreferencia());
                    dispatch(setStatusSuccess("edit", "preferencia"));
                    message.success(
                        "Suas preferências foram atualizadas para as próximas sessões."
                    );
                }
            });
    };
};
