export function getBBoxOp(props: any) {
    if (props.length > 0) {
        return {
            hLat: Math.max.apply(
                Math,
                props.map((o: any) => o.latitude)
            ),
            lLat: Math.min.apply(
                Math,
                props.map((o: any) => o.latitude)
            ),
            hLng: Math.max.apply(
                Math,
                props.map((o: any) => o.longitude)
            ),
            lLng: Math.min.apply(
                Math,
                props.map((o: any) => o.longitude)
            ),
        };
    }
    return null;
}
export function converteHoras(props: any) {
    if (props <= 0) {
        return {
            h: 0,
            m: 0,
        };
    } else {
        let horas: any = Math.trunc(props);
        let minutos: any =
            (props - horas + "").split(".")[1] === undefined
                ? 0
                : parseInt((props - horas + "").split(".")[1].substring(0, 2));
        let min: any = Math.trunc((60 * minutos) / 100);
        return {
            h: horas,
            m: min,
        };
    }
}

export function mediaConsumo(props: any, comb: any) {
    let horas = Number(props.h);
    let min = Number(props.m);
    if (
        !isFinite(comb / (horas + min / 60)) ||
        isNaN(comb / (horas + min / 60))
    ) {
        return 0;
    } else {
        return comb / (horas + min / 60);
    }
}

export function calculoMedia(props: any, campo: any) {
    if (typeof props.reduce === "function") {
        let calc: any = (
            props.reduce((a: any, b: any) => {
                return a + b[campo];
            }, 0) / props.length
        ).toFixed(2);
        if (!isFinite(calc)) {
            return 0;
        } else {
            return parseFloat(calc);
        }
    }
}

export function calculoMax(props: any, campo: any) {
    if (typeof props.map === "function") {
        let max: any = Math.max.apply(
            Math,
            props.map((o: any) => o[campo])
        );
        if (!isFinite(max)) {
            return 0;
        } else {
            return max;
        }
    }
}

export function calculoMin(props: any, campo: any) {
    if (typeof props.map === "function") {
        let maior: any = props.filter((m: any) => {
            return m[campo] > 0;
        });
        if (maior.length > 0) {
            let min: any = Math.min.apply(
                Math,
                maior.map((o: any) => o[campo])
            );
            if (isNaN(min)) {
                return 0;
            } else {
                return min;
            }
        } else {
            return 0;
        }
    }
}

export function getAlertas(props: any) {
    return props.filter((a: any) => a.alerta);
}

export function criarPDF(props: any) {
    var win: any = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title>Empregados</title>");
    // win.document.write(style);
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write("minhaTabela");
    win.document.write("</body></html>");
    win.document.close();
    win.print();
}

export function segParaHoras(segundos: any, tipo: any) {
    var min_total = segundos / 60;
    var horas_totais = min_total / 60;
    var dias_totais = horas_totais / 24;
    var seg = segundos % 60;
    var min = min_total % 60;
    var hor = horas_totais % 24;
    var dia = dias_totais % 30;
    let res: any = "";
    let obj: any = {};
    switch (tipo) {
        case "horas":
            obj = {
                h:
                    horas_totais < 10
                        ? "0" + parseInt(horas_totais + "")
                        : parseInt(horas_totais + ""),
                m: min < 10 ? "0" + parseInt(min + "") : parseInt(min + ""),
                s: seg < 10 ? "0" + seg : seg,
            };

            res = obj;
            break;
        case "dias":
            obj = {
                d: parseInt(dias_totais + ""),
                h: hor < 10 ? "0" + parseInt(hor + "") : parseInt(hor + ""),
                m: min < 10 ? "0" + parseInt(min + "") : parseInt(min + ""),
                s: seg,
            };
            res = obj;
            break;
        case "todos": // retorna um obj com até seculos
            var meses_totais = dias_totais / 30;
            var anos_totais = meses_totais / 12;
            var mes = meses_totais % 12;
            var decadas_totais = anos_totais / 10;
            var ano = anos_totais % 10;
            var seculos_totais = decadas_totais / 10;
            var dec = decadas_totais % 10;
            obj = {
                seculo: seculos_totais,
                decada: dec,
                ano: ano,
                mes: mes,
                dia: dia,
                hora: hor,
                minuto: min,
                segundo: seg,
            };
            res = "";
            res = obj;
            break;
    }
    return res;
}

export function formataTexto(texto: any): any {
    let palavras: any = texto.split(" ");
    for (let i = 0; i < palavras.length; i++) {
        if (palavras[i].length > 0) {
            palavras[i] =
                palavras[i][0].toUpperCase() +
                palavras[i].substr(1).toLowerCase();
        }
    }
    return palavras.join(" ");
}

export function difTalhoes(array_1: any, array_2: any): any {
    let newArr: any = [];
    let arr1 = array_1.map((t: any) => {
        if (t.coordenadas !== null) {
            return JSON.parse(t.coordenadas).bbox;
        }
    });
    let arr2 = array_2.map((t: any) => t.geometry.bbox);
    arr2.filter((a: any, i: any) => {
        if (!arr1.some((b: any) => JSON.stringify(b) === JSON.stringify(a))) {
            newArr.push(array_2[i]);
        }
    });
    return newArr;

    // var arr: any = [];
    // for (var i = 0; i < array_1.length; i++) {
    //     let arr1 = array_1
    //         .map((t: any) => {
    //             if (t.coordenadas !== null) {
    //                 return JSON.parse(t.coordenadas).bbox;
    //             }
    //         })
    //         .sort();
    //     let arr2 = array_2.map((t: any) => t.geometry.bbox).sort();

    //     if (arr1[i] !== undefined && arr2[i] !== undefined) {
    //         if (arr1[i].toString() !== arr2[i].toString()) {
    //             arr.push(array_2[i]);
    //         }
    //     }
    // }
    // return arr;
}

export function getDaysArray(start: any, end: any) {
    for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
    ) {
        arr.push(new Date(dt).toISOString().split("T")[0]);
    }
    return arr;
}
