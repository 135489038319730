import {
    SET_ERROR,
    SET_LOADING,
    SET_SUCCESS,
    SET_NULL,
    SET_KEEP_ALIVE,
} from "../store/actionTypes";
import { IActionStatus, IStatusList } from "../store/types";

const StatusState: IStatusList = {
    loader: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    auth: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    operacao: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    registroOpe: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    antena: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    equipamento: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    fazenda: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    cultura: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    culturaCliente: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    safra: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    safraCliente: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    tipoOp: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    tipoOpCliente: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    tipoParada: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    polyline: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    mensagem: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
    },
    preferencia: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
    },
    servidor: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    filtros: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    relatorio: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    relatorioOne: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    relatorioOpe: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    pdf: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
    },
    operador: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    alerta: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    alertaUser: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    usuario: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    talhao_tablet: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    implemento: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
    parametro: {
        get: {
            loading: false,
            error: "",
            success: "",
        },
        add: {
            loading: false,
            error: "",
            success: "",
        },
        edit: {
            loading: false,
            error: "",
            success: "",
        },
        del: {
            loading: false,
            error: "",
            success: "",
        },
    },
};

export const StatusReducer = (
    state: any = StatusState,
    action: IActionStatus
) => {
    switch (action.type) {
        case SET_ERROR:
            let errState = JSON.parse(JSON.stringify(state));
            errState[action.entity][action.method] = {
                loading: false,
                error: action.error,
                success: "",
            };
            return errState;
        case SET_LOADING:
            let loadingState = JSON.parse(JSON.stringify(state));
            loadingState[action.entity][action.method] = {
                loading: true,
                error: "",
                success: "",
            };
            return loadingState;
        case SET_SUCCESS:
            let sucState = JSON.parse(JSON.stringify(state));
            sucState[action.entity][action.method] = {
                loading: false,
                error: null,
                success: true,
            };
            return sucState;
        case SET_NULL:
            let nullState = JSON.parse(JSON.stringify(state));
            nullState[action.entity][action.method] = {
                loading: false,
                error: null,
                success: null,
            };
            return nullState;
        default:
            return state;
    }
};

export type IKeepAlive = boolean | null;
const KeepAlive: IKeepAlive = null;

export interface IKeepAliveReducer {
    type: any;
    payload: IKeepAlive;
}
export const keepAliveReducer = (
    state = KeepAlive,
    action: IKeepAliveReducer
) => {
    switch (action.type) {
        case SET_KEEP_ALIVE:
            return action.payload;
        default:
            return state;
    }
};
