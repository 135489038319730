interface IMenuModule {
    id: number;
    name: string;
    class: string;
    position: string;
    process: number;
    enable: boolean;
}
export type IMenu = IMenuModule[];

const Menu: IMenu = [
    {
        id: 5,
        name: "Operações",
        class: "fas fa-book",
        position: "top",
        process: 5,
        enable: true,
    },
    {
        id: 2,
        name: "Equipamentos",
        class: "fas fa-tractor",
        position: "top",
        process: 2,
        enable: true,
    },
    {
        id: 1,
        name: "Talhões",
        class: "fas fa-layer-group",
        position: "top",
        process: 1,
        enable: true,
    },
    {
        id: 4,
        name: "Configurações",
        class: "fas fa-cog",
        position: "bottom",
        process: 4,
        enable: true,
    },
    {
        id: 6,
        name: "Operacional",
        class: "fas fa-play-circle",
        position: "top",
        process: 6,
        enable: true,
    },
];

export const menuReducer = (state = Menu, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};
