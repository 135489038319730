import { message } from "antd";
import { IParametro, IParametroList } from "../reducers/ParametroReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_LIST_PARAMETRO, SET_PARAMETRO } from "./actionTypes";

//Métodos para administração de PARAMETROS DE CONSUMO
export const setParametro = (value: IParametro) => ({
    type: SET_PARAMETRO,
    payload: value,
});

export const setListParametro = (value: IParametroList) => ({
    type: SET_LIST_PARAMETRO,
    payload: value,
});

export const getParametros = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "parametro"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/parametroconsumo/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "parametro", res.erro));
                } else {
                    dispatch(setListParametro(res));
                    dispatch(setStatusSuccess("get", "parametro"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "parametro", error.data));
            });
    };
};

interface IAddParametro {
    media: number;
    equipamento_temp: string;
    implemento_temp: string | null;
    tipo_operacao_temp: string | null;
}

export const adicionarParametro = (value: IAddParametro) => {
    console.log(value, JSON.stringify(value));
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "parametro"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/parametroconsumo/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "parametro", res.erro));
                } else {
                    dispatch(getParametros());
                    dispatch(setStatusSuccess("add", "parametro"));
                    message.success("Parâmetro adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "parametro", error.data));
            });
    };
};

interface IEditParametro {
    id_parametro_consumo: string;
    media: number;
    equipamento_temp: string;
    implemento_temp: string | null;
    tipo_operacao_temp: string | null;
}

export const editarParametro = (value: IEditParametro) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "parametro"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/parametroconsumo/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "parametro", res.erro));
                } else {
                    dispatch(getParametros());
                    dispatch(setStatusSuccess("edit", "parametro"));
                    message.success("Parâmetro editado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "parametro", error.data));
            });
    };
};

interface IDeleteParametro {
    value: string;
}

export const deletarParametro = (value: IDeleteParametro) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "parametro"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/parametroconsumo/` +
                value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "parametro", res.erro));
                } else {
                    dispatch(getParametros());
                    dispatch(setStatusSuccess("del", "parametro"));
                    message.success("Parâmetro deletado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "parametro", error.data));
            });
    };
};
