import { SET_LIST_OPERADOR, SET_OPERADOR } from "../store/actionTypes";

export interface IOperadorModule {
    id_operador?: number;
    nome?: string;
    senha?: string;
    ativo?: boolean;
    cod_erp?: string;
}
export type IOperador = IOperadorModule;
const Operador: IOperador = {};

export interface IOperadorReducer {
    type: any;
    payload: IOperador;
}

export const operadorReducer = (state = Operador, action: IOperadorReducer) => {
    switch (action.type) {
        case SET_OPERADOR:
            return action.payload;
        default:
            return state;
    }
};

export type IOperadorList = IOperadorModule[];
const OperadorList: IOperadorList = [];

export interface IOperadorListReducer {
    type: any;
    payload: IOperadorList;
}

export const operadorListReducer = (
    state = OperadorList,
    action: IOperadorListReducer
) => {
    switch (action.type) {
        case SET_LIST_OPERADOR:
            return action.payload;
        default:
            return state;
    }
};
