//Métodos para administração de CULTURA

import { message } from "antd";
import {
    ICultura,
    ICulturaCliente,
    ICulturaClienteList,
    ICulturaList,
} from "../reducers/CulturaReducer";
import {
    setStatusError,
    setStatusStart,
    setStatusSuccess,
    loginProcessTokenExpired,
} from "./actions";
import {
    SET_CULTURA,
    SET_CULTURA_CLIENTE,
    SET_LIST_CULTURA,
    SET_LIST_CULTURA_CLIENTE,
} from "./actionTypes";

export const setCultura = (value: ICultura) => ({
    type: SET_CULTURA,
    payload: value,
});

export const setListCultura = (value: ICulturaList) => ({
    type: SET_LIST_CULTURA,
    payload: value,
});

export const getCulturas = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "cultura"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/cultura/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                //"Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "cultura", res.erro));
                } else {
                    dispatch(setListCultura(res));
                    dispatch(setStatusSuccess("get", "cultura"));
                }
            })
            .catch(error => {
                // if (
                //     error.response.status === 401 ||
                //     error.response.status === 403
                // ) {
                //     dispatch(loginProcessTokenExpired());
                // }
            });
    };
};

export const setCulturaCliente = (value: ICulturaCliente) => ({
    type: SET_CULTURA_CLIENTE,
    payload: value,
});

export const setListCulturaCliente = (value: ICulturaClienteList) => ({
    type: SET_LIST_CULTURA_CLIENTE,
    payload: value,
});

export const getCulturaCliente = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "culturaCliente"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/cultura_cliente/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                //"Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "culturaCliente", res.erro));
                } else {
                    dispatch(setListCulturaCliente(res));
                    dispatch(setStatusSuccess("get", "culturaCliente"));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

interface IAddCulturaCliente {
    cultura_temp: number;
}

export const adicionarCultura = (value: IAddCulturaCliente) => {
    console.log(value, JSON.stringify(value));
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "culturaCliente"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/cultura_cliente/`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                console.log(response);
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "culturaCliente", res.erro));
                } else {
                    dispatch(getCulturaCliente());
                    dispatch(setStatusSuccess("add", "culturaCliente"));
                    // message.success("Parâmetro adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "culturaCliente", error.data));
            });
    };
};

interface IDeletCulturaCliente {
    value: string;
}

export const deletarCultura = (value: IDeletCulturaCliente) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "culturaCliente"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/cultura_cliente/` + value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "culturaCliente", res.erro));
                } else {
                    dispatch(getCulturaCliente());
                    dispatch(setStatusSuccess("del", "culturaCliente"));
                    message.success("Cultura deletada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "culturaCliente", error.data));
            });
    };
};
