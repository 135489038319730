import { message } from "antd";
import { persistor } from ".";
import { IUser, IUserList, IUserModule } from "../reducers/UserReducer";
import {
    loginProcessLogOut,
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_LIST_USUARIO, SET_USUARIO } from "./actionTypes";

export const logoutReset = () => {
    return async (dispatch: any) => {
        dispatch({ type: "RESET" });
        await persistor.purge();
        await persistor.persist();
        window.location.reload();
    };
};

export const uploadAvatar = (value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "avatar"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/avatar`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "avatar", res.erro));
                } else {
                    dispatch(setStatusSuccess("add", "avatar"));
                    message.success("Upload realizado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "avatar", error.data));
            });
    };
};

export const setUsuario = (value: IUser) => ({
    type: SET_USUARIO,
    payload: value,
});

export const setListUsuario = (value: IUserList) => ({
    type: SET_LIST_USUARIO,
    payload: value,
});

export interface IUserModuleAction extends IUserModule, ReadableStream {}

export const getUsuarios = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "usuario"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/clientes`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "usuario", res.erro));
                } else {
                    dispatch(setListUsuario(res));
                    dispatch(setStatusSuccess("get", "usuario"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "usuario", error.data));
            });
    };
};

export const getUsuario = (userId: string) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "usuario"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/` + userId, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "usuario", res.erro));
                } else {
                    dispatch(setUsuario(res));
                    dispatch(setStatusSuccess("get", "usuario"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "usuario", error.data));
            });
    };
};

export const adicionarUsuario = (value: IUserModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "usuario"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "usuario", res.erro));
                } else {
                    dispatch(getUsuarios());
                    dispatch(setStatusSuccess("add", "usuario"));
                    message.success("Usuário adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "usuario", error.data));
            });
    };
};

export const editarUsuario = (value: IUserModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "usuario"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/user/`, {
            method: "put",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "usuario", res.erro));
                } else {
                    dispatch(getUsuarios());
                    dispatch(setStatusSuccess("edit", "usuario"));
                    message.success("Usuário atualizado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "usuario", error.data));
            })
            .finally(() => {
                if (value.pass) {
                    dispatch(logoutReset());
                }
            });
    };
};
