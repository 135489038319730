import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";
import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ITipoOp, ITipoOpList } from "../../../../reducers/TipoOpReducer";
import { useCallback, useEffect, useState } from "react";
import { editarTipoOp } from "../../../../store/tipoOpActions";
import { setStatusNull } from "../../../../store/actions";

const { Option } = Select;

interface EditarParametroOpeProps {
    status: IStatusList;
    cont?: any;
    setModal?: any;

    tiposOp: ITipoOpList;

    editarTipoOp: Function;
    setStatusNull: any;
}

function EditarParametroOpe(props: EditarParametroOpeProps) {
    const { status, tiposOp, editarTipoOp, setStatusNull } = props;
    const [valido, setValido] = useState(true);

    const handleDisable = useCallback(() => {
        if (valido === true) {
            return false;
        }
        return true;
    }, [valido]);

    useEffect(() => {
        if (props.cont.tempo_alerta_parada === 0) {
            setValido(false);
        }
    }, [props.cont]);

    useEffect(() => {
        if (status.tipoOpCliente?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "tipoOpCliente");
        }
    });

    return (
        <>
            <Form
                onChange={() => {}}
                initialValues={{
                    ...props.cont,
                    tipo_operacao_temp:
                        tiposOp[
                            tiposOp.findIndex((o: ITipoOp) => {
                                return (
                                    o.id_tipo_operacao ===
                                    props.cont.tipo_operacao_temp
                                );
                            })
                        ].descricao,
                }}
                layout="vertical"
                onFinish={(v: any) => {
                    let obj = {
                        id_tipo_operacao_cliente: v.id_tipo_operacao_cliente,
                        tipo_operacao_temp: props.cont.tipo_operacao_temp,
                        tempo_alerta_parada: v.tempo_alerta_parada,
                    };
                    editarTipoOp(obj);
                }}
            >
                <Form.Item
                    name="id_tipo_operacao_cliente"
                    style={{ display: "none" }}
                >
                    <Input
                        type="hidden"
                        name="id_tipo_operacao_cliente"
                    ></Input>
                </Form.Item>{" "}
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Tipo de Operação:"
                            name="tipo_operacao_temp"
                        >
                            <Input placeholder="Tipo de Operação" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Preencha um valor",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValido(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValido(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Tempo para alertar parada:"
                            name="tempo_alerta_parada"
                            tooltip="Valor em segundos"
                        >
                            <InputNumber
                                min={4}
                                placeholder="Digite um valor"
                                style={{ width: "100%" }}
                                pattern="[0-9]*"
                            />
                            {/* <Select
                                placeholder="Selecione um tempo"
                                onChange={(e: any) => console.log(e)}
                            >
                                <Option value="08">0m8s</Option>
                                <Option value="10">0m10s</Option>
                                <Option value="12">0m12s</Option>
                                <Option value="15">0m15s</Option>
                                <Option value="20">0m20s</Option>
                                <Option value="25">0m25s</Option>
                                <Option value="30">0m30s</Option>
                                <Option value="35">0m35s</Option>
                                <Option value="40">0m40s</Option>
                                <Option value="45">0m45s</Option>
                                <Option value="50">0m50s</Option>
                                <Option value="55">0m55s</Option>
                                <Option value="60">1m00s</Option>
                                <Option value="70">1m10s</Option>
                                <Option value="75">1m15s</Option>
                                <Option value="80">1m20s</Option>
                            </Select> */}
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={status.tipoOpCliente?.edit?.loading}
                    >
                        Salvar tipo de operação
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        tiposOp: store.tiposOp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarTipoOp,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarParametroOpe);
