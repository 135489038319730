import {
    SET_FILTER_OPERACAO,
    SET_LIST_OPERACAO,
    SET_OPERACAO,
    SET_OPERACAO_BBOX,
    SET_REGISTRO_OPERACAO,
} from "../store/actionTypes";
import { Store } from "../store";

export interface IColumnModule {
    label: string;
    value: string;
    key: string;
    disabled: boolean;
    render?: Function;
    typeValue?: string;
    tipoEquip?: Number;
}

export type IColumn = IColumnModule[];

export type IOpTipoAlerta = IColumnModule[];

export const tipos: IOpTipoAlerta = [
    {
        label: "Horímetro (hrs)",
        value: "horimetro",
        key: "horimetro",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                let paraMin = t * 60;
                let paraHoras = Math.floor(t);
                let min: any = Math.trunc(paraMin % 60);
                return paraHoras + "h" + min + "m";
            }
        },
        typeValue: "number",
    },
    {
        label: "Vel. (km/h)",
        value: "velocidade",
        key: "velocidade",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            return t + "Km";
        },
        typeValue: "number",
    },
    {
        label: "RPM",
        value: "rpm",
        key: "rpm",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            return t;
        },
        typeValue: "number",
    },
    {
        label: "Torque (%)",
        value: "torqueMotor",
        key: "torqueMotor",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Carga (%)",
        value: "cargaMotor",
        key: "cargaMotor",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Pressão/turbo (psi)",
        value: "pressaoTurbo",
        key: "pressaoTurbo",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t + "psi";
            }
        },
        typeValue: "number",
    },
    {
        label: "Pressão/Admissão Ar (psi)",
        value: "pressaoAdmissao",
        key: "pressaoAdmissao",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t + "psi";
            }
        },
        typeValue: "number",
    },
    {
        label: "Pressão óleo/Motor (psi)",
        value: "pressaoOleo",
        key: "pressaoOleo",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
        typeValue: "number",
    },
    {
        label: "Pressão óleo/Transmissão (psi)",
        value: "pressaoTrans",
        key: "pressaoTrans",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
        typeValue: "number",
    },
    {
        label: "Pressão Combustível (psi)",
        value: "pressaoCombustivel",
        key: "pressaoCombustivel",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
        typeValue: "number",
    },
    {
        label: "Temp. óleo/Motor (°C)",
        value: "tempOleo",
        key: "tempOleo",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Temp. água/motor (°C)",
        value: "temAgua",
        key: "temAgua",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Temp. ar/Admissão (°C)",
        value: "tempAdmissao",
        key: "tempAdmissao",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Temp. ar/Ambiente (°C)",
        value: "tempAmbiente",
        key: "tempAmbiente",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Temp. óleo/Transmissão (°C)",
        value: "tempTrans",
        key: "tempTrans",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Temp. fluído Hidraulico (°C)",
        value: "tempFluidoHidra",
        key: "tempFluidoHidra",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Temp. Combustível (°C)",
        value: "tempCombustivel",
        key: "tempCombustivel",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Vazão Combustível (L/h)",
        value: "vazaoComb",
        key: "vazaoComb",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Nível Combustível (%)",
        value: "nivelComb",
        key: "nivelComb",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Nível óleo/Transmissão (%)",
        value: "nivelOleoTrans",
        key: "nivelOleoTrans",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Nível fluído/Hidraulico (%)",
        value: "nivelFluidoHidra",
        key: "nivelFluidoHidra",
        disabled: false,
        typeValue: "number",
        tipoEquip: 1,
    },
    {
        label: "Tensão Bateria (volts)",
        value: "bateria",
        key: "bateria",
        disabled: false,
        typeValue: "number",
    },
    {
        label: "Tomada de Força",
        value: "tomadaForca",
        key: "tomadaForca",
        disabled: false,
        typeValue: "boolean",
    },
    {
        label: "Piloto Automático",
        value: "piloto",
        key: "piloto",
        disabled: false,
        typeValue: "boolean",
    },
    {
        label: "Bomba D'água",
        value: "bombaAgua",
        key: "bombaAgua",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Central",
        value: "bicoPulvCT",
        key: "bicoPulvCT",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Esquerdo 01",
        value: "bicoPulvE1",
        key: "bicoPulvE1",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Esquerdo 02",
        value: "bicoPulvE2",
        key: "bicoPulvE2",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Esquerdo 03",
        value: "bicoPulvE3",
        key: "bicoPulvE3",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Direito 01",
        value: "bicoPulvD1",
        key: "bicoPulvD1",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Direito 02",
        value: "bicoPulvD2",
        key: "bicoPulvD2",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Pulv. Bico Direito 03",
        value: "bicoPulvD3",
        key: "bicoPulvD3",
        disabled: false,
        typeValue: "boolean",
        tipoEquip: 3,
    },
    {
        label: "Inicio / Final de Operação",
        value: "operacao",
        key: "operacao",
        typeValue: "na",

        disabled: false,
    },
    {
        label: "Inicio / Final de Deslocamento",
        value: "deslocamento",
        key: "deslocamento",
        typeValue: "na",

        disabled: false,
    },
    {
        label: "Inicio / Final de Parada",
        value: "paradamaquina",
        key: "paradamaquina",
        typeValue: "na",

        disabled: false,
    },
];

const columns: IColumn = [
    { label: "ID", value: "id_operacao", key: "id_operacao", disabled: false },
    { label: "Firmware", value: "verFW", key: "verFW", disabled: false },
    {
        label: "Protocolo",
        value: "verProtocol",
        key: "verProtocol",
        disabled: false,
    },
    { label: "T. Mensagem", value: "tipoMSG", key: "tipoMSG", disabled: false },
    { label: "Vs. Carga", value: "verCarga", key: "verCarga", disabled: false },
    {
        label: "Tipo Carga",
        value: "tipoCarga",
        key: "tipoCarga",
        disabled: false,
    },
    { label: "ID Carga", value: "idCarga", key: "idCarga", disabled: false },
    {
        label: "Qtd. Msg. Armaz.",
        value: "qtdMsgFLASH",
        key: "qtdMsgFLASH",
        disabled: false,
    },
    {
        label: "Motor Ligado",
        value: "motorON",
        key: "motorON",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t ? "Ligado" : "Desligado";
            }
        },
    },
    {
        label: "Chave ligada",
        value: "ignicaoON",
        key: "ignicaoON",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t ? "Ligado" : "Desligado";
            }
        },
    },
    { label: "GPS Ligado", value: "gpsON", key: "gpsON", disabled: false },
    {
        label: "Id. Alerta",
        value: "idAlerta",
        key: "idAlerta",
        disabled: false,
    },
    {
        label: "Data/Hora",
        value: "timestamp",
        key: "timestamp",
        disabled: true,
    },
    { label: "Latitude", value: "latitude", key: "latitude", disabled: false },
    {
        label: "Longitude",
        value: "longitude",
        key: "longitude",
        disabled: false,
    },
    {
        label: "Bussola",
        value: "bussola",
        key: "bussola",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                let graus = parseInt(t);
                if (graus >= 337 || graus < 23) return "Norte";
                if (graus >= 23 && graus < 68) return "Nordeste";
                if (graus >= 68 && graus < 113) return "Leste";
                if (graus >= 113 && graus < 158) return "Sudeste";
                if (graus >= 158 && graus < 203) return "Sul";
                if (graus >= 203 && graus < 248) return "Sudoeste";
                if (graus >= 248 && graus < 293) return "Oeste";
                if (graus >= 293 && graus < 337) return "Noroeste";
                return t;
            }
        },
    },
    {
        label: "Tensão Bateria",
        value: "bateria",
        key: "bateria",
        disabled: false,
    },
    {
        label: "Horímetro",
        value: "horimetro",
        key: "horimetro",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                let paraMin = t * 60;
                let paraHoras = Math.floor(t);
                let min: any = Math.trunc(paraMin % 60);
                return paraHoras + "h" + min + "m";
            }
        },
    },
    { label: "Comb. Cons.", value: "totComb", key: "totComb", disabled: false },
    { label: "Odometro", value: "odometro", key: "odometro", disabled: false },
    {
        label: "Vel.",
        value: "velocidade",
        key: "velocidade",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            return t + "Km";
        },
    },
    {
        label: "RPM",
        value: "rpm",
        key: "rpm",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            return t;
        },
    },
    {
        label: "Pedal/Acelerador",
        value: "pedalAcelerador",
        key: "pedalAcelerador",
        disabled: false,
    },
    {
        label: "Torque",
        value: "torqueMotor",
        key: "torqueMotor",
        disabled: false,
    },
    { label: "Carga", value: "cargaMotor", key: "cargaMotor", disabled: false },
    {
        label: "Pressão/turbo",
        value: "pressaoTurbo",
        key: "pressaoTurbo",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t + "psi";
            }
        },
    },
    {
        label: "Pressão/Admissão do Ar",
        value: "pressaoAdmissao",
        key: "pressaoAdmissao",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t + "psi";
            }
        },
    },
    {
        label: "Pressão óleo/Motor",
        value: "pressaoOleo",
        key: "pressaoOleo",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
    },
    {
        label: "Pressão óleo/Transmissão",
        value: "pressaoTrans",
        key: "pressaoTrans",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
    },
    {
        label: "Pressão do Combustível",
        value: "pressaoCombustivel",
        key: "pressaoCombustivel",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t.toFixed(2) + "psi";
            }
        },
    },
    {
        label: "Temp. óleo/Motor",
        value: "tempOleo",
        key: "tempOleo",
        disabled: false,
    },
    {
        label: "Temp. água/motor",
        value: "temAgua",
        key: "temAgua",
        disabled: false,
    },
    {
        label: "Temp. ar/Admissão",
        value: "tempAdmissao",
        key: "tempAdmissao",
        disabled: false,
    },
    {
        label: "Temp. ar/Ambiente",
        value: "tempAmbiente",
        key: "tempAmbiente",
        disabled: false,
    },
    {
        label: "Temp. óleo/Transmissão",
        value: "tempTrans",
        key: "tempTrans",
        disabled: false,
    },
    {
        label: "Temp. fluído Hidraulico",
        value: "tempFluidoHidra",
        key: "tempFluidoHidra",
        disabled: false,
    },
    {
        label: "Temp. Combustível",
        value: "tempCombustivel",
        key: "tempCombustivel",
        disabled: false,
    },
    {
        label: "Vazão Combustível",
        value: "vazaoComb",
        key: "vazaoComb",
        disabled: false,
    },
    {
        label: "Nível Combustível",
        value: "nivelComb",
        key: "nivelComb",
        disabled: false,
    },
    {
        label: "Nível óleo/Transmissão",
        value: "nivelOleoTrans",
        key: "nivelOleoTrans",
        disabled: false,
    },
    {
        label: "Nível fluído/Hidraulico",
        value: "nivelFluidoHidra",
        key: "nivelFluidoHidra",
        disabled: false,
    },
    {
        label: "Alerta do Código de falhas",
        value: "dtc",
        key: "dtc",
        disabled: false,
    },
    { label: "Hélice do Radiador", value: "fun", key: "fun", disabled: false },
    {
        label: "Altura do Implemento",
        value: "alturaImpl",
        key: "alturaImpl",
        disabled: false,
    },
    {
        label: "Vel. Unidade Colheita",
        value: "velColheita",
        key: "velColheita",
        disabled: false,
    },
    {
        label: "Tomada de Força",
        value: "tomadaForca",
        key: "tomadaForca",
        disabled: false,
    },
    {
        label: "Piloto Automático",
        value: "piloto",
        key: "piloto",
        disabled: false,
    },
    {
        label: "Indústria",
        value: "industria",
        key: "industria",
        disabled: false,
    },
    {
        label: "Descarga Grãos",
        value: "descargaGrao",
        key: "descargaGrao",
        disabled: false,
    },
    {
        label: "Unidade Colheita",
        value: "stsColheita",
        key: "stsColheita",
        disabled: false,
    },
    {
        label: "Plataforma",
        value: "plataforma",
        key: "plataforma",
        disabled: false,
    },
    {
        label: "Embalar (algodão)",
        value: "embalar",
        key: "embalar",
        disabled: false,
    },
    {
        label: "Bomba D'água",
        value: "bombaAgua",
        key: "bombaAgua",
        disabled: false,
    },
    {
        label: "Taxa Vol. Aplic. por HA",
        value: "taxaAplic",
        key: "taxaAplic",
        disabled: false,
    },
    {
        label: "Liberação Líquido",
        value: "libLiquido",
        key: "libLiquido",
        disabled: false,
    },
    {
        label: "Pulv. Bico Central",
        value: "bicoPulvCT",
        key: "bicoPulvCT",
        disabled: false,
    },
    {
        label: "Pulv. Bico Esquerdo 01",
        value: "bicoPulvE1",
        key: "bicoPulvE1",
        disabled: false,
    },
    {
        label: "Pulv. Bico Esquerdo 02",
        value: "bicoPulvE2",
        key: "bicoPulvE2",
        disabled: false,
    },
    {
        label: "Pulv. Bico Esquerdo 03",
        value: "bicoPulvE3",
        key: "bicoPulvE3",
        disabled: false,
    },
    {
        label: "Pulv. Bico Direito 01",
        value: "bicoPulvD1",
        key: "bicoPulvD1",
        disabled: false,
    },
    {
        label: "Pulv. Bico Direito 02",
        value: "bicoPulvD2",
        key: "bicoPulvD2",
        disabled: false,
    },
    {
        label: "Pulv. Bico Direito 03",
        value: "bicoPulvD3",
        key: "bicoPulvD3",
        disabled: false,
    },
    {
        label: "Código do Usuário",
        value: "codUSER",
        key: "codUSER",
        disabled: false,
    },
    {
        label: "Alerta",
        value: "alerta",
        key: "alerta",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            if (t !== undefined) {
                return t ? "Verdadeiro" : "Falso";
            }
        },
    },
    {
        label: "Descrição de Alerta",
        value: "txtalerta",
        key: "txtalerta",
        disabled: false,
    },
    {
        label: "Equip.",
        value: "equipamento_temp",
        key: "equipamento_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            var retorno: any = "";

            if (t !== undefined) {
                let equipamentos: any = state.equipamentos;
                let equipamentosKey = equipamentos.findIndex(
                    (e: any) => e.id_equipamento === t
                );
                retorno = equipamentos[equipamentosKey].descricao;
            }

            return retorno;
        },
    },
    {
        label: "Faz.",
        value: "fazenda_temp",
        key: "fazenda_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let fazendas: any = state.fazendas;
                let fazKey = fazendas.findIndex((f: any) => f.id_fazenda === t);
                return fazendas[fazKey].nome;
            }
        },
    },
    {
        label: "Tipo Op.",
        value: "tipo_operacao_temp",
        key: "tipo_operacao_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let tiposOp: any = state.tiposOp;
                let tipoOpKey = tiposOp.findIndex(
                    (o: any) => o.id_tipo_operacao === t
                );
                return tiposOp[tipoOpKey].descricao;
            }
        },
    },
    {
        label: "Tipo Parada",
        value: "tipo_operacao_parada_temp",
        key: "tipo_operacao_parada_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let tiposOp: any = state.tiposOp;
                let tipoOpKey = tiposOp.findIndex(
                    (o: any) => o.id_tipo_operacao === t
                );
                return tiposOp[tipoOpKey].descricao;
            }
        },
    },
    {
        label: "Cultura",
        value: "cultura_temp",
        key: "cultura_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let culturas: any = state.culturas;
                let culturasKey = culturas.findIndex(
                    (c: any) => c.id_cultura === t
                );
                return culturas[culturasKey].nomeCultura;
            }
        },
    },
    {
        label: "Safra",
        value: "safra_temp",
        key: "safra_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let safras: any = state.safras;
                let safrasKey = safras.findIndex((s: any) => s.id_safra === t);
                return safras[safrasKey].descricao;
            }
        },
    },
    {
        label: "Operador",
        value: "operador_temp",
        key: "operador_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let operadores: any = state.operadores;
                let operadoresKey = operadores.findIndex(
                    (s: any) => s.id_operador === t
                );
                return operadores[operadoresKey].nome;
            }
        },
    },
    {
        label: "Talhão",
        value: "talhao_temp",
        key: "talhao_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let talhoes: any = state.talhoes;
                let talhoesKey = talhoes.findIndex(
                    (s: any) => s.id_talhao === t
                );
                return talhoes[talhoesKey].nome_talhao;
            }
        },
    },
    {
        label: "Implemento",
        value: "implemento_temp",
        key: "implemento_temp",
        disabled: false,
        render: (t: any, r: any, i: any) => {
            let state: any = Store.getState();
            if (t !== undefined) {
                let implementos: any = state.implementos;
                let implementosKey = implementos.findIndex(
                    (s: any) => s.id_implemento === t
                );
                return implementos[implementosKey].descricao;
            }
        },
    },
];

/*const idAlerta = [
    { id: 0, desc: "Não identificado (REPORT)" },
    { id: 1, desc: "Status Bussola" },
    { id: 2, desc: "Status código de falha" },
    { id: 3, desc: "Status tomada de força" },
    { id: 4, desc: "Status piloto automático" },
    { id: 5, desc: "Status indústria" },
    { id: 6, desc: "Status descarga de grãos" },
    { id: 7, desc: "Status unidade de colheita" },
    { id: 8, desc: "Status plataforma (algodão)" },
    { id: 9, desc: "Status embalar (algodão)" },
    { id: 10, desc: "Status bomba d’água" },
    { id: 11, desc: "Status liberando liquido" },
];*/

export interface IOperacaoModule {
    id_operacao?: number;
    verFw?: string;
    verProtocol?: string;
    tipoMSG?: string;
    verCarga?: string;
    tipoCarga?: string;
    idCarga?: string;
    qtdMsgFLASH?: string;
    motorON?: boolean;
    ignicaoON?: boolean;
    gpsON?: boolean;
    idAlerta?: string;
    timestamp?: Date;
    latitude?: string;
    longitude?: string;
    bussola?: string;
    bateria?: string;
    horimetro?: number;
    totComb?: number;
    odometro?: number;
    velocidade?: number;
    rpm?: number;
    pedalAcelerador?: number;
    torqueMotor?: number;
    cargaMotor?: number;
    pressaoTurbo?: number;
    pressaoAdmissao?: number;
    pressaoOleo?: number;
    pressaoTrans?: number;
    pressaoCombustivel?: number;
    tempOleo?: number;
    temAgua?: number;
    tempAdmissao?: number;
    tempAmbiente?: number;
    tempTrans?: number;
    tempFluidoHidra?: number;
    tempCombustivel?: number;
    vazaoComb?: number;
    nivelComb?: number;
    nivelOleoTrans?: number;
    nivelFluidoHidra?: number;
    dtc?: string;
    fun?: string;
    alturaImpl?: number;
    velColheita?: number;
    tomadaForca?: string;
    piloto?: string;
    industria?: string;
    descargaGrao?: string;
    stsColheita?: string;
    plataforma?: string;
    embalar?: string;
    bombaAgua?: string;
    taxaAplic?: string;
    libLiquido?: string;
    bicoPulvCT?: string;
    bicoPulvE1?: string;
    bicoPulvE2?: string;
    bicoPulvE3?: string;
    bicoPulvD1?: string;
    bicoPulvD2?: string;
    bicoPulvD3?: string;
    codUSER?: string;
    alerta?: boolean | null;
    txtalerta?: string | null;
    talhao?: string;
    equipamento_temp?: string;
    fazenda_temp?: string;
    safra_temp?: number | null;
    cultura_temp?: number | null;
    tipo_operacao_temp?: number | null;
    operador_temp?: number | null;
    tipo_operacao_parada_temp?: number | null;
    implemento_temp?: number | null;
}

export type IOperacao = string;
export type IOperacoes = IOperacaoModule[];
export interface IOperacoesFilter {
    dados?: IOperacaoModule[];
    total_registros?: Number;
}

export interface IOperacaoAction {
    type: any;
    payload: string;
}

export interface IOperacoesAction {
    type: any;
    payload: IOperacaoModule[];
}

export interface IOperacoesFilterAction {
    type: any;
    payload: IOperacaoModule[];
}

const Operacao: IOperacao = "";

const Operacoes: IOperacoes = [];

const OperacoesFilter: IOperacoesFilter = {};

export interface IOpBBoxModule {
    hLat?: number;
    lLat?: number;
    hLng?: number;
    lLng?: number;
}

export type IOpBBox = IOpBBoxModule | null;

const opBBox: IOpBBox = {};
export interface IOpBBoxAction {
    type: any;
    payload: IOpBBox;
}

///////

export interface IRegOpeParadaModule {
    id_registro_operacao_parada?: number;
    data_inicial?: string;
    data_final?: string | null;
    duracao: number;
    consumo: number | null;
    tipoOperacao_parada?: {
        id_tipo_operacao?: number | null;
        descricao?: string | null;
    };
}
export interface IRegOpeAlertaModule {
    id_registro_operacao_alerta?: number;
    data_hora?: string | null;
    txtalerta?: string | null;
    latitude?: string;
    longitude?: string;
}

export interface IRegistroOpeModule {
    id_registro_operacao?: number;
    fazenda_temp?: string;
    talhao_temp?: string | null;
    operador_temp?: number | null;
    equipamento_temp?: string;
    implemento_temp?: string | null;
    marcador_inicial?: number;
    marcador_final?: number | null;
    consumo_combustivel?: number | null;
    data_inicial?: string;
    data_final?: string | null;
    tempo_parado?: number | null;
    tempo_andando?: number | null;
    tempo_total?: number | null;
    ha?: number | null;
    tipo_operacao_temp?: number | null;
    cultura_temp?: number | null;
    safra_temp?: number | null;
    registro_operacao_parada?: IRegOpeParadaModule[];
    registro_operacao_alerta?: IRegOpeAlertaModule[];
}

export type IRegistroOpe = {
    dados: IRegistroOpeModule[];
    total_registros: number;
};
const RegistroOpe: IRegistroOpe = { dados: [], total_registros: 0 };

export interface IRegistroOpeReducer {
    type: any;
    payload: IRegistroOpe;
}

export const registroOpeReducer = (state = RegistroOpe, action: any) => {
    switch (action.type) {
        case SET_REGISTRO_OPERACAO:
            return action.payload;
        default:
            return state;
    }
};

///////

export const opBBoxReducer = (state = opBBox, action: IOpBBoxAction) => {
    switch (action.type) {
        case SET_OPERACAO_BBOX:
            return action.payload;
        default:
            return state;
    }
};

export const opColunasReducer = (state = columns, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const operacaoReducer = (state = Operacao, action: IOperacaoAction) => {
    switch (action.type) {
        case SET_OPERACAO:
            return action.payload;
        default:
            return state;
    }
};

export const operacaoListReducer = (
    state = Operacoes,
    action: IOperacoesAction
) => {
    switch (action.type) {
        case SET_LIST_OPERACAO:
            return action.payload;
        default:
            return state;
    }
};

export const operacaoFilterReducer = (
    state = OperacoesFilter,
    action: IOperacoesFilterAction
) => {
    switch (action.type) {
        case SET_FILTER_OPERACAO:
            return action.payload;
        default:
            return state;
    }
};
