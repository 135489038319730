import { Form, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTipoOp } from "../../store/tipoOpActions";
import { setFocus } from "../../store/actions";
import { setSafra } from "../../store/safraActions";
import { setCultura } from "../../store/culturaActions";
import { ativarProcess } from "../../store/actions";
import { IFazenda } from "../../reducers/FazendaReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { v4 as uuidv4 } from "uuid";
import { IStore } from "../../store";
import { IProcessList } from "../../reducers/ProcessReducer";
import { IPreferencia } from "../../reducers/PreferenciaReducer";
import { IStatusList } from "../../store/types";
import { getEquipamentoListFiltro } from "../../store/polylineAction";
import { ITime } from "../../reducers/TimeReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import {
    ICultura,
    ICulturaClienteList,
    ICulturaList,
} from "../../reducers/CulturaReducer";
import { ISafraList } from "../../reducers/SafraReducer";

const { Option } = Select;
interface ICulcuraController {
    fazenda: IFazenda;
    safras: ISafraList;
    culturas: ICulturaList;
    menuState: IMenu;
    processListState: IProcessList;
    setTipoOp: Function;
    ativarProcess: Function;
    setCultura: Function;
    setFocus: Function;
    setSafra: Function;
    prefs: IPreferencia;
    loader: IStatusList;
    getEquipamentoListFiltro: Function;
    time: ITime;
    tema: ITheme;

    culturaList: ICulturaClienteList;
}
export function CulturaController(props: ICulcuraController) {
    const { fazenda, setFocus, culturas, setCultura, tema, culturaList } =
        props;

    function handleChangeCultura(value: any) {
        if (value === "all") {
            setFocus({ view: 7, subview: null });
            setCultura({});
        } else if (value === "naoIdentificado") {
            setFocus({ view: 7, subview: null });
            setCultura({ id_cultura: 0 });
        } else {
            setFocus({ view: 7, subview: null });
            setCultura(
                culturas[
                    culturas.findIndex((c: ICultura) => c.id_cultura === value)
                ]
            );
        }

        // ativarProcess({
        //     menuItem: menuState[menuState.findIndex(m => m.id === 6)],
        //     processList: processListState,
        // });
        // setFocus(3);
    }

    const filteredCultOptions = culturas.filter((o: any) =>
        culturaList.some((s: any) => s.cultura_temp === o.id_cultura)
    );

    return (
        <>
            <Form>
                <Form.Item
                    name="cultura"
                    label={
                        <span
                            style={{
                                color: tema ? "#fff" : "#606060",
                                paddingLeft: "2px",
                                fontSize: "18px",
                            }}
                        >
                            <Tooltip title="Cultura">
                                <i className="fas fa-seedling"></i>
                            </Tooltip>
                        </span>
                    }
                    colon={false}
                >
                    <Select
                        style={{
                            width: 200,
                            color: tema ? "#fff" : "#606060",
                            border: "1px solid #C1C1C1",
                            backgroundColor: tema ? "#757575" : "",
                        }}
                        size="small"
                        disabled={fazenda.id_fazenda === ""}
                        bordered={false}
                        placeholder="Escolha"
                        //optionLabelProp="label"
                        suffixIcon={<i className="fas fa-chevron-right"></i>}
                        // notFoundContent={
                        //     <Empty
                        //         description="Nenhuma operação foi carregada."
                        //         image={Empty.PRESENTED_IMAGE_SIMPLE}
                        //     />
                        // }
                        // dropdownRender={menu => <div>{menu}</div>}
                        onChange={handleChangeCultura}
                        onSelect={handleChangeCultura}
                        dropdownClassName="agrochip-dropdown"
                    >
                        {filteredCultOptions.length > 0 ? (
                            <>
                                <Option
                                    value="all"
                                    label="Todos"
                                    key={uuidv4()}
                                >
                                    <div
                                        style={{
                                            display: "flex",

                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>Todas as culturas</div>
                                    </div>
                                </Option>
                                {filteredCultOptions.map((e: any) => {
                                    return (
                                        <Option
                                            value={e.id_cultura}
                                            key={e.id_cultura}
                                        >
                                            {e.nomeCultura}
                                        </Option>
                                    );
                                })}
                                <Option
                                    value="naoIdentificado"
                                    label="Não Identificado"
                                    key={uuidv4()}
                                >
                                    <div
                                        style={{
                                            display: "flex",

                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>Não Identificado</div>
                                    </div>
                                </Option>
                            </>
                        ) : (
                            <Option disabled>
                                <div
                                    style={{
                                        display: "flex",

                                        flexDirection: "column",
                                    }}
                                >
                                    {" "}
                                    <div
                                        style={{
                                            flex: 1,
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        Não há nenhuma cultura cadastrada.
                                    </div>
                                </div>
                            </Option>
                        )}
                    </Select>
                </Form.Item>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        tipoOps: store.tiposOp,
        menuState: store.menu,
        processListState: store.processos,
        safras: store.safras,
        culturas: store.culturas,
        prefs: store.preferencia,
        loader: store.loader,
        time: store.time,
        tema: store.theme,

        culturaList: store.culturasCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setTipoOp,
            setCultura,
            setSafra,
            ativarProcess,
            setFocus,
            getEquipamentoListFiltro,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CulturaController);
