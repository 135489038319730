import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IFazenda } from "../../reducers/FazendaReducer";
import {
    IServidor,
    IServidorList,
    IServidorModule,
} from "../../reducers/ServidorReducer";
import { IStore } from "../../store";
import { getServidor } from "../../store/servidorActions";
import { getAntenas, setAntena } from "../../store/antenaActions";
import { setFocus } from "../../store/actions";
import { IAntenaList, IAntenaModule } from "../../reducers/AntenaReducer";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Badge, Empty, notification, Popover } from "antd";
import { IStatusList } from "../../store/types";
import { Spinner } from "../../styles/Main";
import { ITheme } from "../../reducers/ThemeReducer";

const EstiloPopover = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    row-gap: 30px;
`;
const PopoverItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 15px;
`;
const PopoverTitulo = styled.div`
    font-weight: bold;
    font-size: 16px;
`;
const PopoverConteudo = styled.div<any>`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom: 1px solid ${props => (props.tema ? "rgb(66,66,66)" : "#ccc")};
    margin-bottom: 20px;
    align-items: center;
`;
const EstiloLinha = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const LinhaItem = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
`;
const LinhaRodape = styled.div`
    font-size: 11px;
    opacity: 0.5;
    text-align: right;
`;

const AntenaContainer = styled.div`
    & div:last-child {
        border: 0 !important;
    }
`;

const ServidorContainer = styled.div`
    & div:last-child {
        border: 0 !important;
    }
`;

interface ConexaoControllerProps {
    antenas: IAntenaList;
    fazenda: IFazenda;
    servidores: IServidorList;
    loader: IStatusList;
    tema: ITheme;

    getServidor: Function;
    getAntenas: Function;
    setAntena: Function;
    setFocus: Function;
}

let timeout: any = null;
function ConexaoController(props: ConexaoControllerProps) {
    const {
        antenas,
        fazenda,
        servidores,
        loader,

        getServidor,
        getAntenas,
        setAntena,
        setFocus,
    } = props;

    const [serverRefresh, setServerRefresh] = useState(uuidv4());
    const [servidorOn, setServidorOn] = useState(true);
    const [antenaOn, setAntenaOn] = useState(true);
    const [serverAberto, setServerAberto] = useState(Object);
    const [antenaInterval, setAntenaInterval] = useState(false);

    function handleAntena(id: any) {
        setFocus({ view: 6, subView: null });
        setAntena(id);
    }

    function formatTime(data: Date) {
        return (
            data.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            }) +
            " - " +
            data.toLocaleTimeString("pt-BR")
        );
    }

    useEffect(() => {
        antenas.forEach((a: any) => {
            let atualFormat = new Date();
            let uUltimo_timestamp = new Date(a.ultimo_timestamp);
            if (atualFormat.getTime() - uUltimo_timestamp.getTime() > 90000) {
                setAntenaOn(false);
            } else {
                setAntenaOn(true);
            }
        });
    }, [antenas]);

    // useEffect(() => {
    //     if (!antenaOn) {
    //         notification.open({
    //             type: "warning",
    //             top: 50,
    //             message: `Antena Desconectada`,
    //             description:
    //                 "Alguma antena aparenta estar offline. Contate o suporte técnico.",
    //         });
    //     }
    // }, [antenaOn]);

    useEffect(() => {
        servidores.forEach((s: any) => {
            let atualFormat = new Date();
            let uUltimo_timestamp = new Date(s.ultimo_timestamp);
            if (atualFormat.getTime() - uUltimo_timestamp.getTime() > 90000) {
                setServidorOn(false);
            } else {
                setServidorOn(true);
            }
        });
    }, [servidores]);

    useEffect(() => {
        if (!servidorOn) {
            notification.open({
                type: "warning",
                top: 50,
                message: `Servidor Desconectado`,
                description:
                    "Algum servidor aparenta estar offline. Contate o suporte técnico.",
            });
        }
    }, [servidorOn]);

    useEffect(() => {
        if (fazenda.id_fazenda !== "" && fazenda !== null) {
            getServidor(fazenda.id_fazenda);
        }
    }, [serverRefresh, fazenda]);

    useEffect(() => {
        setInterval(() => {
            setServerRefresh(uuidv4());
        }, 30000);
    }, []);

    const conexaoOff = useCallback(() => {
        // if (antenaOn === false) {
        //     return true;
        // }
        if (servidorOn === false) {
            return true;
        }
        return false;
    }, [servidorOn]);

    useEffect(() => {
        return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
        if (antenaInterval === true) {
            if (typeof serverAberto === "string") {
                timeout = setInterval(() => {
                    getAntenas(
                        servidores[
                            servidores.findIndex(
                                (s: IServidorModule) =>
                                    s.id_local_server === serverAberto
                            )
                        ].id_local_server
                    );
                }, 15000);
            }
        }
    }, [antenaInterval]);

    useEffect(() => {
        if (antenaInterval !== true) {
            clearInterval(timeout);
        }
    }, [antenaInterval]);

    return (
        <>
            <Popover
                placement={window.screen.width < 920 ? "bottom" : "bottomLeft"}
                destroyTooltipOnHide={{ keepParent: false }}
                trigger="click"
                onVisibleChange={(x: any) => {
                    if (x === false) {
                        setAntenaInterval(false);
                        setServerAberto(Object);
                    }
                }}
                content={
                    <EstiloPopover>
                        <PopoverItem>
                            <PopoverTitulo>Servidores:</PopoverTitulo>
                            <ServidorContainer>
                                {servidores.map(
                                    (s: IServidorModule, x: any) => {
                                        let uTimestampFormat = new Date(
                                            s.ultimo_timestamp
                                        );
                                        let atualFormat = new Date();
                                        return (
                                            <PopoverConteudo
                                                key={uuidv4()}
                                                tema={props.tema}
                                            >
                                                <EstiloLinha>
                                                    <LinhaItem
                                                        onClick={() => {
                                                            if (
                                                                serverAberto ===
                                                                s.id_local_server
                                                            ) {
                                                                setServerAberto(
                                                                    Object
                                                                );
                                                                setAntenaInterval(
                                                                    false
                                                                );
                                                            } else {
                                                                setServerAberto(
                                                                    s.id_local_server
                                                                );
                                                                getAntenas(
                                                                    s.id_local_server
                                                                );
                                                                setAntenaInterval(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "15px",
                                                            }}
                                                        >
                                                            {loader.antena?.get
                                                                ?.loading ? (
                                                                <Spinner
                                                                    view={true}
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fas fa-spinner"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    ></i>
                                                                </Spinner>
                                                            ) : (
                                                                <i
                                                                    className={
                                                                        serverAberto ===
                                                                        s.id_local_server
                                                                            ? "fas fa-minus"
                                                                            : "fas fa-plus"
                                                                    }
                                                                ></i>
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {s.descricao}
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius:
                                                                    "50%",
                                                                backgroundColor:
                                                                    atualFormat.getTime() -
                                                                        uTimestampFormat.getTime() >
                                                                    90000
                                                                        ? "red"
                                                                        : "green",
                                                            }}
                                                        ></div>
                                                    </LinhaItem>
                                                    <LinhaRodape
                                                        style={{
                                                            display:
                                                                s.ultimo_timestamp ===
                                                                null
                                                                    ? "none"
                                                                    : "block",
                                                        }}
                                                    >
                                                        {formatTime(
                                                            new Date(
                                                                s.ultimo_timestamp
                                                            )
                                                        )}
                                                    </LinhaRodape>
                                                </EstiloLinha>

                                                {serverAberto ===
                                                    s.id_local_server && (
                                                    <>
                                                        {antenas.length > 0 ? (
                                                            <AntenaContainer>
                                                                {antenas.map(
                                                                    (
                                                                        a: IAntenaModule
                                                                    ) => {
                                                                        let aTimestampFormat =
                                                                            new Date(
                                                                                a.ultimo_timestamp
                                                                            );
                                                                        let atualFormatA =
                                                                            new Date();
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        "30px",
                                                                                    //paddingRight: "10px",
                                                                                    display:
                                                                                        "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    borderBottom:
                                                                                        props.tema
                                                                                            ? "1px solid rgb(66, 66, 66)"
                                                                                            : "1px solid #CCC",
                                                                                }}
                                                                                key={uuidv4()}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        marginTop:
                                                                                            "10px",
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        columnGap:
                                                                                            "20px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                "flex",
                                                                                            justifyContent:
                                                                                                "space-between",
                                                                                            columnGap:
                                                                                                "20px",
                                                                                            alignItems:
                                                                                                "center",
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <i className="fas fa-angle-right"></i>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                flex: 1,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                a.nome
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                width: "10px",
                                                                                                height: "10px",
                                                                                                borderRadius:
                                                                                                    "50%",
                                                                                                backgroundColor:
                                                                                                    atualFormatA.getTime() -
                                                                                                        aTimestampFormat.getTime() >
                                                                                                    90000
                                                                                                        ? "red"
                                                                                                        : "green",
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            textAlign:
                                                                                                "right",
                                                                                            fontSize:
                                                                                                "11px",
                                                                                            opacity: 0.5,
                                                                                            display:
                                                                                                a.ultimo_timestamp ===
                                                                                                null
                                                                                                    ? "none"
                                                                                                    : "block",
                                                                                        }}
                                                                                    >
                                                                                        {formatTime(
                                                                                            new Date(
                                                                                                a.ultimo_timestamp
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </AntenaContainer>
                                                        ) : (
                                                            <>
                                                                <Empty
                                                                    description="Não há nenhuma antena instalada."
                                                                    image={
                                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </PopoverConteudo>
                                        );
                                    }
                                )}
                            </ServidorContainer>
                        </PopoverItem>
                        {/* {fazenda === null ? (
                            <Empty
                                description="Nenhuma fazenda foi selecionada."
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        ) : (
                            <>
                                <PopoverItem>
                                    <PopoverTitulo>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",

                                                alignItems: "center",
                                            }}
                                        >
                                            <div>Antenas:</div>
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    handleAntena("all")
                                                }
                                            >
                                                Ver todas
                                            </div>
                                        </div>
                                    </PopoverTitulo>
                                    {antenas.map((a: any) => {
                                        let uTimestampFormat = new Date(
                                            a.ultimo_timestamp
                                        );
                                        let atualFormat = new Date();
                                        return (
                                            <PopoverConteudo>
                                                <EstiloLinha>
                                                    <LinhaItem>
                                                        <div
                                                            onClick={() =>
                                                                handleAntena(
                                                                    a.id_antena
                                                                )
                                                            }
                                                            key={uuidv4()}
                                                        >
                                                            {a.nome}
                                                        </div>
                                                    </LinhaItem>
                                                    <LinhaRodape>
                                                        {formatTime(
                                                            new Date(
                                                                a.ultimo_timestamp
                                                            )
                                                        )}
                                                    </LinhaRodape>
                                                </EstiloLinha>
                                            </PopoverConteudo>
                                        );
                                    })}
                                </PopoverItem>
                            </>
                        )} */}
                    </EstiloPopover>
                }
            >
                <Badge dot={conexaoOff()}>
                    <svg
                        viewBox="0 0 1000 1000"
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                            props.tema
                                ? "server-antena dark tour-conexao"
                                : "server-antena tour-conexao"
                        }
                        style={{ width: "25px" }}
                    >
                        <path d="M751 481.6c-28.7 18.6-47.7 50.7-47.7 87.4 0 17.7 4.9 34.3 12.7 48.9-87.4 97.3-205 166.9-338.2 193.7-18.6-23.2-46.6-38.4-78.5-39.2-24.3-65-38.4-134.9-39-207.9-1.5-148 49.8-284.2 135.6-391.7 9.9 3.1 20.2 5.3 31.1 5.3 26.3 0 50.2-10.1 68.5-26.2C614.7 228.5 706.3 344.4 751 481.6zM531.2 73.8c0 8.5-1.3 16.7-3.3 24.7 132.6 84.9 234.2 213.9 283.3 366.6 1.8.1 3.4.4 5.3.6 38.1-81.4 59.7-171.9 58.8-267.5-.1-5.9-.6-11.7-.7-17.5C802.2 94.7 700.7 34.3 585.4 14c-19.8 10.8-38.7 22.8-57.2 35.6 1.7 7.8 3 15.8 3 24.2zM239.1 789.7c-25.6-70.4-40.7-145.7-41.4-224.6-1.6-161.6 53.7-310.4 146.7-428.3-13.1-17.2-21.2-38.4-21.5-61.6-17.2-4.7-34.5-8.8-52.2-12C115.7 145.4 10 308.3 10 496c0 119.2 42.8 228.7 113.6 313.5 27.6 6 55.8 10.3 84.5 12.5 8-12.7 18.3-24 31-32.3zm695.2-520c-7.5 77.9-28.1 152-59.6 220.2 22.5 19.1 37.2 47.3 37.2 79.1 0 44.8-28.4 82.7-68 97.3 0 2.3.3 4.6.3 6.9 0 69-10.5 135.6-29.7 198.4C921.7 781.8 990 646.9 990 496c-.1-81.7-20.4-158.6-55.7-226.3zM760.4 661.5c-93.8 103.6-218.5 178.2-360 209.2.1 1.9.6 3.8.6 5.9 0 19.7-5.8 37.9-15.2 53.6 13.1 17.4 27 34.1 41.7 50 23.8 3.5 47.8 5.9 72.6 5.9 81 0 157.2-20 224.5-54.8 36.2-78.7 56.9-165.9 56.9-257.9 0-1.3-.2-2.5-.2-3.7-7.4-2.2-14.3-4.9-20.9-8.2z" />
                    </svg>
                </Badge>
            </Popover>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        servidores: store.servidores,
        antenas: store.antenas,
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getServidor,
            getAntenas,
            setAntena,
            setFocus,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConexaoController);
