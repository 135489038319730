import { message } from "antd";
import { formataTexto } from "../config/helpers";
import {
    ITipoOp,
    ITipoOpCliente,
    ITipoOpClienteList,
    ITipoOpList,
} from "../reducers/TipoOpReducer";
import {
    setStatusError,
    setStatusStart,
    setStatusSuccess,
    loginProcessTokenExpired,
} from "./actions";
import {
    SET_LIST_TIPOOP,
    SET_LIST_TIPOOP_CLIENTE,
    SET_TIPOOP,
    SET_TIPOOP_CLIENTE,
    SET_TIPOOP_PARADA,
} from "./actionTypes";

//Métodos para administração de TIPOOP
export const setTipoOp = (value: ITipoOp) => ({
    type: SET_TIPOOP,
    payload: value,
});

export const setTipoOpParada = (value: ITipoOp) => ({
    type: SET_TIPOOP_PARADA,
    payload: value,
});

export const setListTipoOp = (value: ITipoOpList) => ({
    type: SET_LIST_TIPOOP,
    payload: value,
});

export const getTipoOps = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "tipoOp"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/tipooperacao/`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "tipoOp", res.erro));
                } else {
                    let array: any = [];
                    res.forEach((r: any) => {
                        array.push({
                            id_tipo_operacao: r.id_tipo_operacao,
                            descricao: formataTexto(r.descricao),
                        });
                    });
                    dispatch(setListTipoOp(array));
                    dispatch(setStatusSuccess("get", "tipoOp"));
                }
            })
            .catch(error => {
                // if (
                //     error.response.status === 401 ||
                //     error.response.status === 403
                // ) {
                //     dispatch(loginProcessTokenExpired());
                // }
            });
    };
};

export const setTipoOpCliente = (value: ITipoOpCliente) => ({
    type: SET_TIPOOP_CLIENTE,
    payload: value,
});

export const setListTipoOpCliente = (value: ITipoOpClienteList) => ({
    type: SET_LIST_TIPOOP_CLIENTE,
    payload: value,
});

export const getTipoOpCliente = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "tipoOpCliente"));

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/tipooperacao_cliente/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "tipoOpCliente", res.erro));
                } else {
                    dispatch(setListTipoOpCliente(res));
                    dispatch(setStatusSuccess("get", "tipoOpCliente"));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

interface IAddTipoOpCliente {
    tipo_operacao_temp: number;
    tempo_alerta_parada?: number;
}

export const adicionarTipoOp = (value: IAddTipoOpCliente) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "tipoOpCliente"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/tipooperacao_cliente/`,
            {
                method: "post",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },

                body: JSON.stringify(value),
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "tipoOpCliente", res.erro));
                } else {
                    dispatch(getTipoOpCliente());
                    dispatch(setStatusSuccess("add", "tipoOpCliente"));
                    // message.success("Parâmetro adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "tipoOpCliente", error.data));
            });
    };
};
interface IEditTipoOpCliente {
    id_tipo_operacao_cliente: string;
    tipo_operacao_temp: number;
    tempo_alerta_parada?: number;
}

export const editarTipoOp = (value: IEditTipoOpCliente) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "tipoOpCliente"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/tipooperacao_cliente/`,
            {
                method: "post",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },

                body: JSON.stringify(value),
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "tipoOpCliente", res.erro));
                    message.error("Erro ao editar.");
                } else {
                    dispatch(getTipoOpCliente());
                    dispatch(setStatusSuccess("edit", "tipoOpCliente"));
                    // message.success("Parâmetro adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "tipoOpCliente", error.data));
            });
    };
};

interface IDeletTipoOpCliente {
    value: string;
}

export const deletarTipoOp = (value: IDeletTipoOpCliente) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "tipoOpCliente"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/tipooperacao_cliente/` +
                value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "tipoOpCliente", res.erro));
                } else {
                    dispatch(getTipoOpCliente());
                    dispatch(setStatusSuccess("del", "tipoOpCliente"));
                    message.success("Tipo de Operação deletada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "tipoOpCliente", error.data));
            });
    };
};
