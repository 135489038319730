import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import {
    useReactTable,
    GroupingState,
    getPaginationRowModel,
    getFilteredRowModel,
    getCoreRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
    ColumnOrderState,
    Header,
    Table,
    Column,
} from "@tanstack/react-table";
import { FC, useEffect, useState } from "react";
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    MinusOutlined,
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined,
} from "@ant-design/icons";
import { ITheme } from "../../../../reducers/ThemeReducer";
import { Button } from "antd";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const reorderColumn = (
    draggedColumnId: string,
    targetColumnId: string,
    columnOrder: string[]
): ColumnOrderState => {
    columnOrder.splice(
        columnOrder.indexOf(targetColumnId),
        0,
        columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
    );
    return [...columnOrder];
};

const DraggableColumnHeader: FC<{
    header: Header<any, unknown>;
    table: Table<any>;
}> = ({ header, table }: any) => {
    const { getState, setColumnOrder } = table;
    const { columnOrder } = getState();
    const { column } = header;

    const [, dropRef] = useDrop({
        accept: "column",
        drop: (draggedColumn: Column<any>) => {
            const newColumnOrder = reorderColumn(
                draggedColumn.id,
                column.id,
                columnOrder
            );
            setColumnOrder(newColumnOrder);
        },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: "column",
    });

    return (
        <th
            ref={dropRef}
            colSpan={header.colSpan}
            key={header.id}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                }}
                ref={previewRef}
            >
                {" "}
                <div
                    style={{
                        cursor: "pointer",
                    }}
                    ref={dragRef}
                >
                    {header.isPlaceholder ? null : (
                        <div>
                            {header.column.getCanGroup() ? (
                                header.column.getIsGrouped() ? (
                                    <PlusOutlined
                                        {...{
                                            onClick:
                                                header.column.getToggleGroupingHandler(),
                                            style: {
                                                marginRight: "2px",
                                                cursor: "pointer",
                                            },
                                        }}
                                    />
                                ) : (
                                    <MinusOutlined
                                        {...{
                                            onClick:
                                                header.column.getToggleGroupingHandler(),
                                            style: {
                                                marginRight: "2px",
                                                cursor: "pointer",
                                            },
                                        }}
                                    />
                                )
                            ) : null}
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}{" "}
                        </div>
                    )}
                </div>
            </div>
        </th>
    );
};

interface TabelaOperacionalProps {
    columns: any;
    data: any;

    theme: ITheme;

    setTabela: Function;
}
function TabelaOperacional(props: TabelaOperacionalProps) {
    const { columns, data, theme } = props;

    const [grouping, setGrouping] = useState<GroupingState>([]);

    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
        columns.map((column: any) => column.id as string) //must start out with populated columnOrder so we can splice
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            columnOrder,
            grouping,
        },
        onGroupingChange: setGrouping,
        onColumnOrderChange: setColumnOrder,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        groupedColumnMode: false,
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    useEffect(() => {
        props.setTabela(table);
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "calc(100vh - 187px)",
                overflowY: "auto",
                marginTop: "20px",
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <table
                    className={
                        theme ? "tabela-operacional-dark" : "tabela-operacional"
                    }
                >
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <DraggableColumnHeader
                                            key={header.id}
                                            header={header}
                                            table={table}
                                        />
                                        // <th
                                        //     key={header.id}
                                        //     colSpan={header.colSpan}
                                        // >
                                        //     {header.isPlaceholder ? null : (
                                        //         <div>
                                        //             {header.column.getCanGroup() ? (
                                        //                 // If the header can be grouped, let's add a toggle
                                        //                 // <button
                                        //                 // {...{
                                        //                 //     onClick:
                                        //                 //         header.column.getToggleGroupingHandler(),
                                        //                 //     style: {
                                        //                 //         cursor: "pointer",
                                        //                 //     },
                                        //                 // }}
                                        //                 // >
                                        //                 header.column.getIsGrouped() ? (
                                        //                     <PlusOutlined
                                        //                         {...{
                                        //                             onClick:
                                        //                                 header.column.getToggleGroupingHandler(),
                                        //                             style: {
                                        //                                 marginRight:
                                        //                                     "2px",
                                        //                                 cursor: "pointer",
                                        //                             },
                                        //                         }}
                                        //                     />
                                        //                 ) : (
                                        //                     <MinusOutlined
                                        //                         {...{
                                        //                             onClick:
                                        //                                 header.column.getToggleGroupingHandler(),
                                        //                             style: {
                                        //                                 marginRight:
                                        //                                     "2px",
                                        //                                 cursor: "pointer",
                                        //                             },
                                        //                         }}
                                        //                     />
                                        //                 )
                                        //             ) : // </button>
                                        //             null}{" "}
                                        //             {flexRender(
                                        //                 header.column.columnDef
                                        //                     .header,
                                        //                 header.getContext()
                                        //             )}
                                        //         </div>
                                        //     )}
                                        // </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td
                                                {...{
                                                    key: cell.id,
                                                    // style: {
                                                    //     background:
                                                    //         cell.getIsGrouped()
                                                    //             ? "#0aff0082"
                                                    //             : cell.getIsAggregated()
                                                    //             ? "#ffa50078"
                                                    //             : cell.getIsPlaceholder()
                                                    //             ? "#ff000042"
                                                    //             : "white",
                                                    // },
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        {row.getIsExpanded() ? (
                                                            <MinusCircleOutlined
                                                                {...{
                                                                    onClick:
                                                                        row.getToggleExpandedHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: row.getCanExpand()
                                                                            ? "pointer"
                                                                            : "normal",
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <PlusCircleOutlined
                                                                {...{
                                                                    onClick:
                                                                        row.getToggleExpandedHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: row.getCanExpand()
                                                                            ? "pointer"
                                                                            : "normal",
                                                                    },
                                                                }}
                                                            />
                                                        )}{" "}
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}{" "}
                                                        ({row.subRows.length})
                                                    </>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    flexRender(
                                                        cell.column.columnDef
                                                            .aggregatedCell ??
                                                            cell.column
                                                                .columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </DndProvider>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    gap: "10px",
                    width: "25%",
                    marginTop: "10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        type="text"
                        size="small"
                    >
                        <DoubleLeftOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <LeftOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <RightOutlined />
                    </Button>
                    <Button
                        type="text"
                        size="small"
                        onClick={() =>
                            table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                    >
                        <DoubleRightOutlined />
                    </Button>
                </div>
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "5px",
                    }}
                >
                    <div>Página</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} de{" "}
                        {table.getPageCount()}
                    </strong>
                </span>
                <select
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "5px",
                        cursor: "pointer",
                    }}
                    title="Linhas"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaOperacional);
