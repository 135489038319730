import {
    IOperacao,
    IOperacaoModule,
    IOperacoes,
    IOperacoesFilter,
    IOpBBox,
    IRegistroOpe,
} from "../reducers/OperacaoReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import {
    SET_FILTER_OPERACAO,
    SET_LIST_OPERACAO,
    SET_OPERACAO,
    SET_OPERACAO_BBOX,
    SET_REGISTRO_OPERACAO,
} from "./actionTypes";
import { getBBoxOp } from "../config/helpers";

//Métodos para administração de OPERAÇÕES
export const setOperacao = (value: IOperacao) => ({
    type: SET_OPERACAO,
    payload: value,
});

export const setListOperacao = (value: IOperacoes) => ({
    type: SET_LIST_OPERACAO,
    payload: value,
});

export const setFilterOperacao = (method: IOperacoesFilter) => ({
    type: SET_FILTER_OPERACAO,
    payload: method,
});

export const setOpBBox = (value: IOpBBox) => ({
    type: SET_OPERACAO_BBOX,
    payload: value,
});

export const getOperacao = (props: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "operacao"));
        const keys = Object.keys(props);
        var urlApi = "api/operacao/" + props.faz + "/?";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz") urlApi += key + "=" + props[key] + "&";
            }
        });
        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "operacao", res.erro));
                } else {
                    dispatch(setListOperacao(res));
                    dispatch(setStatusSuccess("get", "operacao"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "operacao", error.data));
            });
    };
};
export const getFilterOp = (props: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "operacao"));
        var colunasList = "";
        props.colunas.forEach((c: any) => {
            colunasList += c + ",";
        });
        const keys = Object.keys(props);
        var urlApi =
            "api/operacao/" +
            props.faz +
            "/?colunas=" +
            colunasList +
            "&page=" +
            props.page +
            "&size=" +
            props.size +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (
                    key === "dataIni" ||
                    key === "dataFin" ||
                    key === "cultura" ||
                    key === "tipo_operacao" ||
                    key === "tipo_parada" ||
                    key === "safra" ||
                    key === "equipamento" ||
                    key === "operador" ||
                    key === "talhao"
                )
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "operacao", res.erro));
                } else {
                    dispatch(setFilterOperacao(res));
                    dispatch(setStatusSuccess("get", "operacao"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "operacao", error.data));
            });
    };
};

export const setRegistroOpe = (value: IRegistroOpe) => ({
    type: SET_REGISTRO_OPERACAO,
    payload: value,
});
export const getRegistroOpe = (props: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "registroOpe"));

        const keys = Object.keys(props);
        var urlApi =
            "api/registrooperacao/?dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "dataIni" && key !== "dataFin")
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "registroOpe", res.erro));
                } else {
                    dispatch(setRegistroOpe(res));
                    dispatch(setStatusSuccess("get", "registroOpe"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "registroOpe", error.data));
            });
    };
};
