import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, ConfigProvider, Empty, Select } from "antd";
import { IStore } from "../../../../store";
import { useEffect, useState } from "react";

function Colunas(props: any) {
    const { colunasList } = props;

    let abaAtualObj =
        props.abasList[
            props.abasList.findIndex((a: any) => a.key === props.abaAtiva)
        ];
    const [colunasLocal, setColunasLocal] = useState(
        abaAtualObj.content.colunas.map((a: any) => {
            return colunasList[
                colunasList.findIndex((c: any) => a.accessor === c.key)
            ];
        })
    );

    const [col, setCol] = useState(
        props.colunasOpe.map((c: any) => {
            if (c.accessorKey !== "id_registro_operacao") {
                return {
                    label: c.Header,
                    value: c.accessorKey,
                    key: c.key,
                    disabled: false,
                };
            }
        })
    );

    // const filteredOptions = colunasList.filter(
    //     (o: any) => colunasLocal.findIndex((s: any) => s.key === o.key) === -1
    // );

    return (
        <>
            <Select
                mode="multiple"
                labelInValue={true}
                style={{ width: "100%", zIndex: 1061 }}
                value={col}
                dropdownStyle={{ zIndex: 1061 }}
                allowClear={true}
                notFoundContent={
                    <Empty
                        description="Não há mais colunas para selecionar."
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                }
                onChange={(e: any) => {
                    setCol(e);
                    // setColunasLocal(e);
                    // if (e.length === 0) {
                    //     setColunasLocal([
                    //         {
                    //             key: "timestamp",
                    //             label: "Data/Hora",
                    //             value: "timestamp",
                    //             disabled: true,
                    //         },
                    //     ]);
                    // }
                }}
                placeholder="Selecione as colunas para exibição"
            >
                {/* <Select.Option
                    key="timestamp"
                    value="timestamp"
                    label="Data/Hora"
                    disabled
                >
                    Data/Hora
                </Select.Option> */}
                {props.colunas.map((item: any) => {
                    return (
                        <Select.Option
                            key={item.key}
                            label={item.header}
                            value={item.accessorKey}
                            // disabled={item.disabled}
                        >
                            {item.header}
                        </Select.Option>
                    );
                })}
            </Select>
            <div
                style={{
                    display: "block",
                    textAlign: "right",
                    marginTop: "10px",
                }}
            >
                <Button
                    style={{
                        marginRight: "10px",
                    }}
                    onClick={() => {
                        props.setVisivel(false);
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        let newArray = JSON.parse(
                            JSON.stringify(props.abasList)
                        );
                        let abaKey = newArray.findIndex((a: any) => {
                            return a.key === props.abaAtiva;
                        });
                        // newArray[abaKey].content["colunas"] = colunasLocal.map(
                        //     (c: any) => {
                        //         return {
                        //             Header: c.label,
                        //             accessor: c.key,
                        //         };
                        //     }
                        // );

                        newArray[abaKey].content["colunas"] = col.map(
                            (c: any) => {
                                return {
                                    header: c.label,
                                    accessorKey: c.value,
                                    key: c.key,
                                };
                            }
                        );

                        props.setAbasList(newArray);
                        props.setVisivel(false);
                    }}
                >
                    Aplicar
                </Button>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        colunasList: store.opColunas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Colunas);
