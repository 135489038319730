import { SET_PREFERENCIA } from "../store/actionTypes";
import { ICultura } from "./CulturaReducer";
import { IEquipamento } from "./EquipamentoReducer";
import { IFazendaModule } from "./FazendaReducer";
import { ISafra } from "./SafraReducer";
import { ITipoOp } from "./TipoOpReducer";

export interface IPreferencia {
    faz?: IFazendaModule;
    equipa?: IEquipamento;
    safra?: ISafra;
    tipoOp?: ITipoOp;
    cultura?: ICultura;
    tutorial?: any;
    tema?: any;
}
export interface IPreferenciaReducer {
    type: any;
    payload: IPreferencia;
}
const Preferencia: IPreferencia = {};
export const preferenciaReducer = (
    state = Preferencia,
    action: IPreferenciaReducer
) => {
    switch (action.type) {
        case SET_PREFERENCIA:
            return action.payload;
        default:
            return state;
    }
};
