import { message } from "antd";
import { IImplemento, IImplementoList } from "../reducers/ImplementoReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_IMPLEMENTO, SET_LIST_IMPLEMENTO } from "./actionTypes";

//Métodos para administração de IMPLEMENTOS
export const setImplemento = (value: IImplemento) => ({
    type: SET_IMPLEMENTO,
    payload: value,
});

export const setListImplemento = (value: IImplementoList) => ({
    type: SET_LIST_IMPLEMENTO,
    payload: value,
});

export const getImplementos = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "implemento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/implemento/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "implemento", res.erro));
                } else {
                    dispatch(setListImplemento(res));
                    dispatch(setStatusSuccess("get", "implemento"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "implemento", error.data));
            });
    };
};

interface IAddImplemento {
    descricao: string;
    numero_patrimonio: string;
    tipo: string;
    estado: string;
    largura: number;
}

export const adicionarImplemento = (value: IAddImplemento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "implemento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/implemento/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "implemento", res.erro));
                } else {
                    dispatch(getImplementos());
                    dispatch(setStatusSuccess("add", "implemento"));
                    message.success("Implemento adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "implemento", error.data));
            });
    };
};

interface IEditImplemento {
    id_implemento: string;
    descricao: string;
    numero_patrimonio: string;
    tipo: string;
    estado: string;
    largura: number;
}
export const editarImplemento = (value: IEditImplemento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "implemento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/implemento/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "implemento", res.erro));
                } else {
                    dispatch(getImplementos());
                    dispatch(setStatusSuccess("edit", "implemento"));
                    message.success("Implemento editado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "implemento", error.data));
            });
    };
};

interface IDeleteImplemento {
    value: string;
}

export const deletarImplemento = (value: IDeleteImplemento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "implemento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/implemento/` + value, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "implemento", res.erro));
                } else {
                    dispatch(getImplementos());
                    dispatch(setStatusSuccess("del", "implemento"));
                    message.success("Implemento deletado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "implemento", error.data));
            });
    };
};
