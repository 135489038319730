import { useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    // adicionarFazenda,
    deletarFazenda,
    // fazendaProcessNull,
} from "../../../store/fazendaActions";
import AdicionarFazenda from "./Fazendas/AdicionarFazenda";
import { Modal, Button, Popconfirm } from "antd";
import EditarFazenda from "./Fazendas/EditarFazenda";
import { IFazendaList } from "../../../reducers/FazendaReducer";
import { setStatusNull } from "../../../store/actions";
import { IStore } from "../../../store";
import { IStatusList } from "../../../store/types";
import { ITheme } from "../../../reducers/ThemeReducer";

interface FazendasProps {
    fazendaListState: IFazendaList;
    loader: IStatusList;
    setStatusNull: Function;
    deletarFazenda: Function;
    theme: ITheme;
}

function Fazendas(props: FazendasProps) {
    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [which, setWhich] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);
    const { fazendaListState, deletarFazenda, loader, setStatusNull, theme } =
        props;

    function handleEditar(id: any) {
        const fazId = fazendaListState.findIndex(
            (faz: any) => faz.id_fazenda === id
        );
        setConfigModal({
            titulo: "Editar Fazenda",
            conteudo: (
                <EditarFazenda
                    setModal={setModal}
                    cont={fazendaListState[fazId]}
                />
            ),
        });

        setModal(true);
    }

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Fazenda",
            conteudo: <AdicionarFazenda setModal={setModal} />,
        });
        setModal(true);
    }

    function handleExcluir() {
        setConfirmLoading(true);
        deletarFazenda(which);
    }

    useMemo(() => {
        if (loader.fazenda?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "fazenda");
        }
        // getFazendas();
    }, [loader.fazenda?.del?.success]);

    return (
        <>
            <div style={{ height: "100%" }}>
                Fazendas cadastradas:
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {fazendaListState.map((f: any) => {
                                let classN = theme
                                    ? "select-expand-li-dark"
                                    : "select-expand-li";
                                if (f.id_fazenda === which) {
                                    classN += " active";
                                }
                                return (
                                    <li
                                        key={f.id_fazenda}
                                        className={classN}
                                        onClick={() => setWhich(f.id_fazenda)}
                                        onDoubleClick={() =>
                                            handleEditar(f.id_fazenda)
                                        }
                                    >
                                        {f.nome}
                                    </li>
                                );
                            })}
                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich(0)}
                            ></li>
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir essa fazenda?"
                            onConfirm={handleExcluir}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                // disabled={which === 0 ? true : false}
                                disabled={true}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendaListState: store.fazendas,
        loader: store.loader,
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            //getFazendas,
            // adicionarFazenda,
            deletarFazenda,
            setStatusNull,
            // fazendaProcessNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Fazendas);
