import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { keyframes } from "styled-components";
import { setStatusSuccess, getKeepAlive } from "./store/actions";
import { getFazendas } from "./store/fazendaActions";
import { getEquipamentos } from "./store/equipamentoActions";
import { getCulturas } from "./store/culturaActions";
import { getTipoOps } from "./store/tipoOpActions";
import { getSafras } from "./store/safraActions";
import { getPreferencia } from "./store/preferenciaActions";
import { IStore } from "./store";
import { IKeepAlive } from "./reducers/StatusReducer";
import { IPreferencia } from "./reducers/PreferenciaReducer";
import { getOperador } from "./store/operadorAction";
import { getAlerta } from "./store/alertaActions";
import { getUsuarios } from "./store/userActions";
import { getTalhoes } from "./store/talhaoActions";

const form = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    //animation: ${form} 0.3s linear 1;
    background: url("${process.env.REACT_APP_BASE_URL_ROOT}fundologin.png");
    background-size: cover;

    & .form-cont {
        position: relative;
        width: 400px;
        height: 250px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ccc;
        background-color: rgba(249, 249, 249, 0.5);
        border-radius: 10px;
        transition: border 0.3s linear;
    }
    & .form-cont:hover {
        border: 1px solid #999;
        transition: border 0.3s linear;
    }

    & .form-cont img {
        width: 75%;
    }

    & .form-cont .form-login {
        display: flex;
        padding: 20px;
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        row-gap: 15px;
    }
`;

interface BarraProgressoProps {
    porc: any;
}

const BarraProgresso = styled.div<BarraProgressoProps>`
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);

    & .progresso {
        display: block;
        background: rgba(47, 53, 126, 1);
        background: linear-gradient(
            90deg,
            rgba(47, 53, 126, 1) 0%,
            rgba(28, 70, 46, 1) 100%
        );
        width: ${props => props.porc}%;
        height: 100%;
        border-radius: 5px;
    }
`;

interface LoaderProps {
    getFazendas: Function;
    getEquipamentos: Function;
    getCulturas: Function;
    getTipoOps: Function;
    getSafras: Function;
    getPreferencia: Function;
    fazenda?: string | boolean;
    equipamento?: string | boolean;
    cultura?: string | boolean;
    safra?: string | boolean;
    tipoOp?: string | boolean;
    preferencia?: string | boolean;
    setStatusSuccess: Function;
    keepAlive: IKeepAlive;
    getKeepAlive: Function;
    prefs: IPreferencia;
    getOperador: Function;
    operador?: string | boolean;
    getAlerta: Function;
    alerta?: string | boolean;
    getUsuarios: Function;
    usuario?: string | boolean;

    getTalhoes: Function;
    talhaoTablet?: string | boolean;
}

function Loader(props: LoaderProps) {
    const {
        getFazendas,
        getEquipamentos,
        getCulturas,
        getTipoOps,
        getSafras,
        getPreferencia,
        fazenda,
        equipamento,
        cultura,
        safra,
        tipoOp,
        preferencia,
        setStatusSuccess,
        keepAlive,
        getKeepAlive,
        prefs,
        getOperador,
        operador,
        getAlerta,
        alerta,
        getUsuarios,
        usuario,

        getTalhoes,
        talhaoTablet,
    } = props;
    const [porc, setPorc] = useState(0);
    //const [notify, setNotify] = useState("Iniciando...");

    useEffect(() => {
        getKeepAlive();
    }, []);

    useEffect(() => {
        if (keepAlive === true) {
            if (fazenda === "") {
                getFazendas();
            }
            if (equipamento === "") {
                getEquipamentos();
            }
            if (cultura === "") {
                getCulturas();
            }
            if (tipoOp === "") {
                getTipoOps();
            }
            if (safra === "") {
                getSafras();
            }
            if (operador === "") {
                getOperador();
            }
            if (preferencia === "") {
                getPreferencia();
            }
            if (alerta === "") {
                getAlerta();
            }
            if (usuario === "") {
                getUsuarios();
            }
            if (talhaoTablet === "") {
                getTalhoes();
            }
        }
    }, [keepAlive]);

    useEffect(() => {
        if (prefs.tutorial) {
            localStorage.setItem("tutorial", "show");
        } else {
            localStorage.setItem("tutorial", "hide");
        }
    }, [prefs]);

    useMemo(() => {
        if (fazenda === true) {
            setPorc(porc + 16);
        }
    }, [fazenda]);

    useMemo(() => {
        if (equipamento === true) {
            setPorc(porc + 16);
        }
    }, [equipamento]);

    useMemo(() => {
        if (cultura === true) {
            setPorc(porc + 16);
        }
    }, [cultura]);

    useMemo(() => {
        if (tipoOp === true) {
            setPorc(porc + 16);
        }
    }, [tipoOp]);

    useMemo(() => {
        if (safra === true) {
            setPorc(porc + 6);
        }
    }, [safra]);

    useMemo(() => {
        if (operador === true) {
            setPorc(porc + 6);
        }
    }, [operador]);

    useMemo(() => {
        if (preferencia === true) {
            setPorc(porc + 6);
        }
    }, [preferencia]);

    useMemo(() => {
        if (alerta === true) {
            setPorc(porc + 6);
        }
    }, [alerta]);

    useMemo(() => {
        if (usuario === true) {
            setPorc(porc + 6);
        }
    }, [usuario]);

    useMemo(() => {
        if (talhaoTablet === true) {
            setPorc(porc + 6);
        }
    }, [talhaoTablet]);

    useMemo(() => {
        if (fazenda) {
            if (equipamento) {
                if (cultura) {
                    if (tipoOp) {
                        if (safra) {
                            if (operador) {
                                if (usuario) {
                                    if (talhaoTablet) {
                                        if (alerta) {
                                            if (preferencia) {
                                                setStatusSuccess(
                                                    "get",
                                                    "loader"
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [
        fazenda,
        equipamento,
        cultura,
        tipoOp,
        safra,
        operador,
        preferencia,
        prefs,
        alerta,
        talhaoTablet,
    ]);

    return (
        <Container>
            <div className="form-cont">
                <div style={{ fontWeight: "bold" }}>
                    {keepAlive === true
                        ? "Aguarde, estamos carregando suas informações..."
                        : "Verificando a conexão com servidor..."}
                </div>
                <BarraProgresso porc={porc}>
                    <div
                        style={{
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            left: "0",
                            top: "0",
                            width: "100%",
                            height: "100%",
                            color: porc > 50 ? "#FFF" : "#222",
                        }}
                    >
                        {porc}%
                    </div>
                    <div className="progresso"></div>
                </BarraProgresso>
                <div></div>
            </div>
        </Container>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.loader.fazenda?.get?.success,
        equipamento: store.loader.equipamento?.get?.success,
        cultura: store.loader.cultura?.get?.success,
        safra: store.loader.safra?.get?.success,
        tipoOp: store.loader.tipoOp?.get?.success,
        preferencia: store.loader.preferencia?.get?.success,
        keepAlive: store.keepAlive,
        prefs: store.preferencia,
        operador: store.loader.operador?.get?.success,
        alerta: store.loader.alerta?.get?.success,
        usuario: store.loader.usuario?.get?.success,

        talhaoTablet: store.loader.talhao_tablet?.get?.success,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getFazendas,
            getEquipamentos,
            getCulturas,
            getTipoOps,
            getSafras,
            getPreferencia,
            setStatusSuccess,
            getKeepAlive,
            getOperador,
            getAlerta,
            getUsuarios,

            getTalhoes,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
