import { message } from "antd";
import {
    ISafra,
    ISafraCliente,
    ISafraClienteList,
    ISafraList,
} from "../reducers/SafraReducer";
import {
    setStatusError,
    setStatusStart,
    setStatusSuccess,
    loginProcessTokenExpired,
} from "./actions";
import {
    SET_LIST_SAFRA,
    SET_LIST_SAFRA_CLIENTE,
    SET_SAFRA,
    SET_SAFRA_CLIENTE,
} from "./actionTypes";

//Métodos para a safra

export const setSafra = (value: ISafra) => ({
    type: SET_SAFRA,
    payload: value,
});

export const setListSafra = (value: ISafraList) => ({
    type: SET_LIST_SAFRA,
    payload: value,
});

export const getSafras = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "safra"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/safra/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "safra", res.erro));
                } else {
                    dispatch(setListSafra(res));
                    dispatch(setStatusSuccess("get", "safra"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "safra", error.data));
            });
    };
};

export const setSafraCliente = (value: ISafraCliente) => ({
    type: SET_SAFRA_CLIENTE,
    payload: value,
});

export const setListSafraCliente = (value: ISafraClienteList) => ({
    type: SET_LIST_SAFRA_CLIENTE,
    payload: value,
});

export const getSafraCliente = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "safraCliente"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/safra_cliente/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                //"Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "safraCliente", res.erro));
                } else {
                    dispatch(setListSafraCliente(res));
                    dispatch(setStatusSuccess("get", "safraCliente"));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

interface IAddSafraCliente {
    safra_temp: number;
}

export const adicionarSafra = (value: IAddSafraCliente) => {
    console.log(value, JSON.stringify(value));
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "safraCliente"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/safra_cliente/`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                console.log(response);
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "safraCliente", res.erro));
                } else {
                    dispatch(getSafraCliente());
                    dispatch(setStatusSuccess("add", "safraCliente"));
                    // message.success("Parâmetro adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "safraCliente", error.data));
            });
    };
};

interface IDeletSafraCliente {
    value: string;
}

export const deletarSafra = (value: IDeletSafraCliente) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "safraCliente"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/safra_cliente/` + value,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "safraCliente", res.erro));
                } else {
                    dispatch(getSafraCliente());
                    dispatch(setStatusSuccess("del", "safraCliente"));
                    message.success("Safra deletada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "safraCliente", error.data));
            });
    };
};
