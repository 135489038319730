import { Empty, Form, Select, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ativarProcess, desativarProcess, setFocus } from "../../store/actions";
import { setTalhao } from "../../store/fazendaActions";
import { v4 as uuidv4 } from "uuid";
import { IFazenda, ITalhao } from "../../reducers/FazendaReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { IStore } from "../../store";
import { IProcess, IProcessList } from "../../reducers/ProcessReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import { ITalhaoTablet } from "../../store/types";
import { difTalhoes } from "../../config/helpers";
import { IEquipamento } from "../../reducers/EquipamentoReducer";

interface TalhaoControllerProps {
    process: IProcess;
    fazenda: IFazenda;
    talhao: ITalhao;

    talhoesTablet: ITalhaoTablet[];
    talhoesFiltro: ITalhaoTablet[];

    setTalhao: Function;
    setFocus: Function;
    ativarProcess: Function;
    desativarProcess: Function;
    menuState: IMenu;
    processListState: IProcessList;
    dashboard: string;
    tema: ITheme;

    polylineFiltro: any;
    equipamento: IEquipamento;
}

const { Option } = Select;

function TalhaoController(props: TalhaoControllerProps) {
    const {
        process,
        fazenda,
        talhao,
        setTalhao,
        setFocus,
        ativarProcess,
        desativarProcess,
        menuState,
        processListState,
        dashboard,

        talhoesTablet,
        talhoesFiltro,

        equipamento,
        polylineFiltro,
    } = props;

    const [talhoesFiltroList, setTalhoesFiltroList] = useState([]);

    const [form] = Form.useForm();

    function handleChange(value: any) {
        if (value === "all") {
            if (polylineFiltro.length > 0) {
                setFocus({
                    view: equipamento.id_equipamento !== "" ? 5 : 7,
                    subView: null,
                });
            } else setFocus({ view: 1, subView: null });
        } else {
            setFocus({
                view: equipamento.id_equipamento !== "" ? 5 : 7,
                subView: null,
            });
            if (dashboard === "mapa") {
                if (equipamento.id_equipamento !== "") {
                } else
                    ativarProcess({
                        menuItem:
                            menuState[
                                menuState.findIndex((m: any) => m.id === 1)
                            ],
                        processList: processListState,
                    });
            }
        }
        setTalhao(value);
    }

    const listaTalhao = useCallback(() => {
        if (fazenda.shape !== "") {
            let features: GeoJSON.FeatureCollection = JSON.parse(fazenda.shape);
            return features.features.map((f: any) => {
                return f.properties;
            });
        }

        return [];
    }, [fazenda]);

    const listaTalhoes = useCallback(() => {
        if (talhoesFiltro.length > 0) {
            // let talhoesArr = listaTalhao().map((f: any) => {
            //     return {
            //         nome_talhao: f[JSON.parse(fazenda.campo).nome],
            //         id_talhao: f.id_agrochip,
            //     };
            // });

            // let i = new Set(talhoesF.map((t: any) => t.nome_talhao));

            var newArr = [
                ...talhoesFiltro,
                // ...talhoesArr.filter((tt: any) => !i.has(tt.nome_talhao)),
            ];

            if (talhoesFiltroList.length > 0) {
                newArr = [...newArr, ...talhoesFiltroList];
            }
            return newArr;
        }

        return [];
    }, [talhoesFiltro, talhoesFiltroList]);

    useEffect(() => {
        if (fazenda.shape !== "") {
            let geoTalhaoShp: GeoJSON.FeatureCollection = JSON.parse(
                fazenda.shape
            );
            let talhoesArr = [];
            if (talhoesFiltro.length > 0) {
                let tfiltro = difTalhoes(talhoesFiltro, geoTalhaoShp.features);
                if (tfiltro.length > 0) {
                    talhoesArr = tfiltro.map((f: any) => {
                        return {
                            nome_talhao:
                                f.properties[JSON.parse(fazenda.campo).nome],
                            id_talhao: f.properties.id_agrochip,
                        };
                    });
                }
            }

            setTalhoesFiltroList(talhoesArr);
            // return geoTalhaoShp.features.map((f: any) => {
            //     return f;
            // });
        }
    }, [fazenda, talhoesFiltro]);

    useEffect(() => {
        if (equipamento.id_equipamento !== "") {
        } else if (talhao !== "all" && talhao !== null && talhao !== "") {
            if (dashboard === "mapa") {
                ativarProcess({
                    menuItem:
                        menuState[menuState.findIndex((m: any) => m.id === 1)],
                    processList: processListState,
                });
            }
        }
    }, [talhao, equipamento.id_equipamento, dashboard]);

    useEffect(() => {
        form.setFieldsValue({ nome: talhao });
    }, [talhao]);

    useEffect(() => {
        form.setFieldsValue({ nome: "all" });
        desativarProcess(process.side);
    }, []);

    return (
        <Form
            form={form}
            layout="horizontal"
            // style={{
            //     padding: "4px",
            // }}
            initialValues={{
                nome: talhao,
            }}
            className="tour-talhao"
        >
            <Form.Item
                label={
                    <span
                        className={
                            props.tema ? "icon-filtros-dark" : "icon-filtros"
                        }
                    >
                        <Tooltip title="Talhão">
                            <i className="fas fa-layer-group"></i>
                        </Tooltip>
                    </span>
                }
                name="nome"
                colon={false}
            >
                <Select
                    style={{
                        width: 100,
                        color: props.tema ? "#fff" : "#606060",
                        border: "1px solid #C1C1C1",
                        backgroundColor: props.tema ? "#757575" : "",
                    }}
                    showSearch
                    disabled={fazenda.id_fazenda === ""}
                    size="small"
                    optionLabelProp="label"
                    bordered={false}
                    placeholder="Escolha"
                    optionFilterProp="children"
                    suffixIcon={<i className="fas fa-chevron-right"></i>}
                    dropdownRender={menu => <div>{menu}</div>}
                    notFoundContent={
                        <Empty
                            description="Nenhuma fazenda foi escolhida."
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    }
                    onChange={handleChange}
                    onSelect={handleChange}
                    dropdownClassName="agrochip-dropdown"
                    // dropdownStyle={{ zIndex: 1000 }}
                >
                    <Option value="all" label="Todos" key={uuidv4()}>
                        Todos
                    </Option>

                    {talhoesFiltro.length > 0
                        ? listaTalhoes().map((t: ITalhaoTablet) => {
                              return (
                                  <Option
                                      value={t.id_talhao}
                                      label={t.nome_talhao}
                                      key={t.id_talhao}
                                  >
                                      {t.nome_talhao}
                                  </Option>
                              );
                          })
                        : listaTalhao().map((t: any) => {
                              return (
                                  <Option
                                      value={t.id_agrochip}
                                      label={t[JSON.parse(fazenda.campo).nome]}
                                      key={t.id_agrochip}
                                  >
                                      {t[JSON.parse(fazenda.campo).nome]}
                                  </Option>
                              );
                          })}
                </Select>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        talhao: store.talhao,
        menuState: store.menu,
        processListState: store.processos,
        dashboard: store.dashboard,
        process: store.processo,

        talhoesTablet: store.talhoesTablet,
        talhoesFiltro: store.talhaoFiltro,

        polylineFiltro: store.polylineFiltro,
        equipamento: store.equipamento,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setTalhao,
            setFocus,
            ativarProcess,
            desativarProcess,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TalhaoController);
