import {
    PUSH_FILTRO_POLYLINE,
    PUSH_POLYLINE_RENDER,
    REM_FILTRO_POLYLINE,
    REM_POLYLINE_RENDER,
    SET_FILTRO_POLYLINE,
    SET_LIST_POLYLINE,
} from "../store/actionTypes";

export interface IPolylineModule {
    latitude: string;
    //id_operacao: string;
    longitude: string;
    // totComb: number;
    timestamp: Date;
    // odometro: number;
    // horimetro: number;
    // rpm: number;
    // velocidade: number;
    // alerta: boolean | null;
    // txtalerta: string | null;
    // vazaoComb: number;
    // tipo_operacao_parada_temp: number | null;
}

export type IPolylineList = IPolylineModule[];
const polylineList: IPolylineList = [];

export interface IPolylineReducer {
    type: any;
    payload: IPolylineList;
}
export const polylineListReducer = (
    state = polylineList,
    action: IPolylineReducer
) => {
    switch (action.type) {
        case SET_LIST_POLYLINE:
            return action.payload;
        default:
            return state;
    }
};

export type IPolylineFiltro = [];
const polylineFiltro: any = [];
export interface IPolylineFiltroAction {
    type: any;
    payload: string[];
}
export const polylineFiltroReducer = (state = polylineFiltro, action: any) => {
    switch (action.type) {
        case SET_FILTRO_POLYLINE:
            return action.payload;
        case PUSH_FILTRO_POLYLINE:
            var newState = state;
            const haReg = newState.findIndex(
                (r: any) => r.id === action.payload.id
            );
            if (haReg === -1) {
                // newState.forEach((m: any) => {
                //     if (typeof m.icon.setMap === "function")
                //         m.icon.setMap(null); //Se for Google Maps
                // });
                // newState = [];
                newState.push(action.payload);
            }

            return newState;
        case REM_FILTRO_POLYLINE:
            return [];
        default:
            return state;
    }
};

export type IPolylineRender = [];
const PolylineRender: any = [];
export interface IPolylineRenderAction {
    type: any;
    payload: string[];
}
export const polylineFiltroRenderReducer = (
    state = PolylineRender,
    action: any
) => {
    switch (action.type) {
        case PUSH_POLYLINE_RENDER:
            var newState = state;

            const haReg = newState.findIndex(
                (r: any) => r.id === action.payload.id
            );

            if (haReg === -1) {
                // newState.forEach((m: any) => {
                //     if (typeof m.polyline.setMap === "function")
                //         m.polyline.setMap(null); //Se for Google Maps
                // });
                //newState = [];
                newState.push(action.payload);
            }

            return newState;
        case REM_POLYLINE_RENDER:
            // if (state.length > 0) {

            state.forEach((c: any) => {
                if (typeof c.polyline.setMap === "function")
                    c.polyline.setMap(null);
            });
            // }
            return [];
        default:
            return state;
    }
};
