import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Form,
    Select,
    Button,
    message,
    DatePicker,
    Row,
    Col,
    notification,
} from "antd";
import { useEffect, useState } from "react";
import {
    getOperacao,
    setFilterOperacao,
} from "../../../../store/operacoesAction";
import { IStore } from "../../../../store";
import { IFazenda } from "../../../../reducers/FazendaReducer";
import { IEquipamentoList } from "../../../../reducers/EquipamentoReducer";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import {
    ISafraClienteList,
    ISafraList,
} from "../../../../reducers/SafraReducer";
import {
    ICulturaClienteList,
    ICulturaList,
} from "../../../../reducers/CulturaReducer";
import { ITime } from "../../../../reducers/TimeReducer";
import moment from "moment";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";

import { v4 as uuidv4 } from "uuid";
import { IOperadorList } from "../../../../reducers/OperadorReducer";
import { ITalhaoTablet } from "../../../../store/types";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

export interface IObjIds {
    faz: string;
    equip: string;
    tipoOp: string;
    safra: string;
    cultura: string;
}
export interface IFiltrosProps {
    faz: IFazenda;
    equipamentos: IEquipamentoList;
    tiposOperacao: ITipoOpList;
    safras: ISafraList;
    culturas: ICulturaList;
    time: ITime;

    operadores: IOperadorList;

    talhoes: ITalhaoTablet[];

    culturaCList: ICulturaClienteList;
    safraCList: ISafraClienteList;
    tipoOpCList: ITipoOpClienteList;

    getOperacao: Function;

    setModalFiltros: Function;
}
function Filtros(props: any) {
    const {
        equipamentos,
        tiposOperacao,
        safras,
        culturas,
        getOperacao,
        faz,
        fazendas,
        time,
        operadores,
        talhoes,

        culturaCList,
        safraCList,
        tipoOpCList,
    } = props;

    let abaAtualObj =
        props.abasList[
            props.abasList.findIndex((a: any) => a.key === props.abaAtiva)
        ].content.filtros;

    let tiposOp =
        abaAtualObj.operacoes.length > 0
            ? abaAtualObj.operacoes[0] !== ""
                ? abaAtualObj.operacoes.map((o: any) => ({
                      value: o,
                      label:
                          tiposOperacao.find(
                              (t: any) => o === t.id_tipo_operacao
                          )?.descricao || "Não Identificado",
                      key: o.toString(),
                  }))
                : abaAtualObj.operacoes
            : abaAtualObj.operacoes;
    let fazendasSele = abaAtualObj.fazendas;

    const [btnDesativado, setBtnDesativado] = useState(true);
    const [formDesativado, setFormDesativado] = useState(true);
    const [equip, setEquip] = useState(abaAtualObj.equipamento);

    const [sfr, setSfr] = useState(abaAtualObj.safra);
    const [cult, setCult] = useState(abaAtualObj.cultura);
    const [ope, setOpe] = useState(abaAtualObj.operador);
    const [talhao, setTalhao] = useState(abaAtualObj.talhao);
    const [datas, setDatas] = useState<any>([
        abaAtualObj.datas[0],
        abaAtualObj.datas[1],
    ]);
    const [haDesloc, setHaDesloc] = useState(false);

    const [opesLocal, setOpesLocal] = useState([]);
    const [disabledOps, setDisabledOps] = useState(false);
    const [fazLocal, setFazLocal] = useState<any>([]);
    const [disabledFaz, setDisabledFaz] = useState(false);

    const [filterTalhoes, setFilterTalhoes] = useState(talhoes);

    let tzoffset = new Date().getTimezoneOffset() * 60000;

    const [form] = Form.useForm();

    const filteredCultOptions = culturas.filter((o: any) =>
        culturaCList.some((s: any) => s.cultura_temp === o.id_cultura)
    );
    const filteredSfrOptions = safras.filter((o: any) =>
        safraCList.some((s: any) => s.safra_temp === o.id_safra)
    );

    const filteredOptions = tiposOperacao.filter((o: any) =>
        tipoOpCList.some((s: any) => {
            if (
                (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) ||
                (o.id_tipo_operacao >= 900 && o.id_tipo_operacao <= 999)
            ) {
                if (s.tipo_operacao_temp === o.id_tipo_operacao) {
                    return (
                        opesLocal.findIndex(
                            (i: any) => i.value === o.id_tipo_operacao
                        ) === -1
                    );
                }
            }
        })
    );

    useEffect(() => {
        if (tiposOp.length > 0) {
            if (tiposOp[0] === "") {
                setDisabledOps(true);
            }
        }

        if (fazendasSele.length > 0) {
            if (fazendasSele[0] === "") {
                setDisabledFaz(true);
            }
        }
    }, []);

    useEffect(() => {}, [fazLocal, fazendasSele, disabledFaz, opesLocal]);

    useEffect(() => {
        if (abaAtualObj.operacoes.length > 0) {
            var array: any = [];
            abaAtualObj.operacoes.map((o: any) => {
                if (o === 0 || o === "") {
                } else {
                    return array.push({
                        value: o,
                        label: tiposOperacao[
                            tiposOperacao.findIndex(
                                (t: any) => o === t.id_tipo_operacao
                            )
                        ].descricao,
                        key: o.toString(),
                    });
                }
            });
            setOpesLocal(array);
        } else setOpesLocal(abaAtualObj.operacoes);
    }, []);

    useEffect(() => {
        if (abaAtualObj.fazendas.length > 0) {
            var array: any = [];
            abaAtualObj.fazendas.map((f: any) => {
                if (f !== "") {
                    return array.push({
                        value: f,
                        key: f.toString(),
                    });
                }
            });
            setFazLocal(array);
        } else setFazLocal(abaAtualObj.fazendas);
    }, []);

    useEffect(() => {
        if (filteredOptions.length > 0) {
            filteredOptions.forEach((o: any) => {
                if (o.id_tipo_operacao >= 900 && o.id_tipo_operacao <= 999) {
                    setHaDesloc(true);
                }
            });
        }
    }, [filteredOptions, tipoOpCList]);

    useEffect(() => {
        datas.forEach((d: any) => {
            if (datas.toString().length < 39 || d === "" || d === undefined) {
                setFormDesativado(true);
                setBtnDesativado(true);
            } else {
                setFormDesativado(false);
                setBtnDesativado(false);
            }
        });
    }, [datas]);

    useEffect(() => {
        let filteredTal: any = [];
        if (fazLocal.length > 0) {
            fazLocal.map((f: any) => {
                talhoes.filter((t: any) => {
                    if (t.fazenda_temp === f.key) {
                        return filteredTal.push(t);
                    }
                });
            });
        } else {
            filteredTal = talhoes;
        }
        setFilterTalhoes(filteredTal);
    }, [fazLocal, talhao, talhoes]);

    return (
        <>
            <Form
                form={form}
                onFinish={v => {
                    // if (fazSele !== "") {
                    let arrOp = v.TipoOpForm.map((o: any) => {
                        if (o.value !== undefined) {
                            return o.value;
                        } else return o;
                    });
                    let arrFaz = v.FazendaForm.map((o: any) => {
                        if (o.value !== undefined) {
                            return o.value;
                        } else return o;
                    });
                    let newArray = JSON.parse(JSON.stringify(props.abasList));
                    let abaKey = newArray.findIndex((a: any) => {
                        return a.key === props.abaAtiva;
                    });
                    newArray[abaKey].content["filtros"] = {
                        datas: datas,
                        fazendas: arrFaz,
                        equipamento: equip,
                        operacoes: arrOp,
                        safra: sfr,
                        cultura: cult,
                        operador: ope,
                        talhao: talhao,
                    };
                    newArray[abaKey].content["paginacao"] = {
                        pagina: 0,
                        size: 10,
                    };
                    let faz: any;
                    if (fazLocal.length > 0) {
                        faz =
                            " | " +
                            fazLocal.map((n: any, index: any) => {
                                return (
                                    (index ? " " : "") +
                                    fazendas[
                                        fazendas.findIndex(
                                            (f: any) => f.id_fazenda === n.value
                                        )
                                    ].nome
                                );
                            });
                    } else faz = "";
                    newArray[abaKey].title =
                        new Date(v.periodo[0] - tzoffset)
                            .toISOString()
                            .split("T")[0] +
                        " - " +
                        new Date(v.periodo[1] - tzoffset)
                            .toISOString()
                            .split("T")[0] +
                        faz;
                    props.setAbasList(newArray);
                    props.setVisivel(false);
                    // }
                }}
                layout="vertical"
                initialValues={{
                    periodo:
                        datas.toString().length === 25 || datas[0] === undefined
                            ? ["", ""]
                            : [
                                  moment(datas[0], "YYYY-MM-DDTHH:mm:ss"),
                                  moment(datas[1], "YYYY-MM-DDTHH:mm:ss"),
                              ],
                    FazendaForm: fazendasSele,
                    EquipForm: equip,
                    TipoOpForm: tiposOp,
                    SafraForm: sfr,
                    CulturaForm: cult,
                    OperadorForm: ope,
                    TalhaoForm: talhao,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            label="Periodo:"
                            name="periodo"
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione uma data",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <RangePicker
                                locale={locale}
                                disabledDate={current => {
                                    // Can not select days after today and before start Date
                                    const start = moment(
                                        "2020-01-01",
                                        "YYYY-MM-DD"
                                    );
                                    return (
                                        current < start ||
                                        current > moment().endOf("day")
                                    );
                                }}
                                popupStyle={{ zIndex: 1090 }}
                                format="DD/MM/yyyy HH:mm:ss"
                                onChange={(val: any, val2: any) => {
                                    let datas: any = [];

                                    if (val2[0] !== "" && val2[1] !== "") {
                                        val2.map((v: any) => {
                                            const [dateString, timeString] =
                                                v.split(" ");
                                            const [day, month, year] =
                                                dateString.split("/");
                                            const [hour, minute] =
                                                timeString.split(":");

                                            const dateObj: any = new Date(
                                                +year,
                                                +month - 1,
                                                +day,
                                                +hour,
                                                +minute
                                            );
                                            datas.push(
                                                new Date(dateObj - tzoffset)
                                                    .toISOString()
                                                    .split(".")[0]
                                            );
                                        });
                                    }

                                    setDatas(datas);
                                }}
                                style={{
                                    color: "black",
                                    width: "100%",
                                }}
                                showTime={{
                                    hideDisabledOptions: true,
                                    defaultValue: [
                                        moment("00:00:00", "HH:mm:ss"),
                                        moment("23:59:59", "HH:mm:ss"),
                                    ],
                                }}
                                ranges={{
                                    Hoje: [moment().startOf("day"), moment()],
                                    Ontem: [
                                        moment()
                                            .subtract(1, "days")
                                            .startOf("day"),
                                        moment()
                                            .subtract(1, "days")
                                            .endOf("day"),
                                    ],
                                    "Última semana": [
                                        moment()
                                            .subtract(6, "days")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                    "Último mês": [
                                        moment()
                                            .subtract(1, "months")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                }}
                                placeholder={["Data inicial", "Data final"]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Fazenda:"
                            name="FazendaForm"
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione ao menos uma opção",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={formDesativado}
                                mode="multiple"
                                allowClear={false}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(e: any) => {
                                    setFazLocal(e);
                                }}
                                // onSelect={(value: any) => {
                                //     setFazSele(value);
                                // }}
                                value={fazLocal}
                                labelInValue={true}
                                onSelect={(value: any) => {
                                    if (value.value === "") {
                                        setFazLocal([]);
                                        setDisabledFaz(true);
                                    }
                                }}
                                onDeselect={(value: any) => {
                                    form.setFieldsValue({ TalhaoForm: "" });
                                    if (value.value === "") {
                                        setDisabledFaz(false);
                                    }
                                }}
                                placeholder="Selecione"
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                    disabled={fazLocal.length > 0}
                                >
                                    Sem filtro
                                </Option>
                                {fazendas.map((f: any) => {
                                    return (
                                        <Option
                                            disabled={disabledFaz}
                                            key={f.id_fazenda}
                                            value={f.id_fazenda}
                                        >
                                            {f.nome}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Tipo de Operação:"
                            name="TipoOpForm"
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione ao menos uma opção",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={formDesativado}
                                mode="multiple"
                                allowClear={false}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) => {
                                    let condicao = false;
                                    if (option.value !== 0 && option.children) {
                                        return option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    } else if (option.label) {
                                        return option.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    }

                                    return condicao;
                                }}
                                value={opesLocal}
                                labelInValue={true}
                                onChange={(e: any) => {
                                    setOpesLocal(e);
                                }}
                                onSelect={(value: any, option: any) => {
                                    if (value.value === "") {
                                        setOpesLocal([]);
                                        setDisabledOps(true);
                                    }
                                }}
                                onDeselect={(value: any, option: any) => {
                                    if (value.value === "") {
                                        setDisabledOps(false);
                                    }
                                }}
                                placeholder="Selecione"
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                    disabled={opesLocal.length > 0}
                                >
                                    Sem filtro
                                </Option>
                                {tipoOpCList.length > 0 ? (
                                    <>
                                        <OptGroup label="Tipo Operação">
                                            {filteredOptions.map(
                                                (e: any) =>
                                                    e.id_tipo_operacao > 0 &&
                                                    e.id_tipo_operacao <
                                                        800 && (
                                                        <Option
                                                            disabled={
                                                                disabledOps
                                                            }
                                                            key={
                                                                e.id_tipo_operacao
                                                            }
                                                            value={
                                                                e.id_tipo_operacao
                                                            }
                                                            label={e.descricao}
                                                        >
                                                            {e.descricao}
                                                        </Option>
                                                    )
                                            )}
                                        </OptGroup>
                                        {haDesloc && (
                                            <OptGroup label="Tipo Deslocamento">
                                                {filteredOptions.map(
                                                    (e: any) =>
                                                        e.id_tipo_operacao >=
                                                            900 &&
                                                        e.id_tipo_operacao <=
                                                            999 && (
                                                            <Option
                                                                disabled={
                                                                    disabledOps
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                label={
                                                                    e.descricao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )
                                                )}
                                            </OptGroup>
                                        )}
                                    </>
                                ) : (
                                    <Option disabled>
                                        <div
                                            style={{
                                                display: "flex",

                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                Não há nenhuma op. cadastrada.
                                            </div>
                                        </div>
                                    </Option>
                                )}
                                <Option
                                    value={0}
                                    label="Não Identificado"
                                    key={uuidv4()}
                                    disabled={disabledOps}
                                >
                                    Não Identificado
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "stretch",
                        width: "100%",
                        columnGap: 20,
                    }}
                >
                    <div></div>
                    <div style={{ width: "50%" }}></div>
                    <div style={{ width: "50%" }}></div>
                </div>

                <Row gutter={[24, 0]}>
                    <Col span={6}>
                        <Form.Item label="Operador:" name="OperadorForm">
                            <Select
                                disabled={formDesativado}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value: any) => {
                                    setOpe(value);
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {operadores.map((e: any) => {
                                    return (
                                        <Option value={e.id_operador}>
                                            {e.nome}
                                        </Option>
                                    );
                                })}
                                <Option
                                    value={0}
                                    key={uuidv4()}
                                    label="Não Identificado"
                                >
                                    Não Identificado
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>{" "}
                    <Col span={6}>
                        <Form.Item label="Equipamento:" name="EquipForm">
                            <Select
                                disabled={formDesativado}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) => {
                                    let equipaNome = "";
                                    if (typeof option.children === "string") {
                                        equipaNome = option.children;
                                    } else {
                                        equipaNome = option.children.join();
                                    }
                                    return (
                                        equipaNome
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onSelect={(value: any) => {
                                    setEquip(value);
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {equipamentos.map((e: any) => {
                                    return (
                                        <Option value={e.id_equipamento}>
                                            {e.numero_patrimonio +
                                                " - " +
                                                e.descricao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        {" "}
                        <Form.Item label="Safra:" name="SafraForm">
                            <Select
                                disabled={formDesativado}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value: any) => {
                                    setSfr(value);
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {filteredSfrOptions.map((e: any) => {
                                    return (
                                        <Option value={e.id_safra}>
                                            {e.descricao}
                                        </Option>
                                    );
                                })}
                                <Option
                                    value={0}
                                    key={uuidv4()}
                                    label="Não Identificado"
                                >
                                    Não Identificado
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        {" "}
                        <Form.Item label="Cultura:" name="CulturaForm">
                            <Select
                                disabled={formDesativado}
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value: any) => {
                                    setCult(value);
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {filteredCultOptions.map((e: any) => {
                                    return (
                                        <Option value={e.id_cultura}>
                                            {e.nomeCultura}
                                        </Option>
                                    );
                                })}
                                <Option
                                    value={0}
                                    key={uuidv4()}
                                    label="Não Identificado"
                                >
                                    Não Identificado
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        {" "}
                        <Form.Item label="Talhão:" name="TalhaoForm">
                            <Select
                                disabled={
                                    formDesativado ||
                                    (disabledFaz === false &&
                                        fazLocal.length === 0)
                                }
                                dropdownStyle={{ zIndex: 1090 }}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value: any) => {
                                    setTalhao(value);
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {filterTalhoes.map((e: any) => {
                                    return (
                                        <Option value={e.id_talhao}>
                                            {e.nome_talhao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <Button
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={() => {
                            props.setVisivel(false);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={btnDesativado}
                    >
                        Aplicar
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        faz: store.fazenda,
        equipamentos: store.equipamentos,
        tiposOperacao: store.tiposOp,
        safras: store.safras,
        culturas: store.culturas,
        time: store.time,
        operadores: store.operadores,

        talhoes: store.talhoesTablet,

        culturaCList: store.culturasCliente,
        safraCList: store.safrasCliente,
        tipoOpCList: store.tiposOpCliente,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ getOperacao }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
