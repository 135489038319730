import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { keyframes } from "styled-components";
import { loginProcess } from "./store/actions";
import { Input, Button, message, Form, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { AuthState, IStatusList } from "./store/types";
import { IStore } from "./store";

const inicial = keyframes`
    0% {
        width: 300px;
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        width: 500px;
    }
`;

const form = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    & img {
        width: 500px;
        animation: ${inicial} 1.5s linear 1;
        opacity: 0;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    animation: ${form} 0.3s linear 1;
    background: url("${process.env.REACT_APP_BASE_URL_ROOT}fundologin.png");
    background-size: cover;

    & .form-cont {
        position: relative;
        width: 400px;
        height: 250px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ccc;
        background-color: rgba(249, 249, 249, 0.5);
        border-radius: 10px;
        transition: border 0.3s linear;
    }
    & .form-cont:hover {
        border: 1px solid #999;
        transition: border 0.3s linear;
    }

    & .form-cont img {
        width: 75%;
    }

    & .form-cont .form-login {
        display: flex;
        padding: 20px;
        margin-top: -10px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        row-gap: 15px;
    }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface LoadingProps {
    active: boolean;
}

const Loading = styled.div<LoadingProps>`
    display: ${props => (props.active ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    position: absolute;
    background: transparent;
    backdrop-filter: blur(3px);
    left: 10;
    bottom: 0;
    margin-bottom: 10px;
    width: 90%;
    height: 72%;
    z-index: 2;

    & i {
        font-size: 50px;
        animation: ${rotate} 1.5s linear infinite;
    }
`;

interface LoginProps {
    loginProcess: any;
    auth: AuthState;
    loader: IStatusList;
}

function Login(props: LoginProps) {
    const [anima, setAnima] = useState(true);
    const [user, setUser] = useState("");
    const [senha, setSenha] = useState("");

    const { loginProcess, auth, loader } = props;

    function handleUser(e: any) {
        setUser(e.target.value);
    }
    function handleSenha(e: any) {
        setSenha(e.target.value);
    }
    function handleEnter(e: any) {
        if (e.keyCode !== 13) return false;
        loginProcess({
            email: user,
            pass: senha,
        });
    }

    useEffect(() => {}, []);

    useEffect(() => {
        if (loader.auth?.get?.error !== "") {
            message.error(loader.auth?.get?.error);
        }
    }, [loader.auth?.get?.error]);

    if (anima) {
        setTimeout(() => {
            setAnima(false);
        }, 1500);
        return (
            <Logo>
                <img
                    src={
                        process.env.REACT_APP_BASE_URL_ROOT +
                        "logo_completo.svg"
                    }
                    alt="Logo da Agrochip"
                />
            </Logo>
        );
    } else {
        return (
            <Container>
                <div className="form-cont">
                    <img
                        src={
                            process.env.REACT_APP_BASE_URL_ROOT +
                            "logo_completo.svg"
                        }
                        alt="Logo da Agrochip"
                    />
                    {/* <div className="form-login" onKeyDown={handleEnter}> */}
                    <Loading active={auth.loading}>
                        <i className="fas fa-spinner"></i>
                    </Loading>
                    <Form
                        layout="vertical"
                        className="ant-form-login"
                        style={{ marginTop: "20px", width: "100%" }}
                        onFinish={(v: any) => {
                            loginProcess({
                                email: v.username,
                                pass: v.password,
                            });
                        }}
                        autoComplete="on"
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    name="username"
                                    style={{
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Input
                                        prefix={
                                            <UserOutlined className="site-form-item-icon" />
                                        }
                                        size="large"
                                        placeholder="Nome de usuário"
                                        value={user}
                                        onChange={handleUser}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <Form.Item name="password">
                                    <Input.Password
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        size="large"
                                        placeholder="Senha"
                                        value={senha}
                                        onChange={handleSenha}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        block
                                    >
                                        Entrar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {/* </div> */}
                </div>
            </Container>
        );
    }
}

function mapStateToProps(store: IStore) {
    return {
        auth: store.auth,
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            loginProcess,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
