import {
    Input,
    Form,
    Upload,
    Button,
    message,
    Col,
    Row,
    Select,
    Popover,
    InputNumber,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { CirclePicker } from "react-color";
import { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFazenda, editarFazenda } from "../../../../store/fazendaActions";
import { setStatusNull } from "../../../../store/actions";
import { v4 as uuidv4 } from "uuid";
import shapeConvert from "../../../../config/shapeConvert";
import Fazendas from "../Fazendas";
import {
    fazendaListReducer,
    fusoList,
    IFazenda,
    IFazendaList,
    IFazendaModule,
} from "../../../../reducers/FazendaReducer";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";

const { Option } = Select;

interface EditarFazendaProps {
    status: IStatusList;
    fazendas: IFazendaList;
    fazenda: IFazenda;
    editarFazenda: Function;
    setFazenda: Function;
    setStatusNull: Function;

    cont: any;
    setModal: any;
}

function EditarFazenda(props: EditarFazendaProps) {
    const {
        status,
        fazendas,
        fazenda,
        editarFazenda,
        setFazenda,
        setStatusNull,
    } = props;
    const colorList = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#FFFFFF",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#000000",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
        "#858585",
    ];

    const icons = [
        "fas fa-apple-alt",
        "fas fa-adjust",
        "fas fa-arrow-alt-circle-up",
        "fas fa-award",
        "fas fa-atom",
        "fas fa-baseball-ball",
        "fas fa-bezier-curve",
        "fas fa-bomb",
        "fas fa-bookmark",
        "fas fa-brain",
        "fas fa-carrot",
        "fas fa-check-circle",
        "fas fa-chess-queen",
        "fas fa-chess-knight",
        "fas fa-cloud-rain",
        "fas fa-crown",
        "fas fa-dharmachakra",
        "fas fa-hourglass",
    ];

    const style = JSON.parse(props.cont.estilo);

    const [nome, setNome] = useState(props.cont.nome);
    const [campo, setCampo] = useState("");
    const [size, setSize] = useState("");
    const [shape, setShape] = useState("");
    const [displayCP, setDisplayCP] = useState(false);
    const [corP, setCorP] = useState(style.fill.color);
    const [corB, setCorB] = useState(style.stroke.color);
    const [opaP, setOpaP] = useState(style.fill.opacity);
    const [opaB, setOpaB] = useState(style.stroke.opacity);
    const [larB, setLarB] = useState(style.stroke.weight);
    const [icone, setIcone] = useState(props.cont.icone);
    const [coord, setCoord] = useState(null);
    const [fName, setFName] = useState(null);

    const [fuso, setFuso] = useState(props.cont.fuso);

    const [localList, setLocalList] = useState<any>([]);
    const [local, setLocal] = useState(props.cont.cidade);

    function handleNome(e: any) {
        setNome(e.target.value);
    }

    const validaForm = useCallback(() => {
        if (!shape) {
            if (nome === "") return false;
        } else {
            if (nome === "") return false;
            if (campo === "") return false;
            if (size === "") return false;
            if (fuso === "") return false;
        }

        return true;
    }, [nome, campo, size, shape, fuso]);

    function handleClick() {
        setDisplayCP(!displayCP);
    }

    function handleClose() {
        setDisplayCP(false);
    }

    function handleChangeCorP(color: any) {
        setCorP(color.hex);
    }

    function handleChangeCorB(color: any) {
        setCorB(color.hex);
    }

    function handleOpaP(value: any) {
        setOpaP(value);
    }

    function handleOpaB(value: any) {
        setOpaB(value);
    }

    function handleLarB(value: any) {
        setLarB(value);
    }

    function onSelectIcone(value: any) {
        setIcone(value);
    }

    function onSelectField(value: any) {
        setCampo(value);
    }

    function onSelectSize(value: any) {
        setSize(value);
    }

    function editFazenda() {
        if (!validaForm()) {
            message.warning("É necessário preencher todos os campos.");
            return false;
        }

        let obj = null;

        let estilo = {
            fill: {
                color: corP,
                opacity: opaP,
            },
            stroke: {
                color: corB,
                opacity: opaB,
                weight: larB,
            },
        };

        if (!shape) {
            obj = {
                id_fazenda: props.cont.id_fazenda,
                nome: nome,
                shape: props.cont.shape,
                campo: props.cont.campo,
                coordenadas: props.cont.coordenadas,
                estilo: JSON.stringify(estilo),
                icone: icone,
                fuso: fuso,
                cidade: local,
                cod_erp: props.cont.cod_erp,
            };
        } else {
            obj = {
                id_fazenda: props.cont.id_fazenda,
                nome: nome,
                shape: shape,
                campo: JSON.stringify({ nome: campo, size: size }),
                coordenadas: coord,
                estilo: JSON.stringify(estilo),
                icone: icone,
                fuso: fuso,
                cidade: local,
                cod_erp: props.cont.cod_erp,
            };
        }

        //

        editarFazenda(obj);
    }

    const { Dragger } = Upload;

    const propss = {
        accept: ".zip",
        showUploadList: false,
        beforeUpload(file: any) {
            shapeConvert(file, setShape, setCoord, setFName);

            return false;
        },
    };

    useEffect(() => {
        if (status.fazenda?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "fazenda");
        }
    }, [status.fazenda?.edit?.success]);

    useEffect(() => {
        const fetchLocal = async () => {
            const res = await fetch(
                "https://servicodados.ibge.gov.br/api/v1/localidades/distritos?orderBy=nome"
            );
            let resJSON = JSON.parse(await res.text());
            setLocalList(resJSON);
        };
        fetchLocal();
    }, []);

    return (
        <>
            <Form
                layout="vertical"
                initialValues={{
                    ...props.cont,
                    nome_edit: props.cont.nome,
                    icone: props.cont.icone,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            label="Nome da Fazenda:"
                            name="nome_edit"
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input
                                placeholder="Digite o nome da fazenda"
                                value={nome}
                                onChange={handleNome}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Icone:" name="icone">
                            <Select
                                placeholder="custom"
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="icon-select"
                                onSelect={onSelectIcone}
                            >
                                {icons.map((i, k) => {
                                    return (
                                        <Option
                                            className="icon-picker-item"
                                            key={k}
                                            value={i}
                                        >
                                            <i className={i}></i>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Preenchimento:" name="cor">
                            <Popover
                                placement="bottomRight"
                                overlayStyle={{
                                    width: "200px",
                                }}
                                title="Preenchimento:"
                                content={
                                    <div>
                                        <Form layout="vertical">
                                            <Row gutter={[24, 0]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Cor:"
                                                        name="cor_preench"
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    padding:
                                                                        "3px",
                                                                    background:
                                                                        "#fff",
                                                                    borderRadius:
                                                                        "1px",
                                                                    boxShadow:
                                                                        "0 0 0 1px #D9D9D9",
                                                                    display:
                                                                        "inline-block",
                                                                    cursor: "pointer",
                                                                    width: "100%",
                                                                }}
                                                                onClick={
                                                                    handleClick
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "25px",
                                                                        borderRadius:
                                                                            "5px",
                                                                        background:
                                                                            corP,
                                                                    }}
                                                                />
                                                            </div>
                                                            {displayCP ? (
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            "absolute",
                                                                        right: "0",
                                                                        zIndex: "2",
                                                                        backgroundColor:
                                                                            "#FFF",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding:
                                                                            "10px",
                                                                        boxShadow:
                                                                            "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                "fixed",
                                                                            top: "0px",
                                                                            right: "0px",
                                                                            bottom: "0px",
                                                                            left: "0px",
                                                                        }}
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    />
                                                                    <CirclePicker
                                                                        colors={
                                                                            colorList
                                                                        }
                                                                        width="300px"
                                                                        color={
                                                                            corP
                                                                        }
                                                                        onChange={
                                                                            handleChangeCorP
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Opacidade:"
                                                        name="opacidade_preench"
                                                    >
                                                        <InputNumber
                                                            formatter={value =>
                                                                `${value}%`
                                                            }
                                                            defaultValue={
                                                                style.fill
                                                                    .opacity
                                                            }
                                                            min={0}
                                                            max={100}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            onChange={
                                                                handleOpaP
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                }
                                trigger="click"
                            >
                                <Button block>Editar</Button>
                            </Popover>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Bordas:" name="cor">
                            <Popover
                                placement="bottomRight"
                                overlayStyle={{
                                    width: "300px",
                                }}
                                title="Bordas:"
                                content={
                                    <div>
                                        <Form layout="vertical">
                                            <Row gutter={[24, 0]}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Cor:"
                                                        name="cor_borda"
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    padding:
                                                                        "3px",
                                                                    background:
                                                                        "#fff",
                                                                    borderRadius:
                                                                        "1px",
                                                                    boxShadow:
                                                                        "0 0 0 1px #D9D9D9",
                                                                    display:
                                                                        "inline-block",
                                                                    cursor: "pointer",
                                                                    width: "100%",
                                                                }}
                                                                onClick={
                                                                    handleClick
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "25px",
                                                                        borderRadius:
                                                                            "5px",
                                                                        background:
                                                                            corB,
                                                                    }}
                                                                />
                                                            </div>
                                                            {displayCP ? (
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            "absolute",
                                                                        right: "0",
                                                                        zIndex: "2",
                                                                        backgroundColor:
                                                                            "#FFF",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding:
                                                                            "10px",
                                                                        boxShadow:
                                                                            "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                "fixed",
                                                                            top: "0px",
                                                                            right: "0px",
                                                                            bottom: "0px",
                                                                            left: "0px",
                                                                        }}
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    />
                                                                    <CirclePicker
                                                                        colors={
                                                                            colorList
                                                                        }
                                                                        width="300px"
                                                                        color={
                                                                            corB
                                                                        }
                                                                        onChange={
                                                                            handleChangeCorB
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Opacidade:"
                                                        name="opacidade_borda"
                                                    >
                                                        <InputNumber
                                                            formatter={value =>
                                                                `${value}%`
                                                            }
                                                            defaultValue={
                                                                style.stroke
                                                                    .opacity
                                                            }
                                                            min={0}
                                                            max={100}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            onChange={
                                                                handleOpaB
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Largura:"
                                                        name="largura_preench"
                                                    >
                                                        <InputNumber
                                                            defaultValue={
                                                                style.stroke
                                                                    .weight
                                                            }
                                                            min={0}
                                                            max={100}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            onChange={
                                                                handleLarB
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                }
                                trigger="click"
                            >
                                <Button block>Editar</Button>
                            </Popover>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um fuso",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fuso horário:"
                            name="fuso"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Selecione um fuso..."
                                onSelect={(e: any) => setFuso(e)}
                            >
                                {fusoList.map((t: any) => {
                                    return (
                                        <Option key={uuidv4()} value={t.value}>
                                            {t.displayName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um município",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Município:"
                            name="cidade"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Município"
                                onSelect={(e: any) => setLocal(e)}
                            >
                                {localList.length > 0 &&
                                    localList.map((local: any) => {
                                        return (
                                            <Option
                                                key={local.id}
                                                value={
                                                    local.nome +
                                                    " - " +
                                                    local.municipio.microrregiao
                                                        .mesorregiao.UF.sigla
                                                }
                                            >
                                                {local.nome +
                                                    " - " +
                                                    local.municipio.microrregiao
                                                        .mesorregiao.UF.sigla}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {!shape ? (
                    <Dragger {...propss}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Clique aqui ou arraste um arquivo para substituir o
                            shapefile existente
                        </p>
                        <p className="ant-upload-hint">
                            É necessário que o arquivo esteja no formato *.zip.
                        </p>
                    </Dragger>
                ) : (
                    <div
                        style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #d9d9d9",
                            padding: "20px",
                        }}
                    >
                        <div
                            style={{
                                borderBottom: "1px solid #D4D4D4",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <p style={{ float: "right" }}>
                                <Button onClick={() => setShape("")}>
                                    Cancelar envio
                                </Button>
                            </p>
                            <p style={{}}>
                                <strong>Nome descrito no Shapefile:</strong>{" "}
                                {fName}
                            </p>
                        </div>
                        <div>
                            <p style={{ fontWeight: "bold" }}>
                                Dentre as propriedades do arquivo, relacione com
                                a informação referente:
                            </p>
                            <Row gutter={[24, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Selecione um campo",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject();
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        label={
                                            <span
                                                style={{
                                                    color: "rgba(0,0,0,1)",
                                                    paddingLeft: "2px",
                                                }}
                                            >
                                                Propriedade referente ao talhão:
                                            </span>
                                        }
                                        name="talhao"
                                        colon={false}
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="Selecione um dos campos..."
                                            optionFilterProp="children"
                                            onSelect={onSelectField}
                                            filterOption={(
                                                input,
                                                option: any
                                            ) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {Object.keys(
                                                JSON.parse(shape).features[0]
                                                    .properties
                                            ).map(p => {
                                                return (
                                                    <Option
                                                        key={uuidv4()}
                                                        value={p}
                                                    >
                                                        {p}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Selecione um campo",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject();
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        label={
                                            <span
                                                style={{
                                                    color: "rgba(0,0,0,1)",
                                                    paddingLeft: "2px",
                                                }}
                                            >
                                                Propriedade referente ao
                                                tamanho:
                                            </span>
                                        }
                                        name="size"
                                        colon={false}
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="Selecione um dos campos..."
                                            optionFilterProp="children"
                                            onSelect={onSelectSize}
                                            filterOption={(
                                                input,
                                                option: any
                                            ) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {Object.keys(
                                                JSON.parse(shape).features[0]
                                                    .properties
                                            ).map(p => {
                                                return (
                                                    <Option
                                                        key={uuidv4()}
                                                        value={p}
                                                    >
                                                        {p}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        block
                        disabled={!validaForm()}
                        onClick={() => editFazenda()}
                        loading={status.fazenda?.edit?.loading}
                    >
                        Salvar fazenda
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazenda: store.fazenda,
        fazendas: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setFazenda,
            editarFazenda,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarFazenda);
