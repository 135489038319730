/*
const Process = [
    {
        id: número para incremento
        exe: componente responsável
        tipo: "side" para execução na DIV a direita
              "modal" para execução em um Modal
    }
]
*/

import Configuracoes from "../modules/process/Configuracoes";
import Equipamento from "../modules/process/Equipamento";
import {
    SET_PROCESS,
    DESATIVAR_PROCESS,
    SET_DASHBOARD_TOGGLE,
} from "../store/actionTypes";
import Relatorios from "../modules/process/Relatorios";
import Talhao from "../modules/process/Talhao";
// import Talhao from "../modules/process/Talhao";
// import Equipamento from "../modules/process/Equipamento";
// import Configuracoes from "../modules/process/Configuracoes";
import Operacoes from "../modules/process/Configuracoes/Operacoes";
import Operacional from "../modules/process/Configuracoes/Operacional";

export interface IProcess {
    side?: IProcessModule;
    modal?: IProcessModule;
    full?: IProcessModule;
}

export interface IProcessModule {
    id: number;
    nome: string;
    exe: any;
    tipo: string;
    ativo: boolean;
    w?: string;
    h?: string;
    title?: string | React.FC;
}

export type IProcessList = IProcessModule[];

export interface IProcessAction {
    type: any;
    payload: IProcessModule;
}

const Process: IProcess = {
    side: {
        id: 1,
        nome: "Equipamentos",
        exe: () => "",
        //exe: <Relatorios />,
        tipo: "side",
        ativo: false,
    },
    modal: {
        id: 1,
        nome: "Configurações",
        exe: () => "",
        // exe: <Configuracoes />,
        tipo: "modal",
        ativo: false,
    },
    full: {
        id: 1,
        nome: "",
        exe: () => "",
        tipo: "full",
        ativo: false,
    },
};

const ProcessList = [
    {
        id: 1,
        nome: "Talhões",
        exe: <Talhao />,
        tipo: "side",
        ativo: false,
    },
    {
        id: 2,
        nome: "Equipamentos",
        exe: <Equipamento />,
        tipo: "side",
        ativo: false,
    },
    {
        id: 3,
        nome: "Relatórios",
        exe: <Relatorios />,
        tipo: "full",
        ativo: false,
    },
    {
        id: 4,
        nome: "Configurações",
        exe: <Configuracoes />,
        tipo: "modal",
        ativo: false,
    },
    {
        id: 5,
        nome: "Operações",
        exe: (x: any, y: any, z: any, a: any, b: any, c: any) => {
            return (
                <Operacoes
                    modalFiltros={x}
                    setModalFiltros={y}
                    whichModal={z}
                    setWhichModal={a}
                />
            );
        },
        // exe: (x: any, y: any, z: any, a: any) => {
        //     return <div>opa</div>;
        // },
        tipo: "modal",
        ativo: false,
        w: "100%",
        h: "calc(100vh - 100px)",
        title: <div>Teste</div>,
    },
    {
        id: 6,
        nome: "Operacional",
        exe: <Operacional />,
        tipo: "modal",
        ativo: false,
        w: "100%",
        h: "calc(100vh - 100px)",
    },
];

export const processReducer = (state = Process, action: IProcessAction) => {
    switch (action.type) {
        case SET_PROCESS:
            if (action.payload.tipo === "side") {
                return {
                    side: {
                        ...action.payload,
                        ativo: true,
                    },
                    modal: state.modal,
                };
            } else if (action.payload.tipo === "modal") {
                return {
                    side: state.side,
                    modal: {
                        ...action.payload,
                        ativo: true,
                    },
                };
            }
            return state;
        case DESATIVAR_PROCESS:
            if (action.payload.tipo === "side") {
                return {
                    side: {
                        ...action.payload,
                        ativo: false,
                    },
                    modal: state.modal,
                };
            } else if (action.payload.tipo === "modal") {
                return {
                    side: state.side,
                    modal: {
                        ...action.payload,
                        ativo: false,
                    },
                };
            }
            return state;
        default:
            return state;
    }
};

export const processListReducer = (state = ProcessList, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};

const dashOption = "mapa";
export interface dashboardToggleChange {
    type: any;
    payload: string;
}

export const dashboardToggleReducer = (
    state = dashOption,
    action: dashboardToggleChange
) => {
    switch (action.type) {
        case SET_DASHBOARD_TOGGLE:
            return action.payload;
        default:
            return state;
    }
};
