import { keys } from "localforage";
import { calculoMax, calculoMedia, getAlertas } from "../config/helpers";
import {
    IOperacional,
    IRelatorio,
    ITabOperacional,
} from "../reducers/RelatorioReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import {
    SET_RELATORIO,
    SET_REL_OPERACIONAL,
    SET_TAB_OPERACIONAL,
    SET_REL_ONE,
} from "./actionTypes";

export const setRelatorio = (value: IRelatorio) => ({
    type: SET_RELATORIO,
    payload: value,
});

export const getRelatorio = (props: any) => {
    return async (dispatch: any) => {
        dispatch(setStatusStart("get", "relatorio"));

        const keys = Object.keys(props);

        var url =
            "api/operacao/" +
            props.faz +
            "/filtro_equipa_op?dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "faz" && key !== "dataIni" && key !== "dataFin")
                    url += key + "=" + props[key] + "&";
            }
        });

        const equipasPorFiltros = await fetch(
            `${process.env.REACT_APP_BASE_URL_API}` + url,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );
        const equipasPorFiltrosJSON = JSON.parse(
            await equipasPorFiltros.text()
        );

        var urlApi =
            "api/relatorio/" +
            props.faz +
            "/parada?" +
            "dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (
                props[key] === "" ||
                props[key] === "null" ||
                props[key] === undefined
            ) {
            } else {
                if (
                    key !== "faz" &&
                    // key !== "equip" &&
                    key !== "dataIni" &&
                    key !== "dataFin"
                )
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        var array = [];
        var outraArray = [];

        for (const e of equipasPorFiltrosJSON) {
            // let urlNova = urlApi.split("equipamento=")[0] + "equipamento=" + e;
            const tempoParadoPorEquipa = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}` +
                    urlApi +
                    "equipamento=" +
                    e,
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }
            );

            let urlApii = urlApi.split("dataIni=")[1];
            const tipoParada = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}api/relatorio/` +
                    props.faz +
                    "/tipoparada?" +
                    "&dataIni=" +
                    urlApii +
                    "equipamento=" +
                    e,
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }
            );

            const tipoParadaJSON = JSON.parse(await tipoParada.text());
            const tempoParadoPorEquipaJSON = JSON.parse(
                await tempoParadoPorEquipa.text()
            );
            array.push({
                id: e,
                tempoParado: tempoParadoPorEquipaJSON,
                tipoParada: tipoParadaJSON,
            });

            const operacoes = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}api/operacao/` +
                    props.faz +
                    "/?dataIni=" +
                    urlApii +
                    "equipamento=" +
                    e +
                    "&colunas=tipo_operacao_temp,cultura_temp,safra_temp,velocidade,rpm,vazaoComb,alerta,txtalerta,temAgua,pressaoOleo,cargaMotor,torqueMotor&size=100000",
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }
            );

            const operacoesJSON = JSON.parse(await operacoes.text());

            outraArray.push({
                id: e,
                operacoes: operacoesJSON.dados,
                // velMedia: calculoMedia(operacoesJSON.dados, "velocidade"),
                // velMax: calculoMax(operacoesJSON.dados, "velocidade"),
                // rpmMedia: calculoMedia(operacoesJSON.dados, "rpm"),
                // rpmMax: calculoMax(operacoesJSON.dados, "rpm"),
                alertas: getAlertas(operacoesJSON.dados).length,
            });
        }

        dispatch(
            setRelatorio({
                rel: array,
                ope: outraArray,
            })
        );
        dispatch(setStatusSuccess("get", "relatorio"));
    };
};

export const setRelOne = (value: IRelatorio) => ({
    type: SET_REL_ONE,
    payload: value,
});
export const getRelOne = (props: any) => {
    return async (dispatch: any) => {
        dispatch(setStatusStart("get", "relatorioOne"));

        const keys = Object.keys(props);

        var url =
            "api/relatorio/" +
            props.faz +
            "/parada?" +
            "&equipamento=" +
            props.equip +
            "&";
        keys.forEach((key, index) => {
            if (
                props[key] === "" ||
                props[key] === "null" ||
                props[key] === undefined
            ) {
            } else {
                if (key !== "faz" && key !== "equip")
                    url += key + "=" + props[key] + "&";
            }
        });

        var array: any = [];
        var outraArray: any = [];

        const tempoParadoPorEquipa = await fetch(
            `${process.env.REACT_APP_BASE_URL_API}` + url,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );

        let urlApi = url.split("equipamento=")[1];

        const tipoParada = await fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/relatorio/` +
                props.faz +
                "/tipoparada?" +
                "&equipamento=" +
                urlApi,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );

        const tipoParadaJSON = JSON.parse(await tipoParada.text());
        const tempoParadoPorEquipaJSON = JSON.parse(
            await tempoParadoPorEquipa.text()
        );

        array.push({
            tempoParado: tempoParadoPorEquipaJSON,
            tipoParada: tipoParadaJSON,
        });

        const operacoes = await fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/operacao/` +
                props.faz +
                "/?equipamento=" +
                urlApi +
                "&colunas=tipo_operacao_temp,cultura_temp,safra_temp,velocidade,rpm,vazaoComb,alerta,txtalerta,temAgua,pressaoOleo,cargaMotor,torqueMotor&size=100000",
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );

        const operacoesJSON = JSON.parse(await operacoes.text());

        outraArray.push({
            operacoes: operacoesJSON.dados,
            alertas: getAlertas(operacoesJSON.dados).length,
        });

        dispatch(
            setRelOne({
                rel: array,
                ope: outraArray,
            })
        );
        dispatch(setStatusSuccess("get", "relatorioOne"));
    };
};

export const setRelOperacional = (value: IOperacional) => ({
    type: SET_REL_OPERACIONAL,
    payload: value,
});

export const getRelOperacional = (props: any) => {
    return async (dispatch: any) => {
        dispatch(setStatusStart("get", "relatorio"));

        const keys = Object.keys(props);

        var urlApi =
            "api/relatorio/v2/operacional?" +
            "dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "dataIni" && key !== "dataFin")
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "relatorio", res.erro));
                } else {
                    dispatch(setRelOperacional(res));
                    dispatch(setStatusSuccess("get", "relatorio"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "relatorio", error.data));
            });
    };
};

export const setTabOperacional = (value: ITabOperacional) => ({
    type: SET_TAB_OPERACIONAL,
    payload: value,
});

export const getTabOperacional = (props: any) => {
    return async (dispatch: any) => {
        dispatch(setStatusStart("get", "relatorioOpe"));

        const keys = Object.keys(props);

        var urlApi =
            "api/relatorio/v2/operacional/tabela?" +
            "dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (key !== "dataIni" && key !== "dataFin")
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "relatorioOpe", res.erro));
                } else {
                    dispatch(setTabOperacional([res]));
                    dispatch(setStatusSuccess("get", "relatorioOpe"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "relatorioOpe", error.data));
            });
    };
};
