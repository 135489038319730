import { useCallback, useEffect, useMemo, useState } from "react";
import AdicionarEquipamento from "./Equipamentos/AdicionarEquipamento";
import { Button, Modal, Popconfirm, Radio } from "antd";
import { bindActionCreators } from "redux";
import {
    setListEquipamento,
    getEquipamentos,
    adicionarEquipamento,
    deletarEquipamento,
} from "../../../store/equipamentoActions";
import { setStatusNull } from "../../../store/actions";
import { connect } from "react-redux";
import EditarEquipamento from "./Equipamentos/EditarEquipamento";
import { IStore } from "../../../store";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../reducers/EquipamentoReducer";
import { IStatusList } from "../../../store/types";
import { ITheme } from "../../../reducers/ThemeReducer";

export interface IEquipamentosProps {
    equipamentoListState: IEquipamentoList;
    equipamentoStatus: IStatusList;
    status: any[];
    tipos: any[];
    theme: ITheme;

    getEquipamentos: Function;
    deletarEquipamento: Function;
}
function Equipamentos(props: IEquipamentosProps) {
    const [which, setWhich] = useState(0);
    const [modal, setModal] = useState(false);
    const [equipamentoModal, setEquipamentoModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    const [filterOption, setFilterOption] = useState("ativos");

    const {
        equipamentoListState,
        getEquipamentos,
        deletarEquipamento,
        equipamentoStatus,
        status,
        tipos,
        theme,
    } = props;

    const options = [
        { label: "Ativos", value: "ativos" },
        { label: "Inativos", value: "inativos" },
        { label: "Manutenção", value: "manutencao" },
        { label: "Todos", value: "all" },
    ];

    const listaEquipamentos = useCallback(() => {
        let filteredEquipa: any = [];
        if (equipamentoListState.length > 0) {
            switch (filterOption) {
                case "ativos":
                    equipamentoListState.filter((e: IEquipamento) => {
                        if (e.estado === "1") {
                            return filteredEquipa.push(e);
                        }
                    });
                    break;
                case "inativos":
                    equipamentoListState.filter((e: IEquipamento) => {
                        if (e.estado === "-1") {
                            return filteredEquipa.push(e);
                        }
                    });
                    break;
                case "manutencao":
                    equipamentoListState.filter((e: IEquipamento) => {
                        if (e.estado === "0") {
                            return filteredEquipa.push(e);
                        }
                    });
                    break;
                case "all":
                    return (filteredEquipa = equipamentoListState);
            }
        }

        return filteredEquipa;
    }, [equipamentoListState, filterOption]);

    function handleEditarEquipamento(id: any) {
        const equId = equipamentoListState.findIndex(
            (equ: any) => equ.id_equipamento === id
        );
        setEquipamentoModal({
            titulo: "Editar equipamento",
            conteudo: (
                <EditarEquipamento
                    setModal={setModal}
                    cont={equipamentoListState[equId]}
                />
            ),
        });
        setModal(true);
    }

    function handleAdicionarEquipamento() {
        setEquipamentoModal({
            titulo: "Adicionar Equipamento",
            conteudo: <AdicionarEquipamento setModal={setModal} />,
        });
        setModal(true);
    }

    function handleExcluirEquipamento() {
        setConfirmLoading(true);
        deletarEquipamento(which);
    }

    useMemo(() => {
        if (equipamentoStatus.equipamento?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
        }
        getEquipamentos();
    }, [equipamentoStatus.equipamento?.del?.success, getEquipamentos]);

    useMemo(() => {
        if (equipamentoStatus.equipamento?.edit?.success) {
            setWhich(0);
        }
    }, [equipamentoStatus.equipamento?.edit?.success]);

    useEffect(() => {
        setWhich(0);
    }, [filterOption]);

    return (
        <div style={{ height: "100%" }}>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <div
                    style={{
                        paddingRight: "10px",
                    }}
                >
                    Equipamentos cadastrados:
                </div>
                <div>
                    <Radio.Group
                        style={{
                            display: "block",
                        }}
                        options={options}
                        onChange={(e: any) => {
                            setFilterOption(e.target.value);
                        }}
                        defaultValue="ativos"
                    />
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    marginTop: "5px",
                    height: "calc(100% - 10px)",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        paddingRight: "10px",
                        height: "100%",
                    }}
                >
                    <ul
                        className={
                            theme ? "select-expand-ul-dark" : "select-expand-ul"
                        }
                    >
                        {listaEquipamentos().map((e: any) => {
                            let classN = theme
                                ? "select-expand-li-dark"
                                : "select-expand-li";
                            let estadokey = status.findIndex(
                                (s: any) => s.value === e.estado
                            );
                            if (e.id_equipamento === which) {
                                classN += " active";
                            }
                            return (
                                <li
                                    key={e.id_equipamento}
                                    className={classN}
                                    onClick={() => setWhich(e.id_equipamento)}
                                    onDoubleClick={() =>
                                        handleEditarEquipamento(
                                            e.id_equipamento
                                        )
                                    }
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "agrochip",
                                                fontSize: "32px",
                                            }}
                                        >
                                            {e.tipo}
                                        </span>
                                        {/* <div
                                            style={{
                                                fontSize: "25px",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <i className={e.icone}></i>
                                        </div> */}
                                        <div>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {e.descricao}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {tipos[e.tipo - 1].nome}{" "}
                                                {e.numero_patrimonio}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                "status " +
                                                status[estadokey].optionClass
                                            }
                                        >
                                            {status[estadokey].displayName}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        <li
                            style={{ flex: 1 }}
                            onClick={() => setWhich(0)}
                        ></li>
                    </ul>
                </div>
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => handleAdicionarEquipamento()}
                    >
                        Adicionar
                    </Button>
                    <Button
                        disabled={which === 0 ? true : false}
                        onClick={() => handleEditarEquipamento(which)}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        visible={excluirVisible}
                        title="Você tem certeza que deseja excluir esse equipamento?"
                        onConfirm={handleExcluirEquipamento}
                        onCancel={() => setExcluirVisible(false)}
                        okButtonProps={{ loading: confirmLoading }}
                        okText="Sim"
                        cancelText="Não"
                        disabled={which === 0 ? true : false}
                    >
                        <Button
                            onClick={() => setExcluirVisible(true)}
                            style={{ width: "100%" }}
                            // disabled={which === 0 ? true : false}
                            disabled={true}
                        >
                            Excluir
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <Modal
                title={equipamentoModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
                bodyStyle={{
                    userSelect: "none",
                }}
            >
                {equipamentoModal.conteudo}
            </Modal>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        equipamentoListState: store.equipamentos,
        equipamentoStatus: store.loader,
        status: store.attrEquipamento.estado,
        tipos: store.attrEquipamento.tipos,
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getEquipamentos,
            deletarEquipamento,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipamentos);
