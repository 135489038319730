import { SET_LIST_USUARIO, SET_USUARIO } from "../store/actionTypes";

export interface IUserModule {
    id_user?: string;
    nome?: string;
    email?: string;
    pass?: string;
    since?: string;
    preferencia?: string;
    email_alexa?: string;
    nome_contato?: string;
    fone_contato?: string;
    envia_alerta?: boolean;
    cliente_temp?: string;
    hora_rel_diario?: string;
}

export type IUser = IUserModule;
const Usuario: IUser = {};

export interface IUserReducer {
    type: any;
    payload: IUser;
}

export const userReducer = (state = Usuario, action: IUserReducer) => {
    switch (action.type) {
        case SET_USUARIO:
            return action.payload;
        default:
            return state;
    }
};

export type IUserList = IUserModule[];
const UsuarioList: IUserList = [];

export interface IUserListReducer {
    type: any;
    payload: IUserList;
}

export const userListReducer = (
    state = UsuarioList,
    action: IUserListReducer
) => {
    switch (action.type) {
        case SET_LIST_USUARIO:
            return action.payload;
        default:
            return state;
    }
};
