import { Menu } from "antd";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const { SubMenu } = Menu;

export default function MenuOpera(props: any) {
    const [habilitaExcel, setHabilitaExcel] = useState(false);

    useEffect(() => {}, [props.tabs]);

    useEffect(() => {
        let validaExcel = false;
        if (
            props.tabs[
                props.tabs.findIndex((t: any) => t.key === props.tabAtiva)
            ].content.filtros.datas.length === 0 &&
            props.tabs[
                props.tabs.findIndex((t: any) => t.key === props.tabAtiva)
            ].content.filtros.fazendas === ""
        ) {
            validaExcel = true;
        }
        if (props.haRegistro === undefined) {
            validaExcel = true;
        }
        if (props.haRegistro === 0) {
            validaExcel = true;
        }
        if (
            props.tabs[
                props.tabs.findIndex((t: any) => t.key === props.tabAtiva)
            ].content.colunas.length === 0
        ) {
            validaExcel = true;
        }
        setHabilitaExcel(validaExcel);
    }, [props.tabAtiva, props.tabs, props.haRegistro]);

    return (
        <Menu
            mode="horizontal"
            onClick={(a: any) => {
                switch (a.key) {
                    case "geral:1":
                        let tabKey = uuidv4();
                        let newTabs = JSON.parse(JSON.stringify(props.tabs));
                        newTabs.push({
                            title: "Em branco",
                            content: {
                                colunas: [
                                    // {
                                    //     Header: "ID",
                                    //     accessor: "id_operacao",
                                    // },
                                    // {
                                    //     Header: "Data/Hora",
                                    //     accessor: "timestamp",
                                    // },
                                ],
                                filtros: {
                                    cultura: "",
                                    datas: [],
                                    equipamento: "",
                                    fazendas: [],
                                    safra: "",
                                    operacoes: [],
                                    // tipo_parada: "",
                                    operador: "",
                                    talhao: "",
                                },
                                paginacao: {
                                    pagina: 0,
                                    size: 10,
                                },
                                data: [],
                            },
                            key: tabKey,
                        });
                        props.setTabs(newTabs);
                        props.setTabAtiva(tabKey);
                        break;
                    case "exibir:1":
                        props.setVisivel(true);
                        props.setDialog(1);
                        break;
                    case "filtros:1":
                        props.setVisivel(true);
                        props.setDialog(2);
                        break;
                    case "geral:2":
                        props.exportXls(props.tabela);
                        break;
                    default:
                        break;
                }
            }}
            triggerSubMenuAction="click"
            selectable={false}
        >
            <SubMenu key="geral" title="Geral" popupOffset={[-15, 0]}>
                <Menu.Item key="geral:1">
                    <i
                        style={{ height: "20px", marginRight: "5px" }}
                        className="far fa-window-restore"
                    ></i>
                    <span style={{ marginLeft: "5px" }}>Nova aba</span>
                </Menu.Item>
                <Menu.Item key="geral:2" disabled={habilitaExcel}>
                    <i
                        style={{ height: "25px", marginRight: "5px" }}
                        className="fas fa-file-excel"
                    ></i>

                    <span style={{ marginLeft: "5px" }}>
                        Exportar para excel
                    </span>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="exibir" title="Exibir" popupOffset={[-15, 0]}>
                <Menu.Item key="exibir:1">
                    <i
                        className="fas fa-columns"
                        style={{ height: "23px", marginRight: "10px" }}
                    ></i>
                    Colunas
                </Menu.Item>
            </SubMenu>
            <SubMenu key="filtros" title="Filtros" popupOffset={[-15, 0]}>
                <Menu.Item key="filtros:1">
                    <i
                        style={{ height: "20px", marginRight: "10px" }}
                        className="fas fa-filter"
                    ></i>
                    Editar Filtros
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
}
