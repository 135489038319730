import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import { IStore } from "../../../../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IFazenda, IFazendaList } from "../../../../reducers/FazendaReducer";
import { useCallback, useEffect, useState } from "react";

import moment from "moment";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import {
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import { v4 as uuidv4 } from "uuid";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

export interface IFiltroOpeProps {
    fazendas: IFazendaList;
    tiposOp: ITipoOpList;

    tipoOpCList: ITipoOpClienteList;

    visivel: boolean;
    setVisivel: Function;
    infos: any;
    setInfos: Function;

    abaAtiva: number;
}

function FiltrosOpe(props: IFiltroOpeProps) {
    const { fazendas, tiposOp, tipoOpCList } = props;

    const [form] = Form.useForm();

    let abaAtualObj =
        props.infos[
            props.infos.findIndex((a: any) => a.key === Number(props.abaAtiva))
        ].infos;
    let tiposOpes = abaAtualObj.tipo_operacao;
    let fazendasSele = abaAtualObj.faz;

    const [formDesativado, setFormDesativado] = useState(true);

    const [datas, setDatas] = useState<any>([
        moment(abaAtualObj.datas[0], "YYYY-MM-DDTHH:mm:ss"),
        moment(abaAtualObj.datas[1], "YYYY-MM-DDTHH:mm:ss"),
    ]);
    const [value, setValue] = useState<any>(null);

    const [opesLocal, setOpesLocal] = useState([]);
    const [disabledOps, setDisabledOps] = useState(false);
    const [fazLocal, setFazLocal] = useState<any>([]);
    const [disabledFaz, setDisabledFaz] = useState(false);
    const [btnDesativado, setBtnDesativado] = useState(true);

    const filteredOptions = tiposOp.filter((o: any) =>
        tipoOpCList.some((s: any) => {
            if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                if (s.tipo_operacao_temp === o.id_tipo_operacao) {
                    return (
                        opesLocal.findIndex(
                            (i: any) => i.value === o.id_tipo_operacao
                        ) === -1
                    );
                }
            }
        })
    );

    useEffect(() => {
        datas.forEach((d: any) => {
            if (datas.toString().length < 39 || d === "") {
                setFormDesativado(true);
                setBtnDesativado(true);
            } else {
                setFormDesativado(false);
                setBtnDesativado(false);
            }
        });
    }, [datas]);

    useEffect(() => {
        if (abaAtualObj.tipo_operacao.length > 0) {
            var array: any = [];
            abaAtualObj.tipo_operacao.map((o: any) => {
                if (o === 0 || o === "") {
                } else {
                    return array.push({
                        value: o,
                        label: tiposOp[
                            tiposOp.findIndex(
                                (t: any) => o === t.id_tipo_operacao
                            )
                        ].descricao,
                        key: o.toString(),
                    });
                }
            });
            setOpesLocal(array);
        } else setOpesLocal(abaAtualObj.tipo_operacao);
    }, []);

    useEffect(() => {
        if (abaAtualObj.faz.length > 0) {
            var array: any = [];
            abaAtualObj.faz.map((f: any) => {
                if (f !== "") {
                    return array.push({
                        value: f,
                        key: f.toString(),
                    });
                }
            });
            setFazLocal(array);
        } else setFazLocal(abaAtualObj.faz);
    }, []);

    useEffect(() => {
        if (tiposOpes.length > 0) {
            if (tiposOpes[0] === "") {
                setDisabledOps(true);
            }
        }

        if (fazendasSele.length > 0) {
            if (fazendasSele[0] === "") {
                setDisabledFaz(true);
            }
        }
    }, []);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                FazendaForm: fazendasSele,
                TipoOpForm: tiposOpes,
                periodo:
                    datas.toString().length === 25
                        ? ["", ""]
                        : [
                              moment(datas[0], "YYYY-MM-DDTHH:mm:ss"),
                              moment(datas[1], "YYYY-MM-DDTHH:mm:ss"),
                          ],
            }}
            onFinish={(v: any) => {
                let arrOp = v.TipoOpForm.map((o: any) => {
                    if (o.value !== undefined) {
                        return o.value;
                    } else return o;
                });
                let arrFaz = v.FazendaForm.map((o: any) => {
                    if (o.value !== undefined) {
                        return o.value;
                    } else return o;
                });

                let newArray = JSON.parse(JSON.stringify(props.infos));
                let abaKey = newArray.findIndex((a: any) => {
                    return a.key === Number(props.abaAtiva);
                });
                newArray[abaKey].infos = {
                    datas: datas,
                    faz: arrFaz,
                    tipo_operacao: arrOp,
                };
                props.setInfos(newArray);
                props.setVisivel(false);
            }}
        >
            <Row gutter={[24, 0]}>
                <Col span={10}>
                    <Form.Item
                        label="Periodo:"
                        name="periodo"
                        rules={[
                            {
                                required: true,
                                message: "Selecione uma data",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <RangePicker
                            locale={locale}
                            disabledDate={current => {
                                // Can not select days after today and before start Date
                                const start = moment(
                                    "2020-01-01",
                                    "YYYY-MM-DD"
                                );

                                // const tooLate =
                                //     value !== null && value[0]
                                //         ? current.diff(value[0], "days") >= 60
                                //         : false;
                                // const tooEarly =
                                //     value !== null && value[1]
                                //         ? value[1].diff(current, "days") >= 60
                                //         : false;

                                return (
                                    current < start ||
                                    current > moment().endOf("day")
                                    // ||
                                    // tooLate ||
                                    // tooEarly
                                );
                            }}
                            onCalendarChange={(val: any) => {
                                setValue(val);
                            }}
                            popupStyle={{ zIndex: 1090 }}
                            format="DD/MM/yyyy"
                            onChange={(val: any, val2: any) => {
                                let tzoffset =
                                    new Date().getTimezoneOffset() * 60000;

                                let datas: any = [];
                                if (val2[0] !== "" && val2[1] !== "") {
                                    val2.map((v: any) => {
                                        const [dateString] = v.split(" ");
                                        const [day, month, year] =
                                            dateString.split("/");

                                        const dateObj: any = new Date(
                                            +year,
                                            +month - 1,
                                            +day
                                        );
                                        datas.push(
                                            new Date(dateObj - tzoffset)
                                                .toISOString()
                                                .split(".")[0]
                                        );
                                    });
                                }
                                setDatas(datas);
                            }}
                            style={{
                                color: "black",
                                width: "100%",
                            }}
                            // showTime={{
                            //     hideDisabledOptions: true,
                            //     defaultValue: [
                            //         moment("00:00:00", "HH:mm:ss"),
                            //         moment("23:59:59", "HH:mm:ss"),
                            //     ],
                            // }}
                            ranges={{
                                Hoje: [moment().startOf("day"), moment()],
                                Ontem: [
                                    moment().subtract(1, "days").startOf("day"),
                                    moment().subtract(1, "days").endOf("day"),
                                ],
                                "Última semana": [
                                    moment().subtract(6, "days").startOf("day"),
                                    moment(),
                                ],
                                "Último mês": [
                                    moment()
                                        .subtract(1, "months")
                                        .startOf("day"),
                                    moment(),
                                ],
                            }}
                            placeholder={["Data inicial", "Data final"]}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Fazenda:"
                        name="FazendaForm"
                        rules={[
                            {
                                required: true,
                                message: "Selecione ao menos uma opção",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            disabled={formDesativado}
                            mode="multiple"
                            allowClear={false}
                            dropdownStyle={{ zIndex: 1090 }}
                            showSearch
                            filterOption={(input, option: any) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e: any) => {
                                setFazLocal(e);
                            }}
                            value={fazLocal}
                            labelInValue={true}
                            onSelect={(value: any) => {
                                if (value.value === "") {
                                    setFazLocal([]);
                                    setDisabledFaz(true);
                                }
                            }}
                            onDeselect={(value: any) => {
                                if (value.value === "") {
                                    setDisabledFaz(false);
                                }
                            }}
                            placeholder="Escolha"
                        >
                            <Option
                                value=""
                                style={{
                                    fontWeight: "bold",
                                }}
                                disabled={fazLocal.length > 0}
                            >
                                Sem filtro
                            </Option>
                            {fazendas.map((f: any) => {
                                return (
                                    <Option
                                        disabled={disabledFaz}
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tipo de Operação:"
                        name="TipoOpForm"
                        rules={[
                            {
                                required: true,
                                message: "Selecione ao menos uma opção",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            disabled={formDesativado}
                            mode="multiple"
                            allowClear={false}
                            // maxTagCount={"responsive"}
                            style={{ width: "100%", zIndex: 1090 }}
                            dropdownStyle={{ zIndex: 1090 }}
                            showSearch
                            filterOption={(input, option: any) => {
                                let condicao = false;
                                if (option.value !== 0 && option.children) {
                                    return option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                        ? (condicao = true)
                                        : (condicao = false);
                                } else if (option.label) {
                                    return option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                        ? (condicao = true)
                                        : (condicao = false);
                                }

                                return condicao;
                            }}
                            value={opesLocal}
                            labelInValue={true}
                            onChange={(e: any) => {
                                setOpesLocal(e);
                            }}
                            onSelect={(value: any) => {
                                if (value.value === "") {
                                    setOpesLocal([]);
                                    setDisabledOps(true);
                                }
                            }}
                            onDeselect={(value: any) => {
                                if (value.value === "") {
                                    setDisabledOps(false);
                                }
                            }}
                            placeholder="Selecione"
                        >
                            <Option
                                value=""
                                style={{
                                    fontWeight: "bold",
                                }}
                                disabled={opesLocal.length > 0}
                            >
                                Sem filtro
                            </Option>
                            {tipoOpCList.length > 0 ? (
                                <>
                                    <OptGroup label="Tipo Operação">
                                        {filteredOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao > 0 &&
                                                        e.id_tipo_operacao <
                                                            800 && (
                                                            <Option
                                                                disabled={
                                                                    disabledOps
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                label={
                                                                    e.descricao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })}
                                    </OptGroup>
                                </>
                            ) : (
                                <Option disabled>
                                    <div
                                        style={{
                                            display: "flex",

                                            flexDirection: "column",
                                        }}
                                    >
                                        {" "}
                                        <div
                                            style={{
                                                flex: 1,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Não há nenhum tipo de op.
                                            cadastrada.
                                        </div>
                                    </div>
                                </Option>
                            )}
                            <Option
                                value={0}
                                key={uuidv4()}
                                label="Não Identificado"
                                disabled={disabledOps}
                            >
                                Não Identificado
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <div style={{ textAlign: "right" }}>
                <Button
                    style={{
                        marginRight: "10px",
                    }}
                    onClick={() => {
                        props.setVisivel(false);
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={btnDesativado}
                >
                    Aplicar
                </Button>
            </div>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        tiposOp: store.tiposOp,
        tipoOpCList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltrosOpe);
