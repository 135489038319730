import { message } from "antd";
import { IEquipamento } from "../reducers/EquipamentoReducer";
import { IMensagemList } from "../reducers/MensagemReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { SET_LIST_MENSAGEM } from "./actionTypes";

export const setListMensagem = (value: IMensagemList) => ({
    type: SET_LIST_MENSAGEM,
    payload: value,
});

export const getMensagem = (value?: string) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "mensagem"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/mensagem/` + value, {
            method: "get",
            headers: {
                "Content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "mensagem", res.erro));
                } else {
                    dispatch(setListMensagem(res));
                    dispatch(setStatusSuccess("get", "mensagem"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "mensagem", error.data));
            });
    };
};

interface IAddMsg {
    texto: string;
    data_hora: string;
    equipamento_temp?: string;
    id_mensagem?: string;
}

export const adicionarMensagem = (value: IAddMsg, server: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "mensagem"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/mensagem/${server}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "mensagem", res.erro));
                } else {
                    dispatch(getMensagem(value.equipamento_temp));
                    dispatch(setStatusSuccess("add", "mensagem"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "mensagem", error.data));
            });
    };
};
