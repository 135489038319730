import { Button, Modal, Popconfirm } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IParametro, IParametroList } from "../../../reducers/ParametroReducer";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStore } from "../../../store";
import { IStatusList } from "../../../store/types";
import AdicionarParametro from "./Parametros/AdicionarParametro";
import EditarParametro from "./Parametros/EditarParametro";
import {
    deletarParametro,
    getParametros,
} from "../../../store/parametrosActions";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../reducers/EquipamentoReducer";
import {
    IImplemento,
    IImplementoList,
} from "../../../reducers/ImplementoReducer";
import { getImplementos } from "../../../store/implementoActions";
import { setStatusNull } from "../../../store/actions";
import { ITipoOp, ITipoOpList } from "../../../reducers/TipoOpReducer";

export interface IParametrosProps {
    theme: ITheme;
    parametros: IParametroList;
    equipamentos: IEquipamentoList;
    implementos: IImplementoList;
    tiposOp: ITipoOpList;

    status: IStatusList;

    getParametros: Function;
    deletarParametro: Function;

    getImplementos: Function;
    setStatusNull: Function;
}

function Parametros(props: IParametrosProps) {
    const {
        theme,
        parametros,
        deletarParametro,
        getParametros,
        status,
        equipamentos,
        implementos,
        tiposOp,

        getImplementos,
        setStatusNull,
    } = props;

    const [which, setWhich] = useState(0);
    const [modal, setModal] = useState(false);
    const [parametroModal, setParametroModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    function handleAdicionarParametro() {
        setParametroModal({
            titulo: "Adicionar Parâmetro",
            conteudo: <AdicionarParametro setModal={setModal} />,
        });
        setModal(true);
    }

    function handleEditar(id: any) {
        const parametroId = parametros.findIndex(
            (p: any) => p.id_parametro_consumo === id
        );
        setParametroModal({
            titulo: "Editar Parâmetro",
            conteudo: (
                <EditarParametro
                    setModal={setModal}
                    cont={parametros[parametroId]}
                />
            ),
        });
        setModal(true);
    }

    function handleExcluir() {
        setConfirmLoading(true);
        deletarParametro(which);
    }

    useMemo(() => {
        if (status.parametro?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "alerta");
        }
        getParametros();
        setWhich(0);
    }, [status.parametro?.del?.success, getParametros]);

    useEffect(() => {
        getParametros();
        getImplementos();
    }, []);

    return (
        <div style={{ height: "100%" }}>
            Parâmetros de Consumo cadastrados:
            <div
                style={{
                    display: "flex",
                    marginTop: "5px",
                    height: "calc(100% - 10px)",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        paddingRight: "10px",
                        height: "100%",
                    }}
                >
                    <ul
                        className={
                            theme ? "select-expand-ul-dark" : "select-expand-ul"
                        }
                    >
                        {parametros.map((i: any) => {
                            let classN = theme
                                ? "select-expand-li-dark"
                                : "select-expand-li";
                            if (i.id_parametro_consumo === which) {
                                classN += " active";
                            }
                            return (
                                <li
                                    key={i.id_parametro_consumo}
                                    className={classN}
                                    onClick={() =>
                                        setWhich(i.id_parametro_consumo)
                                    }
                                    onDoubleClick={() =>
                                        handleEditar(i.id_parametro_consumo)
                                    }
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>
                                            <div>
                                                Equipamento:{" "}
                                                {i.equipamento_temp &&
                                                    equipamentos[
                                                        equipamentos.findIndex(
                                                            (e: IEquipamento) =>
                                                                e.id_equipamento ===
                                                                i.equipamento_temp
                                                        )
                                                    ].numero_patrimonio}
                                            </div>
                                            <div>
                                                Tipo de Operação:{" "}
                                                {i.tipo_operacao_temp
                                                    ? tiposOp[
                                                          tiposOp.findIndex(
                                                              (o: ITipoOp) =>
                                                                  o.id_tipo_operacao ===
                                                                  i.tipo_operacao_temp
                                                          )
                                                      ].descricao
                                                    : "Todas"}
                                            </div>
                                        </div>
                                    </div>{" "}
                                    <div>
                                        {" "}
                                        <div>
                                            Média Litros/Hora:{" "}
                                            {i.media && i.media + " L/h"}
                                        </div>
                                        {i.implemento_temp && (
                                            <div
                                                style={{
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                Implemento:{" "}
                                                {implementos.length > 0 &&
                                                    implementos[
                                                        implementos.findIndex(
                                                            (e: IImplemento) =>
                                                                e.id_implemento ===
                                                                i.implemento_temp
                                                        )
                                                    ].descricao}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                        <li
                            style={{ flex: 1 }}
                            onClick={() => setWhich(0)}
                        ></li>
                    </ul>
                </div>
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => handleAdicionarParametro()}
                    >
                        Adicionar
                    </Button>
                    <Button
                        disabled={which === 0 ? true : false}
                        onClick={() => handleEditar(which)}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        visible={excluirVisible}
                        title="Você tem certeza que deseja excluir esse parâmetro?"
                        onConfirm={handleExcluir}
                        onCancel={() => setExcluirVisible(false)}
                        okButtonProps={{ loading: confirmLoading }}
                        okText="Sim"
                        cancelText="Não"
                        disabled={which === 0 ? true : false}
                    >
                        <Button
                            onClick={() => setExcluirVisible(true)}
                            style={{ width: "100%" }}
                            disabled={which === 0 ? true : false}
                            //disabled={true}
                        >
                            Excluir
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <Modal
                title={parametroModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
                bodyStyle={{
                    userSelect: "none",
                }}
            >
                {parametroModal.conteudo}
            </Modal>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        theme: store.theme,
        parametros: store.parametros,
        equipamentos: store.equipamentos,
        implementos: store.implementos,
        status: store.loader,
        tiposOp: store.tiposOp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getParametros,
            deletarParametro,

            getImplementos,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Parametros);
