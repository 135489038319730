import {
    SET_RELATORIO,
    SET_REL_ONE,
    SET_REL_OPERACIONAL,
    SET_TAB_OPERACIONAL,
} from "../store/actionTypes";

export interface IRelatorioModule {
    rel?: any;
    ope?: any;
}
export interface IRelOperacionalModule {
    date?: string;
    fazenda_temp?: string;
    ha_ope?: number;
    ha_nao_id?: number;
    total_horas?: number;
    talhoes?: [];
    inicio_op?: string;
    final_op?: string;
}

export interface ITalhaoTabOperacionalModule {
    date?: string;
    consumo_de_combustivel?: number;
    tipo_operacao_temp?: number;
    ha_ope?: number;
    operador_temp?: number;
    equipamento_temp?: string;
    implemento_temp?: string;
    horas_trabalhadas?: number;
    fazenda_temp?: string;
    talhao_temp?: string;
}
export interface ITabOperacionalModule {
    talhoes?: ITalhaoTabOperacionalModule[];
}

export type IRelatorio = IRelatorioModule;
const relatorio: IRelatorio = {};

export type IOperacional = IRelOperacionalModule[];
const Operacional: IOperacional = [];

export type ITabOperacional = ITabOperacionalModule[];
const TabOperacional: ITabOperacional = [];

export interface IRelatorioReducer {
    type: any;
    payload: IRelatorio;
}
export const relatorioReducer = (state = relatorio, action: any) => {
    switch (action.type) {
        case SET_RELATORIO:
            return action.payload;
        default:
            return state;
    }
};

export interface IRelOneAction {
    type: any;
    payload: IRelatorio;
}
export const relOneReducer = (state = relatorio, action: IRelOneAction) => {
    switch (action.type) {
        case SET_REL_ONE:
            return action.payload;
        default:
            return state;
    }
};

export interface IOperacionalReducer {
    type: any;
    payload: IOperacional;
}
export const operacionalReducer = (state = Operacional, action: any) => {
    switch (action.type) {
        case SET_REL_OPERACIONAL:
            return action.payload;
        default:
            return state;
    }
};

export interface ITabOperacionalReducer {
    type: any;
    payload: ITabOperacional;
}
export const tabOperacionalReducer = (state = TabOperacional, action: any) => {
    switch (action.type) {
        case SET_TAB_OPERACIONAL:
            return action.payload;
        default:
            return state;
    }
};
