import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import { Button, Empty, Select } from "antd";
import { useEffect, useState } from "react";

function ColunasOperacional(props: any) {
    const {} = props;

    // const [col, setCol] = useState(props.colunasLocal);
    const [col, setCol] = useState(
        props.colunasLocal.map((c: any) => {
            return {
                label: c.Header,
                value: c.accessorKey,
                key: c.key,
                disabled: false,
            };
        })
    );

    return (
        <>
            <Select
                mode="multiple"
                labelInValue={true}
                style={{ width: "100%", zIndex: 1061 }}
                value={col}
                dropdownStyle={{ zIndex: 1061 }}
                allowClear={true}
                notFoundContent={
                    <Empty
                        description="Não há mais colunas para selecionar."
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                }
                onChange={(e: any) => {
                    setCol(e);
                }}
                placeholder="Selecione as colunas para exibição"
            >
                {props.colunas.map((item: any) => {
                    return (
                        // <Select.Option
                        //     key={item.key}
                        //     label={item.title}
                        //     value={item.key}
                        // >
                        //     {item.title}
                        // </Select.Option>
                        <Select.Option
                            key={item.key}
                            label={item.header}
                            value={item.accessorKey}
                        >
                            {item.header}
                        </Select.Option>
                    );
                })}
            </Select>
            <div
                style={{
                    textAlign: "right",
                    display: "block",
                    marginTop: "10px",
                }}
            >
                <Button
                    style={{
                        marginRight: "10px",
                    }}
                    onClick={() => {
                        props.setVisivel(false);
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    // disabled={true}
                    onClick={() => {
                        let newArray = props.infos;
                        // newArray[0].colunas = col.map((c: any) => {
                        //     return {
                        //         title: c.label,
                        //         key: c.key,
                        //     };
                        // });
                        newArray[0].colunas = col.map((c: any) => {
                            return {
                                header: c.label,
                                accessorKey: c.value,
                                key: c.key,
                            };
                        });
                        props.setInfos(newArray);
                        props.setVisivel(false);
                    }}
                >
                    Aplicar
                </Button>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ColunasOperacional);
