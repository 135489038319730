import {
    SET_ALERTA,
    SET_ALERTA_USUARIO,
    SET_LIST_ALERTA,
    SET_LIST_ALERTA_USUARIO,
    SET_RENDER_ALERTA,
} from "../store/actionTypes";
import { IEquipamento } from "./EquipamentoReducer";
import { ITipoOp } from "./TipoOpReducer";

export interface IAlertaModule {
    alerta_id?: number;
    tipo?: string;
    criterio?: string;
    valor?: number;
    tipo_equipamento?: number;
    equipamento_temp?: IEquipamento;
    tipo_operacao_temp?: ITipoOp;
    exibe_portal?: boolean;
    exibe_bordo?: boolean;
    exibe_whatsapp?: boolean;
}

export type IAlerta = IAlertaModule;
const Alerta: IAlerta = {};

export interface ICriterioModule {
    id?: number;
    nome?: string;
}
export type ICriterioAlerta = ICriterioModule[];

export const criterioAlerta: ICriterioAlerta = [
    { id: 1, nome: "VERDADEIRO" },
    { id: 2, nome: "FALSO" },
    { id: 3, nome: "MAIOR QUE" },
    { id: 4, nome: "MENOR QUE" },
];

export interface IAlertaReducer {
    type: any;
    payload: IAlerta;
}

export const alertaReducer = (state = Alerta, action: IAlertaReducer) => {
    switch (action.type) {
        case SET_ALERTA:
            return action.payload;
        default:
            return state;
    }
};

export type IAlertaList = IAlertaModule[];
const AlertaList: IAlertaList = [];

export interface IAlertaListReducer {
    type: any;
    payload: IAlertaList;
}

export const alertaListReducer = (
    state = AlertaList,
    action: IAlertaListReducer
) => {
    switch (action.type) {
        case SET_LIST_ALERTA:
            return action.payload;
        default:
            return state;
    }
};

export interface IAlertaRenderModule {
    latitude: string;
    longitude: string;
    alerta: boolean | null;
    txtalerta: string | null;
    equipamento_temp: string;
}

export type IAlertaRender = IAlertaRenderModule[];
const alertaRender: IAlertaRender = [];

export interface IAlertaRenderReducer {
    type: any;
    payload: IAlertaRender;
}
export const alertaRenderReducer = (
    state = alertaRender,
    action: IAlertaRenderReducer
) => {
    switch (action.type) {
        case SET_RENDER_ALERTA:
            return action.payload;
        default:
            return state;
    }
};

export interface IAlertaUserModule {
    id_alerta_user?: string;
    alerta_temp?: number;
    usuario_temp?: string;
}

export type IAlertaUser = IAlertaUserModule;

export type IAlertaUserList = IAlertaUserModule[];

export interface IAlertaUserAction {
    type: any;
    payload: string;
}

export interface ISetListAlertaUserAction {
    type: any;
    payload: [];
}

const AlertaUser: IAlertaUser = {};

const AlertaUserList: IAlertaUserList = [];

export const alertaUserReducer = (
    state = AlertaUser,
    action: IAlertaUserAction
) => {
    switch (action.type) {
        case SET_ALERTA_USUARIO:
            return action.payload;
        default:
            return state;
    }
};

export const alertaUserListReducer = (
    state = AlertaUserList,
    action: ISetListAlertaUserAction
) => {
    switch (action.type) {
        case SET_LIST_ALERTA_USUARIO:
            return action.payload;
        default:
            return state;
    }
};
