import {
    Input,
    Form,
    Upload,
    Button,
    message,
    Col,
    Row,
    Select,
    Popover,
    InputNumber,
    Tooltip,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { CirclePicker } from "react-color";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { adicionarFazenda } from "../../../../store/fazendaActions";
import { setStatusNull } from "../../../../store/actions";
import { v4 as uuidv4 } from "uuid";
import shapeConvert from "../../../../config/shapeConvert";
import { useCallback } from "react";
import { useEffect } from "react";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";
import { SliderValueLabel } from "@mui/material";
import { fusoList } from "../../../../reducers/FazendaReducer";

const { Option } = Select;

interface AddFazendaProps {
    status: IStatusList;

    adicionarFazenda: Function;

    setStatusNull: any;

    setModal: any;
}
function AdicionarFazenda(props: AddFazendaProps) {
    const { status, adicionarFazenda, setStatusNull } = props;
    const [form] = Form.useForm();
    const colorList = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#FFFFFF",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#000000",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
        "#858585",
    ];

    const icons = [
        "fas fa-apple-alt",
        "fas fa-adjust",
        "fas fa-arrow-alt-circle-up",
        "fas fa-award",
        "fas fa-atom",
        "fas fa-baseball-ball",
        "fas fa-bezier-curve",
        "fas fa-bomb",
        "fas fa-bookmark",
        "fas fa-brain",
        "fas fa-carrot",
        "fas fa-check-circle",
        "fas fa-chess-queen",
        "fas fa-chess-knight",
        "fas fa-cloud-rain",
        "fas fa-crown",
        "fas fa-dharmachakra",
        "fas fa-hourglass",
    ];

    const [nome, setNome] = useState("");
    const [campo, setCampo] = useState("");
    const [size, setSize] = useState("");
    const [shape, setShape] = useState("");
    const [displayCP, setDisplayCP] = useState(false);
    const [corP, setCorP] = useState(
        colorList[Math.floor(Math.random() * colorList.length)]
    );
    const [corB, setCorB] = useState(
        colorList[Math.floor(Math.random() * colorList.length)]
    );
    const [opaP, setOpaP] = useState(20);
    const [opaB, setOpaB] = useState(100);
    const [larB, setLarB] = useState(1);
    const [icone, setIcone] = useState(
        icons[Math.floor(Math.random() * icons.length)]
    );
    const [coord, setCoord] = useState(null);
    const [fName, setFName] = useState(null);

    const [validoNome, setValidoNome] = useState(false);
    const [validoCampo, setValidoCampo] = useState(false);
    const [validoSize, setValidoSize] = useState(false);
    const [validoShape, setValidoShape] = useState(false);

    const [fuso, setFuso] = useState(false);

    const [localList, setLocalList] = useState<any>([]);
    const [local, setLocal] = useState(false);

    const handleDisable = useCallback(() => {
        if (
            validoNome === true &&
            validoCampo === true &&
            validoSize === true &&
            validoShape === true &&
            fuso === true &&
            local === true
        ) {
            return false;
        }
        return true;
    }, [validoNome, validoCampo, validoSize, validoShape, fuso, local]);

    function handleNome(e: any) {
        setNome(e.target.value);
    }

    function handleClick() {
        setDisplayCP(!displayCP);
    }

    function handleClose() {
        setDisplayCP(false);
    }

    function handleChangeCorP(color: any) {
        setCorP(color.hex);
        form.setFieldsValue({ estilo: { fill: { color: color.hex } } });
    }

    function handleChangeCorB(color: any) {
        setCorB(color.hex);
        form.setFieldsValue({ estilo: { stroke: { color: color.hex } } });
    }

    function handleOpaP(value: any) {
        setOpaP(value);
    }

    function handleOpaB(value: any) {
        setOpaB(value);
    }

    function handleLarB(value: any) {
        setLarB(value);
    }

    function onSelectIcone(value: any) {
        setIcone(value);
    }

    function onSelectField(value: any) {
        setCampo(value);
    }

    function onSelectSize(value: any) {
        setSize(value);
    }
    const { Dragger } = Upload;

    const propss = {
        accept: ".zip",
        showUploadList: false,
        beforeUpload(file: any) {
            shapeConvert(file, setShape, setCoord, setFName);

            return false;
        },
    };

    useEffect(() => {
        if (status.fazenda?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "fazenda");
        }
    });

    useEffect(() => {
        const fetchLocal = async () => {
            const res = await fetch(
                "https://servicodados.ibge.gov.br/api/v1/localidades/distritos?orderBy=nome"
            );
            let resJSON = JSON.parse(await res.text());
            setLocalList(resJSON);
        };
        fetchLocal();
    }, []);

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    nome: "",
                    icone: icone,
                    estilo: {
                        fill: { color: corP, opacity: opaP },
                        stroke: { color: corB, opacity: opaB, weight: larB },
                    },
                }}
                onFinish={(v: any) => {
                    let obj = {
                        nome: v.nome,
                        shape: shape,
                        coordenadas: coord,
                        campo: JSON.stringify({ nome: campo, size: size }),
                        estilo: JSON.stringify(v.estilo),
                        icone: v.icone,
                        fuso: v.fuso,
                        cidade: v.cidade,
                    };

                    adicionarFazenda(obj);
                    props.setModal(false);
                }}
                layout="vertical"
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome da Fazenda:"
                            name="nome"
                        >
                            <Input
                                placeholder="Digite o nome da fazenda"
                                value={nome}
                                onChange={handleNome}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Icone:" name="icone">
                            <Select
                                defaultValue={icone}
                                placeholder="custom"
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="icon-select"
                                onSelect={onSelectIcone}
                            >
                                {icons.map((i, k) => {
                                    return (
                                        <Option
                                            className="icon-picker-item"
                                            key={k}
                                            value={i}
                                        >
                                            <i className={i}></i>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.List name="estilo">
                        {v => {
                            return (
                                <>
                                    <Col span={5}>
                                        <Form.List name="fill">
                                            {() => (
                                                <>
                                                    <Form.Item label="Preenchimento:">
                                                        <Popover
                                                            placement="bottomRight"
                                                            overlayStyle={{
                                                                width: "200px",
                                                            }}
                                                            title="Preenchimento:"
                                                            content={
                                                                <div>
                                                                    <Row
                                                                        gutter={[
                                                                            24,
                                                                            0,
                                                                        ]}
                                                                    >
                                                                        <Col
                                                                            span={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Form.Item
                                                                                className="form_item_faz"
                                                                                label="Cor"
                                                                                name="color"
                                                                            >
                                                                                <Input
                                                                                    type="hidden"
                                                                                    value={
                                                                                        corP
                                                                                    }
                                                                                ></Input>
                                                                                <div>
                                                                                    <div
                                                                                        style={{
                                                                                            padding:
                                                                                                "1px",
                                                                                            background:
                                                                                                "#fff",
                                                                                            borderRadius:
                                                                                                "1px",
                                                                                            boxShadow:
                                                                                                "0 0 0 1px #D9D9D9",
                                                                                            cursor: "pointer",
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onClick={
                                                                                            handleClick
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                width: "calc(100% - 2)",
                                                                                                height: "30px",
                                                                                                borderRadius:
                                                                                                    "5px",
                                                                                                background:
                                                                                                    corP,
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {displayCP ? (
                                                                                        <div
                                                                                            style={{
                                                                                                position:
                                                                                                    "absolute",
                                                                                                right: "0",
                                                                                                zIndex: "2",
                                                                                                backgroundColor:
                                                                                                    "#FFF",
                                                                                                border: "1px solid #D9D9D9",
                                                                                                padding:
                                                                                                    "10px",
                                                                                                boxShadow:
                                                                                                    "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    position:
                                                                                                        "fixed",
                                                                                                    top: "0px",
                                                                                                    right: "0px",
                                                                                                    bottom: "0px",
                                                                                                    left: "0px",
                                                                                                }}
                                                                                                onClick={
                                                                                                    handleClose
                                                                                                }
                                                                                            />

                                                                                            <CirclePicker
                                                                                                colors={
                                                                                                    colorList
                                                                                                }
                                                                                                width="300px"
                                                                                                color={
                                                                                                    corP
                                                                                                }
                                                                                                onChange={
                                                                                                    handleChangeCorP
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col
                                                                            span={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Form.Item
                                                                                label="Opacidade:"
                                                                                name="opacity"
                                                                                initialValue={
                                                                                    20
                                                                                }
                                                                            >
                                                                                <InputNumber
                                                                                    formatter={value =>
                                                                                        `${value}%`
                                                                                    }
                                                                                    defaultValue={
                                                                                        20
                                                                                    }
                                                                                    min={
                                                                                        0
                                                                                    }
                                                                                    max={
                                                                                        100
                                                                                    }
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                    onChange={
                                                                                        handleOpaP
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            }
                                                            trigger="click"
                                                        >
                                                            <Button block>
                                                                Editar
                                                            </Button>
                                                        </Popover>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Form.List name="stroke">
                                        {() => (
                                            <>
                                                <Col span={5}>
                                                    <Form.Item label="Bordas:">
                                                        <Popover
                                                            placement="bottomRight"
                                                            overlayStyle={{
                                                                width: "300px",
                                                            }}
                                                            title="Bordas:"
                                                            content={
                                                                <div>
                                                                    <Form layout="vertical">
                                                                        <Row
                                                                            gutter={[
                                                                                24,
                                                                                0,
                                                                            ]}
                                                                        >
                                                                            <Col
                                                                                span={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    label="Cor:"
                                                                                    name="color"
                                                                                >
                                                                                    <div>
                                                                                        <div
                                                                                            style={{
                                                                                                padding:
                                                                                                    "3px",
                                                                                                background:
                                                                                                    "#fff",
                                                                                                borderRadius:
                                                                                                    "1px",
                                                                                                boxShadow:
                                                                                                    "0 0 0 1px #D9D9D9",
                                                                                                display:
                                                                                                    "inline-block",
                                                                                                cursor: "pointer",
                                                                                                width: "100%",
                                                                                            }}
                                                                                            onClick={
                                                                                                handleClick
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    height: "25px",
                                                                                                    borderRadius:
                                                                                                        "5px",
                                                                                                    background:
                                                                                                        corB,
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        {displayCP ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    position:
                                                                                                        "absolute",
                                                                                                    right: "0",
                                                                                                    zIndex: "2",
                                                                                                    backgroundColor:
                                                                                                        "#FFF",
                                                                                                    border: "1px solid #D9D9D9",
                                                                                                    padding:
                                                                                                        "10px",
                                                                                                    boxShadow:
                                                                                                        "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        position:
                                                                                                            "fixed",
                                                                                                        top: "0px",
                                                                                                        right: "0px",
                                                                                                        bottom: "0px",
                                                                                                        left: "0px",
                                                                                                    }}
                                                                                                    onClick={
                                                                                                        handleClose
                                                                                                    }
                                                                                                />
                                                                                                <CirclePicker
                                                                                                    colors={
                                                                                                        colorList
                                                                                                    }
                                                                                                    width="300px"
                                                                                                    color={
                                                                                                        corB
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleChangeCorB
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    label="Opacidade:"
                                                                                    name="opacity"
                                                                                >
                                                                                    <InputNumber
                                                                                        formatter={value =>
                                                                                            `${value}%`
                                                                                        }
                                                                                        defaultValue={
                                                                                            100
                                                                                        }
                                                                                        min={
                                                                                            0
                                                                                        }
                                                                                        max={
                                                                                            100
                                                                                        }
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onChange={
                                                                                            handleOpaB
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    label="Largura:"
                                                                                    name="weight"
                                                                                >
                                                                                    <InputNumber
                                                                                        defaultValue={
                                                                                            1
                                                                                        }
                                                                                        min={
                                                                                            0
                                                                                        }
                                                                                        max={
                                                                                            100
                                                                                        }
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                        onChange={
                                                                                            handleLarB
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>
                                                                </div>
                                                            }
                                                            trigger="click"
                                                        >
                                                            <Button block>
                                                                Editar
                                                            </Button>
                                                        </Popover>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                </>
                            );
                        }}
                    </Form.List>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um fuso",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setFuso(false), Promise.reject()
                                            );
                                        }
                                        setFuso(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fuso horário:"
                            name="fuso"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Selecione um fuso..."
                            >
                                {fusoList.map((t: any) => {
                                    return (
                                        <Option key={uuidv4()} value={t.value}>
                                            <Tooltip title={t.displayName}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div>
                                                        {
                                                            t.displayName.split(
                                                                "Horário"
                                                            )[0]
                                                        }
                                                    </div>
                                                    <div
                                                        style={{
                                                            textOverflow:
                                                                "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {
                                                            t.displayName.split(
                                                                "00"
                                                            )[1]
                                                        }
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um município",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setLocal(false),
                                                Promise.reject()
                                            );
                                        }
                                        setLocal(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Município:"
                            name="cidade"
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Município"
                            >
                                {localList.length > 0 &&
                                    localList.map((local: any) => {
                                        return (
                                            <Option
                                                key={local.id}
                                                value={
                                                    local.nome +
                                                    " - " +
                                                    local.municipio.microrregiao
                                                        .mesorregiao.UF.sigla
                                                }
                                            >
                                                {local.nome +
                                                    " - " +
                                                    local.municipio.microrregiao
                                                        .mesorregiao.UF.sigla}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {!shape ? (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Envie um ShapeFile",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return (
                                            setValidoShape(false),
                                            Promise.reject()
                                        );
                                    }
                                    setValidoShape(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        name="shape"
                    >
                        <Dragger {...propss}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Clique aqui ou arraste um Shapefile para fazer
                                upload
                            </p>
                            <p className="ant-upload-hint">
                                É necessário que o arquivo esteja no formato
                                *.zip.
                            </p>
                        </Dragger>
                    </Form.Item>
                ) : (
                    <Form.Item>
                        <div
                            style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #d9d9d9",
                                padding: "20px",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #D4D4D4",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <p style={{ float: "right" }}>
                                    <Button onClick={() => setShape("")}>
                                        Cancelar envio
                                    </Button>
                                </p>
                                <p style={{}}>
                                    <strong>Nome descrito no Shapefile:</strong>{" "}
                                    {fName}
                                </p>
                            </div>
                            <div>
                                <p style={{ fontWeight: "bold" }}>
                                    Dentre as propriedades do arquivo, relacione
                                    com a informação referente:
                                </p>
                                <Row gutter={[24, 0]}>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Selecione um campo",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            return (
                                                                setValidoCampo(
                                                                    false
                                                                ),
                                                                Promise.reject()
                                                            );
                                                        }
                                                        setValidoCampo(true);
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            name="campo"
                                            label={
                                                <span
                                                    style={{
                                                        color: "rgba(0,0,0,1)",
                                                        paddingLeft: "2px",
                                                    }}
                                                >
                                                    Propriedade referente ao
                                                    talhão:
                                                </span>
                                            }
                                            colon={false}
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Selecione um dos campos..."
                                                optionFilterProp="children"
                                                onSelect={onSelectField}
                                                filterOption={(
                                                    input: any,
                                                    option: any
                                                ) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {Object.keys(
                                                    JSON.parse(shape)
                                                        .features[0].properties
                                                ).map(p => {
                                                    return (
                                                        <Option
                                                            key={uuidv4()}
                                                            value={p}
                                                        >
                                                            {p}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Selecione um campo",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            return (
                                                                setValidoSize(
                                                                    false
                                                                ),
                                                                Promise.reject()
                                                            );
                                                        }
                                                        setValidoSize(true);
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            name="size"
                                            label={
                                                <span
                                                    style={{
                                                        color: "rgba(0,0,0,1)",
                                                        paddingLeft: "2px",
                                                    }}
                                                >
                                                    Propriedade referente ao
                                                    tamanho:
                                                </span>
                                            }
                                            colon={false}
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Selecione um dos campos..."
                                                optionFilterProp="children"
                                                onSelect={onSelectSize}
                                                filterOption={(
                                                    input: any,
                                                    option: any
                                                ) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {Object.keys(
                                                    JSON.parse(shape)
                                                        .features[0].properties
                                                ).map(p => {
                                                    return (
                                                        <Option
                                                            key={uuidv4()}
                                                            value={p}
                                                        >
                                                            {p}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form.Item>
                )}
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={handleDisable()}
                        loading={status.fazenda?.add?.loading}
                    >
                        Salvar fazenda
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarFazenda,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarFazenda);
