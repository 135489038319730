import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import { Spinner } from "../../../../styles/Main";
import styled from "styled-components";
import { ITheme } from "../../../../reducers/ThemeReducer";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { IOperacional } from "../../../../reducers/RelatorioReducer";
import { ITipoOpList } from "../../../../reducers/TipoOpReducer";
import { IFazenda, IFazendaList } from "../../../../reducers/FazendaReducer";
import { getDaysArray, segParaHoras } from "../../../../config/helpers";
import { Button, Empty, Form, Select, Table, Tabs, Tooltip } from "antd";
import { BarChartOutlined, TableOutlined } from "@ant-design/icons";
import TabelaOperacional from "./TabelaOperacional";

import { Table as ReactTable } from "@tanstack/react-table";

const { TabPane } = Tabs;

interface IEstiloProps {
    quartos?: any;
    tema?: boolean;
}

const EstiloBoxGeral = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
`;
const EstiloBox = styled.div<IEstiloProps>`
    display: flex;
    flex-direction: column;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    min-height: 200px;
    width: calc(
        ${props => {
                switch (props.quartos) {
                    case 1:
                        return "25%";
                    case 2:
                        return "50%";
                    case 3:
                        return "75%";
                    case 4:
                        return "100%";
                    default:
                        return "25%";
                }
            }} - 5px
    );
    // justify-content: space-between;
    padding: 10px;
    gap: 10px;
    background-color: ${props => (props.tema ? "#424242" : "#e8e8e8")};
`;
const EstiloBoxTitulo = styled.div`
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
`;
const EstiloDivCabecalho = styled.div`
    display: flex;
    white-space: nowrap;
`;
const EstiloBoxRodape = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface DashProps {
    tiposOp: ITipoOpList;
    fazendas: IFazendaList;

    tema: ITheme;
    operacional: IOperacional;
    dataIni: any;
    dataFin: any;
    faz: any;
    tipoOp: any;
    fazSelec: any;

    setDialog: Function;
    setVisivel: Function;
    setFazSelec: Function;

    colunas: any;
    data: any;
}

function DashOperacional(props: DashProps) {
    const { tiposOp, fazendas } = props;

    const [form] = Form.useForm();

    const [series, setSeries] = useState(Array);
    const [seriesLine, setSeriesLine] = useState(Array);
    const [talhoes, setTalhoes] = useState(Array);

    const [iniFinOpe, setIniFinOpe] = useState(Object);

    const [tabAtiva, setTabAtiva] = useState(1);

    const [display, setDisplay] = useState(false);

    const [tabela, setTabela] = useState<any>();

    const dataI =
        props.dataIni !== undefined
            ? new Date(props.dataIni).toLocaleDateString()
            : "";
    const dataF =
        props.dataFin !== undefined
            ? new Date(props.dataFin).toLocaleDateString()
            : "";

    function expExcel(table: ReactTable<any>) {
        let orderTemp: any = table.getState().columnOrder;

        let newCols = props.colunas.sort((a: any, b: any) => {
            return orderTemp.indexOf(a.id) - orderTemp.indexOf(b.id);
        });

        let colunas = newCols.map((c: any) => {
            return {
                name: c.header,
                filterButton: true,
            };
        });

        let rows = table.getCoreRowModel().rows;
        let cols: any = table.getAllColumns();

        let colOrder = cols.sort((a: any, b: any) => {
            return orderTemp.indexOf(a.id) - orderTemp.indexOf(b.id);
        });

        let excelT = rows.map((row: any) => {
            let temp = JSON.parse(JSON.stringify(row.original));
            colOrder.forEach((col: any) => {
                let aaa = row.getVisibleCells().filter((cell: any) => {
                    if (
                        cell.getContext().column.columnDef.accessorKey ===
                        col.columnDef.accessorKey
                    ) {
                        return cell.getContext();
                    }
                });

                if (
                    col.columnDef.accessorKey === "ha" ||
                    col.columnDef.accessorKey === "consumo"
                ) {
                    temp[col.columnDef.accessorKey] = Number(
                        temp[col.columnDef.accessorKey].toFixed(2)
                    );
                } else {
                    temp[col.columnDef.accessorKey] = colOrder[
                        colOrder.findIndex(
                            (i: any) =>
                                i.columnDef.accessorKey ===
                                col.columnDef.accessorKey
                        )
                    ].columnDef.cell(aaa[0]);
                }
            });
            return temp;
        });

        const ExcelJS = require("exceljs");
        const workbook = new ExcelJS.Workbook();
        workbook.creator = "AGROCHIP";
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet(
            "Planilha Operacional Agrochip",
            {}
        );

        let linhas = excelT.map((d: any) => {
            let keys: any = [];
            newCols.forEach((c: any) => {
                keys.push(d[c.accessorKey]);
            });
            return keys;
        });

        worksheet.addTable({
            name: "Tabela",
            ref: "A2",
            style: {
                theme: "TableStyleMedium22",
                showRowStripes: true,
            },
            headerRow: true,
            totalsRow: true,

            columns: colunas,

            rows: linhas,
        });

        worksheet.headerFooter.oddFooter = "Page &P of &N";
        worksheet.headerFooter.addHeader = '&C&KCCCCCC&"Nunito"Agrochip';

        worksheet.columns.forEach((column: any, i: any) => {
            var maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, (cell: any) => {
                var columnLength = cell.value
                    ? cell.value.toString().length
                    : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength + 3;
        });

        workbook.xlsx.writeBuffer().then(function (data: any) {
            var blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            const FileSaver = require("file-saver");
            FileSaver.saveAs(blob, "Planilha Operacional Agrochip.xlsx");
        });
    }

    useEffect(() => {
        setTimeout(() => setDisplay(true), 1);
    }, []);

    useEffect(() => {
        let arr: any = [];
        if (props.operacional.length > 0) {
            props.operacional.forEach((o: any) => {
                if (o.talhoes.length > 0) {
                    return arr.push({
                        d: o.date,
                        t: o.talhoes,
                        i: o.fazenda_temp,
                    });
                }
            });
        }

        setTalhoes(arr);
    }, [props.operacional]);

    useEffect(() => {
        let haGraf: any = [];
        let haOpeGraf: any = [];
        let haNaoIdGraf: any = [];
        let newArr: any = [];

        if (props.operacional.length > 0) {
            let filtroGraf = props.operacional.filter((f: any) => {
                if (props.fazSelec === "") {
                    return f.fazenda_temp === fazendas[0].id_fazenda;
                } else return f.fazenda_temp === props.fazSelec;
            });

            if (filtroGraf.length > 0) {
                filtroGraf.forEach((o: any) => {
                    let arrDate = o.date.split("-");
                    haGraf.push({
                        x: arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0],
                        y: o.ha_ope + o.ha_nao_id,
                    });
                    haOpeGraf.push({
                        x: arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0],
                        y: o.ha_ope,
                    });
                    haNaoIdGraf.push({
                        x: arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0],
                        y: o.ha_nao_id,
                    });
                });

                haGraf = [{ id: filtroGraf[0].fazenda_temp, ha: [...haGraf] }];
                haNaoIdGraf = [
                    { id: filtroGraf[0].fazenda_temp, ha: [...haNaoIdGraf] },
                ];
                haOpeGraf = [
                    { id: filtroGraf[0].fazenda_temp, ha: [...haOpeGraf] },
                ];
            }

            if (haGraf.length > 0) {
                haGraf.forEach((h: any) => {
                    newArr.push({
                        name:
                            "Total de Hectares - Faz. " +
                            fazendas[
                                fazendas.findIndex(
                                    (f: IFazenda) => f.id_fazenda === h.id
                                )
                            ].nome,
                        data: h.ha,
                    });
                });
            }
            if (haNaoIdGraf.length > 0) {
                let newArrr: any = [];
                haNaoIdGraf.forEach((h: any) => {
                    newArrr = h.ha.filter((e: any) => {
                        return e.y > 0;
                    });
                    if (newArrr.length > 0) {
                        newArr.push({
                            name:
                                "Hectares Não Id. - Faz. " +
                                fazendas[
                                    fazendas.findIndex(
                                        (f: IFazenda) => f.id_fazenda === h.id
                                    )
                                ].nome,
                            data: h.ha,
                        });
                    }
                });
            }
            if (haOpeGraf.length > 0) {
                let newArrr: any = [];
                haOpeGraf.forEach((h: any) => {
                    newArrr = h.ha.filter((e: any) => {
                        return e.y > 0;
                    });
                    if (newArrr.length > 0) {
                        newArr.push({
                            name:
                                "Hectares Operacionais - Faz. " +
                                fazendas[
                                    fazendas.findIndex(
                                        (f: IFazenda) => f.id_fazenda === h.id
                                    )
                                ].nome,
                            data: h.ha,
                        });
                    }
                });
            }
        }

        setSeries(newArr);
    }, [props.operacional, fazendas, props.fazSelec]);

    useEffect(() => {
        let arr: any = [];
        if (props.operacional.length > 0) {
            props.operacional.map((o: any) => {
                if (o.inicio_op !== undefined) {
                    return (arr = {
                        inicio: o.inicio_op.split(" ")[1].split(".")[0],
                        fim: o.final_op.split(" ")[1].split(".")[0],
                    });
                } else return (arr = {});
            });
        }

        setIniFinOpe(arr);
    }, [props.operacional]);

    useEffect(() => {
        let hrGraf: any = [];

        let arrTeste: any = [];

        let newarrr: any = [];

        if (props.operacional.length > 0) {
            let daylist = getDaysArray(
                new Date(
                    typeof props.dataIni === "object"
                        ? props.dataIni._i.split("T")[0]
                        : props.dataIni.split("T")[0]
                ),
                new Date(
                    typeof props.dataFin === "object"
                        ? props.dataFin._i.split("T")[0]
                        : props.dataFin.split("T")[0]
                )
            );

            props.operacional.forEach((o: any) => {
                let arrDate = o.date.split("-");

                const matchingIndex = hrGraf.findIndex(
                    (item: any) => item.id === o.fazenda_temp
                );

                if (matchingIndex !== -1) {
                    hrGraf[matchingIndex].horas.push({
                        x: arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0],
                        y:
                            segParaHoras(o.total_horas, "horas").h +
                            "." +
                            segParaHoras(o.total_horas, "horas").m,
                    });
                } else {
                    hrGraf.push({
                        id: o.fazenda_temp,
                        horas: [
                            {
                                x:
                                    arrDate[2] +
                                    "/" +
                                    arrDate[1] +
                                    "/" +
                                    arrDate[0],
                                y:
                                    segParaHoras(o.total_horas, "horas").h +
                                    "." +
                                    segParaHoras(o.total_horas, "horas").m,
                            },
                        ],
                    });
                }
            });

            hrGraf.forEach((h: any) => {
                arrTeste.push({
                    id: h.id,
                    horas: daylist.map((d: any) => {
                        let arrDate = d.split("-");
                        let newDia =
                            arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];

                        if (
                            h.horas.findIndex((i: any) => newDia === i.x) === -1
                        ) {
                            return { x: newDia, y: 0 };
                        } else {
                            return {
                                x: h.horas[
                                    h.horas.findIndex(
                                        (i: any) => newDia === i.x
                                    )
                                ].x,
                                y: h.horas[
                                    h.horas.findIndex(
                                        (i: any) => newDia === i.x
                                    )
                                ].y,
                            };
                        }
                    }),
                });
            });
            if (arrTeste.length > 0) {
                arrTeste.forEach((h: any) => {
                    newarrr.push({
                        name:
                            "Total de Horas" +
                            " - Faz. " +
                            fazendas[
                                fazendas.findIndex(
                                    (f: IFazenda) => f.id_fazenda === h.id
                                )
                            ].nome,
                        data: h.horas,
                    });
                });
            }
        }

        setSeriesLine(newarrr);
    }, [props, fazendas]);

    return (
        <>
            <div
                style={{
                    zIndex: 1056,
                    position: "absolute",
                    display: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(3px)",
                    width: "100%",
                    height: "calc(100vh - 210px)",
                }}
            >
                <Spinner view={true}>
                    <i
                        className="fas fa-spinner"
                        style={{ fontSize: "50px", color: "#000" }}
                    ></i>
                </Spinner>
            </div>
            <div
                style={{
                    overflowY: "auto",
                    height: "calc(100vh - 200px)",
                    zIndex: "1055",
                    padding: "10px",
                    marginTop: "-10px",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        height: "40px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                    }}
                >
                    {" "}
                    {props.faz.length > 0 && props.tipoOp !== undefined && (
                        <>
                            <EstiloDivCabecalho>
                                <EstiloBoxTitulo
                                    style={{
                                        marginLeft: "0px",
                                    }}
                                >
                                    Periodo:
                                </EstiloBoxTitulo>
                                <div
                                    style={{
                                        marginLeft: "15px",
                                        fontSize: "16px",
                                    }}
                                >
                                    {dataI} até {dataF}
                                </div>
                            </EstiloDivCabecalho>
                            <EstiloDivCabecalho
                                style={{
                                    maxWidth: `${
                                        Number(tabAtiva) === 2
                                            ? Object.keys(iniFinOpe).length > 0
                                                ? "calc(18%)"
                                                : "calc(35%)"
                                            : "calc(50%)"
                                    }`,
                                }}
                            >
                                <EstiloBoxTitulo>
                                    {props.faz.length > 1
                                        ? "Fazendas:"
                                        : "Fazenda:"}
                                </EstiloBoxTitulo>

                                <Tooltip
                                    title={
                                        <>
                                            {props.faz && props.faz.length > 1
                                                ? props.faz.map(
                                                      (n: any, index: any) => {
                                                          if (n !== "") {
                                                              return (
                                                                  (index
                                                                      ? ", "
                                                                      : "") +
                                                                  fazendas[
                                                                      fazendas.findIndex(
                                                                          (
                                                                              f: any
                                                                          ) =>
                                                                              f.id_fazenda ===
                                                                              n
                                                                      )
                                                                  ].nome
                                                              );
                                                          } else return "Todas";
                                                      }
                                                  )
                                                : props.faz[0] !== ""
                                                ? fazendas[
                                                      fazendas.findIndex(
                                                          (f: any) =>
                                                              f.id_fazenda ===
                                                              props.faz[0]
                                                      )
                                                  ].nome
                                                : "Todas"}
                                        </>
                                    }
                                >
                                    <div
                                        style={{
                                            marginLeft: "15px",
                                            fontSize: "16px",

                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {props.faz && props.faz.length > 1
                                            ? props.faz.map(
                                                  (n: any, index: any) => {
                                                      if (n !== "") {
                                                          return (
                                                              (index
                                                                  ? ", "
                                                                  : "") +
                                                              fazendas[
                                                                  fazendas.findIndex(
                                                                      (
                                                                          f: any
                                                                      ) =>
                                                                          f.id_fazenda ===
                                                                          n
                                                                  )
                                                              ].nome
                                                          );
                                                      } else return "Todas";
                                                  }
                                              )
                                            : props.faz[0] !== ""
                                            ? fazendas[
                                                  fazendas.findIndex(
                                                      (f: any) =>
                                                          f.id_fazenda ===
                                                          props.faz[0]
                                                  )
                                              ].nome
                                            : "Todas"}
                                    </div>
                                </Tooltip>
                            </EstiloDivCabecalho>
                            <EstiloDivCabecalho
                                style={{
                                    maxWidth: `${
                                        Number(tabAtiva) === 2
                                            ? Object.keys(iniFinOpe).length > 0
                                                ? "calc(20%)"
                                                : "calc(35%)"
                                            : "calc(50%)"
                                    }`,
                                }}
                            >
                                <EstiloBoxTitulo>
                                    {props.tipoOp === "" ||
                                    props.tipoOp.length > 1
                                        ? "Tipos de Operação:"
                                        : "Tipo de Operação:"}
                                </EstiloBoxTitulo>
                                <Tooltip
                                    title={
                                        <>
                                            {props.tipoOp.length > 0
                                                ? props.tipoOp.map(
                                                      (o: any, index: any) => {
                                                          if (o !== "") {
                                                              return (
                                                                  (index
                                                                      ? ", "
                                                                      : "") +
                                                                  (o === 0
                                                                      ? "Não Identificado"
                                                                      : tiposOp[
                                                                            tiposOp.findIndex(
                                                                                (
                                                                                    t: any
                                                                                ) =>
                                                                                    t.id_tipo_operacao ===
                                                                                    o
                                                                            )
                                                                        ]
                                                                            .descricao)
                                                              );
                                                          } else return "Todos";
                                                      }
                                                  )
                                                : ""}
                                        </>
                                    }
                                >
                                    <div
                                        style={{
                                            marginLeft: "15px",
                                            fontSize: "16px",

                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {props.tipoOp.length > 0
                                            ? props.tipoOp.map(
                                                  (o: any, index: any) => {
                                                      if (o !== "") {
                                                          return (
                                                              (index
                                                                  ? ", "
                                                                  : "") +
                                                              (o === 0
                                                                  ? "Não Identificado"
                                                                  : tiposOp[
                                                                        tiposOp.findIndex(
                                                                            (
                                                                                t: any
                                                                            ) =>
                                                                                t.id_tipo_operacao ===
                                                                                o
                                                                        )
                                                                    ].descricao)
                                                          );
                                                      } else return "Todos";
                                                  }
                                              )
                                            : ""}
                                    </div>
                                </Tooltip>
                            </EstiloDivCabecalho>
                        </>
                    )}
                    {Object.keys(iniFinOpe).length > 0 && (
                        <>
                            <EstiloDivCabecalho>
                                <EstiloBoxTitulo>
                                    Inicio da Operação:
                                </EstiloBoxTitulo>
                                <div
                                    style={{
                                        marginLeft: "15px",
                                        fontSize: "16px",
                                    }}
                                >
                                    {iniFinOpe.inicio}
                                </div>
                            </EstiloDivCabecalho>
                            <EstiloDivCabecalho>
                                <EstiloBoxTitulo>
                                    Final da Operação:
                                </EstiloBoxTitulo>
                                <div
                                    style={{
                                        marginLeft: "15px",
                                        fontSize: "16px",
                                    }}
                                >
                                    {iniFinOpe.fim}
                                </div>
                            </EstiloDivCabecalho>
                        </>
                    )}
                    {Number(tabAtiva) === 2 && (
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                columnGap: "5px",
                                justifyContent: "flex-end",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "-5px",
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        props.setDialog(2);
                                        props.setVisivel(true);
                                    }}
                                >
                                    Colunas
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "-5px",
                                }}
                            >
                                <Button
                                    disabled={
                                        props.colunas.length > 0 ? false : true
                                    }
                                    onClick={() => expExcel(tabela)}
                                >
                                    Exportar
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <Tabs
                    hideAdd={true}
                    tabPosition="left"
                    size="small"
                    tabBarGutter={2}
                    tabBarStyle={{
                        width: "50px",
                    }}
                    onChange={(o: any) => {
                        setTabAtiva(o);
                    }}
                >
                    <TabPane
                        tab={
                            <BarChartOutlined
                                style={{
                                    marginLeft: "-5px",
                                }}
                            />
                        }
                        key={1}
                    >
                        <EstiloBoxGeral>
                            {props.operacional.length > 0 ? (
                                <>
                                    <EstiloBox tema={props.tema} quartos={4}>
                                        <EstiloBoxTitulo>
                                            Total de Hectares
                                        </EstiloBoxTitulo>
                                        {!display ? (
                                            <></>
                                        ) : (
                                            <>
                                                {" "}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            columnGap: "5px",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Form
                                                            form={form}
                                                            initialValues={{
                                                                data:
                                                                    props.fazSelec ===
                                                                    ""
                                                                        ? fazendas[0]
                                                                              .nome
                                                                        : fazendas[
                                                                              fazendas.findIndex(
                                                                                  (
                                                                                      f: any
                                                                                  ) =>
                                                                                      f.id_fazenda ===
                                                                                      props.fazSelec
                                                                              )
                                                                          ]
                                                                              .nome,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "flex-start",
                                                                    columnGap:
                                                                        "5px",
                                                                    alignItems:
                                                                        "stretch",
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <Form.Item name="data">
                                                                    <Select
                                                                        style={{
                                                                            minWidth:
                                                                                "120px",
                                                                            width: "100%",
                                                                            backgroundColor:
                                                                                props.tema
                                                                                    ? "#757575"
                                                                                    : "",
                                                                        }}
                                                                        onSelect={(
                                                                            value: any
                                                                        ) => {
                                                                            props.setFazSelec(
                                                                                value
                                                                            );
                                                                        }}
                                                                    >
                                                                        {props.faz.map(
                                                                            (
                                                                                d: any
                                                                            ) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        value={
                                                                                            d
                                                                                        }
                                                                                        key={
                                                                                            d
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            fazendas[
                                                                                                fazendas.findIndex(
                                                                                                    (
                                                                                                        f: any
                                                                                                    ) =>
                                                                                                        f.id_fazenda ===
                                                                                                        d
                                                                                                )
                                                                                            ]
                                                                                                .nome
                                                                                        }
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </Form.Item>{" "}
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                                <Chart
                                                    type="bar"
                                                    series={series}
                                                    options={{
                                                        theme: {
                                                            mode: props.tema
                                                                ? "dark"
                                                                : "light",
                                                        },
                                                        colors: [
                                                            "#bcd631",
                                                            "#205fad",
                                                            "#75c043",
                                                        ],
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal:
                                                                    false,
                                                                barHeight:
                                                                    "70%",
                                                            },
                                                        },
                                                        markers: {
                                                            size: 0,
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: [
                                                                "transparent",
                                                            ],
                                                        },
                                                        fill: {
                                                            opacity: 1,
                                                        },
                                                        dataLabels: {
                                                            enabled: false,
                                                        },
                                                        xaxis: {
                                                            labels: {
                                                                show: false,
                                                            },
                                                        },
                                                        yaxis: {
                                                            max(max) {
                                                                return max;
                                                            },
                                                            tickAmount: 5,
                                                            labels: {
                                                                show: true,
                                                                formatter(
                                                                    val,
                                                                    opts
                                                                ) {
                                                                    return (
                                                                        val.toFixed(
                                                                            2
                                                                        ) +
                                                                        " ha"
                                                                    );
                                                                },
                                                            },
                                                        },
                                                        tooltip: {
                                                            fillSeriesColor:
                                                                false,
                                                            theme: props.tema
                                                                ? "dark"
                                                                : "light",
                                                            enabled: true,
                                                            x: {
                                                                show: true,
                                                            },
                                                            y: {
                                                                formatter: (
                                                                    value: any,
                                                                    w: any
                                                                ) => {
                                                                    if (
                                                                        w.seriesIndex ===
                                                                            0 ||
                                                                        w.seriesIndex ===
                                                                            1 ||
                                                                        w.seriesIndex ===
                                                                            2
                                                                    ) {
                                                                        return (
                                                                            value.toFixed(
                                                                                2
                                                                            ) +
                                                                            " ha"
                                                                        );
                                                                    } else
                                                                        return "";
                                                                },
                                                            },
                                                        },
                                                        chart: {
                                                            id: "graf",
                                                            stacked: false,
                                                            toolbar: {
                                                                autoSelected:
                                                                    "pan",
                                                                show: false,
                                                            },
                                                        },
                                                    }}
                                                    height={230}
                                                />
                                            </>
                                        )}
                                    </EstiloBox>

                                    <EstiloBox tema={props.tema} quartos={2}>
                                        <EstiloBoxTitulo>
                                            Total de Horas
                                        </EstiloBoxTitulo>
                                        {!display ? (
                                            <></>
                                        ) : (
                                            <Chart
                                                type="line"
                                                series={seriesLine}
                                                options={{
                                                    theme: {
                                                        mode: props.tema
                                                            ? "dark"
                                                            : "light",
                                                    },
                                                    colors: [
                                                        "#75c043",
                                                        "#093061",
                                                        "#bcd631",
                                                        "#008ed1",
                                                        "#006830",
                                                        "#205fad",
                                                        "#ABFF73",
                                                        "#91C1FB",
                                                        "#45E329",
                                                        "#2212E0",
                                                        "#00B554",
                                                        "#0C68E8",
                                                        "#E5FF54",
                                                        "#261C94",
                                                        "#36B571",
                                                        "#2266BA",
                                                        "#69BF30",
                                                    ],
                                                    stroke: {
                                                        curve: "smooth",
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        labels: {
                                                            show: false,
                                                        },
                                                    },
                                                    yaxis: {
                                                        show: true,
                                                        forceNiceScale: true,
                                                        min: 0,
                                                        max: function (
                                                            max: number
                                                        ) {
                                                            return max + 1;
                                                        },
                                                        labels: {
                                                            show: true,
                                                            minWidth: 0,
                                                            formatter(
                                                                val,
                                                                opts
                                                            ) {
                                                                return val
                                                                    .toString()
                                                                    .split(
                                                                        "."
                                                                    )[0];
                                                            },
                                                        },
                                                    },
                                                    tooltip: {
                                                        shared: false,
                                                        fillSeriesColor: false,
                                                        theme: props.tema
                                                            ? "dark"
                                                            : "light",
                                                        enabled: true,
                                                        x: {
                                                            show: true,
                                                        },
                                                        y: {
                                                            formatter: (
                                                                value: any,
                                                                w: any
                                                            ) => {
                                                                if (
                                                                    w.series
                                                                        .length >
                                                                    0
                                                                ) {
                                                                    let ret =
                                                                        "";
                                                                    if (
                                                                        value !==
                                                                        undefined
                                                                    ) {
                                                                        if (
                                                                            value
                                                                                .toString()
                                                                                .split(
                                                                                    "."
                                                                                )[1] !==
                                                                            undefined
                                                                        ) {
                                                                            ret =
                                                                                value
                                                                                    .toString()
                                                                                    .split(
                                                                                        "."
                                                                                    )[0] +
                                                                                "h" +
                                                                                value
                                                                                    .toString()
                                                                                    .split(
                                                                                        "."
                                                                                    )[1] +
                                                                                "min";
                                                                        } else
                                                                            ret =
                                                                                value
                                                                                    .toString()
                                                                                    .split(
                                                                                        "."
                                                                                    )[0] +
                                                                                "h00min";
                                                                        return ret;
                                                                    } else
                                                                        return "";
                                                                } else
                                                                    return "";
                                                            },
                                                        },
                                                    },
                                                    chart: {
                                                        id: "grafLine",
                                                        zoom: {
                                                            enabled: false,
                                                        },
                                                        toolbar: {
                                                            autoSelected: "pan",
                                                            show: false,
                                                        },
                                                    },
                                                }}
                                                height={280}
                                            />
                                        )}
                                    </EstiloBox>

                                    <EstiloBox tema={props.tema} quartos={2}>
                                        <EstiloBoxTitulo>
                                            Operações nos Talhões
                                        </EstiloBoxTitulo>
                                        {talhoes.length > 0 ? (
                                            <div
                                                style={{
                                                    padding: "10px",
                                                    maxHeight: "280px",
                                                    overflow: "auto",
                                                    position: "relative",
                                                    zIndex: 1,
                                                }}
                                            >
                                                {" "}
                                                {talhoes.map((t: any) => {
                                                    let arrDate =
                                                        t.d.split("-");

                                                    return t.t.map((o: any) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    padding:
                                                                        "3px",
                                                                    borderBottom:
                                                                        props.tema
                                                                            ? "1px solid #FFF"
                                                                            : "1px solid rgb(217, 217, 217)",
                                                                    marginBottom:
                                                                        "2px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                        whiteSpace:
                                                                            "nowrap",
                                                                        overflow:
                                                                            "hidden",
                                                                        textOverflow:
                                                                            "ellipsis",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                {o.tipo_op +
                                                                                    " - " +
                                                                                    arrDate[2] +
                                                                                    "/" +
                                                                                    arrDate[1] +
                                                                                    "/" +
                                                                                    arrDate[0] +
                                                                                    " - " +
                                                                                    o.nome_equipa +
                                                                                    " - " +
                                                                                    o.nome_operador}
                                                                            </>
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <strong>
                                                                                {
                                                                                    o.nome_talhao
                                                                                }
                                                                            </strong>
                                                                            {" - " +
                                                                                " Faz. " +
                                                                                fazendas[
                                                                                    fazendas.findIndex(
                                                                                        (
                                                                                            f: any
                                                                                        ) =>
                                                                                            f.id_fazenda ===
                                                                                            t.i
                                                                                    )
                                                                                ]
                                                                                    .nome +
                                                                                " - " +
                                                                                o.tipo_op +
                                                                                " - " +
                                                                                arrDate[2] +
                                                                                "/" +
                                                                                arrDate[1] +
                                                                                "/" +
                                                                                arrDate[0]}
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                whiteSpace:
                                                                                    "nowrap",
                                                                                overflow:
                                                                                    "hidden",
                                                                                textOverflow:
                                                                                    "ellipsis",
                                                                            }}
                                                                        >
                                                                            {o.nome_equipa +
                                                                                " - " +
                                                                                o.nome_operador}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        );
                                                    });
                                                })}
                                            </div>
                                        ) : (
                                            <Empty
                                                description="Sem dados para exibição"
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginTop: "70px",
                                                }}
                                            />
                                        )}
                                    </EstiloBox>
                                </>
                            ) : (
                                <Empty
                                    description="Sem dados para exibição"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "70px",
                                    }}
                                />
                            )}
                        </EstiloBoxGeral>
                    </TabPane>
                    <TabPane
                        tab={
                            <TableOutlined
                                style={{
                                    marginLeft: "-5px",
                                }}
                            />
                        }
                        key={2}
                    >
                        <EstiloBoxGeral>
                            {props.colunas.length > 0 ? (
                                <TabelaOperacional
                                    columns={props.colunas}
                                    data={props.data}
                                    setTabela={setTabela}
                                />
                            ) : (
                                <Empty
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                    description="Nenhuma coluna foi selecionada."
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            )}
                        </EstiloBoxGeral>
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        tiposOp: store.tiposOp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashOperacional); //
