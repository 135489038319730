import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IStore } from "../../../store";
import { useEffect, useMemo, useState } from "react";
import MenuOperacional from "./Operacional/MenuOperacional";
import { Empty, Select, Tabs } from "antd";
import { IFazenda, IFazendaList } from "../../../reducers/FazendaReducer";
import { ITipoOp, ITipoOpList } from "../../../reducers/TipoOpReducer";
import DashOperacional from "./Operacional/DashOperacional";
import { ITheme } from "../../../reducers/ThemeReducer";
import {
    getRelOperacional,
    getTabOperacional,
    setRelOperacional,
} from "../../../store/relatorioAction";
import {
    IOperacional,
    ITabOperacional,
} from "../../../reducers/RelatorioReducer";
import { Spinner } from "../../../styles/Main";
import { IStatusList, ITalhaoTablet } from "../../../store/types";
import ModalOperacional from "./Operacional/ModalOperacional";
import { v4 as uuidv4 } from "uuid";
import { SearchOutlined } from "@ant-design/icons";
import { segParaHoras } from "../../../config/helpers";

import { ColumnDef } from "@tanstack/react-table";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../reducers/EquipamentoReducer";
import {
    IImplemento,
    IImplementoList,
} from "../../../reducers/ImplementoReducer";
import { IOperador, IOperadorList } from "../../../reducers/OperadorReducer";

const { TabPane } = Tabs;

export interface IOpeProps {
    fazendas: IFazendaList;
    tiposOp: ITipoOpList;
    equipamentos: IEquipamentoList;
    implementos: IImplementoList;
    talhoes: ITalhaoTablet[];
    operadores: IOperadorList;

    relOperacional: IOperacional;
    tabOperacional: ITabOperacional;

    loader: IStatusList;
    tema: ITheme;

    getRelOperacional: Function;
    setRelOperacional: Function;
    getTabOperacional: Function;
}

var dataSource: any = [];

function Operacional(props: IOpeProps) {
    const {
        tema,
        loader,
        tabOperacional,
        relOperacional,

        fazendas,
        tiposOp,
        equipamentos,
        implementos,
        talhoes,
        operadores,

        getRelOperacional,
        setRelOperacional,
        getTabOperacional,
    } = props;

    const [visivel, setVisivel] = useState(true);

    const [fazList, setFazList] = useState<any>([]);
    const [fazSelec, setFazSelec] = useState("");

    const [tabAtiva, setTabAtiva] = useState(1);
    const [tabs, setTabs] = useState([
        {
            title: "Operacional",
            infos: {
                datas: [],
                faz: [],
                tipo_operacao: [],
            },
            colunas: [],
            key: 1,
        },
    ]);

    const [dialog, setDialog] = useState(1);
    const [colunasLocal, setColunasLocal] = useState<any>([]);
    const [view, setView] = useState(false);

    let tzoffset = new Date().getTimezoneOffset() * 60000;

    const colunasOp = useMemo<ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "fazenda",
                id: "fazenda",
                header: "Fazenda",
                cell: (info: any) => {
                    return fazendas[
                        fazendas.findIndex(
                            (f: IFazenda) => f.id_fazenda === info.getValue()
                        )
                    ].nome;
                },
                /**
                 * override the value used for row grouping
                 * (otherwise, defaults to the value derived from accessorKey / accessorFn)
                 */
                // getGroupingValue: (row) => `${row.firstName} ${row.lastName}`,
            },
            {
                accessorKey: "talhao",
                id: "talhao",
                header: "Talhão",
                cell: (info: any) => {
                    if (
                        info.getValue() === undefined ||
                        info.getValue().includes("NÃO")
                    ) {
                        return "Não Identificado";
                    } else {
                        return talhoes[
                            talhoes.findIndex(
                                (t: ITalhaoTablet) =>
                                    t.id_talhao === info.getValue().toString()
                            )
                        ].nome_talhao;
                    }
                },
            },
            {
                accessorKey: "operador",
                id: "operador",
                header: "Operador",
                cell: (info: any) => {
                    if (
                        typeof info.getValue() === "string" ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return operadores[
                            operadores.findIndex(
                                (o: IOperador) =>
                                    o.id_operador === info.getValue()
                            )
                        ].nome;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
            },
            {
                accessorKey: "equipa",
                id: "equipa",
                header: "Equipamento",
                cell: (info: any) => {
                    if (info.getValue() !== null) {
                        let eKey: IEquipamento =
                            equipamentos[
                                equipamentos.findIndex(
                                    (e: IEquipamento) =>
                                        e.id_equipamento ===
                                        info.getValue().toString()
                                )
                            ];
                        return eKey.numero_patrimonio + " - " + eKey.descricao;
                    } else return "Não Identificado";
                },
            },
            {
                accessorKey: "implemento",
                id: "implemento",
                header: "Implemento",
                cell: (info: any) => {
                    if (
                        info.getValue() === undefined ||
                        info.getValue().includes("NÃO")
                    ) {
                        return "Não Identificado";
                    } else
                        return implementos[
                            implementos.findIndex(
                                (e: IImplemento) =>
                                    e.id_implemento ===
                                    info.getValue().toString()
                            )
                        ].descricao;
                },
            },
            {
                accessorKey: "operacao",
                id: "operacao",
                header: "Operação",
                cell: (info: any) => {
                    if (
                        typeof info.getValue() === "string" ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return tiposOp[
                            tiposOp.findIndex(
                                (o: ITipoOp) =>
                                    o.id_tipo_operacao === info.getValue()
                            )
                        ].descricao;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
            },
            {
                accessorKey: "dia",
                id: "dia",
                header: "Dia",
                cell: (info: any) => {
                    let arrDate = info.getValue().split("-");
                    return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
                },
            },
            {
                accessorKey: "horas",
                id: "horas",
                header: "Horas",
                cell: (info: any) => {
                    return (
                        segParaHoras(info.getValue(), "horas").h +
                        "h" +
                        segParaHoras(info.getValue(), "horas").m +
                        "m"
                    );
                },
                aggregatedCell: ({ getValue }: any) => {
                    return (
                        segParaHoras(getValue(), "horas").h +
                        "h" +
                        segParaHoras(getValue(), "horas").m +
                        "m"
                    );
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "ha",
                id: "ha",
                header: "Hectares",
                cell: (info: any) => info.getValue().toFixed(2) + " ha",
                aggregatedCell: ({ getValue }: any) =>
                    getValue().toFixed(2) + " ha",
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "consumo",
                id: "consumo",
                header: "Consumo",
                aggregationFn: "sum",
                enableGrouping: false,
                cell: (info: any) => info.getValue().toFixed(2) + "L",
                aggregatedCell: ({ getValue }: any) =>
                    getValue().toFixed(2) + "L",
                // aggregatedCell: ({ getValue }) => getValue().toLocaleString(),
            },
        ],
        []
    );

    useEffect(() => {}, [tabs]);

    useEffect(() => {}, [relOperacional]);
    useEffect(() => {}, [tabOperacional]);

    useEffect(() => {
        let aba =
            tabs[
                tabs.findIndex((a: any) => {
                    return a.key === Number(tabAtiva);
                })
            ];
        if (aba !== undefined) {
            if (aba.infos.faz.length > 0) {
                let dataI: any = new Date(aba.infos.datas[0])
                    .toISOString()
                    .split(".")[0];
                let dataF: any = new Date(aba.infos.datas[1])
                    .toISOString()
                    .split(".")[0];

                getRelOperacional({
                    fazendas:
                        aba.infos.faz.length > 0 ? aba.infos.faz.join(",") : "",
                    dataIni: dataI.split("T")[0] + "T00:00:00",
                    dataFin: dataF.split("T")[0] + "T23:59:59",
                    operacoes:
                        aba.infos.tipo_operacao.length > 0
                            ? aba.infos.tipo_operacao.join(",")
                            : "",
                });

                if (Number(aba.key) === 1) {
                    getTabOperacional({
                        fazendas:
                            aba.infos.faz.length > 0
                                ? aba.infos.faz.join(",")
                                : "",
                        dataIni: dataI.split("T")[0] + "T00:00:00",
                        dataFin: dataF.split("T")[0] + "T23:59:59",
                        operacoes:
                            // aba.infos.tipo_operacao !== null
                            aba.infos.tipo_operacao.length > 0
                                ? aba.infos.tipo_operacao.join(",")
                                : "",
                    });
                }
            } else {
                setRelOperacional([]);
            }
        }
    }, [tabs, tabAtiva]); //

    useEffect(() => {
        let newArr: any = [];

        if (tabOperacional.length > 0) {
            tabOperacional.forEach((o: any) => {
                if (o.talhoes.length > 0) {
                    o.talhoes.map((t: any, index: any) => {
                        if (
                            t.tipo_operacao_temp === "NÃO IDENTIFICADO" ||
                            (t.tipo_operacao_temp !== "NÃO IDENTIFICADO" &&
                                t.ha_ope.toFixed(2) > 0)
                        ) {
                            return newArr.push({
                                key: index + 1,
                                fazenda: t.fazenda_temp,

                                talhao: t.talhao_temp,
                                operador: t.operador_temp,
                                operacao: t.tipo_operacao_temp,
                                equipa: t.equipamento_temp,
                                implemento: t.implemento_temp,
                                ha: t.ha_ope,
                                horas: t.horas_trabalhadas,
                                dia: t.date,
                                consumo: t.consumo_de_combustivel,
                            });
                        }
                    });
                }
            });
        }

        dataSource = newArr;
    }, [loader.relatorioOpe?.get?.success, tabOperacional]);

    useEffect(() => {
        setColunasLocal(
            colunasOp.filter((col: any) =>
                tabs[0].colunas?.some((c: any) => col.header === c.header)
            )
        );
    }, [tabs[0].colunas?.length]);

    useEffect(() => {
        if (tabs[0].infos.faz[0] === "") {
            setFazList(fazendas.map((f: any) => f.id_fazenda));
        } else {
            setFazList(tabs[0].infos.faz);
        }
        setFazSelec(tabs[0].infos.faz[0]);
    }, [tabAtiva, tabs, view]);

    useEffect(() => {
        if (relOperacional.length > 0) {
            setView(true);
        } else if (Object.keys(tabOperacional).length > 0) {
            setView(tabOperacional.some((t: any) => t.talhoes.length > 0));
        } else setView(false);
    }, [relOperacional, tabOperacional]);

    return (
        <>
            <div>
                <MenuOperacional
                    setVisivel={setVisivel}
                    setDialog={setDialog}
                />
                <Tabs
                    hideAdd={true}
                    onChange={(o: any) => {
                        setTabAtiva(o);
                    }}
                    tabBarStyle={{ paddingLeft: "10px" }}
                    className="opera-tab"
                    type="card"
                >
                    <TabPane tab={tabs[0].title} key={tabs[0].key}>
                        {view ? (
                            <DashOperacional
                                tema={tema}
                                operacional={relOperacional}
                                dataIni={
                                    tabs[0].infos.datas.length > 0
                                        ? tabs[0].infos.datas[0]
                                        : []
                                }
                                dataFin={
                                    tabs[0].infos.datas.length > 0
                                        ? tabs[0].infos.datas[1]
                                        : []
                                }
                                faz={fazList}
                                fazSelec={fazSelec}
                                setFazSelec={setFazSelec}
                                tipoOp={tabs[0].infos.tipo_operacao}
                                setDialog={setDialog}
                                setVisivel={setVisivel} //
                                colunas={colunasLocal}
                                data={dataSource}
                            />
                        ) : (
                            <Empty
                                description="Sem dados para exibição"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        )}
                    </TabPane>
                </Tabs>

                <ModalOperacional
                    setVisivel={setVisivel}
                    visivel={visivel}
                    dialog={dialog}
                    abaAtiva={tabAtiva}
                    infos={tabs}
                    setInfos={setTabs}
                    data={dataSource}
                    colunas={colunasOp}
                    colunasLocal={colunasLocal}
                    setColunasLocal={setColunasLocal}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(3px)",
                    zIndex: 1031,
                    display:
                        loader.relatorio?.get?.loading ||
                        loader.relatorioOpe?.get?.loading
                            ? "flex"
                            : "none", //
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spinner view={true}>
                    <i
                        className="fas fa-spinner"
                        style={{ fontSize: "50px", color: "#FFF" }}
                    ></i>
                </Spinner>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        tiposOp: store.tiposOp,
        equipamentos: store.equipamentos,
        implementos: store.implementos,
        talhoes: store.talhoesTablet,
        operadores: store.operadores,

        tema: store.theme,
        loader: store.loader,

        relOperacional: store.operacional,
        tabOperacional: store.tabOperacional,
    };
}
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getRelOperacional,
            setRelOperacional,

            getTabOperacional,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Operacional);
