import {
    SET_EQUIPAMENTO,
    SET_LIST_EQUIPAMENTO,
    SET_ONE_EQUIPAMENTO,
} from "../store/actionTypes";

export interface IUltOperacao {
    ignicaoON?: boolean;
    motorON?: boolean;
    velocidade?: number;
    totComb?: number;
    latitude?: string;
    longitude?: string;
    id_operacao?: string;
    rpm?: number;
    timestamp: string;
    nivelComb?: number;
    tipo_operacao_temp?: number | null;
    safra_temp?: number | null;
    cultura_temp?: number | null;
    fazenda_temp?: string;
    tipo_operacao_parada_temp?: number | null;
    talhao_temp?: string;
    temAgua?: number;
    pressaoOleo?: number;
    operador_temp?: number | null;
    implemento_temp?: number | null;
    alerta?: boolean;
    txtalerta?: string;
}

export interface IEquipamento {
    id_equipamento?: string;
    descricao: string;
    estilo: string;
    numero_patrimonio?: string;
    tipo?: string;
    estado?: string;
    comunicacao?: string;
    largura?: number;
    device_id?: string;
    serial?: string;
    last_seen_type?: string;
    last_seen?: string;
    ultima_operacao: IUltOperacao;
}

export type IEquipamentoList = IEquipamento[];
export interface IAttrEquipamento {
    estado: any[];
    tipos: any[];
    comunicacao: any[];
}

export interface IEquipamentoAction {
    type: any;
    payload: IEquipamento;
}

export interface IEquipamentoListAction {
    type: any;
    payload: IEquipamentoList | IEquipamento;
}

const Equipamento: IEquipamento = {
    id_equipamento: "",
    descricao: "",
    estilo: "",
    numero_patrimonio: "",
    tipo: "",
    estado: "",
    comunicacao: "",
    largura: 1,
    device_id: "",
    serial: "",
    last_seen: "",
    last_seen_type: "",
    ultima_operacao: {
        timestamp: "",
    },
};

const EquipamentoList: IEquipamentoList = [];

const AttrEquipamento: IAttrEquipamento = {
    estado: [
        {
            displayName: "Inativo",
            value: "-1",
            optionClass: "inativo",
        },
        {
            displayName: "Ativo",
            value: "1",
            optionClass: "ativo",
        },
        {
            displayName: "Manutenção",
            value: "0",
            optionClass: "manutencao",
        },
    ],
    tipos: [
        { id: 1, nome: "Trator" },
        { id: 2, nome: "Distribuidor" },
        { id: 3, nome: "Pulverizador" },
        { id: 4, nome: "Caminhão" },
        { id: 5, nome: "Comboio" },
        { id: 6, nome: "Colheitadeira" },
        { id: 7, nome: "Despendoador" },
    ],
    comunicacao: [
        { displayName: "Lora", value: "LORA" },
        { displayName: "4G", value: "ONLINE" },
        { displayName: "Offline", value: "OFFLINE" },
    ],
};

export const equipamentoReducer = (
    state = Equipamento,
    action: IEquipamentoAction
) => {
    switch (action.type) {
        case SET_EQUIPAMENTO:
            return action.payload;
        default:
            return state;
    }
};

export const equipamentoListReducer = (
    state = EquipamentoList,
    action: any //IEquipamentoListAction
) => {
    switch (action.type) {
        case SET_LIST_EQUIPAMENTO:
            return action.payload;
        case SET_ONE_EQUIPAMENTO:
            let newState = JSON.parse(JSON.stringify(state));
            let equId = newState.findIndex(
                (e: any) => e.id_equipamento === action.payload.id_equipamento
            );
            newState[equId] = action.payload;

            return newState;
        default:
            return state;
    }
};

export const attrEquipamento = (state = AttrEquipamento, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};
