//Métodos para os operadores

import { message } from "antd";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import {
    SET_FILTRO_TALHAO,
    SET_LIST_TALHAO_TABLET,
    SET_TALHAO_TABLET,
} from "./actionTypes";
import { ITalhaoTablet } from "./types";

export const setTalhao_tablet = (value: ITalhaoTablet) => ({
    type: SET_TALHAO_TABLET,
    payload: value,
});

export const setListTalhao_tablet = (value: ITalhaoTablet[]) => ({
    type: SET_LIST_TALHAO_TABLET,
    payload: value,
});

export const setFiltroTalhao = (value: ITalhaoTablet[]) => ({
    type: SET_FILTRO_TALHAO,
    payload: value,
});

export interface ITalhaoTabletModuleAction
    extends ITalhaoTablet,
        ReadableStream {}

export const getTalhoes = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "talhao_tablet", res.erro));
                } else {
                    dispatch(setListTalhao_tablet(res));
                    dispatch(setStatusSuccess("get", "talhao_tablet"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "talhao_tablet", error.data));
            });
    };
};

export const getFiltroTalhoes = (props: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/${props}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "talhao_tablet", res.erro));
                } else {
                    dispatch(setFiltroTalhao(res));
                    dispatch(setStatusSuccess("get", "talhao_tablet"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "talhao_tablet", error.data));
            });
    };
};

export const adicionarTalhao = (value: ITalhaoTabletModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "talhao_tablet", res.erro));
                } else {
                    dispatch(getTalhoes());
                    dispatch(setStatusSuccess("add", "talhao_tablet"));
                    message.success("Talhão adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "talhao_tablet", error.data));
            });
    };
};

export const deletarTalhao = (talhao: ITalhaoTabletModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/` + talhao, {
            method: "delete",
            headers: {
                "Content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "talhao_tablet", res.erro));
                } else {
                    dispatch(getTalhoes());
                    dispatch(setStatusSuccess("del", "talhao_tablet"));
                    message.success("O talhão selecionado foi excluído.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "talhao_tablet", error.data));
            });
    };
};

export const editarTalhao = (value: ITalhaoTabletModuleAction) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "talhao_tablet", res.erro));
                } else {
                    dispatch(getTalhoes());
                    dispatch(setStatusSuccess("edit", "talhao_tablet"));
                    message.success("Talhão atualizado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "talhao_tablet", error.data));
            });
    };
};

export const importarTalhao = (value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "talhao_tablet"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/talhao/list`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "talhao_tablet", res.erro));
                } else {
                    dispatch(getTalhoes());
                    dispatch(getFiltroTalhoes(value.dados[0].fazenda_temp));
                    dispatch(setStatusSuccess("add", "talhao_tablet"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "talhao_tablet", error.data));
            });
    };
};
