import { SET_TIPOPARADA, SET_LIST_TIPOPARADA } from "../store/actionTypes";

export interface ITipoParadaModule {
    tempo_parado?: number;
    tipo_parada?: string;
}

export type ITipoParada = ITipoParadaModule;

export type ITipoParadaList = ITipoParadaModule[];

export interface ITipoParadaAction {
    type: any;
    payload: ITipoParada;
}

export interface ISetListTipoOpAction {
    type: any;
    payload: ITipoParadaList;
}

const TipoParada = {} as ITipoParada;
const TipoParadaList = [] as ITipoParadaList;

export const tipoParadaReducer = (
    state = TipoParada,
    action: ITipoParadaAction
) => {
    switch (action.type) {
        case SET_TIPOPARADA:
            return action.payload;
        default:
            return state;
    }
};

export const tipoParadaListReducer = (
    state = TipoParadaList,
    action: ISetListTipoOpAction
) => {
    switch (action.type) {
        case SET_LIST_TIPOPARADA:
            return action.payload;
        default:
            return state;
    }
};
