import { message } from "antd";
import { IEquipamento, IEquipamentoList } from "../reducers/EquipamentoReducer";
import {
    setStatusError,
    setStatusStart,
    setStatusSuccess,
    loginProcessTokenExpired,
} from "./actions";
import {
    SET_EQUIPAMENTO,
    SET_LIST_EQUIPAMENTO,
    SET_ONE_EQUIPAMENTO,
} from "./actionTypes";

//Métodos para administração de EQUIPAMENTOS
export const setEquipamento = (value: IEquipamento) => ({
    type: SET_EQUIPAMENTO,
    payload: value,
});

export const setListEquipamento = (value: IEquipamentoList) => ({
    type: SET_LIST_EQUIPAMENTO,
    payload: value,
});

export const setOneEquipamento = (value: IEquipamento) => ({
    type: SET_ONE_EQUIPAMENTO,
    payload: value,
});

export const getEquipamentos = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "equipamento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/equipamento/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "equipamento", res.erro));
                } else {
                    dispatch(setListEquipamento(res));
                    dispatch(setStatusSuccess("get", "equipamento"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "equipamento", error.data));
            });
    };
};

export const getOneEquipamento = (equipaId: string) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "equipamento"));
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/equipamento/` + equipaId,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "equipamento", res.erro));
                } else {
                    dispatch(setEquipamento(res[0]));
                    dispatch(setOneEquipamento(res[0]));
                    dispatch(setStatusSuccess("get", "equipamento"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "equipamento", error.data));
            });
    };
};

interface IAddEquipamento {
    descricao: string;
    numero_patrimonio: string;
    tipo: string;
    estado: string;
    comunicacao: string;
}

export const adicionarEquipamento = (value: IAddEquipamento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "equipamento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/equipamento/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },

            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("add", "equipamento", res.erro));
                } else {
                    dispatch(getEquipamentos());
                    dispatch(setStatusSuccess("add", "equipamento"));
                    message.success("Equipamento adicionado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("add", "equipamento", error.data));
            });
    };
};

interface IEditEquipamento {
    id_equipamento: string;
    descricao: string;
    numero_patrimonio: string;
    tipo: string;
    estado: string;
    comunicacao: string;
}
export const editarEquipamento = (value: IEditEquipamento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "equipamento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/equipamento/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "equipamento", res.erro));
                } else {
                    dispatch(getEquipamentos());
                    dispatch(setStatusSuccess("edit", "equipamento"));
                    message.success("Equipamento editado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "equipamento", error.data));
            });
    };
};

interface IDeletEquipamento {
    value: string;
}

export const deletarEquipamento = (value: IDeletEquipamento) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "equipamento"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/equipamento/` + value, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "equipamento", res.erro));
                } else {
                    dispatch(getEquipamentos());
                    dispatch(setStatusSuccess("del", "equipamento"));
                    message.success("Equipamento deletado com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "equipamento", error.data));
            });
    };
};
