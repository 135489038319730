import {
    SET_SAFRA,
    SET_LIST_SAFRA,
    SET_SAFRA_CLIENTE,
    SET_LIST_SAFRA_CLIENTE,
} from "../store/actionTypes";

export interface ISafraModule {
    id_safra?: number;
    descricao?: string;
    cod_erp?: string;
}

export type ISafra = ISafraModule;

const Safra: ISafra = {};

export type ISafraList = ISafraModule[];

const SafraList: ISafraList = [];

interface ISafraReducerAction {
    type: any;
    payload: ISafra;
}
export const safraReducer = (state = Safra, action: ISafraReducerAction) => {
    switch (action.type) {
        case SET_SAFRA:
            return action.payload;
        default:
            return state;
    }
};

interface ISafraListReducerAction {
    type: any;
    payload: ISafraList;
}
export const safraListReducer = (
    state = SafraList,
    action: ISafraListReducerAction
) => {
    switch (action.type) {
        case SET_LIST_SAFRA:
            return action.payload;
        default:
            return state;
    }
};

export interface ISafraClienteModule {
    id_safra_cliente?: string;
    safra_temp?: number;
}

export type ISafraCliente = ISafraClienteModule;

export type ISafraClienteList = ISafraClienteModule[];

export interface ISafraClienteAction {
    type: any;
    payload: string;
}

export interface ISetListSafraClienteAction {
    type: any;
    payload: [];
}

const SafraCliente: ISafraCliente = {};

const SafraClienteList: ISafraClienteList = [];

export const safraClienteReducer = (
    state = SafraCliente,
    action: ISafraClienteAction
) => {
    switch (action.type) {
        case SET_SAFRA_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};

export const safraClienteListReducer = (
    state = SafraClienteList,
    action: ISafraClienteAction
) => {
    switch (action.type) {
        case SET_LIST_SAFRA_CLIENTE:
            return action.payload;
        default:
            return state;
    }
};
