import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";
import { setStatusNull } from "../../../../store/actions";
import { adicionarUsuario } from "../../../../store/userActions";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Switch,
    TimePicker,
    Tooltip,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
    CloseOutlined,
    CheckOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import InputMask from "react-input-mask";
import moment from "moment";

interface IAdicionarUserProps {
    adicionarUsuario: Function;
    loader: IStatusList;

    setModal?: any;
    tema?: any;
    setStatusNull: any;
}
function AdicionarUsuarios(props: IAdicionarUserProps) {
    const { adicionarUsuario, loader, setStatusNull, setModal } = props;

    const [alerta, setAlerta] = useState(true);
    const [validoNome, setValidoNome] = useState(false);
    const [validoSenha, setValidoSenha] = useState(false);
    const [validoEmail, setValidoEmail] = useState(false);

    const [horario, setHorario] = useState("08:00");
    const [phones, setPhones] = useState([]);

    const format = "HH:mm";

    const handleDisable = useCallback(() => {
        if (
            validoNome === true &&
            validoSenha === true &&
            validoEmail === true
        ) {
            return false;
        }
        return true;
    }, [validoSenha, validoNome, validoEmail]);

    const onChange = (e: any) => {
        const value = e.target.value.replace(/[^0-9\;]/g, "");
        const phonesArray = value.split(";").map((p: any) => "55" + p.trim());
        setPhones(phonesArray);
    };

    useEffect(() => {
        if (loader.usuario?.add?.success) {
            props.setModal(false);
            setStatusNull("add", "usuario");
        }
    }, [loader]);

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    let obj = {
                        nome: v.nome,
                        email: v.email,
                        pass: v.pass,
                        since: "",
                        preferencia: "{}",
                        email_alexa: v.email_alexa,
                        nome_contato: v.nome_contato,
                        fone_contato: phones.join(";"),
                        envia_alerta: alerta,
                        hora_rel_diario: horario,
                    };
                    adicionarUsuario(obj);
                    props.setModal(false);
                }}
                initialValues={{
                    hora_rel_diario: moment(horario, format),
                }}
            >
                <div style={{ marginBottom: "10px" }}>Dados para acesso:</div>
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome"
                        >
                            <Input placeholder="Nome do usuário" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um e-mail",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoEmail(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoEmail(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Email:"
                            name="email"
                        >
                            <Input
                                type="email"
                                placeholder="E-mail para acesso"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque uma senha",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject()
                                            );
                                        }

                                        if (value.length !== 8) {
                                            return (
                                                setValidoSenha(false),
                                                Promise.reject(
                                                    "A senha deve conter 8 digitos"
                                                )
                                            );
                                        }
                                        setValidoSenha(true);

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Senha:"
                            name="pass"
                        >
                            <Input.Password maxLength={8} placeholder="Senha" />
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ marginBottom: "10px" }}>Dados para contato:</div>
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        {" "}
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um nome para contato",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome_contato"
                        >
                            <Input placeholder="Nome do contato" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um e-mail para contato",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Email:"
                            name="email_alexa"
                        >
                            <Input
                                type="email"
                                placeholder="E-mail para contato"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <div
                            style={{
                                display: "flex",
                                flexGrow: "2",
                                marginRight: "10px",
                                justifyContent: "flex-end",
                                marginBottom: "-15px",
                            }}
                        >
                            {" "}
                            <InfoCircleOutlined />
                        </div>
                        <Tooltip
                            placement="topRight"
                            title='Para adicionar mais de um número de celular utilize " ; "'
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Coloque um telefone para contato",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                label="Celular:"
                                name="fone_contato"
                            >
                                <Input
                                    // mask="(99) 9 9999-9999"
                                    id="fone_contato"
                                    placeholder="(99) 9 9999-9999"
                                    onChange={onChange}
                                    // className={props.tema ? "phone-dark" : "phone"}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item
                            name="envia_alerta"
                            label="Envio de Notificações"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "15px",
                                    alignItems: "baseline",
                                    // marginTop: "25px",
                                    marginBottom: "0px",
                                }}
                            >
                                <div>
                                    <Switch
                                        checked={alerta}
                                        onChange={(x: any) => setAlerta(x)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </div>
                                <div
                                    onClick={() => setAlerta(!alerta)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Receber notificações no celular
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="hora_rel_diario"
                            label="Horário para envio de relatório:"
                            rules={[
                                {
                                    required: alerta,
                                    message: "Selecione um horário",
                                },
                                () => ({
                                    validator(_, value) {
                                        console.log(value);
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <TimePicker
                                placeholder="Selecione"
                                defaultValue={moment(horario, format)}
                                onChange={(e: any, value: any) =>
                                    setHorario(value)
                                }
                                format={format}
                                showNow={false}
                                minuteStep={15}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={loader.usuario?.add?.loading}
                    >
                        Salvar usuário
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarUsuario,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarUsuarios);
