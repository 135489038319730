import { Input, Form, Button, message, Col, Row, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { editarAntena } from "../../../../store/antenaActions";
import { setStatusNull, setStatusSuccess } from "../../../../store/actions";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { IStore } from "../../../../store";
import { IStatusList } from "../../../../store/types";
import { IServidorList } from "../../../../reducers/ServidorReducer";

const { Option } = Select;

interface PropsEditAntena {
    status: IStatusList;
    fazendaList: IFazendaList;
    servidorList: IServidorList;

    cont: any;
    setModal: Function;

    editarAntena: Function;
    setStatusNull: Function;
}

function EditarAntena(props: PropsEditAntena) {
    const { status, editarAntena, setStatusNull, fazendaList, servidorList } =
        props;
    const [form] = Form.useForm();

    const [validoNome, setValidoNome] = useState(true);
    const [validoIdGat, setValidoIdGat] = useState(true);
    const [validoProfileGat, setProfileGat] = useState(true);
    const [validoIdService, setValidoIdService] = useState(true);

    const [validoServer, setValidoServer] = useState(true);

    const handleDisable = useCallback(() => {
        if (
            validoNome === true &&
            validoIdGat === true &&
            validoServer === true &&
            validoIdService === true &&
            validoProfileGat === true
        ) {
            return false;
        }
        return true;
    }, [
        validoNome,
        validoIdGat,
        validoServer,
        validoProfileGat,
        validoIdService,
    ]);

    useEffect(() => {
        if (status.antena?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "antena");
        }
    });

    return (
        <>
            <Form
                form={form}
                onFinish={v => {
                    editarAntena(v);
                    //;
                }}
                layout="vertical"
                initialValues={{
                    id_antena: props.cont.id_antena,
                    nome: props.cont.nome,
                    gateway_id: props.cont.gateway_id,
                    local_server_temp: props.cont.local_server_temp,
                    gateway_profile_id: props.cont.gateway_profile_id,
                    service_profile_id: props.cont.service_profile_id,
                }}
            >
                <div style={{ display: "none" }}>
                    <Form.Item name="id_antena">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="local_server_temp">
                        <Input type="hidden" />
                    </Form.Item>
                </div>
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome da antena:"
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                    message: "Use apenas letras.",
                                    pattern: new RegExp(/^[a-zA-Z-/-/]+$/),
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject(
                                                    "Coloque um Nome."
                                                )
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Digite o nome da antena" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="ID do Gateway:"
                            name="gateway_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Id.",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoIdGat(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoIdGat(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Digite o ID do Gateway" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        {" "}
                        <Form.Item
                            name="gateway_profile_id"
                            label="Gateway Profile:"
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Id do Gateway.",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setProfileGat(false),
                                                Promise.reject()
                                            );
                                        }
                                        setProfileGat(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Digite o id do gateway profile" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {" "}
                        <Form.Item
                            name="service_profile_id"
                            label="ID do Service:"
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Id do Service.",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoIdService(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoIdService(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Digite o id do service" />
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={handleDisable()}
                        onClick={() =>
                            form.setFieldsValue({
                                cordenadas: JSON.stringify(
                                    props.cont.coordenadas
                                ),
                                gateway_profile_id:
                                    "be88ac96-79a2-4706-a432-9ab9f13646fc",
                                service_profile_id:
                                    "35dff77d-13f1-4561-95b9-93577276f43d",
                            })
                        }
                        loading={status.antena?.edit?.loading}
                    >
                        Salvar antena
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendaList: store.fazendas,
        servidorList: store.servidores,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarAntena,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarAntena);
