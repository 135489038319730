import { ITipoParada, ITipoParadaList } from "../reducers/TipoParadaReducer";
import {
    setStatusError,
    setStatusStart,
    setStatusSuccess,
    loginProcessTokenExpired,
} from "./actions";
import { SET_LIST_TIPOPARADA, SET_TIPOPARADA } from "./actionTypes";

//Métodos para administração de TIPOOP
export const setTipoParada = (value: ITipoParada) => ({
    type: SET_TIPOPARADA,
    payload: value,
});

export const setListTipoParada = (value: ITipoParadaList) => ({
    type: SET_LIST_TIPOPARADA,
    payload: value,
});

export const getTipoParadas = (props: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "tipoParada"));

        const keys = Object.keys(props);

        var urlApi =
            "api/relatorio/" +
            props.faz +
            "/tipoparada?" +
            "dataIni=" +
            props.dataIni +
            "&dataFin=" +
            props.dataFin +
            "&equipamento=" +
            props.equip +
            "&gpson=true";
        keys.forEach((key, index) => {
            if (
                props[key] === "" ||
                props[key] === "null" ||
                props[key] === undefined
            ) {
            } else {
                if (
                    key !== "faz" &&
                    key !== "equip" &&
                    key !== "dataIni" &&
                    key !== "dataFin"
                )
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}${urlApi}`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "tipoParada", res.erro));
                } else {
                    let array: ITipoParadaList = [];
                    res.forEach((r: ITipoParada) => {
                        array.push({
                            tempo_parado: r.tempo_parado,
                            tipo_parada: r.tipo_parada,
                        });
                    });
                    dispatch(setListTipoParada(array));
                    dispatch(setStatusSuccess("get", "tipoParada"));
                }
            })
            .catch(error => {
                // if (
                //     error.response.status === 401 ||
                //     error.response.status === 403
                // ) {
                //     dispatch(loginProcessTokenExpired());
                // }
            });
    };
};
