import { useTable } from "react-table";
import { Button, Pagination } from "antd";
import { IStore } from "../../../../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Spinner } from "../../../../styles/Main";
import { IStatusList } from "../../../../store/types";
import { useEffect, useState } from "react";
import { ITheme } from "../../../../reducers/ThemeReducer";
import {
    GroupingState,
    PaginationState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    MinusOutlined,
} from "@ant-design/icons";
import { IRegistroOpe } from "../../../../reducers/OperacaoReducer";

interface ViewerTableProps {
    columns: any;
    data: any;
    abas: any;
    setAbas: any;
    abaAtiva: any;
    loader: IStatusList;
    registroOpe: IRegistroOpe;
    setBlob: any;
    blob: any;
    theme: ITheme;

    setTabela: Function;
}
function Viewer(props: ViewerTableProps) {
    const {
        columns,
        data,
        abas,
        setAbas,
        abaAtiva,
        loader,
        registroOpe,
        setBlob,
        blob,
        theme,
    } = props;

    const [grouping, setGrouping] = useState<GroupingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalPages, setTotalPages] = useState(0);

    const table = useReactTable({
        data,
        columns,
        pageCount: Math.ceil(registroOpe.total_registros / pagination.pageSize),
        state: {
            pagination,
            grouping,
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: setPagination,
        manualPagination: true,
        groupedColumnMode: false,
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    useEffect(() => {
        props.setTabela(table);
    }, []);

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [registroOpe.total_registros]);

    useEffect(() => {
        setTotalPages(
            Math.ceil(registroOpe.total_registros / pagination.pageSize)
        );
    }, [pagination, registroOpe.total_registros]);

    useEffect(() => {
        let newArray = JSON.parse(JSON.stringify(abas));
        let abaKey = newArray.findIndex((a: any) => {
            return a.key === abaAtiva;
        });
        newArray[abaKey].content["paginacao"] = {
            pagina: pagination.pageIndex,
            size: pagination.pageSize,
        };
        props.setAbas(newArray);
    }, [pagination]);

    return (
        <>
            <div
                style={{
                    zIndex: 1056,
                    position: "absolute",
                    display: loader.operacao?.get?.loading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-18px",
                    background: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(3px)",
                    width: "100%",
                    height: "calc(100vh - 210px)",
                }}
            >
                <Spinner view={true}>
                    <i
                        className="fas fa-spinner"
                        style={{ fontSize: "50px", color: "#000" }}
                    ></i>
                </Spinner>
            </div>
            <div
                style={{
                    overflowY: "auto",
                    height: "calc(100vh - 187px)",
                    marginTop: "-17px",
                    zIndex: "1055",
                }}
            >
                <table
                    className={
                        theme ? "tabela-operacoes-dark" : "tabela-operacoes"
                    }
                    // {...getTableProps()}
                >
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    {header.column.getCanGroup() ? (
                                                        // If the header can be grouped, let's add a toggle
                                                        // <button
                                                        // {...{
                                                        //     onClick:
                                                        //         header.column.getToggleGroupingHandler(),
                                                        //     style: {
                                                        //         cursor: "pointer",
                                                        //     },
                                                        // }}
                                                        // >
                                                        header.column.getIsGrouped() ? (
                                                            <PlusOutlined
                                                                {...{
                                                                    onClick:
                                                                        header.column.getToggleGroupingHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: "pointer",
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <MinusOutlined
                                                                {...{
                                                                    onClick:
                                                                        header.column.getToggleGroupingHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: "pointer",
                                                                    },
                                                                }}
                                                            />
                                                        )
                                                    ) : // </button>
                                                    null}{" "}
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row, i) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td
                                                {...{
                                                    key: cell.id,
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        {row.getIsExpanded() ? (
                                                            <MinusCircleOutlined
                                                                {...{
                                                                    onClick:
                                                                        row.getToggleExpandedHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: row.getCanExpand()
                                                                            ? "pointer"
                                                                            : "normal",
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <PlusCircleOutlined
                                                                {...{
                                                                    onClick:
                                                                        row.getToggleExpandedHandler(),
                                                                    style: {
                                                                        marginRight:
                                                                            "2px",
                                                                        cursor: row.getCanExpand()
                                                                            ? "pointer"
                                                                            : "normal",
                                                                    },
                                                                }}
                                                            />
                                                        )}{" "}
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}{" "}
                                                        ({row.subRows.length})
                                                    </>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    flexRender(
                                                        cell.column.columnDef
                                                            .aggregatedCell ??
                                                            cell.column
                                                                .columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        gap: "10px",
                        width: "25%",
                        marginTop: "10px",
                        marginLeft: "1vw",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                            type="text"
                            size="small"
                        >
                            <DoubleLeftOutlined />
                        </Button>
                        <Button
                            size="small"
                            type="text"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            <LeftOutlined />
                        </Button>
                        <Button
                            size="small"
                            type="text"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            <RightOutlined />
                        </Button>
                        <Button
                            type="text"
                            size="small"
                            onClick={() =>
                                table.setPageIndex(table.getPageCount() - 1)
                            }
                            disabled={!table.getCanNextPage()}
                        >
                            <DoubleRightOutlined />
                        </Button>
                    </div>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            padding: "5px",
                        }}
                    >
                        <div>Página</div>
                        <strong>
                            {table.getState().pagination.pageIndex + 1} de{" "}
                            {totalPages}
                        </strong>
                    </span>
                    <select
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "5px",
                            cursor: "pointer",
                        }}
                        title="Linhas"
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    {/* <Pagination
                        total={props.data.length}
                        showSizeChanger
                        showTotal={total => `Total ${total} items`}
                        onChange={(e: any, f: any) => {
                            console.log(e, f);
                            let newArray = JSON.parse(JSON.stringify(abas));
                            let abaKey = newArray.findIndex((a: any) => {
                                return a.key === abaAtiva;
                            });
                            newArray[abaKey].content["paginacao"] = {
                                pagina: e - 1,
                                size: f,
                            };
                            table.setPageIndex(e);
                            table.setPageSize(f);
                            setAbas(newArray);
                            setPgDefault(e);
                            setSizeDefault(f);
                        }}
                        current={pgDefault}
                        pageSize={sizeDefault}
                    /> */}
                </div>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        registroOpe: store.registroOpe,
        loader: store.loader,
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {},

        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewer); //
