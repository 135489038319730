import { bindActionCreators } from "redux";
import { IStore } from "./store";
import { connect } from "react-redux";
import { DisconnectOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { IKeepAlive } from "./reducers/StatusReducer";

interface PropsKeepAlive {
    keepAlive: IKeepAlive;
}
function KeepAlive(props: PropsKeepAlive) {
    const { keepAlive } = props;
    return (
        <>
            <Modal
                visible={keepAlive !== null && !keepAlive}
                footer={null}
                width={300}
                bodyStyle={{
                    height: "200px",
                }}
                zIndex={2000}
                centered={true}
                closable={false}
                maskClosable={false}
                maskStyle={{
                    backdropFilter: "blur(5px)",
                    userSelect: "none",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <DisconnectOutlined
                        style={{
                            fontSize: "50px",
                        }}
                    />
                    <div style={{ marginBottom: "10px" }}>
                        A conexão foi perdida.
                    </div>
                    <Button onClick={() => window.location.reload()}>
                        Reconectar...
                    </Button>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        keepAlive: store.keepAlive,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KeepAlive);
