import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import { Button, Col, Empty, Form, Row, Select } from "antd";
import {
    ICultura,
    ICulturaCliente,
    ICulturaList,
} from "../../../../reducers/CulturaReducer";
import {
    ISafra,
    ISafraCliente,
    ISafraList,
} from "../../../../reducers/SafraReducer";
import {
    ITipoOp,
    ITipoOpCliente,
    ITipoOpList,
} from "../../../../reducers/TipoOpReducer";
import { ITheme } from "../../../../reducers/ThemeReducer";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect, useState } from "react";
import { adicionarCultura } from "../../../../store/culturaActions";
import { adicionarSafra } from "../../../../store/safraActions";
import { adicionarTipoOp } from "../../../../store/tipoOpActions";

const { Option, OptGroup } = Select;

export interface IAdicionarParams {
    culturaListState: ICulturaList;
    safraListState: ISafraList;
    tipoOpListState: ITipoOpList;
    tema: ITheme;

    setModal: any;
    cult: any;
    safra: any;
    ope: any;

    adicionarCultura: Function;
    adicionarSafra: Function;
    adicionarTipoOp: Function;
}

function AdicionarParametrosOpe(props: IAdicionarParams) {
    const {
        culturaListState,
        safraListState,
        tipoOpListState,
        tema,
        adicionarCultura,
        adicionarSafra,
        adicionarTipoOp,
    } = props;

    const [opeSelec, setOpeSelec] = useState([]);
    const [safraSelec, setSafraSelec] = useState([]);
    const [cultSelec, setCultSelec] = useState([]);

    const [form] = Form.useForm();

    const handleDisable = useCallback(() => {
        if (
            (cultSelec.length > 0 && cultSelec.length > props.cult.length) ||
            (safraSelec.length > 0 && safraSelec.length > props.safra.length) ||
            (opeSelec.length > 0 && opeSelec.length > props.ope.length)
        ) {
            return false;
        }
        return true;
    }, [cultSelec, safraSelec, opeSelec]);

    const filteredCultOptions = culturaListState.filter((o: any) => {
        if (o.id_cultura !== 12) {
            return (
                props.cult.findIndex(
                    (s: any) => s.cultura_temp === o.id_cultura
                ) === -1
            );
        }
    });
    const filteredSfrOptions = safraListState.filter((o: any) => {
        if (o.id_safra !== 3) {
            return (
                props.safra.findIndex(
                    (s: any) => s.safra_temp === o.id_safra
                ) === -1
            );
        }
    });
    const filteredOpeOptions = tipoOpListState.filter((o: any) => {
        if (o.id_tipo_operacao !== 799) {
            return (
                props.ope.findIndex(
                    (s: any) => s.tipo_operacao_temp === o.id_tipo_operacao
                ) === -1
            );
        }
    });

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={(v: any) => {
                    let culturas = v.cultura.filter(
                        (o: any) =>
                            props.cult.findIndex(
                                (s: any) => s.cultura_temp === o
                            ) === -1
                    );
                    culturas.forEach((item: any) => {
                        let obj = {
                            cultura_temp: item,
                        };
                        adicionarCultura(obj);
                    });

                    let safras = v.safra.filter(
                        (o: any) =>
                            props.safra.findIndex(
                                (s: any) => s.safra_temp === o
                            ) === -1
                    );
                    safras.forEach((item: any) => {
                        let obj = {
                            safra_temp: item,
                        };
                        adicionarSafra(obj);
                    });

                    let opes = v.operacao.filter(
                        (o: any) =>
                            props.ope.findIndex(
                                (t: any) => t.tipo_operacao_temp === o
                            ) === -1
                    );
                    opes.forEach((item: any) => {
                        let obj;
                        if (item > 0 && item < 800) {
                            obj = {
                                tipo_operacao_temp: item,
                                tempo_alerta_parada: 12,
                            };
                        } else {
                            obj = { tipo_operacao_temp: item };
                        }
                        adicionarTipoOp(obj);
                    });
                    props.setModal(false);
                }}
                initialValues={{
                    cultura:
                        props.cult.length > 0
                            ? props.cult.map((c: ICulturaCliente) => {
                                  return culturaListState[
                                      culturaListState.findIndex(
                                          (i: ICultura) =>
                                              c.cultura_temp === i.id_cultura
                                      )
                                  ].id_cultura;
                              })
                            : [],
                    safra:
                        props.safra.length > 0
                            ? props.safra.map((s: ISafraCliente) => {
                                  return safraListState[
                                      safraListState.findIndex(
                                          (i: ISafra) =>
                                              s.safra_temp === i.id_safra
                                      )
                                  ].id_safra;
                              })
                            : [],
                    operacao:
                        props.ope.length > 0
                            ? props.ope.map((o: ITipoOpCliente) => {
                                  return tipoOpListState[
                                      tipoOpListState.findIndex(
                                          (i: ITipoOp) =>
                                              o.tipo_operacao_temp ===
                                              i.id_tipo_operacao
                                      )
                                  ].id_tipo_operacao;
                              })
                            : [],
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label="Tipos de Operações:" name="operacao">
                            <Select
                                mode="multiple"
                                allowClear={true}
                                showSearch
                                filterOption={(inputValue, option: any) => {
                                    let condicao = false;

                                    if (option.value !== 0 && option.children) {
                                        return option.children
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    } else if (option.label) {
                                        return option.label
                                            .toLowerCase()
                                            .indexOf(
                                                inputValue.toLowerCase()
                                            ) >= 0
                                            ? (condicao = true)
                                            : (condicao = false);
                                    }

                                    return condicao;
                                }}
                                placeholder="Escolha"
                                style={{ maxHeight: "80px", overflow: "auto" }}
                                onChange={(e: any) => {
                                    setOpeSelec(e);
                                }}
                                // onSelect={handleChangeTipoOp}
                                dropdownStyle={{ zIndex: 1090 }}
                                dropdownClassName="agrochip-dropdown"
                            >
                                {props.ope.length > 0 &&
                                    props.ope.map((o: ITipoOpCliente) => {
                                        return (
                                            <Select.Option
                                                key={o.tipo_operacao_temp}
                                                value={o.tipo_operacao_temp}
                                                label={
                                                    tipoOpListState[
                                                        tipoOpListState.findIndex(
                                                            (i: ITipoOp) =>
                                                                i.id_tipo_operacao ===
                                                                o.tipo_operacao_temp
                                                        )
                                                    ].descricao
                                                }
                                                disabled
                                            >
                                                {
                                                    tipoOpListState[
                                                        tipoOpListState.findIndex(
                                                            (i: ITipoOp) =>
                                                                i.id_tipo_operacao ===
                                                                o.tipo_operacao_temp
                                                        )
                                                    ].descricao
                                                }
                                            </Select.Option>
                                        );
                                    })}
                                <OptGroup label="Tipo Operação">
                                    {filteredOpeOptions.map((e: any) => {
                                        return (
                                            <>
                                                {e.id_tipo_operacao > 0 &&
                                                    e.id_tipo_operacao <
                                                        800 && (
                                                        <Option
                                                            value={
                                                                e.id_tipo_operacao
                                                            }
                                                            key={
                                                                e.id_tipo_operacao
                                                            }
                                                        >
                                                            {e.descricao}
                                                        </Option>
                                                    )}
                                            </>
                                        );
                                    })}
                                </OptGroup>

                                <OptGroup label="Tipo Deslocamento">
                                    {filteredOpeOptions.map((e: any) => {
                                        return (
                                            <>
                                                {e.id_tipo_operacao >= 900 &&
                                                    e.id_tipo_operacao <=
                                                        999 && (
                                                        <Option
                                                            value={
                                                                e.id_tipo_operacao
                                                            }
                                                            key={
                                                                e.id_tipo_operacao
                                                            }
                                                        >
                                                            {e.descricao}
                                                        </Option>
                                                    )}
                                            </>
                                        );
                                    })}
                                </OptGroup>

                                <OptGroup label="Tipo Parada">
                                    {filteredOpeOptions.map((e: any) => {
                                        return (
                                            <>
                                                {e.id_tipo_operacao >= 800 &&
                                                    e.id_tipo_operacao <
                                                        900 && (
                                                        <Option
                                                            value={
                                                                e.id_tipo_operacao
                                                            }
                                                            key={
                                                                e.id_tipo_operacao
                                                            }
                                                        >
                                                            {e.descricao}
                                                        </Option>
                                                    )}
                                            </>
                                        );
                                    })}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item label="Safras:" name="safra">
                            <Select
                                mode="multiple"
                                allowClear={true}
                                showSearch
                                // disabled={fazenda.id_fazenda === ""}
                                placeholder="Escolha"
                                style={{ maxHeight: "80px", overflow: "auto" }}
                                onChange={(e: any) => {
                                    setSafraSelec(e);
                                }}
                                // onSelect={handleChangeTipoOp}
                                dropdownClassName="agrochip-dropdown"
                            >
                                {props.safra.length > 0 &&
                                    props.safra.map((s: ISafraCliente) => {
                                        return (
                                            <Select.Option
                                                key={s.safra_temp}
                                                value={s.safra_temp}
                                                label={
                                                    safraListState[
                                                        safraListState.findIndex(
                                                            (i: ISafra) =>
                                                                i.id_safra ===
                                                                s.safra_temp
                                                        )
                                                    ].descricao
                                                }
                                                disabled
                                            >
                                                {
                                                    safraListState[
                                                        safraListState.findIndex(
                                                            (i: ISafra) =>
                                                                i.id_safra ===
                                                                s.safra_temp
                                                        )
                                                    ].descricao
                                                }
                                            </Select.Option>
                                        );
                                    })}
                                {filteredSfrOptions.map((e: any) => {
                                    return (
                                        <Option
                                            value={e.id_safra}
                                            key={e.id_safra}
                                        >
                                            {e.descricao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>{" "}
                    <Col span={12}>
                        <Form.Item label="Culturas:" name="cultura">
                            <Select
                                mode="multiple"
                                allowClear={true}
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                // disabled={fazenda.id_fazenda === ""}
                                placeholder="Escolha"
                                style={{ maxHeight: "80px", overflow: "auto" }}
                                onChange={(e: any) => {
                                    setCultSelec(e);
                                }}
                                // onSelect={handleChangeTipoOp}
                                dropdownClassName="agrochip-dropdown"
                            >
                                {props.cult.length > 0 &&
                                    props.cult.map((c: ICulturaCliente) => {
                                        return (
                                            <Select.Option
                                                key={c.cultura_temp}
                                                value={c.cultura_temp}
                                                label={
                                                    culturaListState[
                                                        culturaListState.findIndex(
                                                            (i: ICultura) =>
                                                                i.id_cultura ===
                                                                c.cultura_temp
                                                        )
                                                    ].nomeCultura
                                                }
                                                disabled
                                            >
                                                {
                                                    culturaListState[
                                                        culturaListState.findIndex(
                                                            (i: ICultura) =>
                                                                i.id_cultura ===
                                                                c.cultura_temp
                                                        )
                                                    ].nomeCultura
                                                }
                                            </Select.Option>
                                        );
                                    })}
                                {filteredCultOptions.map((e: any) => {
                                    return (
                                        <Option
                                            value={e.id_cultura}
                                            key={e.id_cultura}
                                        >
                                            {e.nomeCultura}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={handleDisable()}
                            // loading={status.parametro?.add?.loading}
                        >
                            Salvar informações
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        culturaListState: store.culturas,
        safraListState: store.safras,
        tipoOpListState: store.tiposOp,
        tema: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            adicionarCultura,
            adicionarSafra,
            adicionarTipoOp,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdicionarParametrosOpe);
