import { SET_TIME } from "../store/actionTypes";
import { startOfToday } from "date-fns";

export type ITimeModule = string;

export type ITime = {
    i: ITimeModule;
    f: ITimeModule;
};
var tzoffset: any = new Date().getTimezoneOffset() * 60000;

const dataAtual: any = new Date(new Date().getTime() - tzoffset);
const dataFinal = new Date(startOfToday().getTime() - tzoffset);
const Time: ITime = {
    i: dataFinal.toISOString().split(".")[0],
    f: dataAtual.toISOString().split(".")[0],
};

export interface ITImeReducer {
    type: any;
    payload: ITime;
}
export const timeReducer = (state = Time, action: ITImeReducer) => {
    switch (action.type) {
        case SET_TIME:
            return action.payload;
        default:
            return state;
    }
};
