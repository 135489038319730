import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { connect } from "react-redux";
import {
    GroupingState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getGroupedRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    MinusOutlined,
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined,
} from "@ant-design/icons";
import { Button } from "antd";

interface ITabAlertaParadaProps {
    tema: any;
    columns: any;
    data: any;
}

function TabelaAlertaParada(props: ITabAlertaParadaProps) {
    const { tema, data, columns } = props;
    const [grouping, setGrouping] = useState<GroupingState>([]);

    const table = useReactTable({
        data,
        columns,
        state: {
            grouping,
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        groupedColumnMode: false,
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "calc(100vh - 187px)",
                overflowY: "auto",
                marginTop: "10px",
            }}
        >
            <table
                className={
                    tema ? "tabela-operacional-dark" : "tabela-operacional"
                }
            >
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div>
                                                {header.column.getCanGroup() ? (
                                                    // If the header can be grouped, let's add a toggle
                                                    // <button
                                                    // {...{
                                                    //     onClick:
                                                    //         header.column.getToggleGroupingHandler(),
                                                    //     style: {
                                                    //         cursor: "pointer",
                                                    //     },
                                                    // }}
                                                    // >
                                                    header.column.getIsGrouped() ? (
                                                        <PlusOutlined
                                                            {...{
                                                                onClick:
                                                                    header.column.getToggleGroupingHandler(),
                                                                style: {
                                                                    marginRight:
                                                                        "2px",
                                                                    cursor: "pointer",
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <MinusOutlined
                                                            {...{
                                                                onClick:
                                                                    header.column.getToggleGroupingHandler(),
                                                                style: {
                                                                    marginRight:
                                                                        "2px",
                                                                    cursor: "pointer",
                                                                },
                                                            }}
                                                        />
                                                    )
                                                ) : // </button>
                                                null}{" "}
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext()
                                                )}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td
                                            {...{
                                                key: cell.id,
                                            }}
                                        >
                                            {cell.getIsGrouped() ? (
                                                // If it's a grouped cell, add an expander and row count
                                                <>
                                                    {row.getIsExpanded() ? (
                                                        <MinusCircleOutlined
                                                            {...{
                                                                onClick:
                                                                    row.getToggleExpandedHandler(),
                                                                style: {
                                                                    marginRight:
                                                                        "2px",
                                                                    cursor: row.getCanExpand()
                                                                        ? "pointer"
                                                                        : "normal",
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <PlusCircleOutlined
                                                            {...{
                                                                onClick:
                                                                    row.getToggleExpandedHandler(),
                                                                style: {
                                                                    marginRight:
                                                                        "2px",
                                                                    cursor: row.getCanExpand()
                                                                        ? "pointer"
                                                                        : "normal",
                                                                },
                                                            }}
                                                        />
                                                    )}{" "}
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )}{" "}
                                                    ({row.subRows.length})
                                                </>
                                            ) : cell.getIsAggregated() ? (
                                                // If the cell is aggregated, use the Aggregated
                                                // renderer for cell
                                                flexRender(
                                                    cell.column.columnDef
                                                        .aggregatedCell ??
                                                        cell.column.columnDef
                                                            .cell,
                                                    cell.getContext()
                                                )
                                            ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                // Otherwise, just render the regular cell
                                                flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div
                style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "10px",
                    marginRight: "1vw",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        type="text"
                        size="small"
                    >
                        <DoubleLeftOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <LeftOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <RightOutlined />
                    </Button>
                    <Button
                        type="text"
                        size="small"
                        onClick={() =>
                            table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                    >
                        <DoubleRightOutlined />
                    </Button>
                </div>
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "5px",
                    }}
                >
                    <div>Página</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} de{" "}
                        {table.getPageCount()}
                    </strong>
                </span>
                <select
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "5px",
                        cursor: "pointer",
                    }}
                    title="Linhas"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        tema: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaAlertaParada);
