import { bindActionCreators } from "redux";
import { IStore } from "../../../store";
import { connect } from "react-redux";
import { IFazendaList } from "../../../reducers/FazendaReducer";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStatusList } from "../../../store/types";
import { useEffect, useMemo, useState } from "react";
import { Button, Collapse, Empty, Modal, Popconfirm, Select } from "antd";
import AdicionarParametrosOpe from "./ParametrosOpe/AdicionarParametrosOpe";
import {
    ICultura,
    ICulturaClienteList,
    ICulturaList,
} from "../../../reducers/CulturaReducer";
import {
    ISafra,
    ISafraClienteList,
    ISafraList,
} from "../../../reducers/SafraReducer";
import {
    ITipoOp,
    ITipoOpClienteList,
    ITipoOpList,
} from "../../../reducers/TipoOpReducer";
import { setStatusNull } from "../../../store/actions";
import {
    deletarCultura,
    getCulturaCliente,
} from "../../../store/culturaActions";
import { getSafraCliente, deletarSafra } from "../../../store/safraActions";
import { deletarTipoOp, getTipoOpCliente } from "../../../store/tipoOpActions";
import EditarParametroOpe from "./ParametrosOpe/EditarParametroOpe";

const { Option } = Select;
const { Panel } = Collapse;

interface IParametrosOpeProps {
    fazendas: IFazendaList;
    culturaList: ICulturaList;
    safraList: ISafraList;
    tipoOpList: ITipoOpList;
    theme: ITheme;
    loader: IStatusList;

    culturaCList: ICulturaClienteList;
    safraCList: ISafraClienteList;
    tipoOpCList: ITipoOpClienteList;

    deletarCultura: Function;
    deletarSafra: Function;
    deletarTipoOp: Function;
    getCulturaCliente: Function;
    getSafraCliente: Function;
    getTipoOpCliente: Function;

    setStatusNull: Function;
}

function ParametrosOpe(props: IParametrosOpeProps) {
    const {
        fazendas,
        culturaList,
        safraList,
        tipoOpList,
        loader,
        theme,
        culturaCList,
        safraCList,
        tipoOpCList,

        deletarCultura,
        deletarSafra,
        deletarTipoOp,
        getCulturaCliente,
        getSafraCliente,
        getTipoOpCliente,

        setStatusNull,
    } = props;
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [modal, setModal] = useState(false);
    const [which, setWhich] = useState({ id: "", type: "" });

    const [excluirVisible, setExcluirVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [haParad, setHaParad] = useState(false);
    const [haDesloc, setHaDesloc] = useState(false);
    const [haOpe, setHaOpe] = useState(false);

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Parâmetro Operacional",
            conteudo: (
                <AdicionarParametrosOpe
                    setModal={setModal}
                    cult={culturaCList} //
                    safra={safraCList} //
                    ope={tipoOpCList} //
                />
            ),
        });
        setModal(true);
    }

    function handleExcluir() {
        setConfirmLoading(true);
        switch (which.type) {
            case "ope":
                deletarTipoOp(which.id);
                break;
            case "desloc":
                deletarTipoOp(which.id);
                break;
            case "parad":
                deletarTipoOp(which.id);
                break;
            case "sfr":
                deletarSafra(which.id);
                break;
            case "cult":
                deletarCultura(which.id);
                break;
            default:
                break;
        }
    }

    function handleEditar(id: any) {
        const paramKey = tipoOpCList.findIndex(
            (param: any) => param.id_tipo_operacao_cliente === id
        );
        setConfigModal({
            titulo: "Editar Parametro",
            conteudo: (
                <EditarParametroOpe
                    cont={tipoOpCList[paramKey]}
                    setModal={setModal}
                />
            ),
        });

        setModal(true);
    }

    // function handleEditar() {
    //     // const alertaId = alertaList.findIndex((a: any) => a.alerta_id === id);
    //     setConfigModal({
    //         titulo: "Editar Parâmetro Operacional",
    //         conteudo: <EditarParametrosOpe />,
    //     });
    //     setModal(true);
    // }

    useMemo(() => {
        if (loader.culturaCliente?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "culturaCliente");
        }
        getCulturaCliente();
        setWhich({ id: "", type: "" });
    }, [loader.culturaCliente?.del?.success, getCulturaCliente]);

    useMemo(() => {
        if (loader.tipoOpCliente?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "tipoOpCliente");
        }
        getTipoOpCliente();
        setWhich({ id: "", type: "" });
    }, [loader.tipoOpCliente?.del?.success, getTipoOpCliente]);

    useMemo(() => {
        if (loader.safraCliente?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "safraCliente");
        }
        getSafraCliente();
        setWhich({ id: "", type: "" });
    }, [loader.safraCliente?.del?.success, getSafraCliente]);

    useEffect(() => {
        if (tipoOpCList.length > 0) {
            tipoOpCList.forEach((o: any) => {
                if (o.tipo_operacao_temp > 0 && o.tipo_operacao_temp < 800) {
                    setHaOpe(true);
                } else if (
                    o.tipo_operacao_temp >= 900 &&
                    o.tipo_operacao_temp <= 999
                ) {
                    setHaDesloc(true);
                } else if (
                    o.tipo_operacao_temp >= 800 &&
                    o.tipo_operacao_temp < 900
                ) {
                    setHaParad(true);
                }
            });
        }
    }, [tipoOpCList]);

    return (
        <div style={{ height: "100%" }}>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <div
                    style={{
                        paddingRight: "10px",
                    }}
                >
                    Parâmetros cadastrados:
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "5px",
                    height: "calc(100% - 10px)",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        paddingRight: "10px",
                        height: "100%",
                    }}
                >
                    <div
                        className={
                            theme ? "select-expand-ul-dark" : "select-expand-ul"
                        }
                    >
                        <Collapse
                            accordion
                            bordered={false}
                            onChange={() => setWhich({ id: "", type: "" })}
                            // collapsible={faz ? "header" : "disabled"}
                        >
                            <Panel header="Tipos de Operações" key={1}>
                                <Collapse
                                    accordion
                                    onChange={() =>
                                        setWhich({ id: "", type: "" })
                                    }
                                >
                                    <>
                                        <Panel header="Operações" key={1}>
                                            {haOpe ? (
                                                tipoOpCList.map((o: any) => {
                                                    let classN = theme
                                                        ? "select-expand-li-dark"
                                                        : "select-expand-li";
                                                    if (
                                                        o.id_tipo_operacao_cliente ===
                                                        which.id
                                                    ) {
                                                        classN += " active";
                                                    }
                                                    return (
                                                        <>
                                                            {o.tipo_operacao_temp >
                                                                0 &&
                                                                o.tipo_operacao_temp <
                                                                    800 && (
                                                                    <li
                                                                        className={
                                                                            classN
                                                                        }
                                                                        key={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        value={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        onClick={() =>
                                                                            setWhich(
                                                                                {
                                                                                    id: o.id_tipo_operacao_cliente,
                                                                                    type: "ope",
                                                                                }
                                                                            )
                                                                        }
                                                                        onDoubleClick={() =>
                                                                            handleEditar(
                                                                                o.id_tipo_operacao_cliente
                                                                            )
                                                                        }
                                                                        // style={{
                                                                        //     marginLeft:
                                                                        //         "15px",
                                                                        // }}
                                                                        // onDoubleClick={(e: any) =>
                                                                        //     console.log(e)
                                                                        // }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "start",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <div
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        tipoOpList[
                                                                                            tipoOpList.findIndex(
                                                                                                (
                                                                                                    i: ITipoOp
                                                                                                ) =>
                                                                                                    i.id_tipo_operacao ===
                                                                                                    o.tipo_operacao_temp
                                                                                            )
                                                                                        ]
                                                                                            .descricao
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                    }}
                                                                                >
                                                                                    Tempo
                                                                                    para
                                                                                    alertar
                                                                                    parada:{" "}
                                                                                    {
                                                                                        o.tempo_alerta_parada
                                                                                    }

                                                                                    s
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <Empty
                                                    description="Não há nenhum parâmetro de operação cadastrado."
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                />
                                            )}
                                        </Panel>
                                        <Panel header="Deslocamentos" key={2}>
                                            {haDesloc ? (
                                                tipoOpCList.map((o: any) => {
                                                    let classN = theme
                                                        ? "select-expand-li-dark"
                                                        : "select-expand-li";
                                                    if (
                                                        o.id_tipo_operacao_cliente ===
                                                        which.id
                                                    ) {
                                                        classN += " active";
                                                    }
                                                    return (
                                                        <>
                                                            {o.tipo_operacao_temp >=
                                                                900 &&
                                                                o.tipo_operacao_temp <=
                                                                    999 && (
                                                                    <li
                                                                        className={
                                                                            classN
                                                                        }
                                                                        key={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        value={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        onClick={() =>
                                                                            setWhich(
                                                                                {
                                                                                    id: o.id_tipo_operacao_cliente,
                                                                                    type: "desloc",
                                                                                }
                                                                            )
                                                                        }
                                                                        style={{
                                                                            marginLeft:
                                                                                "15px",
                                                                        }}
                                                                        // onDoubleClick={(e: any) =>
                                                                        //     console.log(e)
                                                                        // }
                                                                    >
                                                                        {
                                                                            tipoOpList[
                                                                                tipoOpList.findIndex(
                                                                                    (
                                                                                        i: ITipoOp
                                                                                    ) =>
                                                                                        i.id_tipo_operacao ===
                                                                                        o.tipo_operacao_temp
                                                                                )
                                                                            ]
                                                                                .descricao
                                                                        }
                                                                    </li>
                                                                )}
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <Empty
                                                    description="Não há nenhum parâmetro de deslocamento cadastrado."
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                />
                                            )}
                                        </Panel>
                                        <Panel
                                            header="Motivos de Paradas"
                                            key={3}
                                        >
                                            {haParad ? (
                                                tipoOpCList.map((o: any) => {
                                                    let classN = theme
                                                        ? "select-expand-li-dark"
                                                        : "select-expand-li";
                                                    if (
                                                        o.id_tipo_operacao_cliente ===
                                                        which.id
                                                    ) {
                                                        classN += " active";
                                                    }
                                                    return (
                                                        <>
                                                            {o.tipo_operacao_temp >=
                                                                800 &&
                                                                o.tipo_operacao_temp <
                                                                    900 && (
                                                                    <li
                                                                        className={
                                                                            classN
                                                                        }
                                                                        key={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        value={
                                                                            o.id_tipo_operacao_cliente
                                                                        }
                                                                        onClick={() =>
                                                                            setWhich(
                                                                                {
                                                                                    id: o.id_tipo_operacao_cliente,
                                                                                    type: "parad",
                                                                                }
                                                                            )
                                                                        }
                                                                        style={{
                                                                            marginLeft:
                                                                                "15px",
                                                                        }}
                                                                        // onDoubleClick={(e: any) =>
                                                                        //     console.log(e)
                                                                        // }
                                                                    >
                                                                        {
                                                                            tipoOpList[
                                                                                tipoOpList.findIndex(
                                                                                    (
                                                                                        i: ITipoOp
                                                                                    ) =>
                                                                                        i.id_tipo_operacao ===
                                                                                        o.tipo_operacao_temp
                                                                                )
                                                                            ]
                                                                                .descricao
                                                                        }
                                                                    </li>
                                                                )}
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <Empty
                                                    description="Não há nenhum parâmetro de parada cadastrado."
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                />
                                            )}
                                        </Panel>
                                    </>
                                </Collapse>
                            </Panel>
                            <Panel header="Safra" key={2}>
                                {safraCList.length > 0 ? (
                                    <>
                                        {safraCList.map((s: any) => {
                                            let classN = theme
                                                ? "select-expand-li-dark"
                                                : "select-expand-li";
                                            if (
                                                s.id_safra_cliente === which.id
                                            ) {
                                                classN += " active";
                                            }
                                            return (
                                                <li
                                                    className={classN}
                                                    key={s.id_safra_cliente}
                                                    value={s.id_safra_cliente}
                                                    onClick={() =>
                                                        setWhich({
                                                            id: s.id_safra_cliente,
                                                            type: "sfr",
                                                        })
                                                    }
                                                    style={{
                                                        marginLeft: "15px",
                                                    }}
                                                    // onDoubleClick={(e: any) =>
                                                    //     console.log(e)
                                                    // }
                                                >
                                                    {
                                                        safraList[
                                                            safraList.findIndex(
                                                                (i: ISafra) =>
                                                                    i.id_safra ===
                                                                    s.safra_temp
                                                            )
                                                        ].descricao
                                                    }
                                                </li>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Empty
                                        description="Não há nenhum parâmetro de safra cadastrado."
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                )}
                            </Panel>
                            <Panel header="Cultura" key={3}>
                                {culturaCList.length > 0 ? (
                                    <>
                                        {culturaCList.map((c: any) => {
                                            let classN = theme
                                                ? "select-expand-li-dark"
                                                : "select-expand-li";
                                            if (
                                                c.id_cultura_cliente ===
                                                which.id
                                            ) {
                                                classN += " active";
                                            }
                                            return (
                                                <li
                                                    className={classN}
                                                    key={c.id_cultura_cliente}
                                                    value={c.id_cultura_cliente}
                                                    onClick={() =>
                                                        setWhich({
                                                            id: c.id_cultura_cliente,
                                                            type: "cult",
                                                        })
                                                    }
                                                    style={{
                                                        marginLeft: "15px",
                                                    }}
                                                    // onDoubleClick={(e: any) =>
                                                    //     console.log(e)
                                                    // }
                                                >
                                                    {
                                                        culturaList[
                                                            culturaList.findIndex(
                                                                (i: ICultura) =>
                                                                    i.id_cultura ===
                                                                    c.cultura_temp
                                                            )
                                                        ].nomeCultura
                                                    }
                                                </li>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Empty
                                        description="Não há nenhum parâmetro de cultura cadastrado."
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                )}
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        paddingLeft: "10px",
                    }}
                >
                    <Button type="primary" onClick={() => handleAdicionar()}>
                        Adicionar
                    </Button>

                    <Button
                        disabled={
                            which.id === "" || which.type !== "ope"
                                ? true
                                : false
                        }
                        onClick={() => {
                            handleEditar(which.id);
                        }}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        visible={excluirVisible}
                        title="Você tem certeza que deseja excluir esse parâmetro?"
                        onConfirm={handleExcluir}
                        onCancel={() => setExcluirVisible(false)}
                        okButtonProps={{ loading: confirmLoading }}
                        okText="Sim"
                        cancelText="Não"
                        disabled={which.id === "" ? true : false}
                    >
                        <Button
                            onClick={() => setExcluirVisible(true)}
                            style={{ width: "100%" }}
                            disabled={which.id === "" ? true : false}
                        >
                            Excluir
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazendas: store.fazendas,
        culturaList: store.culturas,
        safraList: store.safras,
        tipoOpList: store.tiposOp,
        theme: store.theme,
        loader: store.loader,

        culturaCList: store.culturasCliente,
        safraCList: store.safrasCliente,
        tipoOpCList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            deletarCultura,
            getCulturaCliente,
            deletarSafra,
            getSafraCliente,
            deletarTipoOp,
            getTipoOpCliente,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ParametrosOpe);
