import { SET_FAZENDA, SET_LIST_FAZENDA, SET_TALHAO } from "./actionTypes";
import { IFazenda, IFazendaList, ITalhao } from "../reducers/FazendaReducer";
import {
    loginProcessTokenExpired,
    setStatusError,
    setStatusStart,
    setStatusSuccess,
} from "./actions";
import { message } from "antd";

export const setFazenda = (value: IFazenda) => ({
    type: SET_FAZENDA,
    payload: value,
});

export const setTalhao = (value: ITalhao) => ({
    type: SET_TALHAO,
    payload: value,
});

export const setListFazenda = (value: IFazendaList) => ({
    type: SET_LIST_FAZENDA,
    payload: value,
});

export const getFazendas = () => {
    return (dispatch: any) => {
        dispatch(setStatusStart("get", "fazenda"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/fazenda/`, {
            method: "get",
            headers: {
                "Content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "fazenda", res.erro));
                } else {
                    dispatch(setListFazenda(res));
                    dispatch(setStatusSuccess("get", "fazenda"));
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "fazenda", error.data));
            });
    };
};

interface IAddFazenda extends ReadableStream {
    nome: string;
    shape: string;
    campo: string;
    coordenadas: string;
    estilo: string;
    icone: string;
    fields: string;
    cidade: string;
}

export const adicionarFazenda = (value: IAddFazenda) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("add", "fazenda"));

        fetch(`${process.env.REACT_APP_BASE_URL_API}api/fazenda/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("get", "fazenda", res.erro));
                } else {
                    dispatch(getFazendas());
                    dispatch(setStatusSuccess("add", "fazenda"));
                    message.success("Fazenda adicionada com sucesso.");
                }
            })
            .catch(error => {
                dispatch(setStatusError("get", "fazenda", error.data));
            });
    };
};

export const deletarFazenda = (value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("del", "fazenda"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/fazenda/` + value, {
            method: "delete",
            headers: {
                "Content-Type": "text/plain",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("del", "fazenda", res.erro));
                } else {
                    dispatch(getFazendas());
                    dispatch(setStatusSuccess("del", "fazenda"));
                    message.success(
                        "A fazenda selecionada foi excluída com sucesso."
                    );
                }
            })
            .catch(error => {
                dispatch(setStatusError("del", "fazenda", error.data));
            });
    };
};

export const editarFazenda = (value: any) => {
    return (dispatch: any) => {
        dispatch(setStatusStart("edit", "fazenda"));
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/fazenda/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatch(loginProcessTokenExpired());
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    dispatch(setStatusError("edit", "fazenda", res.erro));
                } else {
                    dispatch(getFazendas());
                    dispatch(setStatusSuccess("edit", "fazenda"));
                    message.success(
                        "A fazenda selecionada foi editada com sucesso."
                    );
                }
            })
            .catch(error => {
                dispatch(setStatusError("edit", "fazenda", error.data));
            });
    };
};
